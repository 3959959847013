/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../LoginModal";
import RegistModal from "../RegistModal";
import { Link } from "react-router-dom";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoSvg from "../../assets/LogoSvg";
import GBTSLogoSvg from "../../assets/GBTSLogoSvg";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function TermsNav({ pages }) {
  let [isLogin, setIsLogin] = useState(false);
  let [activeMobileNav, setActiveMobileNav] = useState("");
  let [headerID, setHeaderID] = useState("");
  let [unreadCount, setUnreadCount] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (sessionStorage.length) {
      if (isLogin === false) setIsLogin(true);
    } else {
      if (isLogin === true) setIsLogin(false);
    }
  });

  useEffect(() => {
    if (location.pathname !== "/") {
      setHeaderID("plain");
    } else {
      setHeaderID("");
    }

    askNotificationPermission();
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "CommonNav",
      (b, n) => {
        if (n.ret) {
          setUserInfo({ ...n["returnData"][0] });
        }
      }
    );

    window.addEventListener("resize", resizeMobileNavHandler);

    return () => {
      window.removeEventListener("resize", resizeMobileNavHandler);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "CommonNav"
      );
    };
  }, []);

  const resizeMobileNavHandler = () => {
    if (window.innerWidth >= 992) {
      let layer = document.getElementById("layer");
      layer.className = "layer";
      setActiveMobileNav("");
    }
  };

  const mobileNavHandler = () => {
    let layer = document.getElementById("layer");
    if (activeMobileNav === "") {
      layer.className = "layer layer-is-visible";
      setActiveMobileNav("show");
    } else {
      layer.className = "layer";
      setActiveMobileNav("");
    }
  };

  const logout = (e) => {
    e.preventDefault();
    setUserInfo({});
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const notify = () => {
    setUnreadCount(unreadCount + 1);
    const options = {
      onOpen: (props) => console.log(props.foo),
      onClose: (props) => console.log(props.foo),
      onClick: (props) => console.log(props),
      autoClose: 6000,
    };
    const toastId = toast("Wow so easy!", options);
    notifyWindow();
    toast.update(toastId, {
      type: toast.TYPE.DEFAULT,
      render: () => {
        return (
          <>
            <div
              className="customized_notify alert alert-info"
              style={{
                display: "inline-block",
                margin: "0px auto",
              }}
            >
              <span data-notify="title">
                <a>
                  <h4>(주)지비티에스-버디블</h4>
                </a>
              </span>
              <a>
                <span data-notify="message">
                  <figure>
                    <img src="/img/notify_img.jpg" alt={"pic"} />
                  </figure>
                </span>
              </a>
              <p>
                <a>테스트메세지 입니다. </a>
              </p>
            </div>
          </>
        );
      },
    });
  };

  const notifyWindow = () => {
    let img = "/img/notify_img.jpg";
    let text = "테스트메세지 입니다.";
    let notification = new Notification("(주)지비티에스-버디블", {
      body: text,
      icon: img,
    });
    setTimeout(notification.close.bind(notification), 6000);
  };

  const askNotificationPermission = () => {
    // 권한을 실제로 요구하는 함수
    function handlePermission(permission) {
      // 사용자의 응답에 관계 없이 크롬이 정보를 저장할 수 있도록 함
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
    }

    // 브라우저가 알림을 지원하는지 확인
    if (!("Notification" in window)) {
      console.log("이 브라우저는 알림을 지원하지 않습니다.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const createdMenu = () => {
    let menus = [];
    for (let key in pages) {
      menus.push(
        <li className="submenu">
          <Link to={`/Terms/${pages[key].id}`}>{pages[key].title}</Link>
        </li>
      );
    }

    return menus;
  };

  return (
    <>
      <header id={headerID}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div id="top_line">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <i className="icon-phone" />
                <strong>032-751-9880</strong>
                {/*<div>
                  <button onClick={notify}>Notify!</button>
                </div>*/}
              </div>
              <div className="col-6">
                <ul id="top_links">
                  {isLogin ? (
                    <>
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            logout(e);
                          }}
                        >
                          <i className="icon-logout" /> 로그아웃
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a
                          data-toggle="modal"
                          data-target="#loginModal"
                          href="#"
                        >
                          <i className="icon-login" /> 로그인
                        </a>
                      </li>

                      <li>
                        <a
                          data-toggle="modal"
                          data-target="#registModal"
                          href="#"
                        >
                          <i className="icon-user-add-1" /> 회원가입
                        </a>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/Post/qa">
                      <i className="icon-attention-circled" />
                      {"  "}
                      도움말센터
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div id="logo" className="mt-2">
                <a href="/#mainTop">
                  <GBTSLogoSvg height="30px" />
                </a>
              </div>
            </div>

            <nav className="col">
              <a
                className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
                href="javascript:void(0);"
                onClick={() => {
                  mobileNavHandler();
                }}
              >
                <span>Menu mobile</span>
              </a>

              <div className={`main-menu ${activeMobileNav}`}>
                <div id="header_menu">
                  {headerID === "" ? (
                    <a href="/#mainTop">
                      <GBTSLogoSvg height="30px" />
                    </a>
                  ) : (
                    <Link to="/">
                      <GBTSLogoSvg height="30px" />
                    </Link>
                  )}
                </div>
                <a href="#" className="open_close" id="close_in">
                  <i
                    className="icon_set_1_icon-77"
                    onClick={() => {
                      mobileNavHandler();
                    }}
                  />
                </a>
                <ul>
                  {createdMenu()}
                  {activeMobileNav !== "" ? (
                    isLogin ? (
                      <>
                        <li>
                          <a
                            href="/"
                            onClick={(e) => {
                              logout(e);
                            }}
                          >
                            <i className="icon-logout" /> 로그아웃
                          </a>
                        </li>

                        <li>
                          <Link to="/Post/qa">
                            <i className="icon-attention-circled" />
                            {"  "}
                            도움말센터
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <a
                            data-toggle="modal"
                            data-target="#loginModal"
                            href="#"
                          >
                            <i className="icon-login" /> 로그인
                          </a>
                        </li>

                        <li>
                          <a
                            data-toggle="modal"
                            data-target="#registModal"
                            href="#"
                          >
                            <i className="icon-user-add-1" /> 회원가입
                          </a>
                        </li>

                        <li>
                          <Link to="/Post/qa">
                            <i className="icon-attention-circled" />
                            {"  "}
                            도움말센터
                          </Link>
                        </li>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </ul>
              </div>

              <ul id="top_tools">
                <li>
                  {/*<Link
                    to="/LogiTalk"
                    target="_blank"
                    className="search-overlay-menu-btn"
                  >
                    <a className="cart_bt">
                      <i className="icon-chat-empty" />
                      {unreadCount > 0 && <strong>{unreadCount}</strong>}
                    </a>
                  </Link>*/}
                </li>

                {userInfo.comp_cd &&
                  (userInfo.comp_cd.substr(0, 1) === "Z" ||
                    userInfo.comp_cd.substr(0, 1) === "B") && (
                    <li>
                      <a href="/ERP" className="search-overlay-menu-btn">
                        <i className="icon-user" />
                      </a>
                    </li>
                  )}
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <LoginModal setIsLogin={setIsLogin} />
      <RegistModal />
    </>
  );
}
