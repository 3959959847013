import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Oceanlook from "../../../lib/oceanlook";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const oceanlook = new Oceanlook();

export default function ScheduleReserveLink(props) {
  let randLink = util.buddibleDecrypt(useParams()["randLink"]);
  let sb_IDX = randLink;
  const [serverData, setServerData] = useState({});
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );

    requestScheduleMain((data) => {
      requestCFSAddr(data, () => {
        if (document.getElementById("one-vessel")) {
          oceanlook.embed("one-vessel", serverData.sb_VESSEL);
        }
      });
    });
  }, []);

  function requestScheduleMain(callback) {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_booking_select.php",
      msgID: msgID,
      data: {},
      isManager: "1",
      sb_IDX: sb_IDX,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"][0].sb_FILE) {
            setFiles(newData["returnData"][0].sb_FILE.split("|"));
          }
          callback && callback(newData["returnData"][0]);
        }
      }
    });
  }

  function requestCFSAddr(data, callback) {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/JS_customs_store_main_select.php",
      msgID: msgID,
      data: {},
      smKey: data.sb_CFS,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        setServerData({
          ...data,
          smName: data.sb_CFS ? newData["returnData"][0].smName : "",
        });
        callback && callback();
      }
    });
  }

  //첨부파일 다운로드
  function fileDownload(url, name) {
    codeList.Modal.current.confirm(
      "첨부된 파일을 다운로드하시겠습니까?",
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  }

  function openCFS() {
    let url = `https://www.gbts.co.kr/storeMall/${serverData.sb_CFS}`;
    let options =
      "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
    window.open(url, options, "_blank");
  }

  return (
    <>
      <div className="h-100">
        <div
          className="modal_body bg-white shadow rounded w-100 h-100 overflow_scroll-Y link-content"
          style={{
            maxWidth: 600,
            maxHeight: 1000,
          }}
        >
          <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
            <div className="modal-title">BOOK INFORMATION</div>
          </div>

          <div className="p-2" style={{ minWidth: 320 }}>
            <div className="row m-2">
              <div className="col-sm-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  FORWARDER NAME
                </div>
                <div className="p-2 text-center">{serverData.sb_FWD_NM}</div>
              </div>
              <div className="col-sm-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  BOOKING NO, H/BL No
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.sb_BOOK_NO ? serverData.sb_BOOK_NO : "-"}</p>
                  <p>{serverData.sb_USER_NM}</p>
                </div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  SCHEDULE NO
                </div>
                <div className="p-2 text-center">{serverData.sb_IDX_MAIN}</div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  FCL/LCL
                </div>
                <div className="p-2 text-center">{serverData.sb_FCL_LCL}</div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  VESSEL
                </div>
                <div className="p-2 text-center">{serverData.sb_VESSEL}</div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  VOY
                </div>
                <div className="p-2 text-center">{serverData.sb_VOY}</div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  DOC CLOSE
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.sb_DOC_CLOSE}</p>
                  <p>{serverData.sb_DOC_CLOSE_TIME}</p>
                </div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  CARGO CLOSE
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.sb_CARGO_CLOSE}</p>
                  <p>{serverData.sb_CARGO_CLOSE_TIME}</p>
                </div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  POL
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.sb_POL_NAME}</p>
                  <p>{serverData.sb_DEPARTURE_DATE}</p>
                </div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  POD
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.sb_POD_NAME}</p>
                  <p>{serverData.sb_ARRIVE_DATE}</p>
                </div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  SHIPPER
                </div>
                <div className="p-2 text-center">{serverData.sb_COMP_NM}</div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  PRODUCT
                </div>
                <div className="p-2 text-center">
                  {serverData.sb_PRODUCT_NAME}
                </div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  QTY
                </div>
                <div className="p-2 text-center">{serverData.sb_CNT}</div>
              </div>
              <div className="col-6 col-sm-3 border p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center border-bottom">
                  UNIT
                </div>
                <div className="p-2 text-center">
                  {serverData.sb_QUANTITIES}
                </div>
              </div>
            </div>

            <div className="row rounded m-2">
              <div className="col-12 col-sm-3 p-0">
                <div className="p-2 bg-info text-white font-weight-bold text-center d-flex h-100 justify-content-center align-items-center">
                  CFS
                </div>
              </div>
              <div className="col-md-5 border rounded-0 p-0">
                <div className="p-2 font-weight-bold text-center">
                  {serverData.sb_CFS &&
                    `${serverData.smName} (${serverData.sb_CFS})`}
                </div>
              </div>
              <div className="col-12 col-sm-1" />
              <div className="col-12 col-sm-3 border rounded p-0">
                <div
                  className="p-2 bg-success text-white font-weight-bold text-center cursor_pointer d-flex h-100 justify-content-center align-items-center"
                  onClick={() => {
                    if (serverData.sb_CFS) {
                      openCFS();
                    } else {
                      codeList.Modal.current.alert(
                        "CFS 정보가 없습니다.",
                        () => {}
                      );
                    }
                  }}
                >
                  CFS 상세정보
                </div>
              </div>
            </div>

            <div className="p-2">
              <p className="h6 font-weight-bold mb-2">■ BOOKING 요청 메모</p>
              <pre
                className="p-2 rounded"
                style={{
                  fontSize: "1em",
                  border: "1px solid #ccc",
                  fontFamily: "Roboto Helvetica Arial sans-serif",
                }}
              >
                {serverData.sb_MEMO}
              </pre>
            </div>

            {files && files.length > 0 && (
              <div className="p-2">
                <p className="h6 font-weight-bold mb-2">■ 첨부파일</p>
                <p className="mb-2">
                  - 파일명을 클릭시 브라우저를 통해 미리 확인이 가능합니다.
                </p>
                <div
                  className="mb-2"
                  style={{
                    maxWidth: "100vw",
                  }}
                >
                  {files.map((item, index) => {
                    let splitFile = item.split("?");
                    let fileName = splitFile[0];
                    let fileLink = splitFile[1];
                    return (
                      <p key={item + "_" + index} className="py-1">
                        <span
                          className="text-primary font-weight-bold cursor_pointer mr-2"
                          style={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          onClick={() => {
                            window.open(fileLink, "_blank");
                          }}
                        >
                          {fileName}
                        </span>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            fileDownload(fileLink, fileName);
                          }}
                        >
                          다운로드
                        </button>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="p-2">
              <p className="h6 font-weight-bold mb-2">■ 처리상태</p>
              <p style={{ fontSize: 15 }}>
                {
                  {
                    Y: "예약확정",
                    N: "예약요청",
                    B: "B/L 발행",
                  }[serverData.sb_STATUS]
                }
              </p>
            </div>

            {serverData.sb_REMARK && (
              <div className="p-2 py-3">
                <div className="h6 font-weight-bold mb-2">
                  <span>■ 주요안내사항</span>
                  <span
                    className="ml-5"
                    data-toggle="collapse"
                    type="button"
                    role="button"
                    href="#sb_REMARK"
                    aria-expanded="false"
                    aria-controls="sb_REMARK"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    {isOpen ? (
                      <i className="fa-solid fa-chevron-up" />
                    ) : (
                      <i className="fa-solid fa-chevron-down" />
                    )}
                  </span>
                </div>
                <div className="collapse" id="sb_REMARK">
                  <p style={{ fontSize: 15 }}>{serverData.sb_REMARK}</p>
                </div>
              </div>
            )}

            <div className="p-2 py-3">
              <p className="h6 font-weight-bold mb-2">■ 선박위치정보</p>
              <div
                id="one-vessel"
                className="w-100 height-400 rounded border overflow-hidden"
              />
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <p className="text-center">
              Copyright GBTS Corp. All Right Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
