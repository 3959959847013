import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Utilities from "../../../../lib/Utilities";

const util = new Utilities();

function ExpItemMG(props) {
  const { langMode, item, openExpModal, copyInfo } = props;
  const { t } = useTranslation();

  const titleClass =
    langMode === "wide"
      ? "col-4 m-0 p-0 pr-1 font-weight-bold"
      : "col-3 m-0 p-0 pr-2 font-weight-bold";

  let ship_status = "";
  let ship_status_bg = "";
  switch (item.SHIP_STATUS) {
    case "DI":
      ship_status = "Doc Issue";
      break;
    case "DT":
      ship_status = "Departured";
      break;
    case "IT":
      ship_status = "In Transit";
      ship_status_bg = "px-2 font-weight-bold bg-secondary text-white";
      break;
    case "TS":
      ship_status = "Transshipment";
      break;
    case "AP":
      ship_status = "Arrived Port";
      break;
    case "CS":
      ship_status = "Customs";
      break;
    case "DL":
      ship_status = "Delivered";
      ship_status_bg = "px-2 font-weight-bold bg-success text-white";
      break;
    default:
      ship_status = "";
      break;
  }

  return (
    <div className="mb-2 bg-white rounded">
      <div className="p-2 border-bottom border-width-1">
        <p>
          <span className="font-weight-bold">No.</span> {item.ROWINDEX}
        </p>
      </div>
      <div className="p-2">
        <div className="row m-0 p-1">
          <p className={titleClass}>B/L No.</p>
          <p className="col m-0 p-0 pl-1">{item.BLNO || "-"}</p>
        </div>

        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exp_buyFirm")}</p>
          <p className="col m-0 p-0 pl-1">{item.BUY_FIRM}</p>
        </div>

        <div className="row m-0 p-1">
          <p className={titleClass}>INV No.</p>
          <p className="col m-0 p-0 pl-1">{item.MG_CODE}</p>
        </div>

        <div className="row m-0 p-1">
          <p className={titleClass}>{t("forwarder")}</p>
          <p className="col m-0 p-0 pl-1">{item.FILE_NO || "-"}</p>
        </div>

        {item.SHIP_STATUS && (
          <div className="row m-0 p-1">
            <p className={titleClass}>{t("status")}</p>
            <p className="col m-0 p-0 pl-1">
              <span className={"d-inline-block  " + ship_status_bg}>
                {ship_status}
              </span>
            </p>
          </div>
        )}

        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exp_mg_modalProductNm")}</p>
          <p className="col m-0 p-0 pl-1">{item.EXC_GNM || "-"}</p>
        </div>

        <div className="row m-0 p-1">
          <p className={titleClass}>{t("shipPkg")}</p>
          <p className="col m-0 py-0 pl-1">
            {item.TOT_PACK_CNT ? util.addCommas(item.TOT_PACK_CNT) : "-"}
          </p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("shipWt")}(KG)</p>
          <p className="col-6 m-0 p-0 pl-1">
            {item.TOT_WT ? util.addCommas(item.TOT_WT) : "-"}
          </p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exp_ship")}</p>
          <p className="col-6 m-0 p-0 pl-1">
            {item.CUSTOM_SHIP ? item.CUSTOM_SHIP : "-"}
          </p>
        </div>
      </div>
      <div className="row m-0 p-1">
        <div className="col-6 m-0 p-1">
          <button
            className="border-0 w-100 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            onClick={(e) => openExpModal(e, "shipStatus", item)}
            data-toggle="modal"
            data-target="#ExpModal"
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-dolly" />
            </span>
            <span>{t("shipmentStatus")}</span>
          </button>
        </div>
        <div className="col-6 m-0 p-1">
          <button
            className="border-0 w-100 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            onClick={(e) => openExpModal(e, "file", item)}
            data-toggle="modal"
            data-target="#ExpModal"
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-paperclip" />
            </span>
            <span>{t("file")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(ExpItemMG);
