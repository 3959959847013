import React, { useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";

import ScheduleLogiWorkAIRTable from "./ScheduleLogiWorkAIRTable";
import { LocationAirSelectOption } from "../../../../lib/CommonUI";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mCLassName = "ScheduleLogiWorkAIR";

const shUUID = util.makeUUIDv4();
let rowsPerPage = 10;
let page = 0;
let pageSize = 0;
let totalCount = 0;
let totalPageCount = 0;
let filters = {
  sm_FCL_LCL: "AIR",
  sm_POL_CODE: "",
  sm_POD_CODE: "",
  sm_PROMO_YN: "",
  ORIGIN: "",
  DEST: "",
  SEARCH_DATE_START: "",
  SEARCH_DATE_END: "",
  sm_FWD_NO: "", //항공사
  sm_PROVIDER_CODE: "", //공급사
};

let modal_data = null;
let isSearch = false;
let isShareAccess = false;
let airline_list = [];
let supplier_list = [];

let schedule_list = [];
let today = new Date();
let currentMonth = today.getMonth() + 1;
if (currentMonth < 10) {
  currentMonth = `0${currentMonth}`;
}
currentMonth = currentMonth + "";

export default function ScheduleLogiWorkAIR({
  isPromo,
  setIsPromo,
  login_info,
  airportList,
  openCostPopup,
}) {
  const [isScheduleLoad, setIsScheduleLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isDirect, setIsDirect] = useState("Y");
  const [selectWeekDateList, setSelectWeekDateList] = useState({});
  const [service, setService] = useState([]);
  const controller = {
    sm_POL_CODE: useRef(),
    sm_POD_CODE: useRef(),
    sm_PROMO_YN: useRef(),
    SEARCH_DATE_START: useRef(),
    SEARCH_DATE_END: useRef(),
    sm_FWD_NO: useRef(), //항공사
    sm_PROVIDER_CODE: useRef(), //공급사
  };

  useEffect(() => {
    requestAirlineComp();
    makeWeekSelectOptions({ target: { value: currentMonth } });
  }, []);

  useEffect(() => {
    controller.sm_PROMO_YN.current.checked = !!isPromo;
    changedFilters({
      sm_PROMO_YN: isPromo,
    });
  }, [isPromo]);

  const requestAirlineComp = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/airline/JS_airline_select.php",
      msgID: msgID,
      data: {},
      sm_FCL_LCL: "AIR",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = newData["returnData"];
          airline_list = dataList.filter((data) => data.AL_COMP_TYPE === "A");
          supplier_list = dataList.filter((data) => data.AL_COMP_TYPE === "P");
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };
  const requestScheduleAir = () => {
    setIsScheduleLoad(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sm_DEPARTURE_DATE",
          dir: "asc",
        },
      ],
      sp_DISPLAY: 1,
      ...filters,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          schedule_list = newData["returnData"];
          pageSize = newData.pageSize;
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            schedule_list.length > 0
              ? parseInt(
                  parseInt(newData.pageSize, rowsPerPage) / rowsPerPage,
                  rowsPerPage
                ) +
                (parseInt(newData.pageSize, rowsPerPage) % rowsPerPage > 0
                  ? 1
                  : 0)
              : 0;
          setService(newData["returnData"]);
          setIsScheduleLoad(false);
        }
      }
    });
  };

  const changedFilters = (data) => {
    // if (isScheduleLoad) {
    //   codeList.Modal.current.alert("스케줄 조회중입니다.");
    //   return;
    // }
    page = 0;
    isSearch = true;
    filters = {
      ...filters,
      ...data,
    };

    if (data.hasOwnProperty("sm_PROMO_YN")) {
      requestScheduleAir();
    }
  };

  const resetFilters = () => {
    page = 0;
    isSearch = false;
    controller.sm_POL_CODE.current.clearAll();
    controller.sm_POD_CODE.current.clearAll();
    controller.sm_FWD_NO.current.clearAll();
    controller.sm_PROVIDER_CODE.current.clearAll();
    controller.sm_PROMO_YN.current.checked = false;
    changedFilters({
      sm_FCL_LCL: "AIR",
      sm_POL_CODE: "",
      sm_POD_CODE: "",
      sm_PROMO_YN: "",
      ORIGIN: "",
      DEST: "",
      SEARCH_DATE_START: "",
      SEARCH_DATE_END: "",
      sm_FWD_NO: "", //항공사
      sm_PROVIDER_CODE: "", //공급사});
    });
  };

  const changedPage = (val) => {
    page = val;
    document.querySelector(".freiscopplus").scrollTo(0, 0);
    requestScheduleAir();
  };

  const sendUseHistory = (type, isClick, data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_log_insert.php",
      msgID: msgID,
      data: {},
      shIDX: data.sm_IDX,
      shType: data.sm_FCL_LCL,
      shDate: data.sm_DOC_CLOSE || filters.sm_DOC_CLOSE,
      shORIGIN: data.sm_POL_CODE,
      shDEST: data.sm_POD_CODE,
      shClick: isClick,
      shClickFor: type,
      shUUID: shUUID,
      shMethod: window.location.href, //발생 URL
      ...data,
    };

    if (login_info && login_info.hasOwnProperty("user_id")) {
      socketMsg["shCOMPCD"] = login_info.comp_cd;
      socketMsg["shUSERID"] = login_info.user_id;
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  const handleSearchDate = (value) => {
    let splitValue = value.split(" : ")[1].split("|");
    let startDate = new Date(splitValue[0]);
    let endDate = new Date(splitValue[1]);

    let sYear = startDate.getFullYear();
    let sMonth = startDate.getMonth() + 1;
    let sDate = startDate.getDate();

    let sLastDate = new Date(sYear, sMonth, 0);

    let eMonth = endDate.getMonth() + 1;

    let days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    let dateList = {};
    let cnt = 0;
    for (let i = 0; i < 7; i++) {
      let dateTxt = "";

      let month = 0;
      let date = 0;
      if (sDate + i > sLastDate.getDate()) {
        ++cnt;
        month = eMonth;
        date = cnt;
      } else {
        month = sMonth;
        date = sDate + i;
      }

      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      const realDate = new Date(new Date().getFullYear(), month - 1, date);

      dateTxt = `[${month}/${date}]`;
      dateList[days[i]] = { dateTxt: dateTxt, realDate: realDate };
      //dateList[days[i]] = dateTxt;
    }
    filters = {
      ...filters,
      SEARCH_DATE_START: splitValue[0],
      SEARCH_DATE_END: splitValue[1],
    };

    requestScheduleAir();
    setSelectWeekDateList(dateList);
  };

  const makeWeekSelectOptions = (e) => {
    let year = today.getFullYear();
    let month = e.target.value;

    let sDate = new Date(year, month - 1, 1);
    let lastDay = new Date(
      sDate.getFullYear(),
      sDate.getMonth() + 1,
      0
    ).getDate();
    let endDate = new Date(sDate.getFullYear(), sDate.getMonth(), lastDay);

    let week = sDate.getDay();
    sDate.setDate(sDate.getDate() - week);
    let eDate = new Date(
      sDate.getFullYear(),
      sDate.getMonth(),
      sDate.getDate()
    );

    let obj = document.getElementById("sh_week");
    obj.options.length = 0;
    let seled = "";
    let weekCount = 1;

    while (endDate.getTime() >= eDate.getTime()) {
      let sYear = sDate.getFullYear();
      let sMonth = sDate.getMonth() + 1;
      let sDay = sDate.getDate();

      sMonth = sMonth < 10 ? "0" + sMonth : sMonth;
      sDay = sDay < 10 ? "0" + sDay : sDay;

      let stxt = weekCount + "주차 : " + sYear + "-" + sMonth + "-" + sDay;

      eDate.setDate(sDate.getDate() + 6);

      let eYear = eDate.getFullYear();
      let eMonth = eDate.getMonth() + 1;
      let eDay = eDate.getDate();

      eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
      eDay = eDay < 10 ? "0" + eDay : eDay;

      let etxt = eYear + "-" + eMonth + "-" + eDay;

      if (
        today.getTime() >= sDate.getTime() &&
        today.getTime() <= eDate.getTime()
      ) {
        seled = stxt + "|" + etxt;
      }

      obj.options[obj.options.length] = new Option(
        stxt + "~" + etxt,
        stxt + "|" + etxt
      );

      sDate = new Date(
        eDate.getFullYear(),
        eDate.getMonth(),
        eDate.getDate() + 1
      );
      eDate = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate());
      weekCount += 1;
    }

    if (seled) {
      obj.value = seled;
      handleSearchDate(seled);
    }
  };

  return (
    <>
      <div className="h-100">
        <div className="border border-width-3 border-freiscop bg-white">
          <div className="row m-0 p-0">
            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p className="font-size-09 font-weight-bold">주차</p>

              <div className="d-flex flex-row border-bottom">
                <select
                  className="border-0 py-1 bg-white"
                  defaultValue={currentMonth}
                  onChange={(e) => makeWeekSelectOptions(e)}
                >
                  <option value="">-월-</option>
                  <option value="01">1월</option>
                  <option value="02">2월</option>
                  <option value="03">3월</option>
                  <option value="04">4월</option>
                  <option value="05">5월</option>
                  <option value="06">6월</option>
                  <option value="07">7월</option>
                  <option value="08">8월</option>
                  <option value="09">9월</option>
                  <option value="10">10월</option>
                  <option value="11">11월</option>
                  <option value="12">12월</option>
                </select>
                <select
                  id="sh_week"
                  className="border-0 flex-fill py-1 bg-white"
                  style={{ minWidth: 225 }}
                  onChange={(e) => {
                    handleSearchDate(e.target.value);
                  }}
                >
                  <option value="">-월을 선택해주세요-</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p className="font-size-09 font-weight-bold">출발지</p>
              <Select
                className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                ref={controller.sm_POL_CODE}
                options={airportList}
                placeholder="출발지를 선택해주세요"
                onChange={(val) => {
                  let data = {
                    ORIGIN: val.length > 0 ? val[0]["CityName"] : "",
                    sm_POL_CODE: val.length > 0 ? val[0]["CustomCode"] : "",
                  };
                  changedFilters(data);
                }}
                {...LocationAirSelectOption}
              />
            </div>
            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p className="font-size-09 font-weight-bold">도착지</p>
              <Select
                className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                ref={controller.sm_POD_CODE}
                options={airportList}
                placeholder="도착지를 선택해주세요"
                onChange={(val) => {
                  let data = {
                    DEST: val.length > 0 ? val[0]["CityName"] : "",
                    sm_POD_CODE: val.length > 0 ? val[0]["CustomCode"] : "",
                  };
                  changedFilters(data);
                }}
                {...LocationAirSelectOption}
              />
            </div>
            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p className="font-size-09 font-weight-bold">항공사</p>
              <Select
                ref={controller.sm_FWD_NO}
                placeholder="업체를 선택해주세요"
                className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                multi
                clearable
                values={[]}
                options={airline_list}
                name={"AL_COMP_NM"}
                sortBy={"AL_COMP_NM"}
                searchBy={"AL_COMP_NM"}
                valueField={"AL_COMP_CD"}
                labelField={"AL_COMP_NM"}
                autoFocus={false}
                onChange={(value) => {
                  if (value.length < 1) {
                    changedFilters({ sm_FWD_NO: "" });
                    return;
                  }

                  let airlineKeys = [];
                  for (let i = 0; i < value.length; i++) {
                    let comp = value[i];
                    airlineKeys.push(comp.AL_COMP_CD);
                  }

                  airlineKeys = airlineKeys.join("|");
                  changedFilters({ sm_FWD_NO: airlineKeys });
                }}
                noDataRenderer={() => {
                  return (
                    <div className="px-2 py-1 font-weight-bold">
                      - 데이터 없음 -
                    </div>
                  );
                }}
                itemRenderer={({ item, itemIndex, props, state, methods }) => {
                  return (
                    <div key={itemIndex}>
                      <div
                        role="option"
                        aria-selected="false"
                        tabIndex="-1"
                        className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
                        onClick={() => {
                          methods.addItem(item);
                        }}
                      >
                        {item.AL_COMP_LOGO && (
                          <div
                            className="d-inline-block mr-2"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              background: `url(${item.AL_COMP_LOGO}) 50% 50% / contain no-repeat`,
                            }}
                          />
                        )}
                        <p className="d-inline-block">
                          <b>{item.AL_COMP_NM}</b>{" "}
                          <span className="font-size-08">
                            [{item.AL_COMP_NM_EN}]
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p className="font-size-09 font-weight-bold">공급사</p>
              <Select
                ref={controller.sm_PROVIDER_CODE}
                placeholder="업체를 선택해주세요"
                className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                multi
                clearable
                values={[]}
                options={supplier_list}
                name={"AL_COMP_NM"}
                sortBy={"AL_COMP_NM"}
                searchBy={"AL_COMP_NM"}
                valueField={"AL_COMP_CD"}
                labelField={"AL_COMP_NM"}
                autoFocus={false}
                onChange={(value) => {
                  if (value.length < 1) {
                    changedFilters({ sm_PROVIDER_CODE: "" });
                    return;
                  }

                  let airlineKeys = [];
                  for (let i = 0; i < value.length; i++) {
                    let comp = value[i];
                    airlineKeys.push(comp.AL_COMP_CD);
                  }

                  airlineKeys = airlineKeys.join("|");
                  changedFilters({ sm_PROVIDER_CODE: airlineKeys });
                }}
                noDataRenderer={() => {
                  return (
                    <div className="px-2 py-1 font-weight-bold">
                      - 데이터 없음 -
                    </div>
                  );
                }}
                itemRenderer={({ item, itemIndex, props, state, methods }) => {
                  return (
                    <div key={itemIndex}>
                      <div
                        role="option"
                        aria-selected="false"
                        tabIndex="-1"
                        className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
                        onClick={() => {
                          methods.addItem(item);
                        }}
                      >
                        {item.AL_COMP_LOGO && (
                          <div
                            className="d-inline-block mr-2"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              background: `url(${item.AL_COMP_LOGO}) 50% 50% / contain no-repeat`,
                            }}
                          />
                        )}
                        <p className="d-inline-block">
                          <b>{item.AL_COMP_NM}</b>{" "}
                          <span className="font-size-08">
                            [{item.AL_COMP_NM_EN}]
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                }}
              />
            </div>

            <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
              <p> </p>
              <div className="d-flex flex-row">
                <select
                  className="d-inline-block border-top-0 border-left-0 border-right-0 border-bottom bg-white py-1 mr-3"
                  onChange={(e) => {
                    setIsDirect(e.target.value);
                  }}
                >
                  <option value={"Y"}>직항</option>
                  <option value={"N"}>경유</option>
                </select>
                <label className="container_check m-0 mb-2 mb-lg-0 mr-lg-3 p-0">
                  <input
                    ref={controller.sm_PROMO_YN}
                    type="checkbox"
                    className="position-static"
                    defaultChecked={filters.sm_PROMO_YN === "Y"}
                    onChange={(e) => {
                      const value = e.target.checked ? "Y" : "";
                      changedFilters({
                        sm_PROMO_YN: value,
                      });
                      setIsPromo(value);
                    }}
                  />
                  <span className="checkmark" />
                  <span className="ml-3">프로모션 필터</span>
                </label>
              </div>
            </div>
            <div className="col-12 m-0 p-2 px-lg-3  d-flex flex-row justify-content-end align-items-end">
              <button
                className="btn_1 px-4 rounded-0 mr-2"
                onClick={requestScheduleAir}
              >
                검색
              </button>
              <button
                className={"btn_1 bg-secondary border-secondary rounded-0"}
                onClick={resetFilters}
              >
                검색조건 초기화
              </button>
            </div>
          </div>
        </div>

        <p className="h6 mt-3">
          총{" "}
          <span className="text-info font-weight-bold">
            {isDirect === "Y" ? util.addCommas(pageSize) : 0}건
          </span>
          의 스케줄이 검색되었습니다
        </p>

        {isScheduleLoad ? (
          <div className="bg-light rounded p-5 h6 text-center">
            <div className="d-flex justify-content-center">
              <div className="spinner-grow text-secondary" role="status" />
            </div>
          </div>
        ) : isDirect !== "N" && service.length > 0 ? (
          <ScheduleLogiWorkAIRTable
            login_info={login_info}
            airportList={airportList}
            schedule_list={schedule_list}
            selectWeekDateList={selectWeekDateList}
            page={page}
            totalPageCount={totalPageCount}
            openCostPopup={openCostPopup}
            changedPage={changedPage}
            sendUseHistory={sendUseHistory}
          />
        ) : (
          <div className="text-center bg-light rounded p-4 h6 font-weight-bold text-secondary">
            - 조건에 맞는 스케쥴정보가 존재하지 않습니다 -{" "}
          </div>
        )}
      </div>
    </>
  );
}
