import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

function makeSrc(id, options) {
  const availableModules = ["sidebar", "card", "popover", "notice"];
  const availableFields = [
    "mmsi",
    "imo",
    "vessel_name",
    "vessel_type",
    "callsign",
    "longitude",
    "latitude",
    "sog",
    "cog",
    "heading",
    "rot",
    "eta",
    "departure",
    "destination",
    "nav_status",
    "timestamp",
  ];
  let src =
    options?.profile === "development"
      ? "https://app-dev.oceanlook.net?"
      : "https://app.oceanlook.net?";
  if (id) {
    src += `id=${id}`;
  }
  let message = "";

  if (options) {
    const { modules, fields, mmsis, imos, style, cardPosition, cardStyle } =
      options;
    if (modules) {
      modules.forEach((module) => {
        if (!availableModules.includes(module)) {
          message += `Module [${module}] is not available.\n`;
        }
      });
    }

    if (fields) {
      fields.forEach((field) => {
        if (!availableFields.includes(field)) {
          message += `Field [${field}] is not available.\n`;
        }
      });
    }

    modules && (src += `&modules=${modules.join(",")}`);
    cardPosition && (src += `&card-position=${cardPosition}`);
    cardStyle && (src += `&card-style=${cardStyle}`);
    fields && (src += `&fields=${fields.join(",")}`);
    mmsis && (src += `&mmsis=${mmsis.join(",")}`);
    imos && (src += `&imos=${imos.join(",")}`);
    style && (src += `&style=${style}`);
  }

  return { src, message };
}

//오션룩 팝업 재정의
window.Oceanlook.popup = function popup(id, options) {
  const { src, message } = makeSrc(id, options);
  if (message) {
    alert(message);
  }

  let popupOptions =
    "popup=true,resizable=no,scrollbars=no,toolbar=no,location=no,directories=no,status=no,menubar=no";
  if (options) {
    const { width, height } = options;
    if (width && height) {
      popupOptions = `width=${width},height=${height}`;
    }
  }
  let popup = window.open(src, "Oceanlook", popupOptions);
  if (popup) {
    popup.focus(); //앞으로
  }
};

export default class Oceanlook {
  constructor(data) {
    if (Oceanlook.exists) {
      return Oceanlook.instance;
    }
    Oceanlook.instance = this;
    Oceanlook.exists = true;
    this.token = "9b5bfc8c-cf5b-4879-bee9-e4a70e0cfa51";
    this.embedDefaultOptions = {
      modules: ["card"], // 정보 표시 모듈: card
      cardStyle: 1, // 카드 스타일: 1
      fields: [
        "vessel_name",
        "mmsi",
        "imo",
        "departure",
        "destination",
        "eta",
        "nav_status",
        "timestamp",
      ], // 카드에 표시할 정보
      // profile: "development",
      //mmsis: [255806485],// 표시할 선박 MMSI
    };
    this.popupDefaultOptions = {
      modules: ["sidebar"], // 정보 표시 모듈: sidebar
      width: 1200, // 팝업창 너비
      height: 800, // 팝업창 높이
      // profile: "development",
      //mmsis: [255806485],
    };
    return this;
  }

  getIMO = (_shipName) => {
    const imo = ShipList.find((ship) => ship["SHIPNAME"] === _shipName);
    if (imo) {
      return imo["IMO"];
    }
    return "";
  };

  getMMSI = (_shipName) => {
    const mmsi = ShipList.find((ship) => ship["SHIPNAME"] === _shipName);
    if (mmsi) {
      return mmsi["MMSI"];
    }
    return "";
  };

  openPopup = (_shipName) => {
    const options = {
      ...this.popupDefaultOptions,
    };

    const imo = this.getIMO(_shipName);
    const _mmsi = this.getMMSI(_shipName);
    if (imo) {
      options.imos = [imo];
    } else {
      if (_mmsi) {
        options.mmsis = [_mmsi];
      }
    }

    window.Oceanlook.popup(this.token, options);
  };
  embed = (containerId, _shipName) => {
    if (!containerId) {
      alert("임베딩할 DOM 요소 ID를 파라미터로 전달해주세요.");
      return;
    }
    const options = {
      ...this.embedDefaultOptions,
    };

    const imo = this.getIMO(_shipName);
    const _mmsi = this.getMMSI(_shipName);
    if (imo) {
      options.imos = [imo];
    } else {
      if (_mmsi) {
        options.mmsis = [_mmsi];
      }
    }

    window.Oceanlook.embed(containerId, this.token, options);
  };
}

export const ShipList = [
  { MMSI: 200000000, IMO: 0, SHIPNAME: "NEGO AIUB" },
  { MMSI: 205178000, IMO: 9925825, SHIPNAME: "CMA CGM MASAI MARA" },
  { MMSI: 205795000, IMO: 9386524, SHIPNAME: "A LA MARINE" },
  { MMSI: 209087000, IMO: 9719874, SHIPNAME: "CAPE PIONEER" },
  { MMSI: 209135000, IMO: 9395604, SHIPNAME: "CONTSHIP WIN" },
  { MMSI: 209138000, IMO: 9449857, SHIPNAME: "WARNOW CHIEF" },
  { MMSI: 209177000, IMO: 9237371, SHIPNAME: "WEC VERMEER" },
  { MMSI: 209247000, IMO: 9803699, SHIPNAME: "BG SAPPHIRE" },
  { MMSI: 209248000, IMO: 9803704, SHIPNAME: "BG EMERALD" },
  { MMSI: 209251000, IMO: 9307229, SHIPNAME: "PUSAN C" },
  { MMSI: 209258000, IMO: 9040120, SHIPNAME: "DUBAI FORTUNE" },
  { MMSI: 209276000, IMO: 9504035, SHIPNAME: "ELBWAVE" },
  { MMSI: 209335000, IMO: 9235622, SHIPNAME: "CONTSHIP PRO" },
  { MMSI: 209345000, IMO: 9373905, SHIPNAME: "CONTSHIP NEW" },
  { MMSI: 209361000, IMO: 9436214, SHIPNAME: "SAMSKIP INNOVATOR" },
  { MMSI: 209370000, IMO: 9219252, SHIPNAME: "ST. MARY" },
  { MMSI: 209380000, IMO: 9436290, SHIPNAME: "SAMSKIP ENDEAVOUR" },
  { MMSI: 209437000, IMO: 9148790, SHIPNAME: "BLACK RHINO" },
  { MMSI: 209444000, IMO: 9507714, SHIPNAME: "CONTSHIP FOX" },
  { MMSI: 209459000, IMO: 9507702, SHIPNAME: "CONTSHIP ERA" },
  { MMSI: 209467000, IMO: 9483671, SHIPNAME: "X-PRESS AGILITY" },
  { MMSI: 209470000, IMO: 9247742, SHIPNAME: "MSC BOSPHORUS" },
  { MMSI: 209504000, IMO: 9517422, SHIPNAME: "CONTSHIP ICE" },
  { MMSI: 209539000, IMO: 9437256, SHIPNAME: "BF CARP" },
  { MMSI: 209540000, IMO: 9437232, SHIPNAME: "BF TROUT" },
  { MMSI: 209541000, IMO: 9437218, SHIPNAME: "BF PERCH" },
  { MMSI: 209543000, IMO: 9372274, SHIPNAME: "THETIS D" },
  { MMSI: 209550000, IMO: 9285990, SHIPNAME: "AMERICA" },
  { MMSI: 209559000, IMO: 9247730, SHIPNAME: "MSC APOLLO" },
  { MMSI: 209570000, IMO: 9359117, SHIPNAME: "ASIAN MOON" },
  { MMSI: 209575000, IMO: 9435521, SHIPNAME: "CONTSHIP PAX" },
  { MMSI: 209576000, IMO: 9612765, SHIPNAME: "NILEDUTCH BREDA" },
  { MMSI: 209591000, IMO: 9347982, SHIPNAME: "CONTSHIP TEN" },
  { MMSI: 209592000, IMO: 9434802, SHIPNAME: "CONTSHIP VIE" },
  { MMSI: 209593000, IMO: 9434797, SHIPNAME: "CONTSHIP ZOE" },
  { MMSI: 209594000, IMO: 9435533, SHIPNAME: "CONTSHIP WAY" },
  { MMSI: 209613000, IMO: 9266542, SHIPNAME: "BF LETICIA" },
  { MMSI: 209615000, IMO: 9247766, SHIPNAME: "ATHENS GLORY" },
  { MMSI: 209616000, IMO: 9612791, SHIPNAME: "NILEDUTCH ANTWERPEN" },
  { MMSI: 209659000, IMO: 9814014, SHIPNAME: "CONTAINERSHIPSAURORA" },
  { MMSI: 209692000, IMO: 9664263, SHIPNAME: "CONTSHIP YEN" },
  { MMSI: 209695000, IMO: 9822736, SHIPNAME: "NORDMAAS" },
  { MMSI: 209696000, IMO: 9322542, SHIPNAME: "RANGER" },
  { MMSI: 209697000, IMO: 9403451, SHIPNAME: "CONTSHIP LEO" },
  { MMSI: 209699000, IMO: 9406934, SHIPNAME: "CONTSHIP LUV" },
  { MMSI: 209700000, IMO: 9134139, SHIPNAME: "DUBAI ALLIANCE" },
  { MMSI: 209715000, IMO: 9234991, SHIPNAME: "ELBSKIPPER" },
  { MMSI: 209716000, IMO: 9262704, SHIPNAME: "MSC AQUARIUS" },
  { MMSI: 209717000, IMO: 9818400, SHIPNAME: "CONTAINERSHIPSARCTIC" },
  { MMSI: 209719000, IMO: 9355446, SHIPNAME: "BG IRELAND" },
  { MMSI: 209762000, IMO: 9252773, SHIPNAME: "NCL SALTEN" },
  { MMSI: 209795000, IMO: 9450595, SHIPNAME: "SYNERGY KEELUNG" },
  { MMSI: 209798000, IMO: 9450583, SHIPNAME: "SYNERGY OAKLAND" },
  { MMSI: 209836000, IMO: 9395575, SHIPNAME: "CT ROTTERDAM" },
  { MMSI: 209849000, IMO: 9311749, SHIPNAME: "GFS PRIME" },
  { MMSI: 209850000, IMO: 9355422, SHIPNAME: "ANDROMEDA J" },
  { MMSI: 209862000, IMO: 9395032, SHIPNAME: "WARNOW WHALE" },
  { MMSI: 209888000, IMO: 9848730, SHIPNAME: "CAPE ALTIUS" },
  { MMSI: 209912000, IMO: 9261889, SHIPNAME: "CMACGM FORT ST LOUIS" },
  { MMSI: 209920000, IMO: 9299630, SHIPNAME: "CMA CGM NABUCCO" },
  { MMSI: 209979000, IMO: 9307243, SHIPNAME: "LE HAVRE" },
  { MMSI: 210001000, IMO: 9162681, SHIPNAME: "ALASA" },
  { MMSI: 210008000, IMO: 9328027, SHIPNAME: "JORK RULER" },
  { MMSI: 210017000, IMO: 9483334, SHIPNAME: "ELBWIND" },
  { MMSI: 210028000, IMO: 9866249, SHIPNAME: "CONTSHIPS BOREALIS" },
  { MMSI: 210057000, IMO: 9266530, SHIPNAME: "FALMOUTH" },
  { MMSI: 210065000, IMO: 9333369, SHIPNAME: "QUEEN B III" },
  { MMSI: 210070000, IMO: 9848742, SHIPNAME: "CAPE CITIUS" },
  { MMSI: 210072000, IMO: 9365984, SHIPNAME: "WEC VAN EYCK" },
  { MMSI: 210101000, IMO: 9483346, SHIPNAME: "EINSTEIN" },
  { MMSI: 210142000, IMO: 9320051, SHIPNAME: "GFS PEARL" },
  { MMSI: 210159000, IMO: 9395070, SHIPNAME: "WARNOW DOLPHIN" },
  { MMSI: 210167000, IMO: 9349215, SHIPNAME: "ELBSPRINTER" },
  { MMSI: 210195000, IMO: 9866251, SHIPNAME: "CONTSHIPS STELLAR" },
  { MMSI: 210210000, IMO: 9857432, SHIPNAME: "CAPE CORFU" },
  { MMSI: 210281000, IMO: 9315006, SHIPNAME: "WEC VAN RIJN" },
  { MMSI: 210283000, IMO: 9315018, SHIPNAME: "WEC DE HOOGH" },
  { MMSI: 210296000, IMO: 9430090, SHIPNAME: "QUEEN B II" },
  { MMSI: 210308000, IMO: 9857444, SHIPNAME: "CAPE HELLAS" },
  { MMSI: 210312000, IMO: 9134153, SHIPNAME: "WEC MAJORELLE" },
  { MMSI: 210332000, IMO: 9432232, SHIPNAME: "GERDA" },
  { MMSI: 210359000, IMO: 9504073, SHIPNAME: "ELBWATER" },
  { MMSI: 210361000, IMO: 9449699, SHIPNAME: "CONTSHIP EVE" },
  { MMSI: 210367000, IMO: 9319595, SHIPNAME: "CONTSHIP PEP" },
  { MMSI: 210403000, IMO: 9696084, SHIPNAME: "CAPE ARAXOS" },
  { MMSI: 210440000, IMO: 9403449, SHIPNAME: "CONTSHIP SKY" },
  { MMSI: 210497000, IMO: 9676204, SHIPNAME: "CAPE SYROS" },
  { MMSI: 210514000, IMO: 9664275, SHIPNAME: "CONTSHIP ART" },
  { MMSI: 210565000, IMO: 9245689, SHIPNAME: "ASIAN TRADER" },
  { MMSI: 210568000, IMO: 9395563, SHIPNAME: "ELBRUNNER" },
  { MMSI: 210663000, IMO: 9625918, SHIPNAME: "SAN GIORGIO" },
  { MMSI: 210696000, IMO: 9121871, SHIPNAME: "DUBAI ENTERPRISE" },
  { MMSI: 210731000, IMO: 9318773, SHIPNAME: "MSC ELKE F" },
  { MMSI: 210740000, IMO: 9347750, SHIPNAME: "LENA" },
  { MMSI: 210749000, IMO: 9406922, SHIPNAME: "PACIFIC TRADER" },
  { MMSI: 210804000, IMO: 9626235, SHIPNAME: "NORDLION" },
  { MMSI: 210905000, IMO: 9395123, SHIPNAME: "ARSOS" },
  { MMSI: 210950000, IMO: 9395044, SHIPNAME: "VARAMO" },
  { MMSI: 210989000, IMO: 9204506, SHIPNAME: "IONIA" },
  { MMSI: 211003531, IMO: 1111111, SHIPNAME: "QUERIN TEST 2" },
  { MMSI: 211108000, IMO: 9708784, SHIPNAME: "BRUSSELS EXPRESS" },
  { MMSI: 211111000, IMO: 9508902, SHIPNAME: "RIODEJANEIRO EXPRESS" },
  { MMSI: 211112000, IMO: 9520041, SHIPNAME: "MONTEVIDEO EXPRESS" },
  { MMSI: 211119000, IMO: 9540118, SHIPNAME: "BERLIN EXPRESS" },
  { MMSI: 211120000, IMO: 9540132, SHIPNAME: "HANOI EXPRESS" },
  { MMSI: 211141000, IMO: 9297591, SHIPNAME: "BIANCA RAMBOW" },
  { MMSI: 211235880, IMO: 9141118, SHIPNAME: "WEGA" },
  { MMSI: 211256150, IMO: 9336189, SHIPNAME: "CHIQUITA FARMER" },
  { MMSI: 211281220, IMO: 9336191, SHIPNAME: "INDEPENDENT SPIRIT" },
  { MMSI: 211286440, IMO: 9188506, SHIPNAME: "HANNI" },
  { MMSI: 211315100, IMO: 9188518, SHIPNAME: "CONTAINERSHIPS 6" },
  { MMSI: 211549000, IMO: 9501344, SHIPNAME: "BASLE EXPRESS" },
  { MMSI: 211553000, IMO: 9327578, SHIPNAME: "BENEDIKT" },
  { MMSI: 211578000, IMO: 9613020, SHIPNAME: "ULSAN EXPRESS" },
  { MMSI: 211779000, IMO: 9252565, SHIPNAME: "NORTHERN MAJESTIC" },
  { MMSI: 211839000, IMO: 9295268, SHIPNAME: "CHICAGO EXPRESS" },
  { MMSI: 211845000, IMO: 9336244, SHIPNAME: "CONTAINERSHIPS VIII" },
  { MMSI: 211870240, IMO: 9250098, SHIPNAME: "NOVA" },
  { MMSI: 211882920, IMO: 9708851, SHIPNAME: "BARZAN" },
  { MMSI: 211882930, IMO: 9732333, SHIPNAME: "UMM QARN" },
  { MMSI: 211886490, IMO: 9732357, SHIPNAME: "AL JMELIYAH" },
  { MMSI: 211886500, IMO: 9708875, SHIPNAME: "AL ZUBARA" },
  { MMSI: 211886810, IMO: 9708849, SHIPNAME: "AL NASRIYAH" },
  { MMSI: 211887090, IMO: 9708801, SHIPNAME: "LINAH" },
  { MMSI: 211887410, IMO: 9932476, SHIPNAME: "BUENOS AIRES EXPRESS" },
  { MMSI: 211888820, IMO: 9732345, SHIPNAME: "AFIF" },
  { MMSI: 211895580, IMO: 9708825, SHIPNAME: "AL DAHNA EXPRESS" },
  { MMSI: 211897760, IMO: 9708863, SHIPNAME: "AL MURAYKH" },
  { MMSI: 212006000, IMO: 9359260, SHIPNAME: "JUDITH" },
  { MMSI: 212008000, IMO: 9398773, SHIPNAME: "ELBWINTER" },
  { MMSI: 212175000, IMO: 9285988, SHIPNAME: "EUROPE" },
  { MMSI: 212267000, IMO: 9371402, SHIPNAME: "CONTSHIP IVY" },
  { MMSI: 212276000, IMO: 9360910, SHIPNAME: "SEATTLE C" },
  { MMSI: 212283000, IMO: 9626247, SHIPNAME: "NORDTIGER" },
  { MMSI: 212289000, IMO: 9626259, SHIPNAME: "NORDPUMA" },
  { MMSI: 212347000, IMO: 9305673, SHIPNAME: "HYUNDAI TOKYO" },
  { MMSI: 212348000, IMO: 9305661, SHIPNAME: "HYUNDAI HONGKONG" },
  { MMSI: 212350000, IMO: 9305659, SHIPNAME: "HYUNDAI BUSAN" },
  { MMSI: 212351000, IMO: 9305685, SHIPNAME: "HYUNDAI SINGAPORE" },
  { MMSI: 212352000, IMO: 9334349, SHIPNAME: "MSC LILOU III" },
  { MMSI: 212353000, IMO: 9305647, SHIPNAME: "HYUNDAI SHANGHAI" },
  { MMSI: 212357000, IMO: 9322011, SHIPNAME: "MSC YOSHEEN" },
  { MMSI: 212417000, IMO: 9673666, SHIPNAME: "VIOLETA B" },
  { MMSI: 212433000, IMO: 9626261, SHIPNAME: "NORDLEOPARD" },
  { MMSI: 212463000, IMO: 9676199, SHIPNAME: "CAPE MONTEREY" },
  { MMSI: 212531000, IMO: 9673654, SHIPNAME: "NORDPANTHER" },
  { MMSI: 212540000, IMO: 9734147, SHIPNAME: "CAPE FORTIUS" },
  { MMSI: 212557000, IMO: 9360257, SHIPNAME: "PROTOSTAR N" },
  { MMSI: 212563000, IMO: 9360269, SHIPNAME: "MARINA ONE" },
  { MMSI: 212587000, IMO: 9734159, SHIPNAME: "CAPE QUEST" },
  { MMSI: 212597000, IMO: 9107394, SHIPNAME: "THEA II" },
  { MMSI: 212613000, IMO: 9376036, SHIPNAME: "RUTH" },
  { MMSI: 212636000, IMO: 9376048, SHIPNAME: "HANNA" },
  { MMSI: 212637000, IMO: 9803675, SHIPNAME: "BG DIAMOND" },
  { MMSI: 212641000, IMO: 9246566, SHIPNAME: "WEC VAN GOGH" },
  { MMSI: 212659000, IMO: 9724958, SHIPNAME: "NORDAMELIA" },
  { MMSI: 212714000, IMO: 9363364, SHIPNAME: "VANCOUVER" },
  { MMSI: 212723000, IMO: 9130432, SHIPNAME: "BF FORTALEZA" },
  { MMSI: 212724000, IMO: 9625293, SHIPNAME: "SAN LORENZO" },
  { MMSI: 212748000, IMO: 9313199, SHIPNAME: "BALTIC TERN" },
  { MMSI: 212752000, IMO: 9803687, SHIPNAME: "BG JADE" },
  { MMSI: 212777000, IMO: 9376050, SHIPNAME: "WILHELM" },
  { MMSI: 212814000, IMO: 9802487, SHIPNAME: "NORDPACIFIC" },
  { MMSI: 212851000, IMO: 9202259, SHIPNAME: "DUBAI VENTURE" },
  { MMSI: 212906000, IMO: 9313228, SHIPNAME: "BALTIC SHEARWATER" },
  { MMSI: 212917000, IMO: 9395082, SHIPNAME: "FOUMA" },
  { MMSI: 212931000, IMO: 9388522, SHIPNAME: "ELBFEEDER" },
  { MMSI: 212935000, IMO: 9388510, SHIPNAME: "ELBCARRIER" },
  { MMSI: 212960000, IMO: 9318929, SHIPNAME: "QUEEN B" },
  { MMSI: 212962000, IMO: 9696072, SHIPNAME: "CAPE ORIENT" },
  { MMSI: 212963000, IMO: 9504061, SHIPNAME: "WES GESA" },
  { MMSI: 215020000, IMO: 9437139, SHIPNAME: "MICHIGAN" },
  { MMSI: 215125000, IMO: 9780859, SHIPNAME: "CMA CGM A. LINCOLN" },
  { MMSI: 215126000, IMO: 9780861, SHIPNAME: "CMA CGM T.JEFFERSON" },
  { MMSI: 215127000, IMO: 9449819, SHIPNAME: "CMA CGM CENDRILLON" },
  { MMSI: 215131000, IMO: 9410806, SHIPNAME: "CMA CGM LYRA" },
  { MMSI: 215134000, IMO: 9410791, SHIPNAME: "CMA CGM GEMINI" },
  { MMSI: 215141000, IMO: 9350393, SHIPNAME: "CMA CGM CORAL" },
  { MMSI: 215146000, IMO: 9360154, SHIPNAME: "CMA CGM ARISTOTE" },
  { MMSI: 215148000, IMO: 9350381, SHIPNAME: "CMA CGM AMBER" },
  { MMSI: 215154000, IMO: 9454400, SHIPNAME: "CMA CGM CORTE REAL" },
  { MMSI: 215157000, IMO: 9362437, SHIPNAME: "CMA CGM PLATON" },
  { MMSI: 215158000, IMO: 9454424, SHIPNAME: "CMA CGM MAGELLAN" },
  { MMSI: 215159000, IMO: 9331000, SHIPNAME: "CMA CGM SWORDFISH" },
  { MMSI: 215164000, IMO: 9410727, SHIPNAME: "CMA CGM ANDROMEDA" },
  { MMSI: 215165000, IMO: 9351139, SHIPNAME: "CMA CGM VIRGINIA" },
  { MMSI: 215167000, IMO: 9334167, SHIPNAME: "ANL WANGARATTA" },
  { MMSI: 215173000, IMO: 9706889, SHIPNAME: "CMACGM VASCO DE GAMA" },
  { MMSI: 215175000, IMO: 9317963, SHIPNAME: "CMA CGM BLUE WHALE" },
  { MMSI: 215176000, IMO: 9300659, SHIPNAME: "GABRIEL A" },
  { MMSI: 215177000, IMO: 9337377, SHIPNAME: "MARIO A" },
  { MMSI: 215179000, IMO: 9226504, SHIPNAME: "TEOMAN A" },
  { MMSI: 215181000, IMO: 9780885, SHIPNAME: "CMA CGM J. ADAMS" },
  { MMSI: 215183000, IMO: 9351141, SHIPNAME: "CMA CGM NEW JERSEY" },
  { MMSI: 215185000, IMO: 9409194, SHIPNAME: "CMA CGM LAMARTINE" },
  { MMSI: 215187000, IMO: 9331012, SHIPNAME: "CMA CGM TARPON" },
  { MMSI: 215189000, IMO: 9348704, SHIPNAME: "CMA CGM FLORIDA" },
  { MMSI: 215192000, IMO: 9410753, SHIPNAME: "CMA CGM CALLISTO" },
  { MMSI: 215195000, IMO: 9706891, SHIPNAME: "CMA CGM B FRANKLIN" },
  { MMSI: 215196000, IMO: 9410765, SHIPNAME: "CMA CGM CASSIOPEIA" },
  { MMSI: 215197000, IMO: 9780847, SHIPNAME: "CMA CGM G.WASHINGTON" },
  { MMSI: 215199000, IMO: 9706906, SHIPNAME: "CMA CGM ZHENG HE" },
  { MMSI: 215206000, IMO: 9334155, SHIPNAME: "ANL WYONG" },
  { MMSI: 215207000, IMO: 9702132, SHIPNAME: "CMA CGM KERGUELEN" },
  { MMSI: 215215000, IMO: 9409209, SHIPNAME: "CMA CGM MAUPASSANT" },
  { MMSI: 215217000, IMO: 9410741, SHIPNAME: "CMA CGM AQUILA" },
  { MMSI: 215219000, IMO: 9454448, SHIPNAME: "CMA CGM VON HUMBOLDT" },
  { MMSI: 215221000, IMO: 9702144, SHIPNAME: "CMACGM GEORG FORSTER" },
  { MMSI: 215225000, IMO: 9410777, SHIPNAME: "CMA CGM CENTAURUS" },
  { MMSI: 215230000, IMO: 9317975, SHIPNAME: "CMA CGM WHITE SHARK" },
  { MMSI: 215240000, IMO: 9351127, SHIPNAME: "CMA CGM GEORGIA" },
  { MMSI: 215249000, IMO: 9410789, SHIPNAME: "CMA CGM COLUMBA" },
  { MMSI: 215251000, IMO: 9780873, SHIPNAME: "CMA CGM T.ROOSEVELT" },
  { MMSI: 215293000, IMO: 9450612, SHIPNAME: "CMA CGM LA SCALA" },
  { MMSI: 215331000, IMO: 9839909, SHIPNAME: "CMA CGM ARGENTINA" },
  { MMSI: 215334000, IMO: 9261918, SHIPNAME: "CC FORT ST GEORGES" },
  { MMSI: 215342000, IMO: 9261891, SHIPNAME: "CMACGMFORT ST PIERR" },
  { MMSI: 215346000, IMO: 9839935, SHIPNAME: "CMA CGM CHILE" },
  { MMSI: 215347000, IMO: 9839923, SHIPNAME: "CMA CGM PANAMA" },
  { MMSI: 215352000, IMO: 9860245, SHIPNAME: "CMA CGM BRAZIL" },
  { MMSI: 215354000, IMO: 9839911, SHIPNAME: "CMA CGM MEXICO" },
  { MMSI: 215381000, IMO: 9443059, SHIPNAME: "EXPRESS BLACK SEA" },
  { MMSI: 215396000, IMO: 9261906, SHIPNAME: "CMACGM FORT ST MARIE" },
  { MMSI: 215413000, IMO: 9242285, SHIPNAME: "AURETTE A" },
  { MMSI: 215416000, IMO: 9305893, SHIPNAME: "MARGUERITE A" },
  { MMSI: 215417000, IMO: 9379349, SHIPNAME: "MICHEL A" },
  { MMSI: 215419000, IMO: 9337365, SHIPNAME: "CRISTINA A" },
  { MMSI: 215421000, IMO: 9226516, SHIPNAME: "CINZIA A" },
  { MMSI: 215485000, IMO: 9433793, SHIPNAME: "CMA CGM ATTILA" },
  { MMSI: 215558000, IMO: 9461893, SHIPNAME: "APL QINGDAO" },
  { MMSI: 215560000, IMO: 9632040, SHIPNAME: "APL SENTOSA" },
  { MMSI: 215561000, IMO: 9430765, SHIPNAME: "VIRGO" },
  { MMSI: 215577000, IMO: 9469572, SHIPNAME: "CMA CGM ALASKA" },
  { MMSI: 215648000, IMO: 9295969, SHIPNAME: "CMA CGM SAMBHAR" },
  { MMSI: 215650000, IMO: 9326770, SHIPNAME: "CMA CGM JAMAICA" },
  { MMSI: 215651000, IMO: 9295971, SHIPNAME: "CMA CGM AMERICA" },
  { MMSI: 215657000, IMO: 9356294, SHIPNAME: "CMA CGM THALASSA" },
  { MMSI: 215748000, IMO: 9836672, SHIPNAME: "CNC JUPITER" },
  { MMSI: 215761000, IMO: 9334387, SHIPNAME: "PORT GDYNIA" },
  { MMSI: 215765000, IMO: 9859117, SHIPNAME: "CMA CGM TENERE" },
  { MMSI: 215839000, IMO: 9859129, SHIPNAME: "CMA CGM SCANDOLA" },
  { MMSI: 215842000, IMO: 9836684, SHIPNAME: "CNC SATURN" },
  { MMSI: 215857000, IMO: 9702156, SHIPNAME: "CMACGM BOUGAINVILLE" },
  { MMSI: 215878000, IMO: 9280615, SHIPNAME: "CMA CGM MOZART" },
  { MMSI: 215879000, IMO: 9299812, SHIPNAME: "CMA CGM NORMA" },
  { MMSI: 215880000, IMO: 9280639, SHIPNAME: "CMA CGM ROSSINI" },
  { MMSI: 215891000, IMO: 9302956, SHIPNAME: "CMA CGM BEIRA" },
  { MMSI: 215922000, IMO: 9694543, SHIPNAME: "CMA CGM KRIBI" },
  { MMSI: 215930000, IMO: 9454412, SHIPNAME: "CMA CGM LAPEROUSE" },
  { MMSI: 215965000, IMO: 9453559, SHIPNAME: "CMA CGM C COLOMB" },
  { MMSI: 215966000, IMO: 9859131, SHIPNAME: "CMA CGM IGUACU" },
  { MMSI: 215992000, IMO: 9454395, SHIPNAME: "CMA CGM AMERIGO VESP" },
  { MMSI: 218000250, IMO: 9708813, SHIPNAME: "AL NEFUD" },
  { MMSI: 218006290, IMO: 9540120, SHIPNAME: "MANILA EXPRESS" },
  { MMSI: 218008040, IMO: 9736107, SHIPNAME: "TIHAMA" },
  { MMSI: 218044000, IMO: 9354430, SHIPNAME: "HENNEKE RAMBOW" },
  { MMSI: 218098000, IMO: 9354478, SHIPNAME: "IDA RAMBOW" },
  { MMSI: 218284000, IMO: 9343730, SHIPNAME: "KUALA LUMPUR EXPRESS" },
  { MMSI: 218361000, IMO: 9450399, SHIPNAME: "PRAGUE EXPRESS" },
  { MMSI: 218364000, IMO: 9450442, SHIPNAME: "FRANKFURT EXPRESS" },
  { MMSI: 218366000, IMO: 9450404, SHIPNAME: "SOFIA EXPRESS" },
  { MMSI: 218400000, IMO: 9461403, SHIPNAME: "MSC LA SPEZIA" },
  { MMSI: 218426000, IMO: 9501356, SHIPNAME: "HONG KONG EXPRESS" },
  { MMSI: 218427000, IMO: 9501368, SHIPNAME: "SHANGHAI EXPRESS" },
  { MMSI: 218441000, IMO: 9461427, SHIPNAME: "MSC LIVORNO" },
  { MMSI: 218474000, IMO: 9501370, SHIPNAME: "ESSEN EXPRESS" },
  { MMSI: 218565000, IMO: 9613006, SHIPNAME: "LEVERKUSEN EXPRESS" },
  { MMSI: 218566000, IMO: 9613018, SHIPNAME: "LUDWIGSHAFEN EXPRESS" },
  { MMSI: 218627000, IMO: 9432220, SHIPNAME: "VERA RAMBOW" },
  { MMSI: 218643000, IMO: 9290567, SHIPNAME: "ZIM HONG KONG" },
  { MMSI: 218650000, IMO: 9290555, SHIPNAME: "ZIM NEWARK" },
  { MMSI: 218774000, IMO: 9461051, SHIPNAME: "DORTMUND EXPRESS" },
  { MMSI: 218776000, IMO: 9501332, SHIPNAME: "NEW YORK EXPRESS" },
  { MMSI: 218791000, IMO: 9612997, SHIPNAME: "ANTWERPEN EXPRESS" },
  { MMSI: 218816000, IMO: 9333345, SHIPNAME: "BEATE" },
  { MMSI: 218819000, IMO: 9461386, SHIPNAME: "MSC GENOVA" },
  { MMSI: 218833000, IMO: 9777589, SHIPNAME: "VALPARAISO EXPRESS" },
  { MMSI: 218834000, IMO: 9349887, SHIPNAME: "POSEN" },
  { MMSI: 218839000, IMO: 9777606, SHIPNAME: "CALLAO EXPRESS" },
  { MMSI: 218844000, IMO: 9365805, SHIPNAME: "CMA CGM IVANHOE" },
  { MMSI: 218845000, IMO: 9364992, SHIPNAME: "CMA CGM ORFEO" },
  { MMSI: 218847000, IMO: 9480203, SHIPNAME: "MSC CADIZ" },
  { MMSI: 218850000, IMO: 9777618, SHIPNAME: "CARTAGENA EXPRESS" },
  { MMSI: 218851000, IMO: 9777620, SHIPNAME: "GUAYAQUIL EXPRESS" },
  { MMSI: 218853000, IMO: 9480227, SHIPNAME: "MSC VIGO" },
  { MMSI: 218854000, IMO: 9777632, SHIPNAME: "SANTOS EXPRESS" },
  { MMSI: 219018271, IMO: 9619907, SHIPNAME: "MAERSKMCKINNEYMOLLER" },
  { MMSI: 219018501, IMO: 9619919, SHIPNAME: "MAJESTIC MAERSK" },
  { MMSI: 219018692, IMO: 9619921, SHIPNAME: "MARY MAERSK" },
  { MMSI: 219018765, IMO: 9619933, SHIPNAME: "MARIE MAERSK" },
  { MMSI: 219018864, IMO: 9619945, SHIPNAME: "MADISON MAERSK" },
  { MMSI: 219018986, IMO: 9619957, SHIPNAME: "MAGLEBY MAERSK" },
  { MMSI: 219019094, IMO: 9619969, SHIPNAME: "MARIBO MAERSK" },
  { MMSI: 219019139, IMO: 9619971, SHIPNAME: "MARSTAL MAERSK" },
  { MMSI: 219019365, IMO: 9619983, SHIPNAME: "MATZ MAERSK" },
  { MMSI: 219021000, IMO: 9632117, SHIPNAME: "MUNKEBO MAERSK" },
  { MMSI: 219027000, IMO: 9456771, SHIPNAME: "MAERSK EINDHOVEN" },
  { MMSI: 219027748, IMO: 9885130, SHIPNAME: "NAKSKOV MAERSK" },
  { MMSI: 219027758, IMO: 9885166, SHIPNAME: "NIMTOFTE MAERSK" },
  { MMSI: 219027759, IMO: 9885154, SHIPNAME: "NORDAGER MAERSK" },
  { MMSI: 219027760, IMO: 9885142, SHIPNAME: "NORDBORG MAERSK" },
  { MMSI: 219027761, IMO: 9885178, SHIPNAME: "NUUK MAERSK" },
  { MMSI: 219027854, IMO: 9854636, SHIPNAME: "SIUANA ARCTICA" },
  { MMSI: 219027908, IMO: 9854648, SHIPNAME: "MALERAQ ARCTICA" },
  { MMSI: 219031000, IMO: 9458092, SHIPNAME: "MAERSK ESSEX" },
  { MMSI: 219031418, IMO: 9214903, SHIPNAME: "CAROLINE MAERSK" },
  { MMSI: 219031419, IMO: 9214898, SHIPNAME: "A.P. MOLLER" },
  { MMSI: 219031426, IMO: 9219795, SHIPNAME: "CARSTEN MAERSK" },
  { MMSI: 219031427, IMO: 9198587, SHIPNAME: "CORNELIUS MAERSK" },
  { MMSI: 219032189, IMO: 9950117, SHIPNAME: "CAPE SCOTT" },
  { MMSI: 219032229, IMO: 9950129, SHIPNAME: "CAPE SPENCER" },
  { MMSI: 219032707, IMO: 9969857, SHIPNAME: "CAPE SKAGEN" },
  { MMSI: 219033000, IMO: 9456757, SHIPNAME: "MAERSK EDINBURGH" },
  { MMSI: 219034000, IMO: 9780445, SHIPNAME: "MANCHESTER MAERSK" },
  { MMSI: 219036000, IMO: 9780457, SHIPNAME: "MURCIA MAERSK" },
  { MMSI: 219038000, IMO: 9780469, SHIPNAME: "MANILA MAERSK" },
  { MMSI: 219039000, IMO: 9780471, SHIPNAME: "MUMBAI MAERSK" },
  { MMSI: 219045000, IMO: 9780483, SHIPNAME: "MAASTRICHT MAERSK" },
  { MMSI: 219053000, IMO: 9775749, SHIPNAME: "VOLGA MAERSK" },
  { MMSI: 219056000, IMO: 9456769, SHIPNAME: "MAERSK EMDEN" },
  { MMSI: 219068000, IMO: 9430399, SHIPNAME: "SANTA BARBARA" },
  { MMSI: 219069000, IMO: 9444845, SHIPNAME: "SANTA INES" },
  { MMSI: 219071000, IMO: 9430387, SHIPNAME: "SANTA URSULA" },
  { MMSI: 219072000, IMO: 9444730, SHIPNAME: "SANTA CATARINA" },
  { MMSI: 219075000, IMO: 9444716, SHIPNAME: "SANTA CLARA" },
  { MMSI: 219077000, IMO: 9444728, SHIPNAME: "SANTA ISABEL" },
  { MMSI: 219080000, IMO: 9444742, SHIPNAME: "SANTA CRUZ" },
  { MMSI: 219085000, IMO: 9425382, SHIPNAME: "SANTA RITA" },
  { MMSI: 219086000, IMO: 9430363, SHIPNAME: "SANTA ROSA" },
  { MMSI: 219088000, IMO: 9430375, SHIPNAME: "SANTA TERESA" },
  { MMSI: 219095000, IMO: 9622241, SHIPNAME: "SAN ANTONIO MAERSK" },
  { MMSI: 219096000, IMO: 9622227, SHIPNAME: "SAN LORENZO MAERSK" },
  { MMSI: 219100000, IMO: 9622239, SHIPNAME: "SAN AUGUSTIN MAERSK" },
  { MMSI: 219101000, IMO: 9622215, SHIPNAME: "SAN MARCO MAERSK" },
  { MMSI: 219102000, IMO: 9622203, SHIPNAME: "SAN NICOLAS MAERSK" },
  { MMSI: 219107000, IMO: 9622253, SHIPNAME: "SAN RAPHAEL MAERSK" },
  { MMSI: 219110000, IMO: 9775751, SHIPNAME: "VAYENGA MAERSK" },
  { MMSI: 219115000, IMO: 9775763, SHIPNAME: "VENTA MAERSK" },
  { MMSI: 219117000, IMO: 9313955, SHIPNAME: "MAERSK BROWNSVILLE" },
  { MMSI: 219126000, IMO: 9456991, SHIPNAME: "RHINE MAERSK" },
  { MMSI: 219130000, IMO: 9457000, SHIPNAME: "RHONE MAERSK" },
  { MMSI: 219133000, IMO: 9775775, SHIPNAME: "VUOKSI MAERSK" },
  { MMSI: 219136000, IMO: 9778533, SHIPNAME: "VILNIA MAERSK" },
  { MMSI: 219155000, IMO: 9458080, SHIPNAME: "MAERSK EVORA" },
  { MMSI: 219170000, IMO: 9778545, SHIPNAME: "VAGA MAERSK" },
  { MMSI: 219196000, IMO: 9313917, SHIPNAME: "MAERSK BALTIMORE" },
  { MMSI: 219198000, IMO: 9458078, SHIPNAME: "MAERSK ELBA" },
  { MMSI: 219199000, IMO: 9458030, SHIPNAME: "MAERSK EDMONTON" },
  { MMSI: 219204000, IMO: 9313929, SHIPNAME: "MAERSK BENTONVILLE" },
  { MMSI: 219210000, IMO: 9456783, SHIPNAME: "MAERSK ESSEN" },
  { MMSI: 219215000, IMO: 9313931, SHIPNAME: "MAERSK BROOKLYN" },
  { MMSI: 219216000, IMO: 9313905, SHIPNAME: "MAERSK BOSTON" },
  { MMSI: 219223000, IMO: 9356127, SHIPNAME: "MAERSK NEWPORT" },
  { MMSI: 219225000, IMO: 9356139, SHIPNAME: "MAERSK NORFOLK" },
  { MMSI: 219304000, IMO: 9822865, SHIPNAME: "TUKUMA ARCTICA" },
  { MMSI: 219324000, IMO: 0, SHIPNAME: "SALLY MAERSK" },
  { MMSI: 219470000, IMO: 9164237, SHIPNAME: "ALEXANDER MAERSK" },
  { MMSI: 219505000, IMO: 9526899, SHIPNAME: "MAERSK LA PAZ" },
  { MMSI: 219506000, IMO: 9527025, SHIPNAME: "MAERSK LINS" },
  { MMSI: 219508000, IMO: 9526954, SHIPNAME: "MAERSK LOTA" },
  { MMSI: 219509000, IMO: 9526930, SHIPNAME: "MAERSK LEBU" },
  { MMSI: 219510000, IMO: 9526904, SHIPNAME: "MAERSK LUZ" },
  { MMSI: 219515000, IMO: 9526942, SHIPNAME: "MAERSK LAGUNA" },
  { MMSI: 219516000, IMO: 9526966, SHIPNAME: "MAERSK LEON" },
  { MMSI: 219526000, IMO: 9526875, SHIPNAME: "MAERSK LIMA" },
  { MMSI: 219527000, IMO: 9527051, SHIPNAME: "MAERSK LAMANAI" },
  { MMSI: 219530000, IMO: 9527049, SHIPNAME: "MAERSK LANCO" },
  { MMSI: 219532000, IMO: 9526887, SHIPNAME: "MAERSK LIRQUEN" },
  { MMSI: 219533000, IMO: 9526916, SHIPNAME: "MAERSK LETICIA" },
  { MMSI: 219534000, IMO: 9526928, SHIPNAME: "MAERSK LAVRAS" },
  { MMSI: 219538000, IMO: 9527063, SHIPNAME: "MAERSK LABREA" },
  { MMSI: 219540000, IMO: 9527037, SHIPNAME: "MAERSK LONDRINA" },
  { MMSI: 219542000, IMO: 9526978, SHIPNAME: "MAERSK LABERINTO" },
  { MMSI: 219543000, IMO: 9944546, SHIPNAME: "LAURA MAERSK" },
  { MMSI: 219578000, IMO: 9619995, SHIPNAME: "MAYVIEW MAERSK" },
  { MMSI: 219581000, IMO: 9632064, SHIPNAME: "MERETE MAERSK" },
  { MMSI: 219609000, IMO: 9632105, SHIPNAME: "MORTEN MAERSK" },
  { MMSI: 219612000, IMO: 9100255, SHIPNAME: "IRENA ARCTICA" },
  { MMSI: 219620000, IMO: 9632129, SHIPNAME: "MAREN MAERSK" },
  { MMSI: 219629000, IMO: 9632131, SHIPNAME: "MARGRETHE MAERSK" },
  { MMSI: 219630000, IMO: 9632143, SHIPNAME: "MARCHEN MAERSK" },
  { MMSI: 219631000, IMO: 9632155, SHIPNAME: "METTE MAERSK" },
  { MMSI: 219632000, IMO: 9632167, SHIPNAME: "MARIT MAERSK" },
  { MMSI: 219646000, IMO: 9632179, SHIPNAME: "MATHILDE MAERSK" },
  { MMSI: 219668000, IMO: 9618135, SHIPNAME: "MALIK ARCTICA" },
  { MMSI: 219678000, IMO: 9775737, SHIPNAME: "VISTULA MAERSK" },
  { MMSI: 219821000, IMO: 9166792, SHIPNAME: "SKAGEN MAERSK" },
  { MMSI: 219835000, IMO: 9778806, SHIPNAME: "MUNICH MAERSK" },
  { MMSI: 219836000, IMO: 9778791, SHIPNAME: "MADRID MAERSK" },
  { MMSI: 219840000, IMO: 9198575, SHIPNAME: "CLIFFORD MAERSK" },
  { MMSI: 219854000, IMO: 9778844, SHIPNAME: "MARSEILLE MAERSK" },
  { MMSI: 219861000, IMO: 9778820, SHIPNAME: "MILAN MAERSK" },
  { MMSI: 219863000, IMO: 9778832, SHIPNAME: "MONACO MAERSK" },
  { MMSI: 219864000, IMO: 9778818, SHIPNAME: "MOSCOW MAERSK" },
  { MMSI: 219953000, IMO: 9215165, SHIPNAME: "JEPPESEN MAERSK" },
  { MMSI: 219974000, IMO: 9215177, SHIPNAME: "JENS MAERSK" },
  { MMSI: 219982000, IMO: 9215189, SHIPNAME: "JOHANNES MAERSK" },
  { MMSI: 220008000, IMO: 9215191, SHIPNAME: "JOSEPHINE MAERSK" },
  { MMSI: 220010000, IMO: 9632090, SHIPNAME: "MOGENS MAERSK" },
  { MMSI: 220059000, IMO: 9245744, SHIPNAME: "CHARLOTTE MAERSK" },
  { MMSI: 220061000, IMO: 9245756, SHIPNAME: "CORNELIA MAERSK" },
  { MMSI: 220129000, IMO: 9245768, SHIPNAME: "COLUMBINE MAERSK" },
  { MMSI: 220164000, IMO: 9245770, SHIPNAME: "CLEMENTINE MAERSK" },
  { MMSI: 220198000, IMO: 9251614, SHIPNAME: "OLGA MAERSK" },
  { MMSI: 220207000, IMO: 9251626, SHIPNAME: "OLUF MAERSK" },
  { MMSI: 220216000, IMO: 9251638, SHIPNAME: "OLIVIA MAERSK" },
  { MMSI: 220291000, IMO: 9294379, SHIPNAME: "LARS MAERSK" },
  { MMSI: 220379000, IMO: 9302877, SHIPNAME: "GUDRUN MAERSK" },
  { MMSI: 220397000, IMO: 9302889, SHIPNAME: "GRETE MAERSK" },
  { MMSI: 220413000, IMO: 9302891, SHIPNAME: "GUNVOR MAERSK" },
  { MMSI: 220414000, IMO: 9320233, SHIPNAME: "GJERTRUD MAERSK" },
  { MMSI: 220415000, IMO: 9320245, SHIPNAME: "GERD MAERSK" },
  { MMSI: 220416000, IMO: 9320257, SHIPNAME: "GEORG MAERSK" },
  { MMSI: 220417000, IMO: 9321483, SHIPNAME: "EMMA MAERSK" },
  { MMSI: 220477000, IMO: 9321500, SHIPNAME: "ELEONORA MAERSK" },
  { MMSI: 220478000, IMO: 9321495, SHIPNAME: "ESTELLE MAERSK" },
  { MMSI: 220496000, IMO: 9321512, SHIPNAME: "EVELYN MAERSK" },
  { MMSI: 220497000, IMO: 9321524, SHIPNAME: "EBBA MAERSK" },
  { MMSI: 220499000, IMO: 9321536, SHIPNAME: "ELLY MAERSK" },
  { MMSI: 220501000, IMO: 9321548, SHIPNAME: "EDITH MAERSK" },
  { MMSI: 220503000, IMO: 9321550, SHIPNAME: "EUGEN MAERSK" },
  { MMSI: 220596000, IMO: 9359038, SHIPNAME: "GUSTAV MAERSK" },
  { MMSI: 220598000, IMO: 9359052, SHIPNAME: "GERDA MAERSK" },
  { MMSI: 226324000, IMO: 9362334, SHIPNAME: "MARFRET GUYANE" },
  { MMSI: 228038360, IMO: 9839210, SHIPNAME: "CMA CGM SORBONNE" },
  { MMSI: 228335900, IMO: 9299783, SHIPNAME: "CMA CGM TOSCA" },
  { MMSI: 228337900, IMO: 9450624, SHIPNAME: "CMA CGM DALILA" },
  { MMSI: 228339600, IMO: 9450648, SHIPNAME: "CMA CGM ALMAVIVA" },
  { MMSI: 228340900, IMO: 9299795, SHIPNAME: "CMA CGM LA TRAVIATA" },
  { MMSI: 228342900, IMO: 9299800, SHIPNAME: "CMA CGM MEDEA" },
  { MMSI: 228353600, IMO: 9299628, SHIPNAME: "CMA CGM OTELLO" },
  { MMSI: 228354600, IMO: 9299654, SHIPNAME: "CMA CGM RIGOLETTO" },
  { MMSI: 228362900, IMO: 9809825, SHIPNAME: "CMA CGM FORTDEFRANCE" },
  { MMSI: 228367600, IMO: 9809837, SHIPNAME: "CMA CGM FORT ROYAL" },
  { MMSI: 228368600, IMO: 9809849, SHIPNAME: "CCFORT SAINT CHARLES" },
  { MMSI: 228368900, IMO: 9450636, SHIPNAME: "CMA CGM TITUS" },
  { MMSI: 228369800, IMO: 9709219, SHIPNAME: "CMA CGM ST. LAURENT" },
  { MMSI: 228373600, IMO: 9809851, SHIPNAME: "CC FORT FLEUR D EPEE" },
  { MMSI: 228386700, IMO: 9839179, SHIPNAME: "CMACGM JACQUES SAADE" },
  { MMSI: 228386800, IMO: 9839131, SHIPNAME: "CC CHAMPS ELYSEES" },
  { MMSI: 228394600, IMO: 9839181, SHIPNAME: "CMA CGM PALAIS ROYAL" },
  { MMSI: 228394900, IMO: 9839143, SHIPNAME: "CMA CGM LOUVRE" },
  { MMSI: 228395600, IMO: 9845661, SHIPNAME: "DOUCE FRANCE" },
  { MMSI: 228397600, IMO: 9839193, SHIPNAME: "CMA CGM RIVOLI" },
  { MMSI: 228397700, IMO: 9839155, SHIPNAME: "CMA CGM MONTMARTRE" },
  { MMSI: 228401800, IMO: 9839208, SHIPNAME: "CMA CGM CONCORDE" },
  { MMSI: 228402900, IMO: 9839167, SHIPNAME: "CMA CGM TROCADERO" },
  { MMSI: 228403700, IMO: 9845673, SHIPNAME: "CMA CGM SINNAMARY" },
  { MMSI: 228403800, IMO: 9845659, SHIPNAME: "CMA CGM KOUROU" },
  { MMSI: 228409800, IMO: 9894961, SHIPNAME: "CMA CGM PATAGONIA" },
  { MMSI: 228409900, IMO: 9894973, SHIPNAME: "CMA CGM KIMBERLEY" },
  { MMSI: 228410600, IMO: 9894985, SHIPNAME: "CMA CGM EVERGLADE" },
  { MMSI: 228410700, IMO: 9894997, SHIPNAME: "CMA CGM GALAPAGOS" },
  { MMSI: 228410800, IMO: 9895006, SHIPNAME: "CMA CGM GREENLAND" },
  { MMSI: 228451700, IMO: 9802504, SHIPNAME: "MARIUS" },
  { MMSI: 229054000, IMO: 9389409, SHIPNAME: "CMA CGM DUTCH HARBOR" },
  { MMSI: 229075000, IMO: 9431757, SHIPNAME: "CMA CGM CAIMEP" },
  { MMSI: 229111000, IMO: 9415947, SHIPNAME: "WANDA A" },
  { MMSI: 229115000, IMO: 9376892, SHIPNAME: "CMA CGM NOUADHIBOU" },
  { MMSI: 229142000, IMO: 9385532, SHIPNAME: "DIANE A" },
  { MMSI: 229190000, IMO: 9454450, SHIPNAME: "CMA CGM JULES VERNE" },
  { MMSI: 229191000, IMO: 9353735, SHIPNAME: "X-PRESS GODAVARI" },
  { MMSI: 229247000, IMO: 9208356, SHIPNAME: "LION" },
  { MMSI: 229263000, IMO: 9301469, SHIPNAME: "ETOILE" },
  { MMSI: 229270000, IMO: 9776418, SHIPNAME: "CMA CGM ST EXUPERY" },
  { MMSI: 229282000, IMO: 9348431, SHIPNAME: "CMA CGM SAVANNAH" },
  { MMSI: 229290000, IMO: 9348443, SHIPNAME: "CMA CGM MONTOIR" },
  { MMSI: 229321000, IMO: 9882487, SHIPNAME: "CMA CGM ZEPHYR" },
  { MMSI: 229326000, IMO: 9897755, SHIPNAME: "CMA CGM HOPE" },
  { MMSI: 229331000, IMO: 9318101, SHIPNAME: "CMA CGM DON PASCUALE" },
  { MMSI: 229334000, IMO: 9706279, SHIPNAME: "CNC BANGKOK" },
  { MMSI: 229338000, IMO: 9625906, SHIPNAME: "TZINI" },
  { MMSI: 229340000, IMO: 9301433, SHIPNAME: "CMA CGM MALTA" },
  { MMSI: 229358000, IMO: 9532771, SHIPNAME: "APL TURKEY" },
  { MMSI: 229375000, IMO: 9400174, SHIPNAME: "FORT DESAIX" },
  { MMSI: 229384000, IMO: 9320398, SHIPNAME: "MSC LAUSANNE" },
  { MMSI: 229389000, IMO: 9897767, SHIPNAME: "CMA CGM UNITY" },
  { MMSI: 229391000, IMO: 9321902, SHIPNAME: "CMA CGM ISKENDERUN" },
  { MMSI: 229393000, IMO: 9867827, SHIPNAME: "CMA CGM BALI" },
  { MMSI: 229394000, IMO: 9694555, SHIPNAME: "CMA CGM LEKKI" },
  { MMSI: 229452000, IMO: 9620607, SHIPNAME: "MERKUR FJORD" },
  { MMSI: 229454000, IMO: 9882499, SHIPNAME: "CMA CGM HERMES" },
  { MMSI: 229457000, IMO: 9354923, SHIPNAME: "CMA CGM VELA" },
  { MMSI: 229458000, IMO: 9628154, SHIPNAME: "VALOR" },
  { MMSI: 229463000, IMO: 9897779, SHIPNAME: "CMA CGM DIGNITY" },
  { MMSI: 229464000, IMO: 9897781, SHIPNAME: "CMA CGM INTEGRITY" },
  { MMSI: 229465000, IMO: 9897793, SHIPNAME: "CMA CGM LIBERTY" },
  { MMSI: 229467000, IMO: 9628166, SHIPNAME: "VALUE" },
  { MMSI: 229474000, IMO: 9632820, SHIPNAME: "SEASMILE" },
  { MMSI: 229480000, IMO: 9357547, SHIPNAME: "CMA CGM SAIGON" },
  { MMSI: 229488000, IMO: 9280603, SHIPNAME: "CMA CGM CHOPIN" },
  { MMSI: 229489000, IMO: 9280627, SHIPNAME: "CMA CGM PUCCINI" },
  { MMSI: 229528000, IMO: 9344655, SHIPNAME: "CMA CGM BALBOA" },
  { MMSI: 229541000, IMO: 9628178, SHIPNAME: "VALIANT" },
  { MMSI: 229548000, IMO: 9628180, SHIPNAME: "VALENCE" },
  { MMSI: 229550000, IMO: 9632832, SHIPNAME: "SEADREAM" },
  { MMSI: 229556000, IMO: 9477799, SHIPNAME: "MEGALOPOLIS" },
  { MMSI: 229557000, IMO: 9477804, SHIPNAME: "MARATHOPOLIS" },
  { MMSI: 229572000, IMO: 9620619, SHIPNAME: "MERKUR OCEAN" },
  { MMSI: 229573000, IMO: 9323039, SHIPNAME: "CMA CGM ALIAGA" },
  { MMSI: 229584000, IMO: 9625920, SHIPNAME: "SEAMASTER" },
  { MMSI: 229592000, IMO: 9882504, SHIPNAME: "CMA CGM OSIRIS" },
  { MMSI: 229598000, IMO: 9628192, SHIPNAME: "VANTAGE" },
  { MMSI: 229606000, IMO: 9440772, SHIPNAME: "ANL WARRNAMBOOL" },
  { MMSI: 229607000, IMO: 9239850, SHIPNAME: "APL SAIPAN" },
  { MMSI: 229620000, IMO: 9605255, SHIPNAME: "MSC AZOV" },
  { MMSI: 229621000, IMO: 9404613, SHIPNAME: "SOFRANA TOURVILLE" },
  { MMSI: 229622000, IMO: 9484479, SHIPNAME: "MSC TRIESTE" },
  { MMSI: 229625000, IMO: 9605267, SHIPNAME: "MSC AJACCIO" },
  { MMSI: 229626000, IMO: 9605279, SHIPNAME: "MSC AMALFI" },
  { MMSI: 229630000, IMO: 9365960, SHIPNAME: "X-PRESS MULHACEN" },
  { MMSI: 229633000, IMO: 9395068, SHIPNAME: "CMA CGM CALLAO" },
  { MMSI: 229635000, IMO: 9246683, SHIPNAME: "CMA CGM PARANAGUA" },
  { MMSI: 229639000, IMO: 9471408, SHIPNAME: "CMA CGM NEVADA" },
  { MMSI: 229646000, IMO: 9226425, SHIPNAME: "ARTOTINA" },
  { MMSI: 229648000, IMO: 9618587, SHIPNAME: "LEONIDIO" },
  { MMSI: 229649000, IMO: 9618599, SHIPNAME: "KYPARISSIA" },
  { MMSI: 229657000, IMO: 9625281, SHIPNAME: "SAN PEDRO" },
  { MMSI: 229660000, IMO: 9408372, SHIPNAME: "SOFRANA SURVILLE" },
  { MMSI: 229665000, IMO: 9210074, SHIPNAME: "DIMITRIS C" },
  { MMSI: 229680000, IMO: 9395094, SHIPNAME: "CMA CGM ARICA" },
  { MMSI: 229684000, IMO: 9294185, SHIPNAME: "CMA CGM VALPARAISO" },
  { MMSI: 229685000, IMO: 9882516, SHIPNAME: "CMA CGM APOLLON" },
  { MMSI: 229699000, IMO: 9315953, SHIPNAME: "CMA CGM SYDNEY" },
  { MMSI: 229705000, IMO: 9322463, SHIPNAME: "CMA CGM MARLIN" },
  { MMSI: 229726000, IMO: 9674517, SHIPNAME: "APL DANUBE" },
  { MMSI: 229734000, IMO: 9294173, SHIPNAME: "CMA CGM SAN ANTONIO" },
  { MMSI: 229736000, IMO: 9450600, SHIPNAME: "CMA CGM FIGARO" },
  { MMSI: 229742000, IMO: 9784661, SHIPNAME: "CNC LION" },
  { MMSI: 229744000, IMO: 9784659, SHIPNAME: "CNC TIGER" },
  { MMSI: 229747000, IMO: 9709192, SHIPNAME: "CMA CGM CAYENNE" },
  { MMSI: 229749000, IMO: 9709207, SHIPNAME: "CMA CGM MARSEILLE" },
  { MMSI: 229750000, IMO: 9367815, SHIPNAME: "CMA CGM MOMBASA" },
  { MMSI: 229752000, IMO: 9882528, SHIPNAME: "CMA CGM ADONIS" },
  { MMSI: 229753000, IMO: 9477787, SHIPNAME: "CMA CGM PERTH" },
  { MMSI: 229756000, IMO: 9377133, SHIPNAME: "CMA CGM SEMARANG" },
  { MMSI: 229778000, IMO: 9867841, SHIPNAME: "CMA CGM ARCTIC" },
  { MMSI: 229779000, IMO: 9867839, SHIPNAME: "CMA CGM SYMI" },
  { MMSI: 229786000, IMO: 9491628, SHIPNAME: "CMA CGM MAPUTO" },
  { MMSI: 229808000, IMO: 9299642, SHIPNAME: "CMA CGM FIDELIO" },
  { MMSI: 229819000, IMO: 9596313, SHIPNAME: "CMA CGM FUZHOU" },
  { MMSI: 229820000, IMO: 9443463, SHIPNAME: "CMA CGM LEBU" },
  { MMSI: 229821000, IMO: 9337597, SHIPNAME: "GALANI" },
  { MMSI: 229824000, IMO: 9306483, SHIPNAME: "CMA CGM ALEXANDRIA" },
  { MMSI: 229831000, IMO: 9362736, SHIPNAME: "CMA CGM CEBU" },
  { MMSI: 229842000, IMO: 9322475, SHIPNAME: "CMA CGM BARRACUDA" },
  { MMSI: 229846000, IMO: 9363417, SHIPNAME: "CMA CGM MONTREAL" },
  { MMSI: 229865000, IMO: 9347293, SHIPNAME: "CMA CGM DAVAO" },
  { MMSI: 229877000, IMO: 9635652, SHIPNAME: "CMA CGM VOLTAIRE" },
  { MMSI: 229914000, IMO: 9367839, SHIPNAME: "CMA CGM QUELIMANE" },
  { MMSI: 229916000, IMO: 9349629, SHIPNAME: "CMA CGM NANTONG" },
  { MMSI: 229917000, IMO: 9367827, SHIPNAME: "CMA CGM ZANZIBAR" },
  { MMSI: 229921000, IMO: 9322487, SHIPNAME: "CMA CGM DOLPHIN" },
  { MMSI: 229934000, IMO: 9674529, SHIPNAME: "CMA CGM ELBE" },
  { MMSI: 229937000, IMO: 9673642, SHIPNAME: "NORDOCELOT" },
  { MMSI: 229945000, IMO: 9293753, SHIPNAME: "CONTI CORTESIA" },
  { MMSI: 229947000, IMO: 9674531, SHIPNAME: "CMA CGM LOIRE" },
  { MMSI: 229955000, IMO: 9290098, SHIPNAME: "CMA CGM SEATTLE" },
  { MMSI: 229956000, IMO: 9374442, SHIPNAME: "CMA CGM PEMBA" },
  { MMSI: 229966000, IMO: 9673630, SHIPNAME: "JOSITA B" },
  { MMSI: 229978000, IMO: 9330513, SHIPNAME: "SATIE" },
  { MMSI: 229988000, IMO: 9674555, SHIPNAME: "CMA CGM TAGE" },
  { MMSI: 229989000, IMO: 9674543, SHIPNAME: "CMA CGM RHONE" },
  { MMSI: 229993000, IMO: 9634658, SHIPNAME: "SANTA LOUKIA" },
  { MMSI: 229997000, IMO: 9924429, SHIPNAME: "CMA CGM PRIDE" },
  { MMSI: 230984000, IMO: 9354325, SHIPNAME: "LINDA" },
  { MMSI: 230985000, IMO: 9354337, SHIPNAME: "AILA" },
  { MMSI: 231123000, IMO: 9433456, SHIPNAME: "SELFOSS" },
  { MMSI: 231355000, IMO: 9306005, SHIPNAME: "ARNARFELL" },
  { MMSI: 231356000, IMO: 9306017, SHIPNAME: "HELGAFELL" },
  { MMSI: 231789000, IMO: 9641314, SHIPNAME: "LAGARFOSS" },
  { MMSI: 231836000, IMO: 9822841, SHIPNAME: "BRUARFOSS" },
  { MMSI: 231860000, IMO: 9822853, SHIPNAME: "DETTIFOSS" },
  { MMSI: 232008054, IMO: 9799903, SHIPNAME: "SCARLET ARROW" },
  { MMSI: 232013516, IMO: 9360752, SHIPNAME: "SPIRIT OF AUCKLAND" },
  { MMSI: 232013520, IMO: 9391660, SHIPNAME: "SPIRIT OF HAMBURG" },
  { MMSI: 232013521, IMO: 9362413, SHIPNAME: "SPIRIT OF MELBOURNE" },
  { MMSI: 232013524, IMO: 9362396, SHIPNAME: "SPIRIT OF SINGAPORE" },
  { MMSI: 232013525, IMO: 9391672, SHIPNAME: "SPIRIT OF SYDNEY" },
  { MMSI: 232018900, IMO: 9725158, SHIPNAME: "HYUNDAI NEPTUNE" },
  { MMSI: 232018901, IMO: 9725160, SHIPNAME: "HYUNDAI PLUTO" },
  { MMSI: 232018919, IMO: 9725134, SHIPNAME: "HYUNDAI JUPITER" },
  { MMSI: 232024771, IMO: 9725146, SHIPNAME: "HYUNDAI SATURN" },
  { MMSI: 232024772, IMO: 9725110, SHIPNAME: "HYUNDAI EARTH" },
  { MMSI: 232024773, IMO: 9725122, SHIPNAME: "HYUNDAI MARS" },
  { MMSI: 232029038, IMO: 9864215, SHIPNAME: "ZENITH LUMOS" },
  { MMSI: 232030743, IMO: 9864227, SHIPNAME: "ZEPHYR LUMOS" },
  { MMSI: 232031318, IMO: 9864239, SHIPNAME: "ZEUS LUMOS" },
  { MMSI: 232031320, IMO: 9864241, SHIPNAME: "ZEAL LUMOS" },
  { MMSI: 232043367, IMO: 9924211, SHIPNAME: "MAERSK CAMPTON" },
  { MMSI: 232043368, IMO: 9924223, SHIPNAME: "MAERSK CANDOR" },
  { MMSI: 232043372, IMO: 9924235, SHIPNAME: "MAERSK CANYON" },
  { MMSI: 232046824, IMO: 9813993, SHIPNAME: "CONTAINERSHIPS NORD" },
  { MMSI: 232046826, IMO: 9814002, SHIPNAME: "CONTAINERSHIPS POLAR" },
  { MMSI: 232049845, IMO: 9685774, SHIPNAME: "CELANDINE" },
  { MMSI: 235009240, IMO: 9300386, SHIPNAME: "EVER SHINE" },
  { MMSI: 235009850, IMO: 9300398, SHIPNAME: "EVER SIGMA" },
  { MMSI: 235010450, IMO: 9300403, SHIPNAME: "EVER SMART" },
  { MMSI: 235010710, IMO: 9300415, SHIPNAME: "EVER SMILE" },
  { MMSI: 235050802, IMO: 9384875, SHIPNAME: "TOKYO TOWER" },
  { MMSI: 235061354, IMO: 9332846, SHIPNAME: "BRIGHTON" },
  { MMSI: 235067931, IMO: 9221073, SHIPNAME: "COSCO ROTTERDAM" },
  { MMSI: 235067987, IMO: 9221097, SHIPNAME: "COSCO SHANGHAI" },
  { MMSI: 235068025, IMO: 9246396, SHIPNAME: "COSCO ANTWERP" },
  { MMSI: 235068029, IMO: 9227778, SHIPNAME: "COSCO HONGKONG" },
  { MMSI: 235068031, IMO: 9246401, SHIPNAME: "COSCO FELIXSTOWE" },
  { MMSI: 235068032, IMO: 9221102, SHIPNAME: "COSCO SINGAPORE" },
  { MMSI: 235068051, IMO: 9221085, SHIPNAME: "COSCO HAMBURG" },
  { MMSI: 235082896, IMO: 9463011, SHIPNAME: "EDISON" },
  { MMSI: 235084298, IMO: 9463023, SHIPNAME: "ERVING" },
  { MMSI: 235093569, IMO: 9595436, SHIPNAME: "EVER LAMBENT" },
  { MMSI: 235093618, IMO: 9595450, SHIPNAME: "EVER LASTING" },
  { MMSI: 235093619, IMO: 9595462, SHIPNAME: "EVER LEADING" },
  { MMSI: 235098383, IMO: 9629043, SHIPNAME: "EVER LINKING" },
  { MMSI: 235098885, IMO: 9604108, SHIPNAME: "EVER LEARNED" },
  { MMSI: 235102677, IMO: 9604146, SHIPNAME: "EVER LENIENT" },
  { MMSI: 235102678, IMO: 9604160, SHIPNAME: "EVER LIBERAL" },
  { MMSI: 235102679, IMO: 9629079, SHIPNAME: "EVER LISSOME" },
  { MMSI: 235102681, IMO: 9629081, SHIPNAME: "EVER LOADING" },
  { MMSI: 235110737, IMO: 0, SHIPNAME: "EVER LIFTING" },
  { MMSI: 235335000, IMO: 9241310, SHIPNAME: "EVER EAGLE" },
  { MMSI: 235414000, IMO: 9241308, SHIPNAME: "EVER ENVOY" },
  { MMSI: 235479000, IMO: 9241322, SHIPNAME: "EVER EXCEL" },
  { MMSI: 235554000, IMO: 9241281, SHIPNAME: "EVER ELITE" },
  { MMSI: 235603000, IMO: 9241293, SHIPNAME: "EVER ETHIC" },
  { MMSI: 235612000, IMO: 9249233, SHIPNAME: "EVER PRIDE" },
  { MMSI: 235700000, IMO: 9249245, SHIPNAME: "EVER PRIMA" },
  { MMSI: 236465000, IMO: 9386976, SHIPNAME: "WYBELSUM" },
  { MMSI: 241311000, IMO: 9484924, SHIPNAME: "EXPRESS BERLIN" },
  { MMSI: 242198100, IMO: 9141792, SHIPNAME: "CIELO DI RABAT" },
  { MMSI: 242377400, IMO: 9461594, SHIPNAME: "CMA CGM DAKHLA" },
  { MMSI: 242410400, IMO: 9396696, SHIPNAME: "CMA CGM NADOR" },
  { MMSI: 242640300, IMO: 9106467, SHIPNAME: "CASABLANCA A" },
  { MMSI: 242830300, IMO: 9354349, SHIPNAME: "X-PRESS SOUSSE" },
  { MMSI: 244150340, IMO: 1231313, SHIPNAME: "MARY MAERSK" },
  { MMSI: 244150813, IMO: 9144689, SHIPNAME: "A2B SPIRIT" },
  { MMSI: 244170750, IMO: 9113745, SHIPNAME: "A2B AMBITION" },
  { MMSI: 244180000, IMO: 9219874, SHIPNAME: "FREYA" },
  { MMSI: 244184354, IMO: 1548880, SHIPNAME: "EA NODDY" },
  { MMSI: 244201000, IMO: 9483695, SHIPNAME: "NORDICA" },
  { MMSI: 244238000, IMO: 9491496, SHIPNAME: "ESSENCE" },
  { MMSI: 244255000, IMO: 9491501, SHIPNAME: "ESCAPE" },
  { MMSI: 244265000, IMO: 9491484, SHIPNAME: "ESPERANCE" },
  { MMSI: 244279000, IMO: 9491472, SHIPNAME: "ESPOIR" },
  { MMSI: 244357000, IMO: 9312200, SHIPNAME: "ENDURANCE" },
  { MMSI: 244597000, IMO: 9255737, SHIPNAME: "ENFORCER" },
  { MMSI: 244650437, IMO: 9183427, SHIPNAME: "A2B ENERGY" },
  { MMSI: 244820000, IMO: 9324434, SHIPNAME: "BERMUDA ISLANDER" },
  { MMSI: 244850968, IMO: 9302255, SHIPNAME: "SPIRIT" },
  { MMSI: 244850970, IMO: 9302243, SHIPNAME: "SVEN D" },
  { MMSI: 244860282, IMO: 9196943, SHIPNAME: "SAMSKIP HOFFELL" },
  { MMSI: 244870287, IMO: 9122241, SHIPNAME: "A2B FUTURE" },
  { MMSI: 244874000, IMO: 9359258, SHIPNAME: "EMOTION" },
  { MMSI: 244985000, IMO: 9375111, SHIPNAME: "VANTAGE" },
  { MMSI: 244990000, IMO: 9740653, SHIPNAME: "MERSIN EXPRESS" },
  { MMSI: 245078000, IMO: 9255775, SHIPNAME: "ENCOUNTER" },
  { MMSI: 245088000, IMO: 9299501, SHIPNAME: "ENERGIZER" },
  { MMSI: 245222000, IMO: 9312195, SHIPNAME: "ENDEAVOR" },
  { MMSI: 245258000, IMO: 9387425, SHIPNAME: "EMPIRE" },
  { MMSI: 245502000, IMO: 9122239, SHIPNAME: "A2B PROUD" },
  { MMSI: 245570000, IMO: 9116187, SHIPNAME: "ANJA" },
  { MMSI: 245872000, IMO: 9349227, SHIPNAME: "NJORD" },
  { MMSI: 246051000, IMO: 9365996, SHIPNAME: "ROTRA MARE" },
  { MMSI: 246141000, IMO: 9116199, SHIPNAME: "VANQUISH" },
  { MMSI: 246191000, IMO: 9287716, SHIPNAME: "FENJA" },
  { MMSI: 246255000, IMO: 9312212, SHIPNAME: "ENSEMBLE" },
  { MMSI: 246497000, IMO: 9219862, SHIPNAME: "ELISABETH" },
  { MMSI: 246530000, IMO: 9355812, SHIPNAME: "RIJNBORG" },
  { MMSI: 246650000, IMO: 9462794, SHIPNAME: "OOCL RAUMA" },
  { MMSI: 246891000, IMO: 9183415, SHIPNAME: "A2B COMFORT" },
  { MMSI: 246899000, IMO: 9113733, SHIPNAME: "A2B LEADER" },
  { MMSI: 247009600, IMO: 9196979, SHIPNAME: "ITAL USODIMARE" },
  { MMSI: 247012800, IMO: 9196981, SHIPNAME: "ITAL UNICA" },
  { MMSI: 247012900, IMO: 9196993, SHIPNAME: "ITAL UNIVERSO" },
  { MMSI: 247425500, IMO: 9372872, SHIPNAME: "BURGUNDY" },
  { MMSI: 247437600, IMO: 9786970, SHIPNAME: "ITAL BONNY" },
  { MMSI: 247437900, IMO: 9786994, SHIPNAME: "ITAL BONUS" },
  { MMSI: 247439600, IMO: 9904510, SHIPNAME: "EVER COZY" },
  { MMSI: 247483900, IMO: 9484534, SHIPNAME: "JOLLY ROSA" },
  { MMSI: 248013000, IMO: 9399210, SHIPNAME: "CMA CGM PEGASUS" },
  { MMSI: 248030000, IMO: 9729087, SHIPNAME: "CMA CGM CARL ANTOINE" },
  { MMSI: 248052000, IMO: 9399222, SHIPNAME: "CMA CGM TITAN" },
  { MMSI: 248089000, IMO: 9727625, SHIPNAME: "CAPE SOUNIO" },
  { MMSI: 248140000, IMO: 9777175, SHIPNAME: "CAPE ARTEMISIO" },
  { MMSI: 248207000, IMO: 9343974, SHIPNAME: "MAERSK JAIPUR" },
  { MMSI: 248228000, IMO: 9751092, SHIPNAME: "ALIAGA EXPRESS" },
  { MMSI: 248247000, IMO: 9406611, SHIPNAME: "CMA CGM MUSSET" },
  { MMSI: 248269000, IMO: 9312652, SHIPNAME: "CMA CGM SUEZ" },
  { MMSI: 248270000, IMO: 9330537, SHIPNAME: "IRENES RYTHM" },
  { MMSI: 248302000, IMO: 9443011, SHIPNAME: "EXPRESS ARGENTINA" },
  { MMSI: 248402000, IMO: 9280598, SHIPNAME: "CMA CGM BELLINI" },
  { MMSI: 248422000, IMO: 9312640, SHIPNAME: "ELBELLA" },
  { MMSI: 248426000, IMO: 9729099, SHIPNAME: "CMA CGM LISA MARIE" },
  { MMSI: 248430000, IMO: 9406635, SHIPNAME: "CMA CGM RABELAIS" },
  { MMSI: 248431000, IMO: 9406647, SHIPNAME: "RACINE" },
  { MMSI: 248432000, IMO: 9406623, SHIPNAME: "CMA CGM NERVAL" },
  { MMSI: 248455000, IMO: 9248112, SHIPNAME: "CMA CGM EIFFEL" },
  { MMSI: 248556000, IMO: 9797216, SHIPNAME: "POLAR BRASIL" },
  { MMSI: 248572000, IMO: 9443035, SHIPNAME: "EXPRESS FRANCE" },
  { MMSI: 248574000, IMO: 9443023, SHIPNAME: "EXPRESS BRAZIL" },
  { MMSI: 248633000, IMO: 9532795, SHIPNAME: "APL ANTWERP" },
  { MMSI: 248649000, IMO: 9729116, SHIPNAME: "CMA CGM ESTELLE" },
  { MMSI: 248655000, IMO: 9745550, SHIPNAME: "CMA CGM LOUGA" },
  { MMSI: 248659000, IMO: 9745500, SHIPNAME: "CMA CGM PREGOLIA" },
  { MMSI: 248660000, IMO: 9745548, SHIPNAME: "CMA CGM NEVA" },
  { MMSI: 248699000, IMO: 9350032, SHIPNAME: "APL FLORIDA" },
  { MMSI: 248703000, IMO: 9729128, SHIPNAME: "CMA CGM JEAN GABRIEL" },
  { MMSI: 248711000, IMO: 9532783, SHIPNAME: "APL OREGON" },
  { MMSI: 248712000, IMO: 9350044, SHIPNAME: "APL CALIFORNIA" },
  { MMSI: 248729000, IMO: 9802499, SHIPNAME: "CMA CGM ABU DHABI" },
  { MMSI: 248756000, IMO: 9516765, SHIPNAME: "ANL KOKODA" },
  { MMSI: 248758000, IMO: 9776420, SHIPNAME: "CMA CGM JEAN MERMOZ" },
  { MMSI: 248794000, IMO: 9776432, SHIPNAME: "CMACGM LOUIS BLERIOT" },
  { MMSI: 248819000, IMO: 9535096, SHIPNAME: "HANSA DUBURG" },
  { MMSI: 248846000, IMO: 9443047, SHIPNAME: "EXPRESS SPAIN" },
  { MMSI: 248966000, IMO: 9817884, SHIPNAME: "CNC VENUS" },
  { MMSI: 249096000, IMO: 9167942, SHIPNAME: "MERITO" },
  { MMSI: 249167000, IMO: 9722687, SHIPNAME: "CMA CGM OHIO" },
  { MMSI: 249168000, IMO: 9722675, SHIPNAME: "CMA CGM NIAGARA" },
  { MMSI: 249169000, IMO: 9722699, SHIPNAME: "CMA CGM RIO GRANDE" },
  { MMSI: 249202000, IMO: 9706190, SHIPNAME: "CAPE AKRITAS" },
  { MMSI: 249253000, IMO: 9363376, SHIPNAME: "RIO GRANDE" },
  { MMSI: 249322000, IMO: 9722716, SHIPNAME: "CMACGM JACQUESJUNIOR" },
  { MMSI: 249324000, IMO: 9722704, SHIPNAME: "CMA CGM TANYA" },
  { MMSI: 249414000, IMO: 9389681, SHIPNAME: "MERVE A" },
  { MMSI: 249474000, IMO: 9389693, SHIPNAME: "KINGSTON" },
  { MMSI: 249515000, IMO: 9728916, SHIPNAME: "TRITON" },
  { MMSI: 249558000, IMO: 9728928, SHIPNAME: "TITAN" },
  { MMSI: 249675000, IMO: 9391268, SHIPNAME: "DALIAN" },
  { MMSI: 249769000, IMO: 9728930, SHIPNAME: "TALOS" },
  { MMSI: 249773000, IMO: 9728942, SHIPNAME: "TAURUS" },
  { MMSI: 249783000, IMO: 9727613, SHIPNAME: "CAPE KORTIA" },
  { MMSI: 249808000, IMO: 9728954, SHIPNAME: "THESEUS" },
  { MMSI: 249819000, IMO: 9399193, SHIPNAME: "CMA CGM LIBRA" },
  { MMSI: 249830000, IMO: 9403229, SHIPNAME: "ZIM LUANDA" },
  { MMSI: 249837000, IMO: 9302944, SHIPNAME: "MARGARETE SCHULTE" },
  { MMSI: 249877000, IMO: 9729075, SHIPNAME: "CMA CGM RODOLPHE" },
  { MMSI: 249888000, IMO: 9399208, SHIPNAME: "CMA CGM LEO" },
  { MMSI: 249917000, IMO: 9401099, SHIPNAME: "CMA CGM MOLIERE" },
  { MMSI: 249940000, IMO: 9740653, SHIPNAME: "MERSIN EXPRESS" },
  { MMSI: 249982000, IMO: 9706205, SHIPNAME: "CAPE TAINARO" },
  { MMSI: 255618000, IMO: 9327671, SHIPNAME: "MSC NORA III" },
  { MMSI: 255701240, IMO: 9483683, SHIPNAME: "NIEVES B" },
  { MMSI: 255705000, IMO: 9306225, SHIPNAME: "AVA D" },
  { MMSI: 255709000, IMO: 9516789, SHIPNAME: "AS SELINA" },
  { MMSI: 255710000, IMO: 9477294, SHIPNAME: "JULIANA" },
  { MMSI: 255719000, IMO: 9477347, SHIPNAME: "JOST" },
  { MMSI: 255771000, IMO: 9436202, SHIPNAME: "BG RUBY" },
  { MMSI: 255772000, IMO: 9436197, SHIPNAME: "BG ONYX" },
  { MMSI: 255775000, IMO: 9354454, SHIPNAME: "JSP ANNA" },
  { MMSI: 255784000, IMO: 9277395, SHIPNAME: "CANDELARIA B" },
  { MMSI: 255790000, IMO: 9317937, SHIPNAME: "MSC TAMPICO" },
  { MMSI: 255792000, IMO: 9289960, SHIPNAME: "MSC MANZANILLO" },
  { MMSI: 255793000, IMO: 9401776, SHIPNAME: "MSC ACAPULCO" },
  { MMSI: 255796000, IMO: 9289972, SHIPNAME: "MSC TUXPAN" },
  { MMSI: 255797000, IMO: 9287924, SHIPNAME: "MSC VERACRUZ" },
  { MMSI: 255799000, IMO: 9360271, SHIPNAME: "MSC CAPE III" },
  { MMSI: 255801360, IMO: 9726566, SHIPNAME: "ATALANTA" },
  { MMSI: 255801780, IMO: 9354442, SHIPNAME: "DINA TRADER" },
  { MMSI: 255801940, IMO: 9252802, SHIPNAME: "CONESTE" },
  { MMSI: 255802340, IMO: 9670822, SHIPNAME: "ESL ASANTE" },
  { MMSI: 255802360, IMO: 9254848, SHIPNAME: "MSC ANSHIKA VI" },
  { MMSI: 255802470, IMO: 9436472, SHIPNAME: "MSC ZONDA III" },
  { MMSI: 255802560, IMO: 9516753, SHIPNAME: "HANSA SALZBURG" },
  { MMSI: 255802680, IMO: 9150080, SHIPNAME: "WEC BOUMEESTER" },
  { MMSI: 255802760, IMO: 9301108, SHIPNAME: "FAITH" },
  { MMSI: 255802790, IMO: 9514755, SHIPNAME: "NORDIC HAMBURG" },
  { MMSI: 255803201, IMO: 9301483, SHIPNAME: "MSC PARIS" },
  { MMSI: 255803450, IMO: 9393319, SHIPNAME: "MSC RIKKU" },
  { MMSI: 255803590, IMO: 9313943, SHIPNAME: "MSC LEANDRA V" },
  { MMSI: 255803640, IMO: 9514767, SHIPNAME: "ARIES J" },
  { MMSI: 255803670, IMO: 9293777, SHIPNAME: "MSC NEW HAVEN" },
  { MMSI: 255803680, IMO: 9243409, SHIPNAME: "MSC BRIDGEPORT" },
  { MMSI: 255803760, IMO: 9286267, SHIPNAME: "MSC GREENWICH" },
  { MMSI: 255803770, IMO: 9302633, SHIPNAME: "MSC FAIRFIELD" },
  { MMSI: 255803780, IMO: 9243394, SHIPNAME: "MSC DARIEN" },
  { MMSI: 255804350, IMO: 9313967, SHIPNAME: "MSC BREMERHAVEN V" },
  { MMSI: 255804590, IMO: 9964613, SHIPNAME: "BG GREEN" },
  { MMSI: 255805260, IMO: 9932919, SHIPNAME: "MSC AZRA" },
  { MMSI: 255805551, IMO: 9290165, SHIPNAME: "VICTORIA" },
  { MMSI: 255805555, IMO: 9315020, SHIPNAME: "AKACIA" },
  { MMSI: 255805557, IMO: 9297589, SHIPNAME: "ALANA" },
  { MMSI: 255805558, IMO: 9301471, SHIPNAME: "MSC VALENCIA" },
  { MMSI: 255805563, IMO: 9654464, SHIPNAME: "WIELAND" },
  { MMSI: 255805571, IMO: 9301495, SHIPNAME: "MSC BILBAO" },
  { MMSI: 255805572, IMO: 9619385, SHIPNAME: "JADRANA" },
  { MMSI: 255805573, IMO: 9619402, SHIPNAME: "JOGELA" },
  { MMSI: 255805574, IMO: 9304693, SHIPNAME: "NORTHERN VALENCE" },
  { MMSI: 255805575, IMO: 9304708, SHIPNAME: "MSC VIGOUR III" },
  { MMSI: 255805576, IMO: 9304966, SHIPNAME: "NORTHERN VIVACITY" },
  { MMSI: 255805577, IMO: 9369007, SHIPNAME: "ELBSTROM" },
  { MMSI: 255805580, IMO: 9220316, SHIPNAME: "JPO AQUARIUS" },
  { MMSI: 255805587, IMO: 9326990, SHIPNAME: "NCL AVEROY" },
  { MMSI: 255805588, IMO: 9535199, SHIPNAME: "ESL NHAVA SHEVA" },
  { MMSI: 255805589, IMO: 9535137, SHIPNAME: "HELLA" },
  { MMSI: 255805590, IMO: 9535151, SHIPNAME: "HERTA" },
  { MMSI: 255805594, IMO: 9454230, SHIPNAME: "SVENDBORG" },
  { MMSI: 255805600, IMO: 9144720, SHIPNAME: "BARBARA P" },
  { MMSI: 255805601, IMO: 9144718, SHIPNAME: "JONA SOPHIE" },
  { MMSI: 255805603, IMO: 9445019, SHIPNAME: "FERDINANDA S" },
  { MMSI: 255805611, IMO: 9362724, SHIPNAME: "MACAO" },
  { MMSI: 255805617, IMO: 9320441, SHIPNAME: "MSC CAROUGE" },
  { MMSI: 255805622, IMO: 9437115, SHIPNAME: "AS FLORIANA" },
  { MMSI: 255805626, IMO: 9298636, SHIPNAME: "INDEPENDENT PRIMERO" },
  { MMSI: 255805627, IMO: 9287687, SHIPNAME: "SANTUCA B" },
  { MMSI: 255805652, IMO: 9483358, SHIPNAME: "ISABELLA B" },
  { MMSI: 255805657, IMO: 9400203, SHIPNAME: "VENETIA" },
  { MMSI: 255805661, IMO: 9456965, SHIPNAME: "MSC ODESSA V" },
  { MMSI: 255805664, IMO: 9294408, SHIPNAME: "MSC MARITINA V" },
  { MMSI: 255805666, IMO: 9467043, SHIPNAME: "JOLLY ARGENTO" },
  { MMSI: 255805677, IMO: 9221827, SHIPNAME: "BUXCOAST" },
  { MMSI: 255805678, IMO: 9221815, SHIPNAME: "BUXCLIFF" },
  { MMSI: 255805703, IMO: 9239862, SHIPNAME: "FLORA DELMAS" },
  { MMSI: 255805750, IMO: 9376012, SHIPNAME: "SEALAND BALBOA" },
  { MMSI: 255805755, IMO: 9436305, SHIPNAME: "VERONICA B" },
  { MMSI: 255805764, IMO: 9670834, SHIPNAME: "OSAKA" },
  { MMSI: 255805775, IMO: 9354363, SHIPNAME: "WEC MONDRIAAN" },
  { MMSI: 255805776, IMO: 9395525, SHIPNAME: "ESL BUSAN" },
  { MMSI: 255805777, IMO: 9000742, SHIPNAME: "MSC JENNY II" },
  { MMSI: 255805780, IMO: 9290787, SHIPNAME: "PANDA 001" },
  { MMSI: 255805781, IMO: 9397913, SHIPNAME: "AMALTHEA" },
  { MMSI: 255805788, IMO: 9477335, SHIPNAME: "JAN" },
  { MMSI: 255805802, IMO: 9326940, SHIPNAME: "WEC CORNEILLE" },
  { MMSI: 255805803, IMO: 9246712, SHIPNAME: "INDEPENDENT FUTURE" },
  { MMSI: 255805810, IMO: 9690078, SHIPNAME: "UBENA" },
  { MMSI: 255805837, IMO: 9322554, SHIPNAME: "SOLONG" },
  { MMSI: 255805838, IMO: 9322578, SHIPNAME: "DREAM" },
  { MMSI: 255805839, IMO: 9297606, SHIPNAME: "VISITOR" },
  { MMSI: 255805840, IMO: 9323479, SHIPNAME: "SAMSKIP EXPRESS" },
  { MMSI: 255805841, IMO: 9328039, SHIPNAME: "MEANDI" },
  { MMSI: 255805842, IMO: 9328041, SHIPNAME: "MIRROR" },
  { MMSI: 255805843, IMO: 9328053, SHIPNAME: "PANDA 002" },
  { MMSI: 255805844, IMO: 9326952, SHIPNAME: "TROUPER" },
  { MMSI: 255805845, IMO: 9354387, SHIPNAME: "ANDANTE" },
  { MMSI: 255805846, IMO: 9237369, SHIPNAME: "MOVEON" },
  { MMSI: 255805847, IMO: 9347281, SHIPNAME: "ESL WINNER" },
  { MMSI: 255805849, IMO: 9690080, SHIPNAME: "ULANGA" },
  { MMSI: 255805852, IMO: 9440306, SHIPNAME: "MSC MELTEMI III" },
  { MMSI: 255805854, IMO: 0, SHIPNAME: "MSC JEONGMIN" },
  { MMSI: 255805855, IMO: 9720483, SHIPNAME: "MSC CHLOE" },
  { MMSI: 255805856, IMO: 9793947, SHIPNAME: "MSC SIYA B" },
  { MMSI: 255805859, IMO: 9702273, SHIPNAME: "MSC ANTONELLA" },
  { MMSI: 255805864, IMO: 9710438, SHIPNAME: "MSC CHANNE" },
  { MMSI: 255805865, IMO: 9735206, SHIPNAME: "MSC PALAK" },
  { MMSI: 255805866, IMO: 9735218, SHIPNAME: "MSC ELMA" },
  { MMSI: 255805867, IMO: 9745653, SHIPNAME: "MSC ROMANE" },
  { MMSI: 255805868, IMO: 9745665, SHIPNAME: "MSC DESIREE" },
  { MMSI: 255805869, IMO: 9770737, SHIPNAME: "MSC GIULIA" },
  { MMSI: 255805878, IMO: 9404065, SHIPNAME: "CARLOTA B" },
  { MMSI: 255805883, IMO: 9108063, SHIPNAME: "PIRITA" },
  { MMSI: 255805884, IMO: 9386718, SHIPNAME: "NAVI BALTIC" },
  { MMSI: 255805890, IMO: 9157868, SHIPNAME: "LAURA S" },
  { MMSI: 255805891, IMO: 9720495, SHIPNAME: "MSC BRANKA" },
  { MMSI: 255805897, IMO: 9720500, SHIPNAME: "MSC SASHA" },
  { MMSI: 255805899, IMO: 9354351, SHIPNAME: "ANINA" },
  { MMSI: 255805903, IMO: 9344253, SHIPNAME: "CT PACHUCA" },
  { MMSI: 255805906, IMO: 9328625, SHIPNAME: "EDITH" },
  { MMSI: 255805922, IMO: 9236688, SHIPNAME: "MSC ELBE III" },
  { MMSI: 255805923, IMO: 9236690, SHIPNAME: "MSC WESER" },
  { MMSI: 255805929, IMO: 9720512, SHIPNAME: "MSC MICHELA" },
  { MMSI: 255805930, IMO: 9720524, SHIPNAME: "MSC CLEA" },
  { MMSI: 255805937, IMO: 9376024, SHIPNAME: "MISTRAL" },
  { MMSI: 255805947, IMO: 9169031, SHIPNAME: "MSC MARYLENA" },
  { MMSI: 255805954, IMO: 9354375, SHIPNAME: "ATLANTIS A" },
  { MMSI: 255805956, IMO: 9622007, SHIPNAME: "MSC JERSEY" },
  { MMSI: 255805965, IMO: 9308601, SHIPNAME: "MSC TALIA F" },
  { MMSI: 255805966, IMO: 9319600, SHIPNAME: "MSC AMANDA F" },
  { MMSI: 255805967, IMO: 9347762, SHIPNAME: "MSC LENA F" },
  { MMSI: 255805968, IMO: 9308625, SHIPNAME: "MSC LUNA F" },
  { MMSI: 255805969, IMO: 9308584, SHIPNAME: "MSC NINA F" },
  { MMSI: 255805984, IMO: 9319868, SHIPNAME: "JSP CARLA" },
  { MMSI: 255805987, IMO: 9252735, SHIPNAME: "EF OLIVIA" },
  { MMSI: 255805992, IMO: 9297618, SHIPNAME: "BF CARODA" },
  { MMSI: 255805995, IMO: 9138355, SHIPNAME: "K-RIVER" },
  { MMSI: 255805996, IMO: 9143972, SHIPNAME: "K-OCEAN" },
  { MMSI: 255805997, IMO: 9151101, SHIPNAME: "K-STREAM" },
  { MMSI: 255806004, IMO: 9200691, SHIPNAME: "BRUSSELS" },
  { MMSI: 255806005, IMO: 9231248, SHIPNAME: "MSC INDIA" },
  { MMSI: 255806008, IMO: 9277400, SHIPNAME: "MACARENA B" },
  { MMSI: 255806010, IMO: 9083055, SHIPNAME: "MONTE BRASIL" },
  { MMSI: 255806011, IMO: 9123788, SHIPNAME: "MONTE DA GUIA" },
  { MMSI: 255806016, IMO: 9354466, SHIPNAME: "HELMUT" },
  { MMSI: 255806027, IMO: 9778076, SHIPNAME: "MSC SHUBA B" },
  { MMSI: 255806028, IMO: 9778105, SHIPNAME: "MSC SHREYA B" },
  { MMSI: 255806029, IMO: 9778117, SHIPNAME: "MSC NITYA B" },
  { MMSI: 255806031, IMO: 9778088, SHIPNAME: "MSC MADHU B" },
  { MMSI: 255806032, IMO: 9778090, SHIPNAME: "MSC YASHI B" },
  { MMSI: 255806034, IMO: 9466960, SHIPNAME: "MSC JASPER VIII" },
  { MMSI: 255806037, IMO: 9450349, SHIPNAME: "NORTHERN JUVENILE" },
  { MMSI: 255806038, IMO: 9450337, SHIPNAME: "NORTHERN JUBILEE" },
  { MMSI: 255806039, IMO: 9466972, SHIPNAME: "NORTHERN JAGUAR" },
  { MMSI: 255806054, IMO: 9301988, SHIPNAME: "RITA" },
  { MMSI: 255806055, IMO: 9301562, SHIPNAME: "GEESKE" },
  { MMSI: 255806057, IMO: 9778399, SHIPNAME: "BRIGHT" },
  { MMSI: 255806063, IMO: 9435820, SHIPNAME: "MSC MANON" },
  { MMSI: 255806064, IMO: 9286786, SHIPNAME: "AS PETRONIA" },
  { MMSI: 255806065, IMO: 9320049, SHIPNAME: "STADT DRESDEN" },
  { MMSI: 255806066, IMO: 9357092, SHIPNAME: "AS ANGELINA" },
  { MMSI: 255806067, IMO: 9437191, SHIPNAME: "AS FATIMA" },
  { MMSI: 255806068, IMO: 9395111, SHIPNAME: "AS FIORELLA" },
  { MMSI: 255806069, IMO: 9395135, SHIPNAME: "AS FABRIZIA" },
  { MMSI: 255806070, IMO: 9395056, SHIPNAME: "AS FLORETTA" },
  { MMSI: 255806072, IMO: 9320037, SHIPNAME: "AS COLUMBIA" },
  { MMSI: 255806076, IMO: 9286774, SHIPNAME: "AS PAULINA" },
  { MMSI: 255806079, IMO: 173015047, SHIPNAME: "AS FATIMA" },
  { MMSI: 255806081, IMO: 9242651, SHIPNAME: "MSC AMY" },
  { MMSI: 255806085, IMO: 9300972, SHIPNAME: "AS CLARITA" },
  { MMSI: 255806086, IMO: 9454242, SHIPNAME: "SONDERBORG" },
  { MMSI: 255806091, IMO: 9634660, SHIPNAME: "ORNELLA" },
  { MMSI: 255806092, IMO: 9309409, SHIPNAME: "AS CARELIA" },
  { MMSI: 255806093, IMO: 9315812, SHIPNAME: "AS CYPRIA" },
  { MMSI: 255806095, IMO: 9294525, SHIPNAME: "AS PATRIA" },
  { MMSI: 255806096, IMO: 9449869, SHIPNAME: "MSC RICCARDA II" },
  { MMSI: 255806097, IMO: 9449821, SHIPNAME: "MSC ROMINA II" },
  { MMSI: 255806098, IMO: 9449845, SHIPNAME: "AS ROSALIA" },
  { MMSI: 255806100, IMO: 9430935, SHIPNAME: "AS SICILIA" },
  { MMSI: 255806101, IMO: 9362712, SHIPNAME: "SEVILLIA" },
  { MMSI: 255806102, IMO: 9395109, SHIPNAME: "AS FABIANA" },
  { MMSI: 255806103, IMO: 9395020, SHIPNAME: "AS FELICIA" },
  { MMSI: 255806105, IMO: 9450351, SHIPNAME: "MSC JUSTICE VIII" },
  { MMSI: 255806109, IMO: 9188219, SHIPNAME: "MSC MASHA 3" },
  { MMSI: 255806110, IMO: 9368742, SHIPNAME: "AS FILIPPA" },
  { MMSI: 255806113, IMO: 9365972, SHIPNAME: "CMA CGM GOYA" },
  { MMSI: 255806114, IMO: 9248124, SHIPNAME: "CMA CGM PUGET" },
  { MMSI: 255806115, IMO: 9634672, SHIPNAME: "OLIVIA" },
  { MMSI: 255806116, IMO: 9342695, SHIPNAME: "AS CALIFORNIA" },
  { MMSI: 255806121, IMO: 9314947, SHIPNAME: "AS CAMELLIA" },
  { MMSI: 255806122, IMO: 9387451, SHIPNAME: "AS SAVANNA" },
  { MMSI: 255806123, IMO: 9389306, SHIPNAME: "EF AVA" },
  { MMSI: 255806125, IMO: 9314959, SHIPNAME: "AS CLEMENTINA" },
  { MMSI: 255806127, IMO: 9335800, SHIPNAME: "AS SOPHIA" },
  { MMSI: 255806132, IMO: 9436068, SHIPNAME: "WILLIAM" },
  { MMSI: 255806136, IMO: 9515591, SHIPNAME: "RAQUEL S" },
  { MMSI: 255806137, IMO: 9314935, SHIPNAME: "AS CAROLINA" },
  { MMSI: 255806143, IMO: 9295529, SHIPNAME: "AVILA" },
  { MMSI: 255806148, IMO: 9448669, SHIPNAME: "MARIELYST" },
  { MMSI: 255806152, IMO: 9410272, SHIPNAME: "AS SARA" },
  { MMSI: 255806165, IMO: 9326964, SHIPNAME: "WEC FRANS HALS" },
  { MMSI: 255806169, IMO: 9465095, SHIPNAME: "NORTHERN JAVELIN" },
  { MMSI: 255806170, IMO: 9450363, SHIPNAME: "NORTHERN JAMBOREE" },
  { MMSI: 255806171, IMO: 9404077, SHIPNAME: "LUCIA B" },
  { MMSI: 255806179, IMO: 9376907, SHIPNAME: "CMA CGM VENTANIA" },
  { MMSI: 255806190, IMO: 9347803, SHIPNAME: "MSC ANNE F" },
  { MMSI: 255806191, IMO: 9372200, SHIPNAME: "HEINRICH EHLER" },
  { MMSI: 255806209, IMO: 9357808, SHIPNAME: "EF EMMA" },
  { MMSI: 255806219, IMO: 9813840, SHIPNAME: "SPIRIT OF CHENNAI" },
  { MMSI: 255806220, IMO: 9813838, SHIPNAME: "SPIRIT OF KOLKATA" },
  { MMSI: 255806233, IMO: 9450296, SHIPNAME: "MSC PRECISION V" },
  { MMSI: 255806235, IMO: 9326988, SHIPNAME: "CT DANIEL" },
  { MMSI: 255806249, IMO: 9306079, SHIPNAME: "ADELINA D" },
  { MMSI: 255806253, IMO: 9374117, SHIPNAME: "JETTE" },
  { MMSI: 255806259, IMO: 0, SHIPNAME: "SUNAID X" },
  { MMSI: 255806261, IMO: 9353723, SHIPNAME: "LOLA B" },
  { MMSI: 255806267, IMO: 9429273, SHIPNAME: "ELBTEAM" },
  { MMSI: 255806271, IMO: 9324954, SHIPNAME: "WILHELMINE" },
  { MMSI: 255806274, IMO: 9366512, SHIPNAME: "SPIRIT OF DUBAI" },
  { MMSI: 255806280, IMO: 0, SHIPNAME: "WEC JAN STEEN" },
  { MMSI: 255806301, IMO: 9428205, SHIPNAME: "REBECCA S" },
  { MMSI: 255806304, IMO: 9252577, SHIPNAME: "NORTHERN MONUMENT" },
  { MMSI: 255806308, IMO: 9429209, SHIPNAME: "CHARLOTTA" },
  { MMSI: 255806310, IMO: 9429211, SHIPNAME: "CHRISTINA" },
  { MMSI: 255806327, IMO: 9287792, SHIPNAME: "WEC VAN RUYSDAEL" },
  { MMSI: 255806330, IMO: 9318761, SHIPNAME: "ADILIA I" },
  { MMSI: 255806335, IMO: 9857169, SHIPNAME: "MSC ARIES" },
  { MMSI: 255806338, IMO: 9857183, SHIPNAME: "MSC AURIGA" },
  { MMSI: 255806339, IMO: 9857157, SHIPNAME: "MSC ORION" },
  { MMSI: 255806340, IMO: 9857171, SHIPNAME: "MSC VIRGO" },
  { MMSI: 255806346, IMO: 9440576, SHIPNAME: "PANTONIO" },
  { MMSI: 255806347, IMO: 9440588, SHIPNAME: "PENGALIA" },
  { MMSI: 255806352, IMO: 9323467, SHIPNAME: "JSP ROVER" },
  { MMSI: 255806362, IMO: 9289087, SHIPNAME: "MSC BUSAN" },
  { MMSI: 255806363, IMO: 9371414, SHIPNAME: "PERSEUS" },
  { MMSI: 255806367, IMO: 9299537, SHIPNAME: "MSC CHARLESTON" },
  { MMSI: 255806368, IMO: 9631876, SHIPNAME: "MSC GUERNSEY" },
  { MMSI: 255806370, IMO: 9429261, SHIPNAME: "ELBSUN" },
  { MMSI: 255806376, IMO: 9289099, SHIPNAME: "MSC BEIJING" },
  { MMSI: 255806377, IMO: 9299525, SHIPNAME: "MSC TORONTO" },
  { MMSI: 255806430, IMO: 9724049, SHIPNAME: "MSC BRITTANY" },
  { MMSI: 255806434, IMO: 9618264, SHIPNAME: "MSC ABIDJAN" },
  { MMSI: 255806436, IMO: 9404089, SHIPNAME: "KRISTIN SCHEPERS" },
  { MMSI: 255806437, IMO: 9373462, SHIPNAME: "HANSA OSTERBURG" },
  { MMSI: 255806448, IMO: 9360764, SHIPNAME: "MSC BASEL V" },
  { MMSI: 255806449, IMO: 9362449, SHIPNAME: "MSC BERN V" },
  { MMSI: 255806451, IMO: 9474395, SHIPNAME: "NORDIC ITALIA" },
  { MMSI: 255806452, IMO: 9474383, SHIPNAME: "NORDIC ISTRIA" },
  { MMSI: 255806459, IMO: 9323041, SHIPNAME: "MSC ANUSHA III" },
  { MMSI: 255806464, IMO: 9448683, SHIPNAME: "AMINA" },
  { MMSI: 255806476, IMO: 9458975, SHIPNAME: "SKALAR" },
  { MMSI: 255806481, IMO: 9246700, SHIPNAME: "INDEPENDENT QUEST" },
  { MMSI: 255806484, IMO: 9584475, SHIPNAME: "HEINRICH" },
  { MMSI: 255806485, IMO: 9401166, SHIPNAME: "MSC SAGITTA III" },
  { MMSI: 255806489, IMO: 9704960, SHIPNAME: "MSC LILY" },
  { MMSI: 255806490, IMO: 9702065, SHIPNAME: "MSC LETIZIA" },
  { MMSI: 255806491, IMO: 9702106, SHIPNAME: "MSC BRUNELLA" },
  { MMSI: 255806492, IMO: 9705005, SHIPNAME: "MSC CATERINA" },
  { MMSI: 255806493, IMO: 9702089, SHIPNAME: "MSC VITA" },
  { MMSI: 255806494, IMO: 9704972, SHIPNAME: "MSC ELODIE" },
  { MMSI: 255806495, IMO: 9702091, SHIPNAME: "MSC SOFIA CELESTE" },
  { MMSI: 255806496, IMO: 9710426, SHIPNAME: "MSC ANZU" },
  { MMSI: 255806497, IMO: 9702077, SHIPNAME: "MSC MELINE" },
  { MMSI: 255806498, IMO: 9704996, SHIPNAME: "MSC JULIE" },
  { MMSI: 255806499, IMO: 9702261, SHIPNAME: "MSC SARA ELENA" },
  { MMSI: 255806500, IMO: 9704984, SHIPNAME: "MSC NAOMI" },
  { MMSI: 255806502, IMO: 9793947, SHIPNAME: "MSC SIYA B" },
  { MMSI: 255806506, IMO: 9720457, SHIPNAME: "MSC SILVIA" },
  { MMSI: 255806507, IMO: 9720201, SHIPNAME: "MSC DOMITILLE" },
  { MMSI: 255806508, IMO: 9319571, SHIPNAME: "MITO" },
  { MMSI: 255806513, IMO: 9306067, SHIPNAME: "CALISTO" },
  { MMSI: 255806514, IMO: 9306201, SHIPNAME: "ANNABA" },
  { MMSI: 255806515, IMO: 9404821, SHIPNAME: "GRETA" },
  { MMSI: 255806520, IMO: 9720196, SHIPNAME: "MSC GISELLE" },
  { MMSI: 255806521, IMO: 9720287, SHIPNAME: "MSC MAXINE" },
  { MMSI: 255806525, IMO: 9584865, SHIPNAME: "KATHARINA SCHEPERS" },
  { MMSI: 255807025, IMO: 9720287, SHIPNAME: "MSC MAXINE" },
  { MMSI: 255826000, IMO: 9354399, SHIPNAME: "DAGMAR" },
  { MMSI: 255915586, IMO: 9797204, SHIPNAME: "POLAR ARGENTINA" },
  { MMSI: 255915587, IMO: 9360996, SHIPNAME: "STEEN" },
  { MMSI: 255915596, IMO: 9287704, SHIPNAME: "RS LISA" },
  { MMSI: 255915610, IMO: 9450313, SHIPNAME: "JOLLY ORO" },
  { MMSI: 255915612, IMO: 9368730, SHIPNAME: "EF ELENA" },
  { MMSI: 255915616, IMO: 9605152, SHIPNAME: "MSC LAGOS X" },
  { MMSI: 255915617, IMO: 9605231, SHIPNAME: "MSC DAKAR X" },
  { MMSI: 255915618, IMO: 9447861, SHIPNAME: "ROME EXPRESS" },
  { MMSI: 255915619, IMO: 9447885, SHIPNAME: "SOUTHAMPTON EXPRESS" },
  { MMSI: 255915621, IMO: 9357810, SHIPNAME: "EF EMIRA" },
  { MMSI: 255915622, IMO: 9334375, SHIPNAME: "INDEPENDENT HORIZON" },
  { MMSI: 255915624, IMO: 9360697, SHIPNAME: "APOLLON D" },
  { MMSI: 255915636, IMO: 9535216, SHIPNAME: "ESL DACHAN BAY" },
  { MMSI: 255915641, IMO: 9535175, SHIPNAME: "ESL KABIR" },
  { MMSI: 255915654, IMO: 9470882, SHIPNAME: "EF ELDRA" },
  { MMSI: 255915657, IMO: 9701279, SHIPNAME: "FRIDA RUSS" },
  { MMSI: 255915659, IMO: 9484558, SHIPNAME: "MSC LOME V" },
  { MMSI: 255915674, IMO: 9948633, SHIPNAME: "ALS HERCULES" },
  { MMSI: 255915695, IMO: 9926192, SHIPNAME: "CMA CGM ZINGARO" },
  { MMSI: 255975000, IMO: 9444285, SHIPNAME: "MSC DUBAI VII" },
  { MMSI: 255980000, IMO: 9288409, SHIPNAME: "MSC YAMUNA VI" },
  { MMSI: 255983000, IMO: 9311737, SHIPNAME: "MSC CAPETOWN III" },
  { MMSI: 255993000, IMO: 9333357, SHIPNAME: "ANDREA" },
  { MMSI: 256011000, IMO: 9293806, SHIPNAME: "CONTI CONTESSA" },
  { MMSI: 256019000, IMO: 9436422, SHIPNAME: "CMA CGM AMBARLI" },
  { MMSI: 256022000, IMO: 9323481, SHIPNAME: "CMA CGM ABIDJAN" },
  { MMSI: 256033000, IMO: 9705055, SHIPNAME: "CMA CGM LITANI" },
  { MMSI: 256042000, IMO: 9308194, SHIPNAME: "EMMA A" },
  { MMSI: 256058000, IMO: 9250995, SHIPNAME: "DIMITRA C" },
  { MMSI: 256062000, IMO: 9705067, SHIPNAME: "CMA CGM TIGRIS" },
  { MMSI: 256071000, IMO: 9322499, SHIPNAME: "CMA CGM MANTA RAY" },
  { MMSI: 256095000, IMO: 9331165, SHIPNAME: "CMA CGM VERDI" },
  { MMSI: 256104000, IMO: 9718117, SHIPNAME: "CMA CGM GANGES" },
  { MMSI: 256108000, IMO: 9718105, SHIPNAME: "CMA CGM MEKONG" },
  { MMSI: 256127000, IMO: 9550307, SHIPNAME: "CMA CGM NACALA" },
  { MMSI: 256142000, IMO: 9760627, SHIPNAME: "CNC URANUS" },
  { MMSI: 256143000, IMO: 9760586, SHIPNAME: "CNC PLUTO" },
  { MMSI: 256144000, IMO: 9303780, SHIPNAME: "CMA CGM NAVEGANTES" },
  { MMSI: 256148000, IMO: 9334820, SHIPNAME: "CMA CGM AQABA" },
  { MMSI: 256150000, IMO: 9435258, SHIPNAME: "ANL DHAMBI" },
  { MMSI: 256151000, IMO: 9480198, SHIPNAME: "MSC MADRID" },
  { MMSI: 256163000, IMO: 9705079, SHIPNAME: "CMA CGM URAL" },
  { MMSI: 256169000, IMO: 9705081, SHIPNAME: "CMA CGM VOLGA" },
  { MMSI: 256170000, IMO: 9412830, SHIPNAME: "CMA CGM SURABAYA" },
  { MMSI: 256171000, IMO: 9434905, SHIPNAME: "CMA CGM VITORIA" },
  { MMSI: 256172000, IMO: 9480215, SHIPNAME: "MSC CORUNA" },
  { MMSI: 256208000, IMO: 9471214, SHIPNAME: "CMA CGM TARRAGONA" },
  { MMSI: 256213000, IMO: 9674567, SHIPNAME: "CMA CGM THAMES" },
  { MMSI: 256214000, IMO: 9471202, SHIPNAME: "CMA CGM CONSTANZA" },
  { MMSI: 256229000, IMO: 9400291, SHIPNAME: "CMA CGM OSAKA" },
  { MMSI: 256244000, IMO: 9539494, SHIPNAME: "CMA CGM LOME" },
  { MMSI: 256267000, IMO: 9936185, SHIPNAME: "CNC JAGUAR" },
  { MMSI: 256272000, IMO: 9434917, SHIPNAME: "CMA CGM MANAUS" },
  { MMSI: 256292000, IMO: 9434929, SHIPNAME: "CMACGM VILA DO CONDE" },
  { MMSI: 256300000, IMO: 9436355, SHIPNAME: "CMA CGM TANCREDI" },
  { MMSI: 256311000, IMO: 9332884, SHIPNAME: "CMA CGM GEORGE SAND" },
  { MMSI: 256318000, IMO: 9343089, SHIPNAME: "SINE A" },
  { MMSI: 256381000, IMO: 9936197, SHIPNAME: "CNC CHEETAH" },
  { MMSI: 256430000, IMO: 9354662, SHIPNAME: "A. IDEFIX" },
  { MMSI: 256432000, IMO: 9354674, SHIPNAME: "A. OBELIX" },
  { MMSI: 256441000, IMO: 9936202, SHIPNAME: "CNC PUMA" },
  { MMSI: 256443000, IMO: 9732589, SHIPNAME: "CMA CGM NILE" },
  { MMSI: 256449000, IMO: 9732606, SHIPNAME: "CMA CGM YUKON" },
  { MMSI: 256475000, IMO: 9321471, SHIPNAME: "GISELE A" },
  { MMSI: 256482000, IMO: 9948217, SHIPNAME: "CMA CGM EXCELLENCE" },
  { MMSI: 256485000, IMO: 9948229, SHIPNAME: "CMA CGM EXEMPLARITY" },
  { MMSI: 256486000, IMO: 9740665, SHIPNAME: "IZMIT EXPRESS" },
  { MMSI: 256490000, IMO: 9635640, SHIPNAME: "CMA CGM RIMBAUD" },
  { MMSI: 256510000, IMO: 9239874, SHIPNAME: "CMA CGM GULF EXPRESS" },
  { MMSI: 256547000, IMO: 9303807, SHIPNAME: "STANLEY A" },
  { MMSI: 256548000, IMO: 9365788, SHIPNAME: "CMA CGM PELLEAS" },
  { MMSI: 256558000, IMO: 9670107, SHIPNAME: "OREA" },
  { MMSI: 256559000, IMO: 9431800, SHIPNAME: "CMA CGM LISBON" },
  { MMSI: 256567000, IMO: 9480174, SHIPNAME: "MSC ALICANTE" },
  { MMSI: 256570000, IMO: 9295373, SHIPNAME: "SANTA VIOLA" },
  { MMSI: 256572000, IMO: 9356696, SHIPNAME: "CMA CGM F.SAGAN" },
  { MMSI: 256573000, IMO: 9480186, SHIPNAME: "MSC BARCELONA" },
  { MMSI: 256579000, IMO: 9372585, SHIPNAME: "VERA A" },
  { MMSI: 256587000, IMO: 9950557, SHIPNAME: "CMA CGM BETTER WAYS" },
  { MMSI: 256589000, IMO: 9356684, SHIPNAME: "ALEXANDRA A" },
  { MMSI: 256590000, IMO: 9322358, SHIPNAME: "CMA CGM VALENCIA" },
  { MMSI: 256592000, IMO: 9951525, SHIPNAME: "CMA CGM INNOVATION" },
  { MMSI: 256598000, IMO: 9938248, SHIPNAME: "CMA CGM BAHIA" },
  { MMSI: 256604000, IMO: 9436367, SHIPNAME: "CMA CGM BIANCA" },
  { MMSI: 256612000, IMO: 9938133, SHIPNAME: "CMA CGM CAPE COD" },
  { MMSI: 256615000, IMO: 9938121, SHIPNAME: "CMA CGM CEDRUS" },
  { MMSI: 256619000, IMO: 9925837, SHIPNAME: "CMA CGM KHAO SOK" },
  { MMSI: 256644000, IMO: 9722651, SHIPNAME: "CMA CGM ARKANSAS" },
  { MMSI: 256649000, IMO: 9127459, SHIPNAME: "ALLEGRI" },
  { MMSI: 256658000, IMO: 9938250, SHIPNAME: "CMA CGM BUZIOS" },
  { MMSI: 256676000, IMO: 9951537, SHIPNAME: "CMA CGM LEGACY" },
  { MMSI: 256687000, IMO: 9436379, SHIPNAME: "CMA CGM SAMSON" },
  { MMSI: 256703000, IMO: 9751107, SHIPNAME: "GEMLIK EXPRESS" },
  { MMSI: 256728000, IMO: 9670119, SHIPNAME: "HARIS" },
  { MMSI: 256763000, IMO: 9723277, SHIPNAME: "ISTANBUL EXPRESS" },
  { MMSI: 256766000, IMO: 9723265, SHIPNAME: "SYDNEY EXPRESS" },
  { MMSI: 256767000, IMO: 9723253, SHIPNAME: "BREMERHAVEN EXPRESS" },
  { MMSI: 256771000, IMO: 9723241, SHIPNAME: "CZECH" },
  { MMSI: 256888000, IMO: 9473028, SHIPNAME: "CMA CGM MELISANDE" },
  { MMSI: 256930000, IMO: 9305594, SHIPNAME: "YANTIAN" },
  { MMSI: 256932000, IMO: 9308510, SHIPNAME: "COSCO HELLAS" },
  { MMSI: 256937000, IMO: 9308508, SHIPNAME: "BEIJING" },
  { MMSI: 256940000, IMO: 9305570, SHIPNAME: "ZIM SHANGHAI" },
  { MMSI: 256968000, IMO: 9722663, SHIPNAME: "CMA CGM COLUMBIA" },
  { MMSI: 256976000, IMO: 9571301, SHIPNAME: "NEW JERSEY TRADER" },
  { MMSI: 257221000, IMO: 9311878, SHIPNAME: "SILVER MARY" },
  { MMSI: 271000261, IMO: 8913320, SHIPNAME: "MEGAN" },
  { MMSI: 271000609, IMO: 9207261, SHIPNAME: "BMI EXPRESS" },
  { MMSI: 271000647, IMO: 9238064, SHIPNAME: "DANIEL A" },
  { MMSI: 271000677, IMO: 9242297, SHIPNAME: "LUCIEN GA" },
  { MMSI: 271000744, IMO: 9297577, SHIPNAME: "KARLA A" },
  { MMSI: 271001057, IMO: 9126742, SHIPNAME: "ROZA A" },
  { MMSI: 271001058, IMO: 9126754, SHIPNAME: "TOMRIZ A" },
  { MMSI: 271001092, IMO: 9379387, SHIPNAME: "CLAIRE A" },
  { MMSI: 271040008, IMO: 9415959, SHIPNAME: "BERNARD A" },
  { MMSI: 271040635, IMO: 9491848, SHIPNAME: "VIVIEN A" },
  { MMSI: 271041000, IMO: 9020340, SHIPNAME: "MARTINE A" },
  { MMSI: 271042433, IMO: 9365855, SHIPNAME: "MUSTAFA DAYI" },
  { MMSI: 271042493, IMO: 9491850, SHIPNAME: "GULBENIZ A" },
  { MMSI: 271042494, IMO: 9020352, SHIPNAME: "INGA A" },
  { MMSI: 271042759, IMO: 9365867, SHIPNAME: "NEVZAT KALKAVAN" },
  { MMSI: 271043028, IMO: 9365879, SHIPNAME: "KAAN KALKAVAN" },
  { MMSI: 271043163, IMO: 9292448, SHIPNAME: "MATILDE A" },
  { MMSI: 271043491, IMO: 9163984, SHIPNAME: "TIMUCIN A" },
  { MMSI: 271044070, IMO: 9299484, SHIPNAME: "MARTHA A" },
  { MMSI: 271044163, IMO: 9308182, SHIPNAME: "TURKON EGYPT" },
  { MMSI: 271044177, IMO: 9315927, SHIPNAME: "SASKIA A" },
  { MMSI: 271044212, IMO: 9265598, SHIPNAME: "MED TEKIRDAG" },
  { MMSI: 271044301, IMO: 9242302, SHIPNAME: "KAPTAN AYTAC A" },
  { MMSI: 271044302, IMO: 9248916, SHIPNAME: "MEHMET KAHVECI A" },
  { MMSI: 271044398, IMO: 9106493, SHIPNAME: "MED DENIZLI" },
  { MMSI: 271044570, IMO: 9740653, SHIPNAME: "MERSIN EXPRESS" },
  { MMSI: 271044633, IMO: 9106479, SHIPNAME: "MED CORLU" },
  { MMSI: 271044978, IMO: 9126924, SHIPNAME: "LEYLA KALKAVAN" },
  { MMSI: 271045478, IMO: 9225794, SHIPNAME: "MED SAMSUN" },
  { MMSI: 271047044, IMO: 9134701, SHIPNAME: "PARPALI" },
  { MMSI: 271047045, IMO: 9211157, SHIPNAME: "KOSOVAK" },
  { MMSI: 271048694, IMO: 9148025, SHIPNAME: "MED AYDIN" },
  { MMSI: 271049029, IMO: 9365831, SHIPNAME: "TURKON ISTANBUL" },
  { MMSI: 271049958, IMO: 9141780, SHIPNAME: "MED TRABZON" },
  { MMSI: 271050526, IMO: 9356660, SHIPNAME: "DIONYSSIS A" },
  { MMSI: 271050679, IMO: 9330252, SHIPNAME: "KAPPA" },
  { MMSI: 271050950, IMO: 9126766, SHIPNAME: "CORELLI" },
  { MMSI: 271050951, IMO: 9356672, SHIPNAME: "VASSILIS A" },
  { MMSI: 271051022, IMO: 9289063, SHIPNAME: "NARA" },
  { MMSI: 271051067, IMO: 9319569, SHIPNAME: "VENTO" },
  { MMSI: 271051138, IMO: 9143879, SHIPNAME: "MED IZMIR" },
  { MMSI: 271051490, IMO: 9162667, SHIPNAME: "LIONFISH" },
  { MMSI: 271051980, IMO: 9330903, SHIPNAME: "DENIS A" },
  { MMSI: 271051981, IMO: 9330903, SHIPNAME: "DENIS A" },
  { MMSI: 271051982, IMO: 9292462, SHIPNAME: "OYKU A" },
  { MMSI: 271051983, IMO: 9330939, SHIPNAME: "ONUR G.A" },
  { MMSI: 271052043, IMO: 9152911, SHIPNAME: "MEDKON MIRA" },
  { MMSI: 272771000, IMO: 9136371, SHIPNAME: "DNIPRO LINE" },
  { MMSI: 273210520, IMO: 9277412, SHIPNAME: "FESCO MONERON" },
  { MMSI: 273212630, IMO: 9341988, SHIPNAME: "PERSEY" },
  { MMSI: 273257110, IMO: 9389291, SHIPNAME: "KAPITAN BYANKIN" },
  { MMSI: 273298570, IMO: 9301304, SHIPNAME: "FESCO YANINA" },
  { MMSI: 273310730, IMO: 9330836, SHIPNAME: "NORILSKIY NICKEL" },
  { MMSI: 273329660, IMO: 9296987, SHIPNAME: "FESCO NOVIK" },
  { MMSI: 273340920, IMO: 9404015, SHIPNAME: "MONCHEGORSK" },
  { MMSI: 273343170, IMO: 9296999, SHIPNAME: "FESCO NAVARIN" },
  { MMSI: 273347430, IMO: 9118355, SHIPNAME: "ZEYA" },
  { MMSI: 273347820, IMO: 9404041, SHIPNAME: "NADEZHDA" },
  { MMSI: 273348820, IMO: 9404039, SHIPNAME: "TALNAKH" },
  { MMSI: 273373920, IMO: 9246140, SHIPNAME: "SASCO AVACHA" },
  { MMSI: 273374720, IMO: 9208459, SHIPNAME: "NAYADA" },
  { MMSI: 273383520, IMO: 9255402, SHIPNAME: "SASCO ANIVA" },
  { MMSI: 273390170, IMO: 9242986, SHIPNAME: "SASCO ANGARA" },
  { MMSI: 273390174, IMO: 9242986, SHIPNAME: "SASCO ANGARA" },
  { MMSI: 273399770, IMO: 9226384, SHIPNAME: "IVAN KAPRALOV" },
  { MMSI: 273430810, IMO: 9428229, SHIPNAME: "IRIDA" },
  { MMSI: 273452980, IMO: 9287699, SHIPNAME: "FESCO MAGADAN" },
  { MMSI: 273455590, IMO: 9367853, SHIPNAME: "VENIAMIN KHLOPIN" },
  { MMSI: 273611080, IMO: 9155389, SHIPNAME: "CRYSTAL STPETERSBURG" },
  { MMSI: 273611350, IMO: 9319583, SHIPNAME: "TRANSIT LUGOVAYA" },
  { MMSI: 273611980, IMO: 9367970, SHIPNAME: "MIKHAIL ROBKANOV" },
  { MMSI: 273612270, IMO: 9957048, SHIPNAME: "TRANSIT TAVAYZA" },
  { MMSI: 273613560, IMO: 9505522, SHIPNAME: "FESCO ANASTASIA" },
  { MMSI: 273615650, IMO: 9339105, SHIPNAME: "FESCO TATARSTAN" },
  { MMSI: 273616390, IMO: 9338292, SHIPNAME: "TRANSIT MILLIONKA" },
  { MMSI: 275508000, IMO: 9366225, SHIPNAME: "ARTEMIS" },
  { MMSI: 275524000, IMO: 9129469, SHIPNAME: "PERSEUS" },
  { MMSI: 275535000, IMO: 9328651, SHIPNAME: "SHIPLILLY REINVENTIO" },
  { MMSI: 276870000, IMO: 9083043, SHIPNAME: "NANTO" },
  { MMSI: 277549000, IMO: 9327580, SHIPNAME: "KANTATA" },
  { MMSI: 277550000, IMO: 9165308, SHIPNAME: "VICTORIA" },
  { MMSI: 277562000, IMO: 9316103, SHIPNAME: "SONATA" },
  { MMSI: 303189000, IMO: 9215696, SHIPNAME: "SSG CARTER" },
  { MMSI: 303340000, IMO: 9526502, SHIPNAME: "PRESIDENT CLEVELAND" },
  { MMSI: 303352000, IMO: 9295220, SHIPNAME: "PRESIDENT EISENHOWER" },
  { MMSI: 303584000, IMO: 9273686, SHIPNAME: "MAUNALEI" },
  { MMSI: 303657000, IMO: 9333010, SHIPNAME: "MAERSK KENSINGTON" },
  { MMSI: 304010000, IMO: 9294018, SHIPNAME: "JULIUS S" },
  { MMSI: 304010376, IMO: 9063988, SHIPNAME: "BORDER" },
  { MMSI: 304010618, IMO: 9110547, SHIPNAME: "CARIBE MARINER" },
  { MMSI: 304044000, IMO: 9491599, SHIPNAME: "VEGA SCORPIO" },
  { MMSI: 304071000, IMO: 9675834, SHIPNAME: "ELBTRAVELLER" },
  { MMSI: 304080000, IMO: 9234989, SHIPNAME: "AURORA" },
  { MMSI: 304109000, IMO: 9113721, SHIPNAME: "CARIBE VOYAGER" },
  { MMSI: 304122000, IMO: 9355434, SHIPNAME: "PEGASUS J" },
  { MMSI: 304157000, IMO: 9362956, SHIPNAME: "HELENE" },
  { MMSI: 304198000, IMO: 9150432, SHIPNAME: "MARIA DA PAZ" },
  { MMSI: 304304000, IMO: 9430064, SHIPNAME: "REGULA" },
  { MMSI: 304373000, IMO: 9235385, SHIPNAME: "JANINA" },
  { MMSI: 304374000, IMO: 9328637, SHIPNAME: "FREDERIK" },
  { MMSI: 304416000, IMO: 9242596, SHIPNAME: "STAR COMET" },
  { MMSI: 304427000, IMO: 9046423, SHIPNAME: "TONY STARK" },
  { MMSI: 304460000, IMO: 9252876, SHIPNAME: "NADJA" },
  { MMSI: 304461000, IMO: 9252864, SHIPNAME: "MARINA" },
  { MMSI: 304474000, IMO: 9295531, SHIPNAME: "PEYTON LYNN C" },
  { MMSI: 304490000, IMO: 9175705, SHIPNAME: "MANDO" },
  { MMSI: 304496000, IMO: 9126998, SHIPNAME: "TITAN" },
  { MMSI: 304504000, IMO: 9219381, SHIPNAME: "LODUR" },
  { MMSI: 304619000, IMO: 9298612, SHIPNAME: "NCL HAUGESUND" },
  { MMSI: 304623000, IMO: 9246530, SHIPNAME: "KATHERINE BORCHARD" },
  { MMSI: 304667000, IMO: 9448695, SHIPNAME: "ELBSAILOR" },
  { MMSI: 304670000, IMO: 9118018, SHIPNAME: "CHARLOTTE" },
  { MMSI: 304688000, IMO: 9123324, SHIPNAME: "BF PHILIPP" },
  { MMSI: 304747000, IMO: 9306251, SHIPNAME: "MAUREN" },
  { MMSI: 304809000, IMO: 9433949, SHIPNAME: "ZORAN DJINDJIC" },
  { MMSI: 304811000, IMO: 9242314, SHIPNAME: "HORIZON" },
  { MMSI: 304812000, IMO: 9339052, SHIPNAME: "BEOGRAD" },
  { MMSI: 304834000, IMO: 9327566, SHIPNAME: "IRIS MIKO" },
  { MMSI: 304846000, IMO: 9197521, SHIPNAME: "EMILIA" },
  { MMSI: 304903000, IMO: 9306835, SHIPNAME: "ASTRID L" },
  { MMSI: 304906000, IMO: 9344239, SHIPNAME: "DIANA J" },
  { MMSI: 304907000, IMO: 9252785, SHIPNAME: "BF ESPERANZA" },
  { MMSI: 304922000, IMO: 9322566, SHIPNAME: "JSP RIDER" },
  { MMSI: 304941000, IMO: 9344241, SHIPNAME: "DENEB" },
  { MMSI: 304959000, IMO: 9431331, SHIPNAME: "MARINA L" },
  { MMSI: 304972000, IMO: 9349186, SHIPNAME: "ALDEBARAN J" },
  { MMSI: 304979000, IMO: 9347798, SHIPNAME: "NCL IKORNNES" },
  { MMSI: 305002000, IMO: 9343663, SHIPNAME: "OKEE AURELIA" },
  { MMSI: 305032000, IMO: 9390824, SHIPNAME: "LUCY BORCHARD" },
  { MMSI: 305055000, IMO: 9355458, SHIPNAME: "PAVO J" },
  { MMSI: 305060000, IMO: 9343675, SHIPNAME: "PACANDA" },
  { MMSI: 305115000, IMO: 9355460, SHIPNAME: "SPICA J" },
  { MMSI: 305126000, IMO: 9383596, SHIPNAME: "LANTAU BAY" },
  { MMSI: 305127000, IMO: 9392901, SHIPNAME: "WES SINA" },
  { MMSI: 305134000, IMO: 9435818, SHIPNAME: "HOHEBANK" },
  { MMSI: 305140000, IMO: 9355472, SHIPNAME: "TUCANA" },
  { MMSI: 305213000, IMO: 9295517, SHIPNAME: "AS FRANZISKA" },
  { MMSI: 305230000, IMO: 9944730, SHIPNAME: "MINDORO" },
  { MMSI: 305235000, IMO: 9550345, SHIPNAME: "JAN" },
  { MMSI: 305236000, IMO: 9366237, SHIPNAME: "HOHEPLATE" },
  { MMSI: 305258000, IMO: 9437127, SHIPNAME: "WARNOW BELUGA" },
  { MMSI: 305268000, IMO: 9404091, SHIPNAME: "HENRIKE SCHEPERS" },
  { MMSI: 305287000, IMO: 9231846, SHIPNAME: "NEUENFELDE" },
  { MMSI: 305291000, IMO: 9389423, SHIPNAME: "BREEZE" },
  { MMSI: 305293000, IMO: 9386988, SHIPNAME: "PETKUM" },
  { MMSI: 305313000, IMO: 9437141, SHIPNAME: "ANNALISA P" },
  { MMSI: 305346000, IMO: 9311799, SHIPNAME: "AS CHRISTIANA" },
  { MMSI: 305347000, IMO: 9311775, SHIPNAME: "AS CARLOTTA" },
  { MMSI: 305350000, IMO: 9389435, SHIPNAME: "STORM" },
  { MMSI: 305409000, IMO: 9434450, SHIPNAME: "AS PIA" },
  { MMSI: 305411000, IMO: 9375252, SHIPNAME: "SKOGAFOSS" },
  { MMSI: 305473000, IMO: 9944742, SHIPNAME: "SIARGAO" },
  { MMSI: 305482000, IMO: 9434474, SHIPNAME: "AS PAMELA" },
  { MMSI: 305484000, IMO: 9428217, SHIPNAME: "ORION" },
  { MMSI: 305500000, IMO: 9944754, SHIPNAME: "SAMAL" },
  { MMSI: 305506000, IMO: 9301134, SHIPNAME: "THEODOR" },
  { MMSI: 305510000, IMO: 9353747, SHIPNAME: "WIEBKE SCHEPERS" },
  { MMSI: 305517000, IMO: 9360520, SHIPNAME: "IMKE SCHEPERS" },
  { MMSI: 305523000, IMO: 9944766, SHIPNAME: "HELGOLAND" },
  { MMSI: 305524000, IMO: 9944780, SHIPNAME: "HOOGE" },
  { MMSI: 305538000, IMO: 9445887, SHIPNAME: "CALANDRA" },
  { MMSI: 305540000, IMO: 9944778, SHIPNAME: "LANGENESS" },
  { MMSI: 305545000, IMO: 9937373, SHIPNAME: "PANAY" },
  { MMSI: 305558000, IMO: 9937385, SHIPNAME: "BALTRUM" },
  { MMSI: 305563000, IMO: 9937397, SHIPNAME: "BORKUM" },
  { MMSI: 305567000, IMO: 9937402, SHIPNAME: "PALAWAN" },
  { MMSI: 305568000, IMO: 9968437, SHIPNAME: "CEBU" },
  { MMSI: 305575000, IMO: 9412529, SHIPNAME: "ELBSPRING" },
  { MMSI: 305576000, IMO: 9412531, SHIPNAME: "ELBSKY" },
  { MMSI: 305588000, IMO: 9968449, SHIPNAME: "NORDERNEY" },
  { MMSI: 305600000, IMO: 9242558, SHIPNAME: "NAAMA BORCHARD" },
  { MMSI: 305643000, IMO: 9368041, SHIPNAME: "JSP MISTRAL" },
  { MMSI: 305648000, IMO: 9504047, SHIPNAME: "PHOENIX J" },
  { MMSI: 305654000, IMO: 9328649, SHIPNAME: "ALEXANDER B" },
  { MMSI: 305678000, IMO: 9365154, SHIPNAME: "HS BUSAN" },
  { MMSI: 305680000, IMO: 9846536, SHIPNAME: "HS SINGAPORE" },
  { MMSI: 305685000, IMO: 9846524, SHIPNAME: "HS HONG KONG" },
  { MMSI: 305707000, IMO: 9110559, SHIPNAME: "MARUS" },
  { MMSI: 305723000, IMO: 9368998, SHIPNAME: "PARADERO" },
  { MMSI: 305765000, IMO: 9336294, SHIPNAME: "LOUISE BORCHARD" },
  { MMSI: 305773000, IMO: 9287807, SHIPNAME: "VOHBURG" },
  { MMSI: 305784000, IMO: 9303792, SHIPNAME: "BF TIGER" },
  { MMSI: 305832000, IMO: 9139127, SHIPNAME: "DALYA H" },
  { MMSI: 305886000, IMO: 9242560, SHIPNAME: "AMELIE BORCHARD" },
  { MMSI: 305904000, IMO: 9265586, SHIPNAME: "NOAH" },
  { MMSI: 305969000, IMO: 9634684, SHIPNAME: "OCEANA" },
  { MMSI: 305971000, IMO: 9371426, SHIPNAME: "VIVIENNE SHERI D" },
  { MMSI: 305977000, IMO: 9634696, SHIPNAME: "MCC SHENZHEN" },
  { MMSI: 305979000, IMO: 9429194, SHIPNAME: "BAKKAFOSS" },
  { MMSI: 308015000, IMO: 9321249, SHIPNAME: "MOL CHARISMA" },
  { MMSI: 308025000, IMO: 9202780, SHIPNAME: "RESOLUTION" },
  { MMSI: 309046000, IMO: 9321237, SHIPNAME: "MOL CREATION" },
  { MMSI: 309403000, IMO: 9185293, SHIPNAME: "DOLE COLOMBIA" },
  { MMSI: 309901000, IMO: 9321251, SHIPNAME: "MOL CELEBRATION" },
  { MMSI: 309942000, IMO: 9185281, SHIPNAME: "DOLE CHILE" },
  { MMSI: 309947000, IMO: 9403853, SHIPNAME: "HYPERION" },
  { MMSI: 309972000, IMO: 9460057, SHIPNAME: "HARRIER" },
  { MMSI: 310132000, IMO: 9108128, SHIPNAME: "LISBON EXPRESS" },
  { MMSI: 310749000, IMO: 9253727, SHIPNAME: "TORONTO EXPRESS" },
  { MMSI: 310750000, IMO: 9253741, SHIPNAME: "MONTREAL EXPRESS" },
  { MMSI: 310760000, IMO: 9294836, SHIPNAME: "QUEBEC EXPRESS" },
  { MMSI: 310761000, IMO: 9290816, SHIPNAME: "ONTARIO EXPRESS" },
  { MMSI: 310764000, IMO: 9143544, SHIPNAME: "KOBE EXPRESS" },
  { MMSI: 310765000, IMO: 9143568, SHIPNAME: "LONDON EXPRESS" },
  { MMSI: 310768000, IMO: 9143556, SHIPNAME: "DUESSELDORF EXPRESS" },
  { MMSI: 310823000, IMO: 9232565, SHIPNAME: "LIVERPOOL EXPRESS" },
  { MMSI: 310825000, IMO: 9232577, SHIPNAME: "DUBLIN EXPRESS" },
  { MMSI: 310826000, IMO: 9232589, SHIPNAME: "GLASGOW EXPRESS" },
  { MMSI: 311000169, IMO: 9674646, SHIPNAME: "ULTIMA" },
  { MMSI: 311000414, IMO: 9703057, SHIPNAME: "DOLE PACIFIC" },
  { MMSI: 311000415, IMO: 9703069, SHIPNAME: "DOLE ATLANTIC" },
  { MMSI: 311000416, IMO: 9703071, SHIPNAME: "DOLE CARIBBEAN" },
  { MMSI: 311000629, IMO: 9796511, SHIPNAME: "KUO LIN" },
  { MMSI: 311000725, IMO: 9808039, SHIPNAME: "EPONYMA" },
  { MMSI: 311000780, IMO: 9808041, SHIPNAME: "ESTIMA" },
  { MMSI: 311000921, IMO: 9356309, SHIPNAME: "CMA CGM HYDRA" },
  { MMSI: 311000922, IMO: 9356311, SHIPNAME: "CMA CGM MUSCA" },
  { MMSI: 311000923, IMO: 9454436, SHIPNAME: "CMA CGM MARCO POLO" },
  { MMSI: 311001005, IMO: 9877729, SHIPNAME: "DOLE MAYA" },
  { MMSI: 311001006, IMO: 9877731, SHIPNAME: "DOLE AZTEC" },
  { MMSI: 311001120, IMO: 9938420, SHIPNAME: "LIMA EXPRESS" },
  { MMSI: 311001150, IMO: 9224312, SHIPNAME: "CMA CGM CHIWAN" },
  { MMSI: 311001218, IMO: 9275050, SHIPNAME: "CMA CGM P.ANTIOQUIA" },
  { MMSI: 311001244, IMO: 9227027, SHIPNAME: "SUNNY PHOENIX" },
  { MMSI: 311001345, IMO: 9227039, SHIPNAME: "FELIXSTOWE" },
  { MMSI: 311001416, IMO: 9365790, SHIPNAME: "CMA CGM BUTTERFLY" },
  { MMSI: 311043200, IMO: 9451915, SHIPNAME: "CMA CGM AFRICA ONE" },
  { MMSI: 311043300, IMO: 9451927, SHIPNAME: "CMA CGM AFRICA TWO" },
  { MMSI: 311044100, IMO: 9451965, SHIPNAME: "CMA CGM AFRICA FOUR" },
  { MMSI: 311044200, IMO: 9451939, SHIPNAME: "CMA CGM AFRICA THREE" },
  { MMSI: 311049600, IMO: 9609419, SHIPNAME: "OPTIMA" },
  { MMSI: 311058000, IMO: 9390642, SHIPNAME: "HALCYON" },
  { MMSI: 311060500, IMO: 9220859, SHIPNAME: "KUMASI" },
  { MMSI: 311272000, IMO: 9250933, SHIPNAME: "REVERENCE" },
  { MMSI: 311326000, IMO: 9220861, SHIPNAME: "NICOLAS DELMAS" },
  { MMSI: 311365000, IMO: 9251169, SHIPNAME: "RESURGENCE" },
  { MMSI: 311691000, IMO: 9291339, SHIPNAME: "REFLECTION" },
  { MMSI: 311703000, IMO: 9296468, SHIPNAME: "MAGNA" },
  { MMSI: 311935000, IMO: 9301110, SHIPNAME: "RENOWN" },
  { MMSI: 312016000, IMO: 9223734, SHIPNAME: "RISUN 9" },
  { MMSI: 312101000, IMO: 9038373, SHIPNAME: "LADY NURAY" },
  { MMSI: 312134000, IMO: 9364710, SHIPNAME: "BEI JIANG" },
  { MMSI: 312338000, IMO: 9377353, SHIPNAME: "JING YE" },
  { MMSI: 312479000, IMO: 9234422, SHIPNAME: "GRETA" },
  { MMSI: 312991000, IMO: 9118513, SHIPNAME: "SKY LIGHT" },
  { MMSI: 314732000, IMO: 9400590, SHIPNAME: "SPICA STAR" },
  { MMSI: 314747000, IMO: 9387607, SHIPNAME: "RMS MEL" },
  { MMSI: 314749000, IMO: 9143855, SHIPNAME: "HANA H" },
  { MMSI: 314753000, IMO: 9343699, SHIPNAME: "GLEVIN" },
  { MMSI: 314754000, IMO: 9282170, SHIPNAME: "RMS TEAM" },
  { MMSI: 314792000, IMO: 9168207, SHIPNAME: "PALERMO" },
  { MMSI: 316006074, IMO: 9315044, SHIPNAME: "OCEANEX AVALON" },
  { MMSI: 316030879, IMO: 9348182, SHIPNAME: "ASTERIX" },
  { MMSI: 319200700, IMO: 9337614, SHIPNAME: "NYK DAEDALUS" },
  { MMSI: 319211600, IMO: 9468310, SHIPNAME: "ONE ARCADIA" },
  { MMSI: 319219200, IMO: 9356713, SHIPNAME: "ONE TRITON" },
  { MMSI: 325974000, IMO: 8908545, SHIPNAME: "CARINA" },
  { MMSI: 334953000, IMO: 9130121, SHIPNAME: "AMO 2" },
  { MMSI: 334955000, IMO: 9134622, SHIPNAME: "AMO" },
  { MMSI: 338071000, IMO: 9342176, SHIPNAME: "MAERSK PITTSBURGH" },
  { MMSI: 338078000, IMO: 9348649, SHIPNAME: "MAERSK ATLANTA" },
  { MMSI: 338121000, IMO: 9814600, SHIPNAME: "LURLINE" },
  { MMSI: 338241000, IMO: 9333022, SHIPNAME: "MAERSK KINLOSS" },
  { MMSI: 338403000, IMO: 9333008, SHIPNAME: "MAERSK HARTFORD" },
  { MMSI: 338408000, IMO: 9332975, SHIPNAME: "MAERSK CHICAGO" },
  { MMSI: 338418000, IMO: 9332999, SHIPNAME: "MAERSK DENVER" },
  { MMSI: 338459000, IMO: 9360142, SHIPNAME: "APL ISLANDER" },
  { MMSI: 338474000, IMO: 9333034, SHIPNAME: "MAERSK DETROIT" },
  { MMSI: 338525000, IMO: 9332987, SHIPNAME: "MAERSK COLUMBUS" },
  { MMSI: 338566000, IMO: 9232979, SHIPNAME: "KAMOKUIKI" },
  { MMSI: 338679000, IMO: 9538658, SHIPNAME: "PRESIDENT TRUMAN" },
  { MMSI: 338760000, IMO: 9680841, SHIPNAME: "ISLA BELLA" },
  { MMSI: 338775000, IMO: 9356074, SHIPNAME: "MAERSK CHESAPEAKE" },
  { MMSI: 338789000, IMO: 9680853, SHIPNAME: "PERLA DEL CARIBE" },
  { MMSI: 338796000, IMO: 9814612, SHIPNAME: "MATSONIA" },
  { MMSI: 338864000, IMO: 9215660, SHIPNAME: "MV PAGE" },
  { MMSI: 341063001, IMO: 9266126, SHIPNAME: "BOSON" },
  { MMSI: 341104000, IMO: 9128324, SHIPNAME: "MM MADRID" },
  { MMSI: 341233001, IMO: 9148491, SHIPNAME: "SWIMMER.M" },
  { MMSI: 341257000, IMO: 9053919, SHIPNAME: "URANUS" },
  { MMSI: 341258000, IMO: 9083897, SHIPNAME: "SHIRA-E" },
  { MMSI: 341281001, IMO: 9141041, SHIPNAME: "AK FERAS" },
  { MMSI: 341339000, IMO: 9124677, SHIPNAME: "GLUON" },
  { MMSI: 341540000, IMO: 9204116, SHIPNAME: "MARSA VICTORY" },
  { MMSI: 341644000, IMO: 9153135, SHIPNAME: "TANYA" },
  { MMSI: 341652000, IMO: 9122473, SHIPNAME: "DHANU" },
  { MMSI: 341765000, IMO: 9122461, SHIPNAME: "NADIA" },
  { MMSI: 341817000, IMO: 9217022, SHIPNAME: "MARSA NEPTUNE" },
  { MMSI: 341884000, IMO: 9138343, SHIPNAME: "LUCIA" },
  { MMSI: 341988000, IMO: 9259408, SHIPNAME: "CERUS" },
  { MMSI: 351018000, IMO: 9322865, SHIPNAME: "SOL PROGRESS" },
  { MMSI: 351038000, IMO: 9823766, SHIPNAME: "MCC TAIPEI" },
  { MMSI: 351109000, IMO: 9567673, SHIPNAME: "OOCL DURBAN" },
  { MMSI: 351248000, IMO: 9251690, SHIPNAME: "MSC LUDOVICA" },
  { MMSI: 351249000, IMO: 9312975, SHIPNAME: "NYK OCEANUS" },
  { MMSI: 351268000, IMO: 9073062, SHIPNAME: "MSC SOPHIE" },
  { MMSI: 351272000, IMO: 9197349, SHIPNAME: "BANGKOK" },
  { MMSI: 351285000, IMO: 9263344, SHIPNAME: "MSC ASTRID III" },
  { MMSI: 351325000, IMO: 9441740, SHIPNAME: "POLARIS 3" },
  { MMSI: 351356000, IMO: 8715857, SHIPNAME: "MSC SARISKA V" },
  { MMSI: 351361000, IMO: 9399026, SHIPNAME: "MSC KALINA" },
  { MMSI: 351400000, IMO: 9316347, SHIPNAME: "MSC HANNAH" },
  { MMSI: 351404000, IMO: 9868364, SHIPNAME: "HMM ST PETERSBURG" },
  { MMSI: 351453000, IMO: 9689653, SHIPNAME: "SUNNY DAISY" },
  { MMSI: 351465000, IMO: 9786932, SHIPNAME: "EVER BLISS" },
  { MMSI: 351473000, IMO: 9235050, SHIPNAME: "MSC ALYSSA" },
  { MMSI: 351496000, IMO: 9333840, SHIPNAME: "MOL EARNEST" },
  { MMSI: 351513000, IMO: 9154828, SHIPNAME: "PACIFIC GRACE" },
  { MMSI: 351563000, IMO: 9138161, SHIPNAME: "AEGEAN EXPRESS" },
  { MMSI: 351566000, IMO: 9138159, SHIPNAME: "ISEACO FORTUNE" },
  { MMSI: 351567000, IMO: 9149835, SHIPNAME: "STRIDE" },
  { MMSI: 351571000, IMO: 9850575, SHIPNAME: "EVER FOREVER" },
  { MMSI: 351577000, IMO: 9149847, SHIPNAME: "FUTURE" },
  { MMSI: 351607000, IMO: 9060649, SHIPNAME: "MSC MONICA III" },
  { MMSI: 351634000, IMO: 8616520, SHIPNAME: "MSC MARIA LAURA II" },
  { MMSI: 351639000, IMO: 9470753, SHIPNAME: "PENANG BRIDGE" },
  { MMSI: 351645000, IMO: 9149859, SHIPNAME: "ADVANCE" },
  { MMSI: 351663000, IMO: 9163441, SHIPNAME: "A GALAXY" },
  { MMSI: 351664000, IMO: 9629407, SHIPNAME: "ITX EHIME" },
  { MMSI: 351674000, IMO: 9826562, SHIPNAME: "SUNNY LAUREL" },
  { MMSI: 351675000, IMO: 9110389, SHIPNAME: "MSC KATYAYNI" },
  { MMSI: 351676000, IMO: 9259642, SHIPNAME: "MOHSEN ILYAS" },
  { MMSI: 351678000, IMO: 9871165, SHIPNAME: "MAERSK DHAKA" },
  { MMSI: 351738000, IMO: 9060637, SHIPNAME: "MSC MARTINA" },
  { MMSI: 351753000, IMO: 9875070, SHIPNAME: "EVER CORE" },
  { MMSI: 351819000, IMO: 9618288, SHIPNAME: "MSC ALGHERO" },
  { MMSI: 351841000, IMO: 9149861, SHIPNAME: "SPRINTER" },
  { MMSI: 351848000, IMO: 9467457, SHIPNAME: "MSC KATIE" },
  { MMSI: 351862000, IMO: 9839260, SHIPNAME: "MSC MINA" },
  { MMSI: 351917000, IMO: 9219393, SHIPNAME: "MSC HELENA III" },
  { MMSI: 351934000, IMO: 9188154, SHIPNAME: "EVER UTILE" },
  { MMSI: 351965000, IMO: 9148544, SHIPNAME: "KUO LUNG" },
  { MMSI: 351977000, IMO: 9624275, SHIPNAME: "MAERSK ARAS" },
  { MMSI: 352001038, IMO: 9913872, SHIPNAME: "EVER OPUS" },
  { MMSI: 352001068, IMO: 9913858, SHIPNAME: "EVER OATH" },
  { MMSI: 352001116, IMO: 9357121, SHIPNAME: "TRANSMAR LEGACY" },
  { MMSI: 352001129, IMO: 9166704, SHIPNAME: "SOO SHIN" },
  { MMSI: 352001168, IMO: 9290426, SHIPNAME: "MSC SANTA MARIA" },
  { MMSI: 352001178, IMO: 9913846, SHIPNAME: "EVER CONFORM" },
  { MMSI: 352001244, IMO: 9232395, SHIPNAME: "MSC FLOSTA III" },
  { MMSI: 352001259, IMO: 9893955, SHIPNAME: "EVER ALOT" },
  { MMSI: 352001261, IMO: 9919462, SHIPNAME: "EVER OBEY" },
  { MMSI: 352001313, IMO: 9665619, SHIPNAME: "EVER TOP" },
  { MMSI: 352001323, IMO: 9360283, SHIPNAME: "MSC AUBE F" },
  { MMSI: 352001363, IMO: 9919450, SHIPNAME: "EVER CONSIST" },
  { MMSI: 352001367, IMO: 9289544, SHIPNAME: "MSC BARBADOS" },
  { MMSI: 352001525, IMO: 9919474, SHIPNAME: "EVER OPTIMA" },
  { MMSI: 352001564, IMO: 9305934, SHIPNAME: "MEDKON SAMSUN" },
  { MMSI: 352001639, IMO: 9186431, SHIPNAME: "PROVIDENT" },
  { MMSI: 352001683, IMO: 9961271, SHIPNAME: "ANL TASMAN TRADER" },
  { MMSI: 352001685, IMO: 9962524, SHIPNAME: "CMA CGM BLOSSOM" },
  { MMSI: 352001710, IMO: 9430040, SHIPNAME: "MEDKON MERSIN" },
  { MMSI: 352001731, IMO: 9936147, SHIPNAME: "GH RIVER" },
  { MMSI: 352001747, IMO: 9909132, SHIPNAME: "EVER ARIA" },
  { MMSI: 352001841, IMO: 9139907, SHIPNAME: "XIN HE LU 1" },
  { MMSI: 352001848, IMO: 9377080, SHIPNAME: "ZHONG XING HONG YUN" },
  { MMSI: 352001881, IMO: 9159153, SHIPNAME: "HUA XIANG 936" },
  { MMSI: 352001951, IMO: 9913901, SHIPNAME: "EVER OUTWIT" },
  { MMSI: 352001987, IMO: 9976240, SHIPNAME: "MAO GANG QUAN ZHOU" },
  { MMSI: 352002065, IMO: 9928188, SHIPNAME: "MAERSK ACADIA" },
  { MMSI: 352002086, IMO: 9499539, SHIPNAME: "LIDER PERIHAN" },
  { MMSI: 352002088, IMO: 9266102, SHIPNAME: "SOL RESILIENCE" },
  { MMSI: 352002094, IMO: 9923970, SHIPNAME: "KANWAY LUCKY" },
  { MMSI: 352002104, IMO: 9958066, SHIPNAME: "SKY IRIS" },
  { MMSI: 352002182, IMO: 9168233, SHIPNAME: "FESCO TRADER" },
  { MMSI: 352002186, IMO: 9276341, SHIPNAME: "YASAR" },
  { MMSI: 352002215, IMO: 8741026, SHIPNAME: "YONG YUE 11" },
  { MMSI: 352002245, IMO: 9928190, SHIPNAME: "MAERSK BISCAYNE" },
  { MMSI: 352002265, IMO: 9158745, SHIPNAME: "HONOR OCEAN" },
  { MMSI: 352002269, IMO: 9158757, SHIPNAME: "HONOR VOYAGER" },
  { MMSI: 352002303, IMO: 9179995, SHIPNAME: "CHANG SHIN" },
  { MMSI: 352002312, IMO: 9305881, SHIPNAME: "MERATUS JAYAWIJAYA" },
  { MMSI: 352002350, IMO: 9300130, SHIPNAME: "D QUEENS" },
  { MMSI: 352002356, IMO: 9159646, SHIPNAME: "SHUN LONG" },
  { MMSI: 352002364, IMO: 9341964, SHIPNAME: "MEDKON CANAKKALE" },
  { MMSI: 352002368, IMO: 9961532, SHIPNAME: "A GORYU" },
  { MMSI: 352002391, IMO: 9928205, SHIPNAME: "MAERSK EVERGLADES" },
  { MMSI: 352002399, IMO: 9960942, SHIPNAME: "POS QINGDAO" },
  { MMSI: 352002408, IMO: 9960954, SHIPNAME: "POS GUANGZHOU" },
  { MMSI: 352002410, IMO: 9958078, SHIPNAME: "SKY TIARA" },
  { MMSI: 352002451, IMO: 9555242, SHIPNAME: "A KOBE" },
  { MMSI: 352002466, IMO: 9235828, SHIPNAME: "EUPHORIA" },
  { MMSI: 352002487, IMO: 9238753, SHIPNAME: "SFT TURKEY" },
  { MMSI: 352002499, IMO: 9150195, SHIPNAME: "OVP ARIES" },
  { MMSI: 352002534, IMO: 9139921, SHIPNAME: "XIN HE LU 2" },
  { MMSI: 352002539, IMO: 9981348, SHIPNAME: "MAO GANG GUANG ZHOU" },
  { MMSI: 352002559, IMO: 9255531, SHIPNAME: "ESCADA" },
  { MMSI: 352002561, IMO: 9305879, SHIPNAME: "MERATUS JAYAKARTA" },
  { MMSI: 352002579, IMO: 9961544, SHIPNAME: "A WASHIBA" },
  { MMSI: 352002592, IMO: 9292943, SHIPNAME: "ZEYNEP" },
  { MMSI: 352002597, IMO: 9385441, SHIPNAME: "OPUS ONE" },
  { MMSI: 352002625, IMO: 9928217, SHIPNAME: "MAERSK GLACIER" },
  { MMSI: 352002645, IMO: 9213105, SHIPNAME: "MERATUS JIMBARAN" },
  { MMSI: 352002648, IMO: 9374583, SHIPNAME: "MERATUS TOMINI" },
  { MMSI: 352002649, IMO: 9213117, SHIPNAME: "MERATUS JAYAGIRI" },
  { MMSI: 352002650, IMO: 9178393, SHIPNAME: "MERATUS MEDAN 5" },
  { MMSI: 352002731, IMO: 9964742, SHIPNAME: "SUNNY LILAC" },
  { MMSI: 352002748, IMO: 9226413, SHIPNAME: "HONWELL" },
  { MMSI: 352002790, IMO: 9139646, SHIPNAME: "MEDKON LIA" },
  { MMSI: 352002798, IMO: 9431630, SHIPNAME: "ALEJANDRINA" },
  { MMSI: 352002865, IMO: 9230206, SHIPNAME: "HONRISE" },
  { MMSI: 352002880, IMO: 9182019, SHIPNAME: "STAR BLESSING" },
  { MMSI: 352002943, IMO: 9366457, SHIPNAME: "MEDKON PERLA" },
  { MMSI: 352002971, IMO: 9963138, SHIPNAME: "YEOSU VOYAGER" },
  { MMSI: 352002972, IMO: 9963140, SHIPNAME: "INCHEON VOYAGER" },
  { MMSI: 352002986, IMO: 9233648, SHIPNAME: "ABRAO COCHIN" },
  { MMSI: 352002992, IMO: 9301445, SHIPNAME: "MARSA PRIDE" },
  { MMSI: 352002997, IMO: 9928229, SHIPNAME: "MAERSK MAMMOTH" },
  { MMSI: 352003005, IMO: 9230232, SHIPNAME: "NZ SHANGHAI" },
  { MMSI: 352003007, IMO: 9108233, SHIPNAME: "SIDRA OCEAN" },
  { MMSI: 352003029, IMO: 9337028, SHIPNAME: "MED STAR" },
  { MMSI: 352003036, IMO: 9961568, SHIPNAME: "A ONTAKE" },
  { MMSI: 352003050, IMO: 9233636, SHIPNAME: "RC OCEAN" },
  { MMSI: 352003066, IMO: 9178525, SHIPNAME: "HONG CHANG SHENG" },
  { MMSI: 352003081, IMO: 9964754, SHIPNAME: "SUNNY VIOLET" },
  { MMSI: 352003099, IMO: 9342798, SHIPNAME: "TC MESSENGER" },
  { MMSI: 352003103, IMO: 9430870, SHIPNAME: "CTM ISTMO" },
  { MMSI: 352003107, IMO: 9963152, SHIPNAME: "ULSAN VOYAGER" },
  { MMSI: 352003112, IMO: 9989807, SHIPNAME: "BRIGHT SAKURA" },
  { MMSI: 352003114, IMO: 9989821, SHIPNAME: "BRIGHT COSMOS" },
  { MMSI: 352003133, IMO: 9200811, SHIPNAME: "FLYING FISH 1" },
  { MMSI: 352003243, IMO: 9669639, SHIPNAME: "ITX HIGO" },
  { MMSI: 352003254, IMO: 9238155, SHIPNAME: "AN HAI" },
  { MMSI: 352003258, IMO: 9133331, SHIPNAME: "GRAND MARINER" },
  { MMSI: 352003293, IMO: 9982419, SHIPNAME: "YUAN XIANG FEN JIN" },
  { MMSI: 352003391, IMO: 1029871, SHIPNAME: "HUA XIN 678" },
  { MMSI: 352029885, IMO: 9903243, SHIPNAME: "EVER CLEVER" },
  { MMSI: 352058000, IMO: 9826550, SHIPNAME: "SUNNY CANNA" },
  { MMSI: 352070000, IMO: 9418640, SHIPNAME: "SPIL CAYA" },
  { MMSI: 352111000, IMO: 9850812, SHIPNAME: "EVER FINE" },
  { MMSI: 352135000, IMO: 9604122, SHIPNAME: "EVER LOTUS" },
  { MMSI: 352138000, IMO: 9051478, SHIPNAME: "MSC JEMIMA" },
  { MMSI: 352140000, IMO: 9230490, SHIPNAME: "MSC LORETTA" },
  { MMSI: 352149000, IMO: 9737486, SHIPNAME: "TOLEDO TRIUMPH" },
  { MMSI: 352153000, IMO: 9160700, SHIPNAME: "SONGYUNHE" },
  { MMSI: 352157000, IMO: 9158563, SHIPNAME: "PROGRESS C" },
  { MMSI: 352254000, IMO: 9894686, SHIPNAME: "MAERSK NORESUND" },
  { MMSI: 352260000, IMO: 9868314, SHIPNAME: "HMM LE HAVRE" },
  { MMSI: 352269000, IMO: 9839480, SHIPNAME: "MSC AMBRA" },
  { MMSI: 352297000, IMO: 9343687, SHIPNAME: "FESCO EKATERINA" },
  { MMSI: 352335000, IMO: 9226920, SHIPNAME: "MSC MARIANNA" },
  { MMSI: 352358000, IMO: 9633939, SHIPNAME: "CAP SAN ARTEMISSIO" },
  { MMSI: 352361000, IMO: 9399038, SHIPNAME: "MSC BETTINA" },
  { MMSI: 352366000, IMO: 9737503, SHIPNAME: "TEXAS TRIUMPH" },
  { MMSI: 352374000, IMO: 9848871, SHIPNAME: "KMTC TOKYO" },
  { MMSI: 352382000, IMO: 9172595, SHIPNAME: "TI2 APPLE" },
  { MMSI: 352385000, IMO: 9345972, SHIPNAME: "NAVIOS NERINE" },
  { MMSI: 352408000, IMO: 9264764, SHIPNAME: "MERIOS" },
  { MMSI: 352456000, IMO: 9228772, SHIPNAME: "MIYUNHE" },
  { MMSI: 352471000, IMO: 9243239, SHIPNAME: "MSC ALIZEE III" },
  { MMSI: 352544000, IMO: 9400813, SHIPNAME: "MARINA VOYAGER" },
  { MMSI: 352578000, IMO: 9221059, SHIPNAME: "MSC QINGDAO F" },
  { MMSI: 352619000, IMO: 9618290, SHIPNAME: "MSC ADELAIDE" },
  { MMSI: 352631000, IMO: 9345427, SHIPNAME: "COSCO AMERICA" },
  { MMSI: 352687000, IMO: 9081019, SHIPNAME: "SOL MALAYSIA" },
  { MMSI: 352688000, IMO: 9290402, SHIPNAME: "MSC FELIXSTOWE" },
  { MMSI: 352745000, IMO: 9318254, SHIPNAME: "SOL FORTUNE" },
  { MMSI: 352757000, IMO: 9703318, SHIPNAME: "MSC ZOE" },
  { MMSI: 352808000, IMO: 9053505, SHIPNAME: "HAE SHIN" },
  { MMSI: 352822000, IMO: 9312999, SHIPNAME: "NYK ORION" },
  { MMSI: 352830000, IMO: 9412804, SHIPNAME: "ORIENTAL BRIGHT" },
  { MMSI: 352853000, IMO: 9606302, SHIPNAME: "MSC LONDON" },
  { MMSI: 352866000, IMO: 9870006, SHIPNAME: "EVER CHEER" },
  { MMSI: 352870000, IMO: 9251705, SHIPNAME: "MSC FLORENTINA" },
  { MMSI: 352871000, IMO: 9339284, SHIPNAME: "MSC CANDICE" },
  { MMSI: 352898704, IMO: 9153410, SHIPNAME: "CHENNAI VOYAGER" },
  { MMSI: 352898711, IMO: 9330264, SHIPNAME: "MSC LEVANTE F" },
  { MMSI: 352898717, IMO: 9893929, SHIPNAME: "EVER ALP" },
  { MMSI: 352898766, IMO: 9919589, SHIPNAME: "EVER CONNECT" },
  { MMSI: 352898818, IMO: 9239733, SHIPNAME: "MSC SANDY III" },
  { MMSI: 352921000, IMO: 9390812, SHIPNAME: "MEDKON NLS" },
  { MMSI: 352950000, IMO: 9110810, SHIPNAME: "XIN YU JIN XIANG" },
  { MMSI: 352952000, IMO: 9606314, SHIPNAME: "MSC NEW YORK" },
  { MMSI: 352965000, IMO: 9143790, SHIPNAME: "YAKOOT" },
  { MMSI: 352978125, IMO: 9187485, SHIPNAME: "HE YUAN 1" },
  { MMSI: 352978135, IMO: 9714941, SHIPNAME: "HUI DA 9" },
  { MMSI: 352978152, IMO: 9714939, SHIPNAME: "HUI FA" },
  { MMSI: 352978178, IMO: 9159878, SHIPNAME: "LOA GLORY" },
  { MMSI: 352978183, IMO: 9913810, SHIPNAME: "EVER COMPOSE" },
  { MMSI: 352978194, IMO: 9878515, SHIPNAME: "YM TOPMOST" },
  { MMSI: 352978199, IMO: 9893905, SHIPNAME: "EVER ACT" },
  { MMSI: 352978207, IMO: 9212448, SHIPNAME: "STORM I" },
  { MMSI: 352978233, IMO: 9367803, SHIPNAME: "SPIL NIRMALA" },
  { MMSI: 352980780, IMO: 9273959, SHIPNAME: "SPIL NINGSIH" },
  { MMSI: 352980788, IMO: 9893917, SHIPNAME: "EVER AIM" },
  { MMSI: 352980799, IMO: 9232400, SHIPNAME: "SPIL NITA" },
  { MMSI: 352980812, IMO: 9913822, SHIPNAME: "EVER CONCISE" },
  { MMSI: 352980861, IMO: 9913860, SHIPNAME: "EVER OWN" },
  { MMSI: 352980873, IMO: 9913834, SHIPNAME: "EVER CONCERT" },
  { MMSI: 352983000, IMO: 9279989, SHIPNAME: "MSC STELLA" },
  { MMSI: 352986146, IMO: 9893890, SHIPNAME: "EVER ACE" },
  { MMSI: 352986159, IMO: 9878503, SHIPNAME: "YM TRAVEL" },
  { MMSI: 352986169, IMO: 9273947, SHIPNAME: "SPIL NIKEN" },
  { MMSI: 353000000, IMO: 0, SHIPNAME: "NAVIOS ALTAIR" },
  { MMSI: 353025000, IMO: 9337664, SHIPNAME: "NYK DEMETER" },
  { MMSI: 353051000, IMO: 9401142, SHIPNAME: "MSC GAIA" },
  { MMSI: 353059000, IMO: 9698379, SHIPNAME: "HEUNG-A SARAH" },
  { MMSI: 353066000, IMO: 9228760, SHIPNAME: "QI YUN HE" },
  { MMSI: 353111000, IMO: 9292175, SHIPNAME: "MSC SHANELLE V" },
  { MMSI: 353117000, IMO: 9306172, SHIPNAME: "MAERSK PUELO" },
  { MMSI: 353136000, IMO: 9811000, SHIPNAME: "EVER GIVEN" },
  { MMSI: 353155000, IMO: 9399052, SHIPNAME: "MSC EMANUELA" },
  { MMSI: 353160000, IMO: 9404663, SHIPNAME: "MSC SONIA" },
  { MMSI: 353162000, IMO: 9399014, SHIPNAME: "MSC BEATRICE" },
  { MMSI: 353201000, IMO: 9859650, SHIPNAME: "POS HOCHIMINH" },
  { MMSI: 353241000, IMO: 9228746, SHIPNAME: "JINYUNHE" },
  { MMSI: 353258000, IMO: 9153082, SHIPNAME: "EASLINE LIANYUNGANG" },
  { MMSI: 353289000, IMO: 9372482, SHIPNAME: "MSC ORIANE" },
  { MMSI: 353290000, IMO: 9515606, SHIPNAME: "MILLENNIUM BRIGHT" },
  { MMSI: 353293000, IMO: 9911874, SHIPNAME: "STRAITS CITY" },
  { MMSI: 353365000, IMO: 9633941, SHIPNAME: "CAP SAN MALEAS" },
  { MMSI: 353368000, IMO: 9463279, SHIPNAME: "BANGKOK BRIDGE" },
  { MMSI: 353428000, IMO: 9467407, SHIPNAME: "MSC LAUREN" },
  { MMSI: 353467000, IMO: 9318125, SHIPNAME: "DOLPHIN II" },
  { MMSI: 353499000, IMO: 9250983, SHIPNAME: "MSC DAMLA" },
  { MMSI: 353590000, IMO: 9839272, SHIPNAME: "MSC ISABELLA" },
  { MMSI: 353592000, IMO: 9588093, SHIPNAME: "ONE HANOI" },
  { MMSI: 353612000, IMO: 9103685, SHIPNAME: "MSC BRIANNA" },
  { MMSI: 353640000, IMO: 9719795, SHIPNAME: "SKY ORION" },
  { MMSI: 353666000, IMO: 9230488, SHIPNAME: "MSC MICHAELA" },
  { MMSI: 353719000, IMO: 9181663, SHIPNAME: "MSC MATILDE V" },
  { MMSI: 353728000, IMO: 9305702, SHIPNAME: "MSC MADELEINE" },
  { MMSI: 353775000, IMO: 9226932, SHIPNAME: "MSC BARBARA" },
  { MMSI: 353780000, IMO: 9409039, SHIPNAME: "ALEXANDRIA BRIDGE" },
  { MMSI: 353800000, IMO: 9784154, SHIPNAME: "EVER BREED" },
  { MMSI: 353824000, IMO: 9172301, SHIPNAME: "ISEACO WISDOM" },
  { MMSI: 353848000, IMO: 9158575, SHIPNAME: "HIGHWAY" },
  { MMSI: 353850000, IMO: 8512891, SHIPNAME: "MSC AUGUSTA" },
  { MMSI: 353851000, IMO: 9158587, SHIPNAME: "BRIDGE" },
  { MMSI: 353852000, IMO: 0, SHIPNAME: "MSC ANNAMARIA" },
  { MMSI: 353873000, IMO: 9282261, SHIPNAME: "MSC ANS" },
  { MMSI: 353903000, IMO: 9560352, SHIPNAME: "SEATTLE BRIDGE" },
  { MMSI: 353934000, IMO: 9282259, SHIPNAME: "MSC ELA" },
  { MMSI: 353968000, IMO: 9281279, SHIPNAME: "MSC LISA" },
  { MMSI: 353997000, IMO: 9757204, SHIPNAME: "ONE MONACO" },
  { MMSI: 354021000, IMO: 9138317, SHIPNAME: "LOA PEACE" },
  { MMSI: 354022000, IMO: 9850824, SHIPNAME: "EVER FORE" },
  { MMSI: 354060000, IMO: 9624299, SHIPNAME: "MAERSK KARUN" },
  { MMSI: 354092000, IMO: 9225665, SHIPNAME: "MSC LAURA" },
  { MMSI: 354106000, IMO: 9451496, SHIPNAME: "CALLAO BRIDGE" },
  { MMSI: 354118000, IMO: 9451472, SHIPNAME: "LOS ANDES BRIDGE" },
  { MMSI: 354120000, IMO: 9463267, SHIPNAME: "BAY BRIDGE" },
  { MMSI: 354194000, IMO: 9249207, SHIPNAME: "BAL PEACE" },
  { MMSI: 354195000, IMO: 9249221, SHIPNAME: "UGL GUANGZHOU" },
  { MMSI: 354202000, IMO: 9281267, SHIPNAME: "MSC ORNELLA" },
  { MMSI: 354212000, IMO: 9337638, SHIPNAME: "NYK METEOR" },
  { MMSI: 354275000, IMO: 9461647, SHIPNAME: "KOTA NILAM" },
  { MMSI: 354299000, IMO: 9860532, SHIPNAME: "GREEN CELESTE" },
  { MMSI: 354315000, IMO: 9309461, SHIPNAME: "MSC TOMOKO" },
  { MMSI: 354340000, IMO: 9226918, SHIPNAME: "MSC MELISSA" },
  { MMSI: 354344000, IMO: 9112246, SHIPNAME: "ORIENTAL PEARL VI" },
  { MMSI: 354358000, IMO: 9247871, SHIPNAME: "COSCO KIKU" },
  { MMSI: 354380000, IMO: 9121869, SHIPNAME: "SIDRA HALIMA" },
  { MMSI: 354401000, IMO: 9848900, SHIPNAME: "KMTC POHANG" },
  { MMSI: 354415000, IMO: 9279965, SHIPNAME: "MSC FABIENNE" },
  { MMSI: 354462000, IMO: 9461398, SHIPNAME: "MSC ROSA M" },
  { MMSI: 354464000, IMO: 9575369, SHIPNAME: "DONG YU" },
  { MMSI: 354467000, IMO: 9821081, SHIPNAME: "KMTC SURABAYA" },
  { MMSI: 354499000, IMO: 9304411, SHIPNAME: "MSC ESTHI" },
  { MMSI: 354505000, IMO: 9181651, SHIPNAME: "MSC INGRID" },
  { MMSI: 354530000, IMO: 9349813, SHIPNAME: "MSC CARMEN" },
  { MMSI: 354537000, IMO: 8918966, SHIPNAME: "MSC MANDY III" },
  { MMSI: 354540000, IMO: 8913423, SHIPNAME: "MSC SHANNON III" },
  { MMSI: 354625000, IMO: 9404651, SHIPNAME: "MSC CAMILLE" },
  { MMSI: 354646000, IMO: 9226827, SHIPNAME: "EASLINE KWANGYANG" },
  { MMSI: 354654000, IMO: 9811012, SHIPNAME: "EVER GOLDEN" },
  { MMSI: 354690000, IMO: 9869978, SHIPNAME: "EVER CAST" },
  { MMSI: 354711000, IMO: 9155107, SHIPNAME: "MSC MARIA PIA" },
  { MMSI: 354712000, IMO: 9290282, SHIPNAME: "MSC RACHELE" },
  { MMSI: 354725000, IMO: 9051507, SHIPNAME: "MSC KRITTIKA" },
  { MMSI: 354727000, IMO: 9817925, SHIPNAME: "OSLO TRADER" },
  { MMSI: 354744000, IMO: 9339296, SHIPNAME: "MSC ASYA" },
  { MMSI: 354745000, IMO: 9351579, SHIPNAME: "MSC TAMARA" },
  { MMSI: 354751000, IMO: 9318113, SHIPNAME: "ORCA I" },
  { MMSI: 354776000, IMO: 9129885, SHIPNAME: "MSC RAFAELA" },
  { MMSI: 354785000, IMO: 9632507, SHIPNAME: "KMARIN ATLANTICA" },
  { MMSI: 354839000, IMO: 9395147, SHIPNAME: "ONE HAMMERSMITH" },
  { MMSI: 354884000, IMO: 9353254, SHIPNAME: "SPIL KARTIKA" },
  { MMSI: 354886000, IMO: 9345415, SHIPNAME: "COSCO EUROPE" },
  { MMSI: 354891000, IMO: 9337640, SHIPNAME: "NYK NEBULA" },
  { MMSI: 354928000, IMO: 9741437, SHIPNAME: "ONE SWAN" },
  { MMSI: 354932000, IMO: 9194505, SHIPNAME: "TAICHUNG" },
  { MMSI: 354942000, IMO: 9463293, SHIPNAME: "BEAR MOUNTAIN BRIDGE" },
  { MMSI: 354972000, IMO: 9275971, SHIPNAME: "MSC MARINA" },
  { MMSI: 354977000, IMO: 9786841, SHIPNAME: "EVER GLOBE" },
  { MMSI: 355006000, IMO: 9353278, SHIPNAME: "MATSON KAUAI" },
  { MMSI: 355033000, IMO: 9276406, SHIPNAME: "HF SPIRIT" },
  { MMSI: 355106000, IMO: 9790086, SHIPNAME: "EVER BURLY" },
  { MMSI: 355113000, IMO: 9632492, SHIPNAME: "KMARIN AZUR" },
  { MMSI: 355129000, IMO: 9008574, SHIPNAME: "MSC TASMANIA" },
  { MMSI: 355194000, IMO: 8918980, SHIPNAME: "MSC JORDAN III" },
  { MMSI: 355216000, IMO: 9251717, SHIPNAME: "MSC MAUREEN" },
  { MMSI: 355224000, IMO: 9859923, SHIPNAME: "GREEN CELEBRITY" },
  { MMSI: 355232000, IMO: 9472581, SHIPNAME: "ST SUCCESS" },
  { MMSI: 355233000, IMO: 9399040, SHIPNAME: "MSC IRENE" },
  { MMSI: 355249000, IMO: 8913447, SHIPNAME: "MSC ATLANTIC III" },
  { MMSI: 355254000, IMO: 8918954, SHIPNAME: "MSC NEDERLAND III" },
  { MMSI: 355271000, IMO: 9395161, SHIPNAME: "ONE HONG KONG" },
  { MMSI: 355283000, IMO: 9398383, SHIPNAME: "MSC LUCIANA" },
  { MMSI: 355288000, IMO: 9502946, SHIPNAME: "MAERSK ENSHI" },
  { MMSI: 355289000, IMO: 8918978, SHIPNAME: "MSC SUEZ" },
  { MMSI: 355297000, IMO: 9073701, SHIPNAME: "SANG SHIN" },
  { MMSI: 355305000, IMO: 9203954, SHIPNAME: "MSC SANDRA" },
  { MMSI: 355308000, IMO: 9225677, SHIPNAME: "MSC LUISA" },
  { MMSI: 355319000, IMO: 9135250, SHIPNAME: "BIRYONG" },
  { MMSI: 355341000, IMO: 9869992, SHIPNAME: "EVER CHARM" },
  { MMSI: 355363000, IMO: 8913411, SHIPNAME: "MSC SANTHYA" },
  { MMSI: 355400000, IMO: 9560364, SHIPNAME: "SAN FRANCISCO BRIDGE" },
  { MMSI: 355433000, IMO: 9337626, SHIPNAME: "NYK CONSTELLATION" },
  { MMSI: 355443000, IMO: 9495038, SHIPNAME: "OOCL BRAZIL" },
  { MMSI: 355449000, IMO: 9258727, SHIPNAME: "SITC TOKYO" },
  { MMSI: 355474000, IMO: 9228758, SHIPNAME: "CAIYUNHE" },
  { MMSI: 355537000, IMO: 9866598, SHIPNAME: "EVER CALM" },
  { MMSI: 355623000, IMO: 9850525, SHIPNAME: "EVER FAITH" },
  { MMSI: 355632000, IMO: 9894662, SHIPNAME: "MAERSK NUSSFJORD" },
  { MMSI: 355674000, IMO: 9179464, SHIPNAME: "RUN SHENG" },
  { MMSI: 355714000, IMO: 9124366, SHIPNAME: "MSC IMMA III" },
  { MMSI: 355722000, IMO: 9887009, SHIPNAME: "EVER CHASTE" },
  { MMSI: 355738000, IMO: 9139505, SHIPNAME: "MSC REBECCA III" },
  { MMSI: 355746000, IMO: 9064748, SHIPNAME: "MSC LARA II" },
  { MMSI: 355771000, IMO: 9356804, SHIPNAME: "HF LUCKY" },
  { MMSI: 355776000, IMO: 9236212, SHIPNAME: "MSC MAKOTO II" },
  { MMSI: 355798000, IMO: 9839466, SHIPNAME: "MSC MIA" },
  { MMSI: 355808000, IMO: 9335185, SHIPNAME: "COSCO NEW YORK" },
  { MMSI: 355898000, IMO: 9330575, SHIPNAME: "SITC TIANJIN" },
  { MMSI: 355902000, IMO: 9202223, SHIPNAME: "UNI-PREMIER" },
  { MMSI: 355906000, IMO: 9703291, SHIPNAME: "MSC OSCAR" },
  { MMSI: 355919000, IMO: 9304423, SHIPNAME: "MSC MARIA ELENA" },
  { MMSI: 355925000, IMO: 9350692, SHIPNAME: "RIZHAO ORIENT" },
  { MMSI: 355928000, IMO: 9145047, SHIPNAME: "NEW GOLDEN BRIDGE V" },
  { MMSI: 356005000, IMO: 9021332, SHIPNAME: "YOUNG SHIN" },
  { MMSI: 356012000, IMO: 9202481, SHIPNAME: "SEA OF LUCK" },
  { MMSI: 356015000, IMO: 9840697, SHIPNAME: "MAERSK VLADIVOSTOK" },
  { MMSI: 356037000, IMO: 9039250, SHIPNAME: "MSC JOY" },
  { MMSI: 356093000, IMO: 9299549, SHIPNAME: "MSC JUDITH" },
  { MMSI: 356101000, IMO: 8714205, SHIPNAME: "MSC SABRINA III" },
  { MMSI: 356112000, IMO: 9305714, SHIPNAME: "MSC INES" },
  { MMSI: 356189000, IMO: 9660011, SHIPNAME: "YM MOVEMENT" },
  { MMSI: 356205000, IMO: 9351581, SHIPNAME: "MSC KIM" },
  { MMSI: 356207000, IMO: 9351804, SHIPNAME: "OEL COLOMBO" },
  { MMSI: 356217000, IMO: 9149823, SHIPNAME: "PHOENIX D" },
  { MMSI: 356234000, IMO: 9839296, SHIPNAME: "MSC NELA" },
  { MMSI: 356249000, IMO: 9110377, SHIPNAME: "MSC SAMANTHA VI" },
  { MMSI: 356289000, IMO: 9703306, SHIPNAME: "MSC OLIVER" },
  { MMSI: 356298000, IMO: 9362700, SHIPNAME: "EMORA" },
  { MMSI: 356330000, IMO: 9372470, SHIPNAME: "MSC KRYSTAL" },
  { MMSI: 356352000, IMO: 9463346, SHIPNAME: "BAI CHAY BRIDGE" },
  { MMSI: 356367000, IMO: 9444986, SHIPNAME: "PEARL RIVER BRIDGE" },
  { MMSI: 356369000, IMO: 9823742, SHIPNAME: "MCC DANANG" },
  { MMSI: 356396000, IMO: 9153068, SHIPNAME: "EASLINE YANTAI" },
  { MMSI: 356407000, IMO: 9604172, SHIPNAME: "EVER LUCKY" },
  { MMSI: 356426000, IMO: 9160401, SHIPNAME: "LADY OF LUCK" },
  { MMSI: 356432000, IMO: 9839454, SHIPNAME: "MSC LENI" },
  { MMSI: 356481000, IMO: 9689665, SHIPNAME: "SUNNY ACACIA" },
  { MMSI: 356505000, IMO: 9004231, SHIPNAME: "MSC ESHA F" },
  { MMSI: 356517000, IMO: 9890874, SHIPNAME: "EVER CLEAR" },
  { MMSI: 356554000, IMO: 9409053, SHIPNAME: "ATHENS BRIDGE" },
  { MMSI: 356565000, IMO: 9444261, SHIPNAME: "MOL PREMIUM" },
  { MMSI: 356575000, IMO: 9495040, SHIPNAME: "HAKATA SEOUL" },
  { MMSI: 356579000, IMO: 9102746, SHIPNAME: "MSC DON GIOVANNI" },
  { MMSI: 356581000, IMO: 9345403, SHIPNAME: "COSCO ASIA" },
  { MMSI: 356582000, IMO: 9832717, SHIPNAME: "EVER GOVERN" },
  { MMSI: 356629000, IMO: 9419632, SHIPNAME: "NYK PAULA" },
  { MMSI: 356644000, IMO: 9759202, SHIPNAME: "ORIENTAL PEARL VIII" },
  { MMSI: 356712000, IMO: 9863302, SHIPNAME: "HMM COPENHAGEN" },
  { MMSI: 356758000, IMO: 9894650, SHIPNAME: "MAERSK NESNA" },
  { MMSI: 356792000, IMO: 9462275, SHIPNAME: "LIDER HALIL" },
  { MMSI: 356815000, IMO: 9138147, SHIPNAME: "ISEACO GENESIS" },
  { MMSI: 356828000, IMO: 9842023, SHIPNAME: "NEW GRAND PEACE" },
  { MMSI: 356853000, IMO: 9865881, SHIPNAME: "MAERSK JAKARTA" },
  { MMSI: 356864000, IMO: 9065443, SHIPNAME: "MSC ROSSELLA" },
  { MMSI: 356886000, IMO: 9136230, SHIPNAME: "NIIGATA TRADER" },
  { MMSI: 356893000, IMO: 9205665, SHIPNAME: "EASLINE OSAKA" },
  { MMSI: 356904000, IMO: 9129873, SHIPNAME: "MSC ALEXA" },
  { MMSI: 356907000, IMO: 9869980, SHIPNAME: "EVER CHANT" },
  { MMSI: 356931000, IMO: 9515618, SHIPNAME: "QUEZON BRIDGE" },
  { MMSI: 356946000, IMO: 9251688, SHIPNAME: "MSC VANESSA" },
  { MMSI: 356984000, IMO: 9773210, SHIPNAME: "ONE TRUTH" },
  { MMSI: 356985000, IMO: 9308041, SHIPNAME: "SITC YOKOHAMA" },
  { MMSI: 357025000, IMO: 9160712, SHIPNAME: "FENGYUNHE" },
  { MMSI: 357048000, IMO: 9264726, SHIPNAME: "MEDKON LTF" },
  { MMSI: 357051000, IMO: 9404649, SHIPNAME: "MSC DANIT" },
  { MMSI: 357067000, IMO: 9278143, SHIPNAME: "MSC ELENI" },
  { MMSI: 357096000, IMO: 9618276, SHIPNAME: "MSC AGRIGENTO" },
  { MMSI: 357104000, IMO: 9502972, SHIPNAME: "MAERSK ESMERALDAS" },
  { MMSI: 357106000, IMO: 9202649, SHIPNAME: "MSC DIEGO" },
  { MMSI: 357142000, IMO: 9322877, SHIPNAME: "SOL STRIDE" },
  { MMSI: 357157000, IMO: 9130585, SHIPNAME: "UNI-ASSENT" },
  { MMSI: 357170000, IMO: 9177430, SHIPNAME: "AH SHIN" },
  { MMSI: 357175000, IMO: 9416965, SHIPNAME: "NYK REMUS" },
  { MMSI: 357178000, IMO: 9859935, SHIPNAME: "GREEN CLARITY" },
  { MMSI: 357191000, IMO: 9051492, SHIPNAME: "MSC NILGUN" },
  { MMSI: 357210000, IMO: 9773222, SHIPNAME: "ONE TREASURE" },
  { MMSI: 357214000, IMO: 9335197, SHIPNAME: "CMA CGM ALCAZAR" },
  { MMSI: 357231000, IMO: 9275361, SHIPNAME: "ATHENA" },
  { MMSI: 357240000, IMO: 9404675, SHIPNAME: "MSC MELATILDE" },
  { MMSI: 357286000, IMO: 9194490, SHIPNAME: "IBN AL ABBAR" },
  { MMSI: 357332000, IMO: 9202651, SHIPNAME: "MSC REGINA" },
  { MMSI: 357345000, IMO: 9163609, SHIPNAME: "MARIA DISCO??2Y" },
  { MMSI: 357368000, IMO: 9308053, SHIPNAME: "SITC NAGOYA" },
  { MMSI: 357380000, IMO: 9203502, SHIPNAME: "MAIRA" },
  { MMSI: 357388000, IMO: 9203526, SHIPNAME: "NIKOLAS" },
  { MMSI: 357398000, IMO: 9130597, SHIPNAME: "UNI-ASSURE" },
  { MMSI: 357405000, IMO: 9279977, SHIPNAME: "MSC POH LIN" },
  { MMSI: 357411000, IMO: 9209104, SHIPNAME: "NEWYORKER" },
  { MMSI: 357413000, IMO: 9784116, SHIPNAME: "EVER BRACE" },
  { MMSI: 357420000, IMO: 9890898, SHIPNAME: "EVER CANDID" },
  { MMSI: 357444000, IMO: 9102710, SHIPNAME: "MSC MEDITERRANEAN" },
  { MMSI: 357463000, IMO: 9820855, SHIPNAME: "EVER GRADE" },
  { MMSI: 357506000, IMO: 9203942, SHIPNAME: "MSC ANIELLO" },
  { MMSI: 357542000, IMO: 9202663, SHIPNAME: "MSC GINA" },
  { MMSI: 357547000, IMO: 9560376, SHIPNAME: "SAN DIEGO BRIDGE" },
  { MMSI: 357577000, IMO: 9390252, SHIPNAME: "KOTA NEKAD" },
  { MMSI: 357632000, IMO: 8201648, SHIPNAME: "MSC EYRA" },
  { MMSI: 357702000, IMO: 8505836, SHIPNAME: "A395" },
  { MMSI: 357773000, IMO: 8201624, SHIPNAME: "MSC IRIS" },
  { MMSI: 357779000, IMO: 9123805, SHIPNAME: "JORDANIA" },
  { MMSI: 357848000, IMO: 9451484, SHIPNAME: "ARICA BRIDGE" },
  { MMSI: 357874000, IMO: 9168879, SHIPNAME: "EVER USEFUL" },
  { MMSI: 357891000, IMO: 9469560, SHIPNAME: "MSC TERESA" },
  { MMSI: 357926000, IMO: 9467392, SHIPNAME: "MSC BERYL" },
  { MMSI: 357939000, IMO: 9169160, SHIPNAME: "EVER URBAN" },
  { MMSI: 357979000, IMO: 9202182, SHIPNAME: "UNI PERFECT" },
  { MMSI: 357980000, IMO: 9202194, SHIPNAME: "UNI-PRUDENT" },
  { MMSI: 357981000, IMO: 9202209, SHIPNAME: "UNI POPULAR" },
  { MMSI: 357988000, IMO: 9567661, SHIPNAME: "SEROJA LIMA" },
  { MMSI: 366418000, IMO: 9349526, SHIPNAME: "POTOMAC EXPRESS" },
  { MMSI: 366557000, IMO: 8419142, SHIPNAME: "MATSON ANCHORAGE" },
  { MMSI: 366562000, IMO: 7907984, SHIPNAME: "MANOA" },
  { MMSI: 366563000, IMO: 7907996, SHIPNAME: "MAHIMAHI" },
  { MMSI: 366629000, IMO: 7729459, SHIPNAME: "HORIZON SPIRIT" },
  { MMSI: 366791000, IMO: 7729461, SHIPNAME: "GEORGE II" },
  { MMSI: 366793000, IMO: 8419166, SHIPNAME: "MATSON KODIAK" },
  { MMSI: 366794000, IMO: 8419154, SHIPNAME: "MATSON TACOMA" },
  { MMSI: 367196000, IMO: 7908005, SHIPNAME: "MOKIHANA" },
  { MMSI: 367422000, IMO: 9349514, SHIPNAME: "DELAWARE EXPRESS" },
  { MMSI: 367438000, IMO: 9268538, SHIPNAME: "MAUNAWILI" },
  { MMSI: 367578740, IMO: 9218686, SHIPNAME: "PRESIDENT WILSON" },
  { MMSI: 367606000, IMO: 9298686, SHIPNAME: "MAERSK IOWA" },
  { MMSI: 367619000, IMO: 9436070, SHIPNAME: "APL OCEANIA" },
  { MMSI: 367759000, IMO: 9305312, SHIPNAME: "MAERSK MONTANA" },
  { MMSI: 367775000, IMO: 9298698, SHIPNAME: "MAERSK OHIO" },
  { MMSI: 367781000, IMO: 9349552, SHIPNAME: "MISSOURI EXPRESS" },
  { MMSI: 367834000, IMO: 7234430, SHIPNAME: "GARY I GORDON" },
  { MMSI: 368053000, IMO: 9311701, SHIPNAME: "MAERSK KANSAS" },
  { MMSI: 368160000, IMO: 9322009, SHIPNAME: "SAGAMORE" },
  { MMSI: 368305000, IMO: 9273674, SHIPNAME: "MANULANI" },
  { MMSI: 368359000, IMO: 9349564, SHIPNAME: "HUDSON EXPRESS" },
  { MMSI: 369096000, IMO: 9289207, SHIPNAME: "MAERSK SARATOGA" },
  { MMSI: 369207000, IMO: 9719056, SHIPNAME: "DANIEL K. INOUYE" },
  { MMSI: 369209000, IMO: 9719068, SHIPNAME: "KAIMANA HILA" },
  { MMSI: 369215000, IMO: 9349502, SHIPNAME: "COLORADO EXPRESS" },
  { MMSI: 369246000, IMO: 9315202, SHIPNAME: "MAERSK SENTOSA" },
  { MMSI: 369248000, IMO: 9289192, SHIPNAME: "MAERSK YORKTOWN" },
  { MMSI: 369309000, IMO: 9315197, SHIPNAME: "MAERSK SELETAR" },
  { MMSI: 369339000, IMO: 9295218, SHIPNAME: "PRESIDENT KENNEDY" },
  { MMSI: 369340000, IMO: 9299044, SHIPNAME: "MAERSK DURBAN" },
  { MMSI: 369355000, IMO: 9837092, SHIPNAME: "GEORGE III" },
  { MMSI: 369357000, IMO: 9837107, SHIPNAME: "JANET MARIE" },
  { MMSI: 369390000, IMO: 9314210, SHIPNAME: "MAERSK TENNESSEE" },
  { MMSI: 369558000, IMO: 9760603, SHIPNAME: "APL EAGLE" },
  { MMSI: 369751000, IMO: 9400069, SHIPNAME: "PRESIDENTFDROOSEVELT" },
  { MMSI: 370012000, IMO: 9146285, SHIPNAME: "SOL VALOUR" },
  { MMSI: 370014000, IMO: 9146297, SHIPNAME: "BLPL FAITH" },
  { MMSI: 370031000, IMO: 9138745, SHIPNAME: "DSL MARINER" },
  { MMSI: 370109000, IMO: 9162435, SHIPNAME: "PADIAN 3" },
  { MMSI: 370121000, IMO: 9786815, SHIPNAME: "EVER GENIUS" },
  { MMSI: 370188000, IMO: 9345439, SHIPNAME: "COSCO AFRICA" },
  { MMSI: 370206000, IMO: 9172612, SHIPNAME: "RUN LONG" },
  { MMSI: 370254000, IMO: 9351593, SHIPNAME: "MSC ANGELA" },
  { MMSI: 370271000, IMO: 9372494, SHIPNAME: "MSC SORAYA" },
  { MMSI: 370273000, IMO: 9349825, SHIPNAME: "MSC NURIA" },
  { MMSI: 370288000, IMO: 9129471, SHIPNAME: "SARA STAR" },
  { MMSI: 370320000, IMO: 9380271, SHIPNAME: "MSC NAGOYA V" },
  { MMSI: 370341000, IMO: 9461439, SHIPNAME: "MSC VALERIA" },
  { MMSI: 370354000, IMO: 9363962, SHIPNAME: "HF FORTUNE" },
  { MMSI: 370357000, IMO: 9307401, SHIPNAME: "M.V.KOTA KAYA" },
  { MMSI: 370376000, IMO: 9890886, SHIPNAME: "EVER CROWN" },
  { MMSI: 370437000, IMO: 9307267, SHIPNAME: "MSC CORINNA" },
  { MMSI: 370439000, IMO: 9360611, SHIPNAME: "ACX CRYSTAL" },
  { MMSI: 370442000, IMO: 9353266, SHIPNAME: "SPIL KARTINI" },
  { MMSI: 370511000, IMO: 9043146, SHIPNAME: "SEDRA" },
  { MMSI: 370587000, IMO: 9302176, SHIPNAME: "ONE HENRY HUDSON" },
  { MMSI: 370616000, IMO: 9790062, SHIPNAME: "EVER BUILD" },
  { MMSI: 370651000, IMO: 9741401, SHIPNAME: "ONE CRANE" },
  { MMSI: 370700000, IMO: 9787015, SHIPNAME: "EVER BOOMY" },
  { MMSI: 370711000, IMO: 9839301, SHIPNAME: "MSC SIXIN" },
  { MMSI: 370892000, IMO: 9399002, SHIPNAME: "MSC DANIELA" },
  { MMSI: 370894000, IMO: 9401104, SHIPNAME: "MSC SOLA" },
  { MMSI: 370928000, IMO: 9243241, SHIPNAME: "MSC DAVAO III" },
  { MMSI: 370930000, IMO: 9398371, SHIPNAME: "MSC IVANA" },
  { MMSI: 370932000, IMO: 9351816, SHIPNAME: "OEL INDIA" },
  { MMSI: 370966000, IMO: 9122382, SHIPNAME: "JI PENG" },
  { MMSI: 370993000, IMO: 9401116, SHIPNAME: "MSC FRANCESCA" },
  { MMSI: 371002000, IMO: 9109558, SHIPNAME: "EASLINE DALIAN" },
  { MMSI: 371047000, IMO: 9839442, SHIPNAME: "MSC SAMAR" },
  { MMSI: 371057000, IMO: 8917778, SHIPNAME: "MSC ELOISE" },
  { MMSI: 371059000, IMO: 9289104, SHIPNAME: "MSC LUCY" },
  { MMSI: 371076000, IMO: 9757187, SHIPNAME: "ONE MILANO" },
  { MMSI: 371141000, IMO: 9886990, SHIPNAME: "EVER CENTER" },
  { MMSI: 371215000, IMO: 9706736, SHIPNAME: "ONE MILLAU" },
  { MMSI: 371218000, IMO: 9401130, SHIPNAME: "MSC EVA" },
  { MMSI: 371228000, IMO: 9290531, SHIPNAME: "MSC PAMELA" },
  { MMSI: 371233000, IMO: 9441001, SHIPNAME: "MSC PALOMA" },
  { MMSI: 371245000, IMO: 9289116, SHIPNAME: "MSC RITA" },
  { MMSI: 371249000, IMO: 9848895, SHIPNAME: "KMTC GWANGYANG" },
  { MMSI: 371274000, IMO: 9502958, SHIPNAME: "MAERSK ENSENADA" },
  { MMSI: 371308000, IMO: 9810991, SHIPNAME: "EVER GOODS" },
  { MMSI: 371319000, IMO: 9868326, SHIPNAME: "HMM OSLO" },
  { MMSI: 371369000, IMO: 9859648, SHIPNAME: "POS BANGKOK" },
  { MMSI: 371444000, IMO: 9823754, SHIPNAME: "MCC YANGON" },
  { MMSI: 371449000, IMO: 9416977, SHIPNAME: "NYK RIGEL" },
  { MMSI: 371466000, IMO: 9290543, SHIPNAME: "MSC SUSANNA" },
  { MMSI: 371474000, IMO: 9295385, SHIPNAME: "MSC MARTA" },
  { MMSI: 371475000, IMO: 9295397, SHIPNAME: "MSC CAROLINA" },
  { MMSI: 371519000, IMO: 9307059, SHIPNAME: "MARCOS V" },
  { MMSI: 371543000, IMO: 9305013, SHIPNAME: "MSC CHULAI III" },
  { MMSI: 371582000, IMO: 9467419, SHIPNAME: "MSC LAURENCE" },
  { MMSI: 371602000, IMO: 9123166, SHIPNAME: "MSC ALABAMA III" },
  { MMSI: 371633000, IMO: 9664897, SHIPNAME: "YM MODERATION" },
  { MMSI: 371711000, IMO: 9152856, SHIPNAME: "MSC GIANNA III" },
  { MMSI: 371714000, IMO: 9550383, SHIPNAME: "MARGARETRIVERBRIDGE" },
  { MMSI: 371743000, IMO: 9203904, SHIPNAME: "MSC BELLE" },
  { MMSI: 371752000, IMO: 9309447, SHIPNAME: "MSC RANIA" },
  { MMSI: 371753000, IMO: 9461374, SHIPNAME: "MSC ALEXANDRA" },
  { MMSI: 371791000, IMO: 9515620, SHIPNAME: "HORAI BRIDGE" },
  { MMSI: 371793000, IMO: 9229609, SHIPNAME: "A HOUOU" },
  { MMSI: 371799000, IMO: 9302073, SHIPNAME: "GEORGE WASHINGTON BR" },
  { MMSI: 371818000, IMO: 9379571, SHIPNAME: "PANDA VEGA" },
  { MMSI: 371829000, IMO: 9832729, SHIPNAME: "EVER GREET" },
  { MMSI: 371836000, IMO: 9333852, SHIPNAME: "MOL ENDOWMENT" },
  { MMSI: 371851000, IMO: 9289128, SHIPNAME: "MSC MAEVA" },
  { MMSI: 371860000, IMO: 9320403, SHIPNAME: "MSC LORENA" },
  { MMSI: 371866000, IMO: 9339545, SHIPNAME: "CMA CGM KAILAS" },
  { MMSI: 371908000, IMO: 9309459, SHIPNAME: "MSC SILVANA" },
  { MMSI: 371929000, IMO: 9393321, SHIPNAME: "MSC ELAINE" },
  { MMSI: 372003000, IMO: 9839430, SHIPNAME: "MSC GULSUN" },
  { MMSI: 372008000, IMO: 9309473, SHIPNAME: "MSC HEIDI" },
  { MMSI: 372009000, IMO: 9066710, SHIPNAME: "EASTERN DREAM" },
  { MMSI: 372023000, IMO: 9136228, SHIPNAME: "BAL BOAN" },
  { MMSI: 372038000, IMO: 9302140, SHIPNAME: "ONE HUMBER" },
  { MMSI: 372050000, IMO: 9304435, SHIPNAME: "MSC JOANNA" },
  { MMSI: 372051000, IMO: 9299551, SHIPNAME: "MSC VITTORIA" },
  { MMSI: 372104000, IMO: 9302138, SHIPNAME: "ONE HANNOVER" },
  { MMSI: 372123000, IMO: 9320439, SHIPNAME: "MSC LEIGH" },
  { MMSI: 372162000, IMO: 9121857, SHIPNAME: "ABANOZ" },
  { MMSI: 372174000, IMO: 9045699, SHIPNAME: "LDR BEDEL" },
  { MMSI: 372218000, IMO: 9312781, SHIPNAME: "NYK VEGA" },
  { MMSI: 372242000, IMO: 9629158, SHIPNAME: "GREEN HORIZON" },
  { MMSI: 372280000, IMO: 9741413, SHIPNAME: "ONE HAWK" },
  { MMSI: 372319000, IMO: 9337688, SHIPNAME: "NYK DIANA" },
  { MMSI: 372354000, IMO: 9784130, SHIPNAME: "EVER BRAVE" },
  { MMSI: 372367000, IMO: 9302152, SHIPNAME: "ONE HARBOUR" },
  { MMSI: 372400000, IMO: 9467421, SHIPNAME: "MSC MARIA SAVERIA" },
  { MMSI: 372440000, IMO: 9361067, SHIPNAME: "XINQUNDAO" },
  { MMSI: 372490000, IMO: 9854480, SHIPNAME: "SKY WIND" },
  { MMSI: 372491000, IMO: 9237151, SHIPNAME: "MSC DONATA" },
  { MMSI: 372512000, IMO: 9312793, SHIPNAME: "NYK VENUS" },
  { MMSI: 372531000, IMO: 9312808, SHIPNAME: "NYK VESTA" },
  { MMSI: 372546000, IMO: 9262895, SHIPNAME: "JAWAN" },
  { MMSI: 372570000, IMO: 9748411, SHIPNAME: "SUNNY LAVENDER" },
  { MMSI: 372588000, IMO: 9823728, SHIPNAME: "MCC TOKYO" },
  { MMSI: 372596000, IMO: 9894674, SHIPNAME: "MAERSK NORDDAL" },
  { MMSI: 372604000, IMO: 9848883, SHIPNAME: "KMTC OSAKA" },
  { MMSI: 372665000, IMO: 9302164, SHIPNAME: "HUMEN BRIDGE" },
  { MMSI: 372707000, IMO: 9550319, SHIPNAME: "M WILMINGTON" },
  { MMSI: 372723000, IMO: 9633953, SHIPNAME: "CAP SAN SOUNIO" },
  { MMSI: 372724000, IMO: 9395159, SHIPNAME: "ONE HAMBURG" },
  { MMSI: 372729000, IMO: 9839478, SHIPNAME: "MSC FEBE" },
  { MMSI: 372733000, IMO: 9850795, SHIPNAME: "EVER FIT" },
  { MMSI: 372736000, IMO: 9336048, SHIPNAME: "MSC SINDY" },
  { MMSI: 372737000, IMO: 9320453, SHIPNAME: "MSC ROSARIA" },
  { MMSI: 372753000, IMO: 9345958, SHIPNAME: "NAVIOS JASMINE" },
  { MMSI: 372762000, IMO: 9252357, SHIPNAME: "D ANGELS" },
  { MMSI: 372765000, IMO: 9331115, SHIPNAME: "SITC HONGKONG" },
  { MMSI: 372797000, IMO: 9418884, SHIPNAME: "OYSTER" },
  { MMSI: 372818000, IMO: 9348493, SHIPNAME: "GAUJA" },
  { MMSI: 372827000, IMO: 9333826, SHIPNAME: "MOL EXPLORER" },
  { MMSI: 372843000, IMO: 9369758, SHIPNAME: "MSC FIAMMETTA" },
  { MMSI: 372862000, IMO: 8413875, SHIPNAME: "MSC GABRIELLA" },
  { MMSI: 372925000, IMO: 9412799, SHIPNAME: "SITC RIZHAO" },
  { MMSI: 372926000, IMO: 9517678, SHIPNAME: "GANG TONG 19" },
  { MMSI: 372932000, IMO: 9823730, SHIPNAME: "MCC CEBU" },
  { MMSI: 372934000, IMO: 9335173, SHIPNAME: "COSCO BOSTON" },
  { MMSI: 372946000, IMO: 9033713, SHIPNAME: "SWIMMER" },
  { MMSI: 372955000, IMO: 9333838, SHIPNAME: "MOL EXPERIENCE" },
  { MMSI: 372972000, IMO: 9263332, SHIPNAME: "MSC BANU III" },
  { MMSI: 372973000, IMO: 9339272, SHIPNAME: "MSC PINA" },
  { MMSI: 373004000, IMO: 9467433, SHIPNAME: "MSC FLAVIA" },
  { MMSI: 373005000, IMO: 9484467, SHIPNAME: "MSC VANDYA" },
  { MMSI: 373021000, IMO: 9393022, SHIPNAME: "MSC SHAY" },
  { MMSI: 373031000, IMO: 9465289, SHIPNAME: "MSC CAPELLA" },
  { MMSI: 373044000, IMO: 9340752, SHIPNAME: "NUMBER 9" },
  { MMSI: 373068000, IMO: 9465291, SHIPNAME: "MSC REGULUS" },
  { MMSI: 373069000, IMO: 9484481, SHIPNAME: "MSC AURORA" },
  { MMSI: 373080000, IMO: 9234379, SHIPNAME: "HE SHENG" },
  { MMSI: 373119000, IMO: 9588081, SHIPNAME: "ONE HELSINKI" },
  { MMSI: 373126000, IMO: 9595802, SHIPNAME: "SKY FLOWER" },
  { MMSI: 373128000, IMO: 9595797, SHIPNAME: "SKY HOPE" },
  { MMSI: 373136000, IMO: 9270804, SHIPNAME: "ONYX 1" },
  { MMSI: 373184000, IMO: 9318931, SHIPNAME: "ORITA" },
  { MMSI: 373220000, IMO: 9859911, SHIPNAME: "KMTC INCHEON" },
  { MMSI: 373233000, IMO: 9484429, SHIPNAME: "MSC CLORINDA" },
  { MMSI: 373260000, IMO: 9894648, SHIPNAME: "MAERSK NARVIK" },
  { MMSI: 373271000, IMO: 9624287, SHIPNAME: "MAERSK IYO" },
  { MMSI: 373285000, IMO: 9850549, SHIPNAME: "EVER FRONT" },
  { MMSI: 373316000, IMO: 9367891, SHIPNAME: "XIN HE DA" },
  { MMSI: 373319000, IMO: 9875068, SHIPNAME: "EVER COPE" },
  { MMSI: 373355000, IMO: 9484443, SHIPNAME: "MSC ARIANE" },
  { MMSI: 373404000, IMO: 9467445, SHIPNAME: "MSC KATRINA" },
  { MMSI: 373457000, IMO: 9415727, SHIPNAME: "MOL MAESTRO" },
  { MMSI: 373459000, IMO: 9392559, SHIPNAME: "SPIL CITRA" },
  { MMSI: 373517000, IMO: 9110391, SHIPNAME: "MSC DYMPHNA" },
  { MMSI: 373579000, IMO: 9289051, SHIPNAME: "MSC GIANNINA II" },
  { MMSI: 373582000, IMO: 9316361, SHIPNAME: "MSC CELINE" },
  { MMSI: 373595000, IMO: 9821093, SHIPNAME: "KMTC PENANG" },
  { MMSI: 373598000, IMO: 9606338, SHIPNAME: "MSC AMSTERDAM" },
  { MMSI: 373649000, IMO: 9588079, SHIPNAME: "ONE HONOLULU" },
  { MMSI: 373712000, IMO: 9850800, SHIPNAME: "EVER FAME" },
  { MMSI: 373721000, IMO: 9595448, SHIPNAME: "EVER LADEN" },
  { MMSI: 373817000, IMO: 8606056, SHIPNAME: "GMT ASTRO" },
  { MMSI: 373839000, IMO: 9322841, SHIPNAME: "SING" },
  { MMSI: 373911000, IMO: 9706750, SHIPNAME: "ONE MUNCHEN" },
  { MMSI: 373932000, IMO: 9566394, SHIPNAME: "ONE HANGZHOU BAY" },
  { MMSI: 374091000, IMO: 9256418, SHIPNAME: "HONG PROSPERITY" },
  { MMSI: 374158000, IMO: 9850537, SHIPNAME: "EVER FOCUS" },
  { MMSI: 374239000, IMO: 9187320, SHIPNAME: "NZ NINGBO" },
  { MMSI: 374286000, IMO: 9119660, SHIPNAME: "BLPL TRUST" },
  { MMSI: 374302000, IMO: 9738765, SHIPNAME: "SUNNY ROSE" },
  { MMSI: 374391000, IMO: 9248928, SHIPNAME: "MSC TIGER F" },
  { MMSI: 374431000, IMO: 9748394, SHIPNAME: "SUNNY CLOVER" },
  { MMSI: 374508000, IMO: 9817913, SHIPNAME: "FILOTIMO" },
  { MMSI: 374571000, IMO: 9363156, SHIPNAME: "SOL PROMISE" },
  { MMSI: 374588000, IMO: 9629160, SHIPNAME: "GREEN HOPE" },
  { MMSI: 374607000, IMO: 9839284, SHIPNAME: "MSC ARINA" },
  { MMSI: 374645000, IMO: 9708681, SHIPNAME: "MSC SVEVA" },
  { MMSI: 374658000, IMO: 9633965, SHIPNAME: "CAP SAN TAINARO" },
  { MMSI: 374766000, IMO: 9708693, SHIPNAME: "MSC CLARA" },
  { MMSI: 374776000, IMO: 9332250, SHIPNAME: "NAVIOS MAGNOLIA" },
  { MMSI: 374782000, IMO: 9859894, SHIPNAME: "KMTC NAGOYA" },
  { MMSI: 374815000, IMO: 9741384, SHIPNAME: "ONE IBIS" },
  { MMSI: 374855000, IMO: 9316098, SHIPNAME: "HAMBURG TRADER" },
  { MMSI: 374858000, IMO: 9806043, SHIPNAME: "ONE AQUILA" },
  { MMSI: 374859000, IMO: 9708679, SHIPNAME: "MSC MAYA" },
  { MMSI: 374869000, IMO: 9788318, SHIPNAME: "HUADONG PEARL 8" },
  { MMSI: 374886000, IMO: 9647461, SHIPNAME: "MSC HAMBURG" },
  { MMSI: 374898000, IMO: 9786956, SHIPNAME: "EVER BIRTH" },
  { MMSI: 374935000, IMO: 9737462, SHIPNAME: "TAMPA TRIUMPH" },
  { MMSI: 374946000, IMO: 9305001, SHIPNAME: "MSC ULSAN III" },
  { MMSI: 375172000, IMO: 9363390, SHIPNAME: "LEO" },
  { MMSI: 375751000, IMO: 9187875, SHIPNAME: "GFS JUNO" },
  { MMSI: 376609000, IMO: 9142447, SHIPNAME: "ALLEGRO" },
  { MMSI: 376846000, IMO: 9228538, SHIPNAME: "ACE" },
  { MMSI: 376908000, IMO: 9169500, SHIPNAME: "INTER SYDNEY" },
  { MMSI: 377074000, IMO: 9187344, SHIPNAME: "ATHENA" },
  { MMSI: 377311000, IMO: 9187863, SHIPNAME: "GFS JADE" },
  { MMSI: 377523000, IMO: 9123518, SHIPNAME: "ARIES" },
  { MMSI: 377751000, IMO: 9137569, SHIPNAME: "GFS RANNA" },
  { MMSI: 377782000, IMO: 9137571, SHIPNAME: "LIBRA" },
  { MMSI: 377901104, IMO: 9809928, SHIPNAME: "TROPIC FREEDOM" },
  { MMSI: 377901107, IMO: 9809904, SHIPNAME: "TROPIC HOPE" },
  { MMSI: 377901110, IMO: 9809930, SHIPNAME: "TROPIC GEM" },
  { MMSI: 377901111, IMO: 9809916, SHIPNAME: "TROPIC ISLAND" },
  { MMSI: 377901119, IMO: 9819947, SHIPNAME: "TROPIC JEWEL" },
  { MMSI: 377901123, IMO: 9819959, SHIPNAME: "TROPIC LISSETTE" },
  { MMSI: 405000190, IMO: 9693654, SHIPNAME: "M.V. HARBOUR-1" },
  { MMSI: 405000223, IMO: 9816361, SHIPNAME: "INVICTA 1" },
  { MMSI: 405000291, IMO: 9157404, SHIPNAME: "HR SARERA" },
  { MMSI: 405000292, IMO: 9157399, SHIPNAME: "HR SAHARE" },
  { MMSI: 405000308, IMO: 9175597, SHIPNAME: "HR RHEA" },
  { MMSI: 405000337, IMO: 9123582, SHIPNAME: "HR FARHA" },
  { MMSI: 405000338, IMO: 9123594, SHIPNAME: "HR AARAI" },
  { MMSI: 405000384, IMO: 9266114, SHIPNAME: "HR BALU" },
  { MMSI: 405000386, IMO: 9175779, SHIPNAME: "HR TURAG" },
  { MMSI: 412123456, IMO: 8501713, SHIPNAME: "XIN HE SHI BA" },
  { MMSI: 412200640, IMO: 8020630, SHIPNAME: "DA FU" },
  { MMSI: 412204480, IMO: 9272591, SHIPNAME: "YA LU JIANG" },
  { MMSI: 412633000, IMO: 9437531, SHIPNAME: "TIAN JIN HE" },
  { MMSI: 412658000, IMO: 9437543, SHIPNAME: "TIAN SHENG HE" },
  { MMSI: 412702640, IMO: 9388285, SHIPNAME: "HUA HANG 1" },
  { MMSI: 412705880, IMO: 9162265, SHIPNAME: "XIN LONG YUN 86" },
  { MMSI: 412713000, IMO: 9400576, SHIPNAME: "TIAN KANG HE" },
  { MMSI: 412714000, IMO: 9400564, SHIPNAME: "TIAN AN HE" },
  { MMSI: 412746000, IMO: 9437567, SHIPNAME: "TIAN FU HE" },
  { MMSI: 412750000, IMO: 9437555, SHIPNAME: "TIAN QING HE" },
  { MMSI: 412767360, IMO: 0, SHIPNAME: "XINMINGZHOU78" },
  { MMSI: 412767750, IMO: 0, SHIPNAME: "XIN MING ZHOU 82" },
  { MMSI: 413050000, IMO: 9234331, SHIPNAME: "XIN DA LIAN" },
  { MMSI: 413053000, IMO: 9234343, SHIPNAME: "XIN TIAN JIN" },
  { MMSI: 413054000, IMO: 9270452, SHIPNAME: "XIN QING DAO" },
  { MMSI: 413055000, IMO: 9262118, SHIPNAME: "XIN CHONG QING" },
  { MMSI: 413057000, IMO: 9270464, SHIPNAME: "XIN NING BO" },
  { MMSI: 413058000, IMO: 9234355, SHIPNAME: "XIN LIAN YUN GANG" },
  { MMSI: 413059000, IMO: 9262120, SHIPNAME: "XIN YANG ZHOU" },
  { MMSI: 413060000, IMO: 9270440, SHIPNAME: "XIN PU DONG" },
  { MMSI: 413063000, IMO: 9262132, SHIPNAME: "XIN NAN TONG" },
  { MMSI: 413064000, IMO: 9234367, SHIPNAME: "XIN YAN TIAN" },
  { MMSI: 413065000, IMO: 9270476, SHIPNAME: "XIN XIA MEN" },
  { MMSI: 413068000, IMO: 9262144, SHIPNAME: "XIN SU ZHOU" },
  { MMSI: 413072000, IMO: 9304772, SHIPNAME: "XIN CHI WAN" },
  { MMSI: 413073000, IMO: 9304784, SHIPNAME: "XIN QIN HUANG DAO" },
  { MMSI: 413076000, IMO: 9304796, SHIPNAME: "XIN FU ZHOU" },
  { MMSI: 413125000, IMO: 9304801, SHIPNAME: "XIN YAN TAI" },
  { MMSI: 413131000, IMO: 9310020, SHIPNAME: "XIN YANG SHAN" },
  { MMSI: 413132000, IMO: 9310032, SHIPNAME: "XIN QUAN ZHOU" },
  { MMSI: 413133000, IMO: 9304813, SHIPNAME: "XIN CHANG SHU" },
  { MMSI: 413138000, IMO: 9309930, SHIPNAME: "XIN FANG CHENG" },
  { MMSI: 413141000, IMO: 9309942, SHIPNAME: "XIN SHAN TOU" },
  { MMSI: 413142000, IMO: 9310044, SHIPNAME: "XIN HUANG PU" },
  { MMSI: 413144000, IMO: 9309954, SHIPNAME: "XIN HAI KOU" },
  { MMSI: 413145000, IMO: 9309966, SHIPNAME: "XIN BEI LUN" },
  { MMSI: 413146000, IMO: 9312559, SHIPNAME: "XIN CHANG SHA" },
  { MMSI: 413147000, IMO: 9310056, SHIPNAME: "XIN NAN SHA" },
  { MMSI: 413148000, IMO: 9312561, SHIPNAME: "XIN RI ZHAO" },
  { MMSI: 413149000, IMO: 9312573, SHIPNAME: "XIN WEI HAI" },
  { MMSI: 413150000, IMO: 9378814, SHIPNAME: "XIN ZHAN JIANG" },
  { MMSI: 413151000, IMO: 9312585, SHIPNAME: "XIN YING KOU" },
  { MMSI: 413153000, IMO: 9312597, SHIPNAME: "XIN DAN DONG" },
  { MMSI: 413159000, IMO: 9337913, SHIPNAME: "XIN OU ZHOU" },
  { MMSI: 413161000, IMO: 9334935, SHIPNAME: "XIN YA ZHOU" },
  { MMSI: 413165000, IMO: 9337925, SHIPNAME: "XIN MEI ZHOU" },
  { MMSI: 413166000, IMO: 9320465, SHIPNAME: "XIN TAI CANG" },
  { MMSI: 413167000, IMO: 9320477, SHIPNAME: "XIN YANG PU" },
  { MMSI: 413169000, IMO: 9328596, SHIPNAME: "XIN WU HAN" },
  { MMSI: 413170000, IMO: 9328601, SHIPNAME: "XIN ZHANG ZHOU" },
  { MMSI: 413171000, IMO: 9337937, SHIPNAME: "XIN FEI ZHOU" },
  { MMSI: 413173000, IMO: 9337949, SHIPNAME: "XIN DA YANG ZHOU" },
  { MMSI: 413205380, IMO: 0, SHIPNAME: "JI FA NAN HAI" },
  { MMSI: 413205520, IMO: 0, SHIPNAME: "JI FA BEI HAI" },
  { MMSI: 413211430, IMO: 0, SHIPNAME: "HAN HAI 8 HAO" },
  { MMSI: 413213320, IMO: 9842310, SHIPNAME: "ZHONG GU NAN HAI" },
  { MMSI: 413215160, IMO: 9842322, SHIPNAME: "ZHONG GU DONG HAI" },
  { MMSI: 413218940, IMO: 9367932, SHIPNAME: "ZHONGWAIYUN SHENZHEN" },
  { MMSI: 413219020, IMO: 9367956, SHIPNAME: "ZHONG WAI YUN NINGBO" },
  { MMSI: 413220120, IMO: 9842334, SHIPNAME: "ZHONG GU HUANG HAI" },
  { MMSI: 413222810, IMO: 9842346, SHIPNAME: "ZHONG GU BO HAI" },
  { MMSI: 413223320, IMO: 9842358, SHIPNAME: "ZHONG GU BEI HAI" },
  { MMSI: 413228080, IMO: 9842360, SHIPNAME: "ZHONG GU DIZHONG HAI" },
  { MMSI: 413238570, IMO: 9243605, SHIPNAME: "ZHONG HANG SHENG" },
  { MMSI: 413247480, IMO: 9809215, SHIPNAME: "ZHONG GU LIN YI" },
  { MMSI: 413256960, IMO: 0, SHIPNAME: "HAN HAI 5 HAO" },
  { MMSI: 413262830, IMO: 0, SHIPNAME: "HUA SHENG 67" },
  { MMSI: 413263160, IMO: 0, SHIPNAME: "ZHONG GU DONG GUAN" },
  { MMSI: 413274380, IMO: 9331127, SHIPNAME: "HAI FENG HAI KOU" },
  { MMSI: 413275260, IMO: 0, SHIPNAME: "HUA XIN 968" },
  { MMSI: 413280820, IMO: 9216834, SHIPNAME: "TIAN HAI PING ZE" },
  { MMSI: 413281350, IMO: 9342671, SHIPNAME: "JIANG YUAN YUAN DA" },
  { MMSI: 413293840, IMO: 9914993, SHIPNAME: "XIN MING ZHOU 98" },
  { MMSI: 413295960, IMO: 9214513, SHIPNAME: "QING YUN HE" },
  { MMSI: 413297720, IMO: 9915002, SHIPNAME: "XIN MING ZHOU 102" },
  { MMSI: 413298050, IMO: 0, SHIPNAME: "XIN MING ZHOU 88" },
  { MMSI: 413307840, IMO: 9912804, SHIPNAME: "XIN MING ZHOU 90" },
  { MMSI: 413310040, IMO: 9915014, SHIPNAME: "XIN MING ZHOU 106" },
  { MMSI: 413314440, IMO: 9912816, SHIPNAME: "XIN MING ZHOU 92" },
  { MMSI: 413329850, IMO: 9258715, SHIPNAME: "HAI FENG LIAN XING" },
  { MMSI: 413333930, IMO: 9223760, SHIPNAME: "FEI YUN HE" },
  { MMSI: 413334630, IMO: 9879533, SHIPNAME: "HUA HANG HAN YA 1" },
  { MMSI: 413336090, IMO: 9915026, SHIPNAME: "XIN MING ZHOU 108" },
  { MMSI: 413338660, IMO: 9810240, SHIPNAME: "ZHONG GU XIA MEN" },
  { MMSI: 413342860, IMO: 9077288, SHIPNAME: "GUANGZHOU" },
  { MMSI: 413343250, IMO: 0, SHIPNAME: "HONG TAI 639" },
  { MMSI: 413374510, IMO: 8773237, SHIPNAME: "XIUHONG" },
  { MMSI: 413376690, IMO: 9130999, SHIPNAME: "HAILIANSHENG" },
  { MMSI: 413377680, IMO: 9155092, SHIPNAME: "ZHONGWAIYUNXINGANG" },
  { MMSI: 413377920, IMO: 9102734, SHIPNAME: "HONG YUAN 01" },
  { MMSI: 413378770, IMO: 9736535, SHIPNAME: "ZHONG GU SHANG HAI" },
  { MMSI: 413380220, IMO: 9613771, SHIPNAME: "HAI MEN" },
  { MMSI: 413380750, IMO: 9810252, SHIPNAME: "ZHONG GU ZHU HAI" },
  { MMSI: 413380760, IMO: 9809203, SHIPNAME: "ZHONG GU PENG LAI" },
  { MMSI: 413381170, IMO: 9809227, SHIPNAME: "ZHONG GU DA LIAN" },
  { MMSI: 413381180, IMO: 9810264, SHIPNAME: "ZHONG GU TIAN JIN" },
  { MMSI: 413381250, IMO: 9809239, SHIPNAME: "ZHONG GU YING KOU" },
  { MMSI: 413381280, IMO: 9812901, SHIPNAME: "ZHONG GU XIONG AN" },
  { MMSI: 413381440, IMO: 9812913, SHIPNAME: "ZHONG GU RI ZHAO" },
  { MMSI: 413381750, IMO: 9102538, SHIPNAME: "QIU JIN" },
  { MMSI: 413381830, IMO: 9223758, SHIPNAME: "TENG YUN HE" },
  { MMSI: 413382030, IMO: 9113173, SHIPNAME: "CHUN JIN" },
  { MMSI: 413383270, IMO: 9223772, SHIPNAME: "LINGYUNHE" },
  { MMSI: 413384190, IMO: 0, SHIPNAME: "HONG TAI637" },
  { MMSI: 413393620, IMO: 9162423, SHIPNAME: "DONG FANG FU" },
  { MMSI: 413405430, IMO: 9419060, SHIPNAME: "KAI HE ZHI CHENG" },
  { MMSI: 413439580, IMO: 8901872, SHIPNAME: "MING HAO" },
  { MMSI: 413453290, IMO: 9179476, SHIPNAME: "HE YANG" },
  { MMSI: 413453390, IMO: 0, SHIPNAME: "HONG DA XIN 58" },
  { MMSI: 413453640, IMO: 9656307, SHIPNAME: "XIN MING ZHOU 18" },
  { MMSI: 413453650, IMO: 9656319, SHIPNAME: "XIN MING ZHOU 20" },
  { MMSI: 413454360, IMO: 9187423, SHIPNAME: "HEBIN" },
  { MMSI: 413455660, IMO: 9138252, SHIPNAME: "HE JIN" },
  { MMSI: 413455950, IMO: 9736389, SHIPNAME: "XIN MING ZHOU 22" },
  { MMSI: 413456130, IMO: 9736432, SHIPNAME: "XIN MING ZHOU 26" },
  { MMSI: 413458850, IMO: 0, SHIPNAME: "XIN MING ZHOU76" },
  { MMSI: 413497490, IMO: 9984065, SHIPNAME: "HUA HANG HAN YA 6" },
  { MMSI: 413497770, IMO: 9984053, SHIPNAME: "HUA HANG HAN YA 5" },
  { MMSI: 413519440, IMO: 9992775, SHIPNAME: "MAO GANG XIA MEN" },
  { MMSI: 413526530, IMO: 9459278, SHIPNAME: "REN JIAN 15" },
  { MMSI: 413528620, IMO: 9360348, SHIPNAME: "ZHONG LIAN SHAN TOU" },
  { MMSI: 413536120, IMO: 9348986, SHIPNAME: "XIN XIN SHAN" },
  { MMSI: 413548380, IMO: 1025186, SHIPNAME: "HONG DA XIN 778" },
  { MMSI: 413556780, IMO: 9252993, SHIPNAME: "ZHUCHENGXINZHOU" },
  { MMSI: 413558710, IMO: 1025174, SHIPNAME: "FENG XIN DA 29" },
  { MMSI: 413560380, IMO: 9986685, SHIPNAME: "CA TOKYO" },
  { MMSI: 413564860, IMO: 9915997, SHIPNAME: "YANGTZE TRADER" },
  { MMSI: 413690160, IMO: 9401269, SHIPNAME: "XIAO JIANG" },
  { MMSI: 413692490, IMO: 9456185, SHIPNAME: "HUA HANG 3" },
  { MMSI: 413695730, IMO: 9143116, SHIPNAME: "RENJIAN5" },
  { MMSI: 413695760, IMO: 9122538, SHIPNAME: "DONG FANG QIANG" },
  { MMSI: 413695770, IMO: 9143104, SHIPNAME: "RENJIAN6" },
  { MMSI: 413698170, IMO: 0, SHIPNAME: "REN JIAN GUANG ZHOU" },
  { MMSI: 413699570, IMO: 9521497, SHIPNAME: "YONG XIN 101" },
  { MMSI: 413700420, IMO: 9137557, SHIPNAME: "SHI SHANG 18" },
  { MMSI: 413701060, IMO: 9144316, SHIPNAME: "JINSHUNHE" },
  { MMSI: 413701260, IMO: 9064798, SHIPNAME: "ZE HONG" },
  { MMSI: 413701950, IMO: 0, SHIPNAME: "CI TONG 1" },
  { MMSI: 413702160, IMO: 9144328, SHIPNAME: "WAN XING DA" },
  { MMSI: 413702170, IMO: 9134505, SHIPNAME: "WANFUDA" },
  { MMSI: 413703960, IMO: 9339014, SHIPNAME: "HAI SU 7" },
  { MMSI: 413704320, IMO: 0, SHIPNAME: "REN JIAN TANG SHAN" },
  { MMSI: 413705230, IMO: 0, SHIPNAME: "REN JIAN RI ZHAO" },
  { MMSI: 413760000, IMO: 9390616, SHIPNAME: "TIAN BAO HE" },
  { MMSI: 413761000, IMO: 9400538, SHIPNAME: "TIAN LONG HE" },
  { MMSI: 413762000, IMO: 9400526, SHIPNAME: "TIAN XING HE" },
  { MMSI: 413763000, IMO: 9400514, SHIPNAME: "TIAN YUN HE" },
  { MMSI: 413790872, IMO: 9433949, SHIPNAME: "HUA FEI LUN 009" },
  { MMSI: 413798794, IMO: 1269780, SHIPNAME: "GUIGANGXINGTAI15" },
  { MMSI: 413965000, IMO: 9400552, SHIPNAME: "TIAN LI HE" },
  { MMSI: 413966000, IMO: 9400540, SHIPNAME: "TIAN XIU HE" },
  { MMSI: 413996000, IMO: 9326744, SHIPNAME: "REN JIAN 10" },
  { MMSI: 414195000, IMO: 9326756, SHIPNAME: "RENJIAN8" },
  { MMSI: 414201000, IMO: 9157650, SHIPNAME: "RI ZHAO SHENG SHI" },
  { MMSI: 414209000, IMO: 9157648, SHIPNAME: "RI ZHAO HONG YUN" },
  { MMSI: 414239000, IMO: 9232113, SHIPNAME: "ZHONG GU JI LIN" },
  { MMSI: 414240000, IMO: 9185413, SHIPNAME: "XIN BIN ZHOU" },
  { MMSI: 414246000, IMO: 9333060, SHIPNAME: "ZHONG GU SHAN DONG" },
  { MMSI: 414257000, IMO: 9252242, SHIPNAME: "ZHONG AN XIN HUAYUAN" },
  { MMSI: 414293000, IMO: 9450923, SHIPNAME: "REN JIAN 17" },
  { MMSI: 414303000, IMO: 9300300, SHIPNAME: "TIAN XIANG HE" },
  { MMSI: 414304000, IMO: 9300312, SHIPNAME: "TIAN SHUN HE" },
  { MMSI: 414310000, IMO: 9300324, SHIPNAME: "TIAN CHANG HE" },
  { MMSI: 414328000, IMO: 9302554, SHIPNAME: "FAN YA NING DE" },
  { MMSI: 414333000, IMO: 9280809, SHIPNAME: "REN JIAN 19" },
  { MMSI: 414334000, IMO: 9464699, SHIPNAME: "REN JIAN 20" },
  { MMSI: 414342000, IMO: 9302580, SHIPNAME: "FAN YA GUANG ZHOU" },
  { MMSI: 414345000, IMO: 9295359, SHIPNAME: "FAN YA TIAN JIN" },
  { MMSI: 414346000, IMO: 9389411, SHIPNAME: "REN JIAN 23" },
  { MMSI: 414349000, IMO: 9400124, SHIPNAME: "ZHONG GU FU JIAN" },
  { MMSI: 414350000, IMO: 9290529, SHIPNAME: "FAN YA SHANG HAI" },
  { MMSI: 414362000, IMO: 9450935, SHIPNAME: "REN JIAN 25" },
  { MMSI: 414365000, IMO: 9450947, SHIPNAME: "REN JIAN 26" },
  { MMSI: 414366000, IMO: 9484510, SHIPNAME: "REN JIAN 27" },
  { MMSI: 414400430, IMO: 0, SHIPNAME: "XIN MING ZHOU 86" },
  { MMSI: 414472000, IMO: 9885714, SHIPNAME: "XIN XIANG XUE LAN" },
  { MMSI: 414626000, IMO: 9323015, SHIPNAME: "HENG HUI 5" },
  { MMSI: 414653000, IMO: 9287895, SHIPNAME: "HENG HUI 6" },
  { MMSI: 414654000, IMO: 9938872, SHIPNAME: "ZHONG GU JI NAN" },
  { MMSI: 414661000, IMO: 9938884, SHIPNAME: "ZHONG GU NAN NING" },
  { MMSI: 414664000, IMO: 0, SHIPNAME: "HUA DA 610" },
  { MMSI: 414672000, IMO: 9938901, SHIPNAME: "ZHONG GU FU ZHOU" },
  { MMSI: 414676000, IMO: 9938896, SHIPNAME: "ZHONG GU HANG ZHOU" },
  { MMSI: 414682000, IMO: 0, SHIPNAME: "LIANG XIANG 82" },
  { MMSI: 414685000, IMO: 9933808, SHIPNAME: "ZHONG GU NAN JING" },
  { MMSI: 414688000, IMO: 9938913, SHIPNAME: "ZHONG GU YIN CHUAN" },
  { MMSI: 414693000, IMO: 9450911, SHIPNAME: "REN JIAN 16" },
  { MMSI: 414700000, IMO: 9523005, SHIPNAME: "XIN QIN ZHOU" },
  { MMSI: 414713000, IMO: 9523017, SHIPNAME: "XIN HANG ZHOU" },
  { MMSI: 414714000, IMO: 9523029, SHIPNAME: "XIN ZHENG ZHOU" },
  { MMSI: 414721000, IMO: 9523031, SHIPNAME: "XIN LAN ZHOU" },
  { MMSI: 414736000, IMO: 9523043, SHIPNAME: "XIN WEN ZHOU" },
  { MMSI: 414752000, IMO: 9523081, SHIPNAME: "XIN XU ZHOU" },
  { MMSI: 414758000, IMO: 9523093, SHIPNAME: "XIN CANG ZHOU" },
  { MMSI: 414760000, IMO: 9523108, SHIPNAME: "XIN HUI ZHOU" },
  { MMSI: 414793000, IMO: 9938925, SHIPNAME: "ZHONG GU SHEN YANG" },
  { MMSI: 414798000, IMO: 9981776, SHIPNAME: "CHANG SHUN JIN XIU" },
  { MMSI: 414806000, IMO: 9933810, SHIPNAME: "ZHONG GU NAN CHANG" },
  { MMSI: 414807000, IMO: 9938937, SHIPNAME: "ZHONG GU CHANG CHUN" },
  { MMSI: 414808000, IMO: 9938949, SHIPNAME: "ZHONG GU LAN ZHOU" },
  { MMSI: 414816000, IMO: 9981788, SHIPNAME: "CHANGSHUN QIANCHENG" },
  { MMSI: 414821000, IMO: 9938951, SHIPNAME: "ZHONG GU XI AN" },
  { MMSI: 414830000, IMO: 9933822, SHIPNAME: "ZHONG GU WU HAN" },
  { MMSI: 414841000, IMO: 0, SHIPNAME: "HONG TAI 869" },
  { MMSI: 414842000, IMO: 9938963, SHIPNAME: "ZHONG GU CHANG SHA" },
  { MMSI: 414849000, IMO: 9933834, SHIPNAME: "ZHONG GU CHONG QING" },
  { MMSI: 414877000, IMO: 9933846, SHIPNAME: "ZHONG GU CHENG DU" },
  { MMSI: 414885000, IMO: 0, SHIPNAME: "CHANG AN RUN" },
  { MMSI: 414888000, IMO: 9969390, SHIPNAME: "ALS LUNA" },
  { MMSI: 414987512, IMO: 9253179, SHIPNAME: "TEST" },
  { MMSI: 416003900, IMO: 9790074, SHIPNAME: "EVER BLESS" },
  { MMSI: 416004889, IMO: 9869978, SHIPNAME: "EVER CAST" },
  { MMSI: 416016000, IMO: 9596349, SHIPNAME: "WAN HAI 103" },
  { MMSI: 416020000, IMO: 9172313, SHIPNAME: "KUO CHANG" },
  { MMSI: 416024000, IMO: 9404508, SHIPNAME: "FORMOSACONTAINER NO4" },
  { MMSI: 416031000, IMO: 9786944, SHIPNAME: "EVER BALMY" },
  { MMSI: 416032000, IMO: 9786968, SHIPNAME: "EVER BASIS" },
  { MMSI: 416033000, IMO: 9786982, SHIPNAME: "EVER BEADY" },
  { MMSI: 416034000, IMO: 9787003, SHIPNAME: "EVER BEAMY" },
  { MMSI: 416035000, IMO: 9787027, SHIPNAME: "EVER BLOOM" },
  { MMSI: 416036000, IMO: 9784128, SHIPNAME: "EVER BEFIT" },
  { MMSI: 416037000, IMO: 9784142, SHIPNAME: "EVER BEING" },
  { MMSI: 416038000, IMO: 9790050, SHIPNAME: "EVER BOARD" },
  { MMSI: 416039000, IMO: 9790074, SHIPNAME: "EVER BLESS" },
  { MMSI: 416040000, IMO: 9790098, SHIPNAME: "EVER BLINK" },
  { MMSI: 416047000, IMO: 9208162, SHIPNAME: "WAN HAI 232" },
  { MMSI: 416061000, IMO: 9904508, SHIPNAME: "EVER CREATE" },
  { MMSI: 416063000, IMO: 9904522, SHIPNAME: "EVER CONVEY" },
  { MMSI: 416064000, IMO: 9904534, SHIPNAME: "EVER CAREER" },
  { MMSI: 416070000, IMO: 9893931, SHIPNAME: "EVER ARM" },
  { MMSI: 416071000, IMO: 9893943, SHIPNAME: "EVER ART" },
  { MMSI: 416075000, IMO: 9493298, SHIPNAME: "WAN HAI 101" },
  { MMSI: 416077000, IMO: 9362566, SHIPNAME: "TEH VICTORY" },
  { MMSI: 416078000, IMO: 9493286, SHIPNAME: "WAN HAI 275" },
  { MMSI: 416340000, IMO: 9130547, SHIPNAME: "UNI ACTIVE" },
  { MMSI: 416341000, IMO: 9130559, SHIPNAME: "UNI ADROIT" },
  { MMSI: 416426000, IMO: 9301275, SHIPNAME: "YM HEIGHTS" },
  { MMSI: 416427000, IMO: 9299329, SHIPNAME: "YM HARMONY" },
  { MMSI: 416428000, IMO: 9301263, SHIPNAME: "YM HORIZON" },
  { MMSI: 416429000, IMO: 9299317, SHIPNAME: "YM HAWK" },
  { MMSI: 416438000, IMO: 9167461, SHIPNAME: "KANWAY GLOBAL" },
  { MMSI: 416453000, IMO: 9202211, SHIPNAME: "UNI-PROMOTE" },
  { MMSI: 416456000, IMO: 9202235, SHIPNAME: "UNI PROBITY" },
  { MMSI: 416464000, IMO: 9462732, SHIPNAME: "YM UNICORN" },
  { MMSI: 416465000, IMO: 9462720, SHIPNAME: "YM UPSURGENCE" },
  { MMSI: 416466000, IMO: 9462718, SHIPNAME: "YM UNANIMITY" },
  { MMSI: 416467000, IMO: 9462706, SHIPNAME: "YM UBIQUITY" },
  { MMSI: 416468000, IMO: 9462691, SHIPNAME: "YM UNIFORMITY" },
  { MMSI: 416475000, IMO: 9595486, SHIPNAME: "EVER LIBRA" },
  { MMSI: 416481000, IMO: 9595527, SHIPNAME: "EVER LIVEN" },
  { MMSI: 416482000, IMO: 9604081, SHIPNAME: "EVER LOGIC" },
  { MMSI: 416486000, IMO: 9319117, SHIPNAME: "YM INTELLIGENT" },
  { MMSI: 416487000, IMO: 9334002, SHIPNAME: "YM INAUGURATION" },
  { MMSI: 416488000, IMO: 9319129, SHIPNAME: "YM IDEALS" },
  { MMSI: 416490000, IMO: 9496460, SHIPNAME: "YM EVOLUTION" },
  { MMSI: 416491000, IMO: 9496599, SHIPNAME: "YM ESSENCE" },
  { MMSI: 416492000, IMO: 9319131, SHIPNAME: "YM IMMENSE" },
  { MMSI: 416495000, IMO: 9604158, SHIPNAME: "EVER LOYAL" },
  { MMSI: 416497000, IMO: 9629093, SHIPNAME: "EVER LUNAR" },
  { MMSI: 416498000, IMO: 9629108, SHIPNAME: "EVER LYRIC" },
  { MMSI: 416509000, IMO: 9629055, SHIPNAME: "EVER LUCID" },
  { MMSI: 419000623, IMO: 9157674, SHIPNAME: "SSL KOCHI" },
  { MMSI: 419000914, IMO: 9137545, SHIPNAME: "SSL MUMBAI" },
  { MMSI: 419000970, IMO: 9137533, SHIPNAME: "SSL GUJARAT" },
  { MMSI: 419001019, IMO: 9141314, SHIPNAME: "SSL BHARAT" },
  { MMSI: 419001093, IMO: 9649615, SHIPNAME: "SAN PRIDE" },
  { MMSI: 419001126, IMO: 9137521, SHIPNAME: "SSL VISAKHAPATNAM" },
  { MMSI: 419001250, IMO: 9217034, SHIPNAME: "SSL DELHI" },
  { MMSI: 419001324, IMO: 9238806, SHIPNAME: "SSL BRAHMAPUTRA" },
  { MMSI: 419001352, IMO: 9138238, SHIPNAME: "TCI EXPRESS" },
  { MMSI: 419001369, IMO: 9239898, SHIPNAME: "SSL KRISHNA" },
  { MMSI: 419001380, IMO: 9169495, SHIPNAME: "MOGRAL" },
  { MMSI: 419001386, IMO: 9295206, SHIPNAME: "VARADA" },
  { MMSI: 419001483, IMO: 9282273, SHIPNAME: "TCI ANAND" },
  { MMSI: 419001574, IMO: 9236042, SHIPNAME: "SM KAVERI" },
  { MMSI: 419001615, IMO: 9236511, SHIPNAME: "SM MAHI" },
  { MMSI: 419001618, IMO: 9305635, SHIPNAME: "SM NEYYAR" },
  { MMSI: 419001631, IMO: 9303742, SHIPNAME: "SM MANALI" },
  { MMSI: 419001809, IMO: 9399791, SHIPNAME: "SSL GODAVARI" },
  { MMSI: 419001812, IMO: 9363429, SHIPNAME: "SSL KAVERI" },
  { MMSI: 419001814, IMO: 9312468, SHIPNAME: "SSL THAMIRABARANI" },
  { MMSI: 419738000, IMO: 9419539, SHIPNAME: "SCI MUMBAI" },
  { MMSI: 419739000, IMO: 9418298, SHIPNAME: "SCI CHENNAI" },
  { MMSI: 422021500, IMO: 0, SHIPNAME: "YARAN" },
  { MMSI: 422026900, IMO: 9283033, SHIPNAME: "GOLBON" },
  { MMSI: 422027500, IMO: 9270646, SHIPNAME: "SHIBA" },
  { MMSI: 422031200, IMO: 9349588, SHIPNAME: "SHABDIS" },
  { MMSI: 422031300, IMO: 9349590, SHIPNAME: "BEHTA" },
  { MMSI: 422031500, IMO: 9349576, SHIPNAME: "FLORA" },
  { MMSI: 422031600, IMO: 9349667, SHIPNAME: "ABYAN" },
  { MMSI: 422031700, IMO: 9349679, SHIPNAME: "ZARDIS" },
  { MMSI: 422032200, IMO: 9346548, SHIPNAME: "BEHNAVAZ" },
  { MMSI: 422032300, IMO: 9346524, SHIPNAME: "SHABGOUN" },
  { MMSI: 422032600, IMO: 9328900, SHIPNAME: "TOUSKA" },
  { MMSI: 422032700, IMO: 9346536, SHIPNAME: "BASHT" },
  { MMSI: 422038800, IMO: 9284154, SHIPNAME: "ARTAM" },
  { MMSI: 422038900, IMO: 9284142, SHIPNAME: "ARZIN" },
  { MMSI: 422039100, IMO: 9283007, SHIPNAME: "ARTABAZ" },
  { MMSI: 422039200, IMO: 9283021, SHIPNAME: "ARTENOS" },
  { MMSI: 422039300, IMO: 9283019, SHIPNAME: "AZARGOUN" },
  { MMSI: 422058200, IMO: 9110535, SHIPNAME: "SEPEHR PAYAM" },
  { MMSI: 422068100, IMO: 9270696, SHIPNAME: "KASHAN" },
  { MMSI: 422153400, IMO: 9820245, SHIPNAME: "RAYEN" },
  { MMSI: 422153500, IMO: 9820257, SHIPNAME: "RADIN" },
  { MMSI: 422154100, IMO: 9820269, SHIPNAME: "BARZIN" },
  { MMSI: 422154200, IMO: 9820271, SHIPNAME: "HAMOUNA" },
  { MMSI: 422517000, IMO: 9184691, SHIPNAME: "IRAN SHAHED" },
  { MMSI: 422812000, IMO: 9379636, SHIPNAME: "ABTIN 1" },
  { MMSI: 422813000, IMO: 9420356, SHIPNAME: "SHAYAN 1" },
  { MMSI: 422814000, IMO: 9420368, SHIPNAME: "TABAN1" },
  { MMSI: 422905000, IMO: 9270684, SHIPNAME: "DAISY" },
  { MMSI: 428002000, IMO: 9231808, SHIPNAME: "ZIM VIRGINIA" },
  { MMSI: 431000527, IMO: 8738134, SHIPNAME: "MAIKO" },
  { MMSI: 431003000, IMO: 9806079, SHIPNAME: "ONE APUS" },
  { MMSI: 431004022, IMO: 9658848, SHIPNAME: "NAHA2" },
  { MMSI: 431004911, IMO: 9673185, SHIPNAME: "SHINSETO" },
  { MMSI: 431004956, IMO: 9710842, SHIPNAME: "SAGAMI" },
  { MMSI: 431007132, IMO: 9729788, SHIPNAME: "NATORI" },
  { MMSI: 431008189, IMO: 9800477, SHIPNAME: "SAKURA" },
  { MMSI: 431008693, IMO: 9815422, SHIPNAME: "MISAKI" },
  { MMSI: 431009828, IMO: 9820659, SHIPNAME: "RYUNAN" },
  { MMSI: 431010468, IMO: 9826873, SHIPNAME: "SHIGENOBU" },
  { MMSI: 431010652, IMO: 9832846, SHIPNAME: "UMIKAJI" },
  { MMSI: 431011592, IMO: 9843778, SHIPNAME: "NAGARA" },
  { MMSI: 431019062, IMO: 9944675, SHIPNAME: "NOGAMI" },
  { MMSI: 431021728, IMO: 9961544, SHIPNAME: "KISO" },
  { MMSI: 431022362, IMO: 9961556, SHIPNAME: "KAIFU" },
  { MMSI: 431027000, IMO: 9356701, SHIPNAME: "ONE THESEUS" },
  { MMSI: 431073000, IMO: 9806081, SHIPNAME: "ONE CYGNUS" },
  { MMSI: 431080000, IMO: 9741372, SHIPNAME: "ONE BLUE JAY" },
  { MMSI: 431120000, IMO: 9475648, SHIPNAME: "ONE MANEUVER" },
  { MMSI: 431187000, IMO: 9458999, SHIPNAME: "BROOKLYN BRIDGE" },
  { MMSI: 431241000, IMO: 9689603, SHIPNAME: "ONE MACKINAC" },
  { MMSI: 431260000, IMO: 9689615, SHIPNAME: "ONE MANHATTAN" },
  { MMSI: 431296000, IMO: 9321263, SHIPNAME: "MOL COURAGE" },
  { MMSI: 431301812, IMO: 9099236, SHIPNAME: "KOYO" },
  { MMSI: 431315000, IMO: 9339662, SHIPNAME: "ONE COMPETENCE" },
  { MMSI: 431332000, IMO: 9629902, SHIPNAME: "ONE COMMITMENT" },
  { MMSI: 431402037, IMO: 9089671, SHIPNAME: "HYOGO" },
  { MMSI: 431402072, IMO: 9099248, SHIPNAME: "TENMA" },
  { MMSI: 431446000, IMO: 9184562, SHIPNAME: "HAMAYUU" },
  { MMSI: 431501816, IMO: 9328845, SHIPNAME: "KAMIWAKA" },
  { MMSI: 431501857, IMO: 9089683, SHIPNAME: "IKUTA" },
  { MMSI: 431552000, IMO: 9741396, SHIPNAME: "ONE EAGLE" },
  { MMSI: 431602232, IMO: 9304497, SHIPNAME: "NEW CAMELLIA" },
  { MMSI: 431654000, IMO: 9806067, SHIPNAME: "ONE GRUS" },
  { MMSI: 431655000, IMO: 9806055, SHIPNAME: "ONE COLUMBA" },
  { MMSI: 431710000, IMO: 9468308, SHIPNAME: "ONE ALTAIR" },
  { MMSI: 431772000, IMO: 9741449, SHIPNAME: "ONE OWL" },
  { MMSI: 431787000, IMO: 9769295, SHIPNAME: "ONE TRIBUTE" },
  { MMSI: 431792000, IMO: 9741425, SHIPNAME: "ONE FALCON" },
  { MMSI: 431808000, IMO: 9769300, SHIPNAME: "ONE TRADITION" },
  { MMSI: 431888000, IMO: 9784776, SHIPNAME: "ONE WREN" },
  { MMSI: 431929000, IMO: 9805477, SHIPNAME: "ONE MINATO" },
  { MMSI: 431981000, IMO: 9805453, SHIPNAME: "ONE MADRID" },
  { MMSI: 431986000, IMO: 9784788, SHIPNAME: "ONE STORK" },
  { MMSI: 431989000, IMO: 9805465, SHIPNAME: "ONE MEISHAN" },
  { MMSI: 440006000, IMO: 9532276, SHIPNAME: "HOCHIMINH VOYAGER" },
  { MMSI: 440017000, IMO: 9879375, SHIPNAME: "SAWASDEE ATLANTIC" },
  { MMSI: 440022000, IMO: 9395939, SHIPNAME: "SM QINGDAO" },
  { MMSI: 440059000, IMO: 9374129, SHIPNAME: "SM TOKYO" },
  { MMSI: 440061000, IMO: 9701293, SHIPNAME: "HMM CHITTAGONG" },
  { MMSI: 440065000, IMO: 9014121, SHIPNAME: "DOOWOO FAMILY" },
  { MMSI: 440075000, IMO: 9377705, SHIPNAME: "SAWASDEE THAILAND" },
  { MMSI: 440086000, IMO: 9347425, SHIPNAME: "SM KWANGYANG" },
  { MMSI: 440088000, IMO: 9925722, SHIPNAME: "SKY RAINBOW" },
  { MMSI: 440111000, IMO: 9464704, SHIPNAME: "SM TIANJIN" },
  { MMSI: 440112000, IMO: 9742168, SHIPNAME: "HMM PROMISE" },
  { MMSI: 440117000, IMO: 9742170, SHIPNAME: "HMM BLESSING" },
  { MMSI: 440119000, IMO: 9128300, SHIPNAME: "SUNNY PALM" },
  { MMSI: 440120000, IMO: 9128996, SHIPNAME: "HEUNG-A ULSAN" },
  { MMSI: 440141000, IMO: 9312767, SHIPNAME: "SM BUSAN" },
  { MMSI: 440147000, IMO: 9314923, SHIPNAME: "KMTC HOCHIMINH" },
  { MMSI: 440151000, IMO: 9312937, SHIPNAME: "SM NINGBO" },
  { MMSI: 440162000, IMO: 9275385, SHIPNAME: "PORT KLANG VOYAGER" },
  { MMSI: 440166000, IMO: 9402500, SHIPNAME: "HONGKONG VOYAGER" },
  { MMSI: 440185000, IMO: 9658458, SHIPNAME: "HMM DHAKA" },
  { MMSI: 440189000, IMO: 9312949, SHIPNAME: "SM YANTIAN" },
  { MMSI: 440190000, IMO: 9402512, SHIPNAME: "KHARIS HERITAGE" },
  { MMSI: 440206000, IMO: 9219240, SHIPNAME: "SM JAKARTA" },
  { MMSI: 440220000, IMO: 9312779, SHIPNAME: "SM SHANGHAI" },
  { MMSI: 440221000, IMO: 9707522, SHIPNAME: "PEGASUS PETA" },
  { MMSI: 440223000, IMO: 9863338, SHIPNAME: "HMM HAMBURG" },
  { MMSI: 440251000, IMO: 9886079, SHIPNAME: "SAWASDEE SUNRISE" },
  { MMSI: 440273000, IMO: 9329576, SHIPNAME: "OSAKA VOYAGER" },
  { MMSI: 440278000, IMO: 9705445, SHIPNAME: "SKY CHALLENGE" },
  { MMSI: 440279000, IMO: 9292266, SHIPNAME: "GRACE BRIDGE" },
  { MMSI: 440280000, IMO: 9385245, SHIPNAME: "SKY VICTORIA" },
  { MMSI: 440284000, IMO: 9397107, SHIPNAME: "SENDAI TRADER" },
  { MMSI: 440287000, IMO: 9292230, SHIPNAME: "BEIJING BRIDGE" },
  { MMSI: 440288000, IMO: 9863340, SHIPNAME: "HMM HELSINKI" },
  { MMSI: 440301000, IMO: 9915961, SHIPNAME: "YOKOHAMA TRADER" },
  { MMSI: 440303000, IMO: 9915973, SHIPNAME: "KOBE TRADER" },
  { MMSI: 440309000, IMO: 9915985, SHIPNAME: "QINGDAO TRADER" },
  { MMSI: 440313000, IMO: 9658446, SHIPNAME: "HMM MONGLA" },
  { MMSI: 440316000, IMO: 9886067, SHIPNAME: "SAWASDEE BALTIC" },
  { MMSI: 440321000, IMO: 9879363, SHIPNAME: "SAWASDEE PACIFIC" },
  { MMSI: 440326000, IMO: 9863297, SHIPNAME: "HMM ALGECIRAS" },
  { MMSI: 440328000, IMO: 9868338, SHIPNAME: "HMM ROTTERDAM" },
  { MMSI: 440330000, IMO: 9536973, SHIPNAME: "SHANGHAI VOYAGER" },
  { MMSI: 440335000, IMO: 9129005, SHIPNAME: "HEUNG-A TOKYO" },
  { MMSI: 440337000, IMO: 9347968, SHIPNAME: "NINGBO TRADER" },
  { MMSI: 440342000, IMO: 9532288, SHIPNAME: "JAKARTA VOYAGER" },
  { MMSI: 440346000, IMO: 9704300, SHIPNAME: "DONGJIN ENTERPRISE" },
  { MMSI: 440348000, IMO: 9863314, SHIPNAME: "HMM DUBLIN" },
  { MMSI: 440349000, IMO: 9164603, SHIPNAME: "POS YOKOHAMA" },
  { MMSI: 440350000, IMO: 9863326, SHIPNAME: "HMM GDANSK" },
  { MMSI: 440361000, IMO: 9868352, SHIPNAME: "HMM STOCKHOLM" },
  { MMSI: 440363000, IMO: 9868340, SHIPNAME: "HMM SOUTHAMPTON" },
  { MMSI: 440366000, IMO: 9754795, SHIPNAME: "STAR VOYAGER" },
  { MMSI: 440375000, IMO: 9749116, SHIPNAME: "PANCON VICTORY" },
  { MMSI: 440401000, IMO: 9754783, SHIPNAME: "STAR CHALLENGER" },
  { MMSI: 440473000, IMO: 9133874, SHIPNAME: "POS TOKYO" },
  { MMSI: 440486000, IMO: 9868364, SHIPNAME: "HMM ST PETERSBURG" },
  { MMSI: 440489000, IMO: 9868326, SHIPNAME: "HMM OSLO" },
  { MMSI: 440491000, IMO: 9217424, SHIPNAME: "KMTC JAKARTA" },
  { MMSI: 440493000, IMO: 9217412, SHIPNAME: "KMTC SINGAPORE" },
  { MMSI: 440505000, IMO: 9167306, SHIPNAME: "GLOBAL NUBIRA" },
  { MMSI: 440516000, IMO: 9517628, SHIPNAME: "NAGOYA TRADER" },
  { MMSI: 440550000, IMO: 9749128, SHIPNAME: "PANCON SUNSHINE" },
  { MMSI: 440559000, IMO: 9347607, SHIPNAME: "HYUNDAI GOODWILL" },
  { MMSI: 440562000, IMO: 9301316, SHIPNAME: "PEGASUS YOTTA" },
  { MMSI: 440567000, IMO: 9102849, SHIPNAME: "SUNNY OAK" },
  { MMSI: 440572000, IMO: 9415985, SHIPNAME: "POS SHANGHAI" },
  { MMSI: 440585000, IMO: 9157741, SHIPNAME: "KMTC KEELUNG" },
  { MMSI: 440642000, IMO: 9248227, SHIPNAME: "PANSTAR GENIE" },
  { MMSI: 440655000, IMO: 9802023, SHIPNAME: "PANCON CHAMPION" },
  { MMSI: 440716000, IMO: 9802011, SHIPNAME: "DONGJIN VOYAGER" },
  { MMSI: 440728000, IMO: 9375496, SHIPNAME: "KMTC MANILA" },
  { MMSI: 440761000, IMO: 9375501, SHIPNAME: "KMTC NHAVA SHEVA" },
  { MMSI: 440764000, IMO: 9375305, SHIPNAME: "KMTC JEBEL ALI" },
  { MMSI: 440838000, IMO: 9375513, SHIPNAME: "KMTC CHENNAI" },
  { MMSI: 440965000, IMO: 9695298, SHIPNAME: "HEUNG-A YOUNG" },
  { MMSI: 441030000, IMO: 9365166, SHIPNAME: "PEGASUS UNIX" },
  { MMSI: 441107000, IMO: 9121041, SHIPNAME: "SUNNY SPRUCE" },
  { MMSI: 441127000, IMO: 9281358, SHIPNAME: "TY INCHEON" },
  { MMSI: 441158000, IMO: 9133484, SHIPNAME: "SUNNY MAPLE" },
  { MMSI: 441163000, IMO: 9241700, SHIPNAME: "SEONG HEE" },
  { MMSI: 441174000, IMO: 9131852, SHIPNAME: "KMTC PUSAN" },
  { MMSI: 441178000, IMO: 9162150, SHIPNAME: "PANSTAR DREAM" },
  { MMSI: 441225000, IMO: 9665695, SHIPNAME: "KMTC MUMBAI" },
  { MMSI: 441236000, IMO: 9251145, SHIPNAME: "DONGJIN FORTUNE" },
  { MMSI: 441246000, IMO: 9251016, SHIPNAME: "PANSTAR GENIE NO.2" },
  { MMSI: 441248000, IMO: 9723928, SHIPNAME: "KMTC DUBAI" },
  { MMSI: 441252000, IMO: 9626405, SHIPNAME: "KMTC NINGBO" },
  { MMSI: 441261000, IMO: 9247302, SHIPNAME: "DONGJIN FIDES" },
  { MMSI: 441266000, IMO: 9347449, SHIPNAME: "KMTC MUNDRA" },
  { MMSI: 441271000, IMO: 9884904, SHIPNAME: "PANCON HARMONY" },
  { MMSI: 441283000, IMO: 9891933, SHIPNAME: "DONGJIN CONTINENTAL" },
  { MMSI: 441293000, IMO: 9347437, SHIPNAME: "KMTC COLOMBO" },
  { MMSI: 441300000, IMO: 9115779, SHIPNAME: "SUNNY LINDEN" },
  { MMSI: 441387000, IMO: 9383546, SHIPNAME: "HECAN" },
  { MMSI: 441388000, IMO: 9383534, SHIPNAME: "SHECAN" },
  { MMSI: 441398000, IMO: 9323522, SHIPNAME: "HYUNDAI JAKARTA" },
  { MMSI: 441401000, IMO: 9409182, SHIPNAME: "KMTC DELHI" },
  { MMSI: 441403000, IMO: 9409027, SHIPNAME: "SM PORTLAND" },
  { MMSI: 441420000, IMO: 9931721, SHIPNAME: "PANCON BRIDGE" },
  { MMSI: 441421000, IMO: 9882205, SHIPNAME: "KMTC SEOUL" },
  { MMSI: 441424000, IMO: 9939266, SHIPNAME: "DONGJIN CONFIDENT" },
  { MMSI: 441432000, IMO: 9545015, SHIPNAME: "STAR EXPRESS" },
  { MMSI: 441433000, IMO: 9545003, SHIPNAME: "STAR PIONEER" },
  { MMSI: 441442000, IMO: 9641156, SHIPNAME: "WECAN" },
  { MMSI: 441485000, IMO: 9247297, SHIPNAME: "SUNNY DAHLIA" },
  { MMSI: 441502000, IMO: 9626417, SHIPNAME: "KMTC SHENZHEN" },
  { MMSI: 441548000, IMO: 9297527, SHIPNAME: "ANTWERP BRIDGE" },
  { MMSI: 441567000, IMO: 9283150, SHIPNAME: "PEGASUS PACER" },
  { MMSI: 441568000, IMO: 9283162, SHIPNAME: "PEGASUS PRIME" },
  { MMSI: 441701000, IMO: 9312755, SHIPNAME: "SM LONG BEACH" },
  { MMSI: 441702000, IMO: 9235593, SHIPNAME: "KMTC QINGDAO" },
  { MMSI: 441736000, IMO: 9347592, SHIPNAME: "HYUNDAI INTEGRAL" },
  { MMSI: 441743000, IMO: 9105487, SHIPNAME: "SANSTAR DREAM" },
  { MMSI: 441772000, IMO: 9209908, SHIPNAME: "SINOKOR NIIGATA" },
  { MMSI: 441777000, IMO: 9146663, SHIPNAME: "SINOKOR HONGKONG" },
  { MMSI: 441788000, IMO: 9131864, SHIPNAME: "KMTC ULSAN" },
  { MMSI: 441805000, IMO: 9615339, SHIPNAME: "DONGJIN VENUS" },
  { MMSI: 441872000, IMO: 9635418, SHIPNAME: "PANCON GLORY" },
  { MMSI: 441875000, IMO: 9635420, SHIPNAME: "PANCON SUCCESS" },
  { MMSI: 441885000, IMO: 9081021, SHIPNAME: "SAWASDEE SINGAPORE" },
  { MMSI: 441902000, IMO: 9274202, SHIPNAME: "KMTC SHANGHAI" },
  { MMSI: 441933000, IMO: 9315848, SHIPNAME: "KMTC TIANJIN" },
  { MMSI: 441934000, IMO: 9329590, SHIPNAME: "VOSTOCHNY VOYAGER" },
  { MMSI: 441952000, IMO: 9160906, SHIPNAME: "SINOKOR QINGDAO" },
  { MMSI: 441953000, IMO: 9159335, SHIPNAME: "HANSUNG INCHEON" },
  { MMSI: 441973000, IMO: 9329588, SHIPNAME: "HAKATA VOYAGER" },
  { MMSI: 445091000, IMO: 9045990, SHIPNAME: "BOUN1" },
  { MMSI: 445879000, IMO: 9446893, SHIPNAME: "THO SONG" },
  { MMSI: 450617000, IMO: 7528556, SHIPNAME: "PRINCESS M" },
  { MMSI: 457130000, IMO: 9159268, SHIPNAME: "PROSPER" },
  { MMSI: 457583000, IMO: 9159309, SHIPNAME: "DONG HO" },
  { MMSI: 466055000, IMO: 9415997, SHIPNAME: "AL BIDDA" },
  { MMSI: 466057000, IMO: 9416006, SHIPNAME: "AL RUMEILA" },
  { MMSI: 466061000, IMO: 9416018, SHIPNAME: "OSHAIRIJ" },
  { MMSI: 466073000, IMO: 9535307, SHIPNAME: "MILAHA 1" },
  { MMSI: 466543000, IMO: 9323027, SHIPNAME: "MAJD" },
  { MMSI: 470143000, IMO: 9574016, SHIPNAME: "AL SADR-I" },
  { MMSI: 470305000, IMO: 9799927, SHIPNAME: "SAFEEN PRINCIPAL" },
  { MMSI: 470552000, IMO: 9573505, SHIPNAME: "AL BAZM II" },
  { MMSI: 470752000, IMO: 9300984, SHIPNAME: "AL REEM-I" },
  { MMSI: 477001100, IMO: 9619464, SHIPNAME: "MSC AGADIR" },
  { MMSI: 477001700, IMO: 9484364, SHIPNAME: "COSCO PIRAEUS" },
  { MMSI: 477002200, IMO: 9708461, SHIPNAME: "YM WIND" },
  { MMSI: 477002300, IMO: 9781188, SHIPNAME: "KMTC TAIPEIS" },
  { MMSI: 477003200, IMO: 9367920, SHIPNAME: "SINOTRANS BEIJING" },
  { MMSI: 477004600, IMO: 9484417, SHIPNAME: "COSCO WELLINGTON" },
  { MMSI: 477004700, IMO: 9484405, SHIPNAME: "COSCO VENICE" },
  { MMSI: 477006500, IMO: 9684641, SHIPNAME: "YM WISH" },
  { MMSI: 477006600, IMO: 9704582, SHIPNAME: "MILD TEMPO" },
  { MMSI: 477007100, IMO: 9329540, SHIPNAME: "OOCL BUSAN" },
  { MMSI: 477010600, IMO: 9792620, SHIPNAME: "SINOTRANS KAOHSIUNG" },
  { MMSI: 477014800, IMO: 9224300, SHIPNAME: "SEASPAN HAMBURG" },
  { MMSI: 477016900, IMO: 9253179, SHIPNAME: "SITC HAKATA" },
  { MMSI: 477020100, IMO: 9227039, SHIPNAME: "SEASPAN FELIXSTOWE" },
  { MMSI: 477020300, IMO: 9286009, SHIPNAME: "SEASPAN OCEANIA" },
  { MMSI: 477020400, IMO: 9286011, SHIPNAME: "CSCL AFRICA" },
  { MMSI: 477021100, IMO: 9641003, SHIPNAME: "KOTA JOHAN" },
  { MMSI: 477024800, IMO: 9639634, SHIPNAME: "SITC LIANYUNGANG" },
  { MMSI: 477024900, IMO: 9639646, SHIPNAME: "SITC SHENZHEN" },
  { MMSI: 477027100, IMO: 9727998, SHIPNAME: "SITC HANSHIN" },
  { MMSI: 477030700, IMO: 9516416, SHIPNAME: "COSCO FRANCE" },
  { MMSI: 477035800, IMO: 9776183, SHIPNAME: "OOCL GERMANY" },
  { MMSI: 477036600, IMO: 9329552, SHIPNAME: "OOCL TEXAS" },
  { MMSI: 477043200, IMO: 9731925, SHIPNAME: "COSCO SHIPPING VOLGA" },
  { MMSI: 477045600, IMO: 9216858, SHIPNAME: "TANG SHAN GANG JI 1" },
  { MMSI: 477047100, IMO: 9338280, SHIPNAME: "GLORY OCEAN" },
  { MMSI: 477056400, IMO: 9334923, SHIPNAME: "COSCO OCEANIA" },
  { MMSI: 477056600, IMO: 9355769, SHIPNAME: "OOCL PANAMA" },
  { MMSI: 477057100, IMO: 9731949, SHIPNAME: "COSCO SHIPPING SEINE" },
  { MMSI: 477057600, IMO: 9339038, SHIPNAME: "TANG SHAN GANG JI2" },
  { MMSI: 477057900, IMO: 9339040, SHIPNAME: "HUA HANG 6" },
  { MMSI: 477062000, IMO: 8907412, SHIPNAME: "KOKOPO CHIEF" },
  { MMSI: 477065200, IMO: 9472127, SHIPNAME: "COSCO FORTUNE" },
  { MMSI: 477066300, IMO: 9689938, SHIPNAME: "CORAL CHIEF" },
  { MMSI: 477066600, IMO: 9698393, SHIPNAME: "SUNNY CAMELLIA" },
  { MMSI: 477066700, IMO: 9713337, SHIPNAME: "SEASPAN BEACON" },
  { MMSI: 477067800, IMO: 9477907, SHIPNAME: "OOCL TAIPEI" },
  { MMSI: 477067900, IMO: 9486087, SHIPNAME: "OOCL UTAH" },
  { MMSI: 477071700, IMO: 9385972, SHIPNAME: "MAERSK NILE" },
  { MMSI: 477076300, IMO: 9301770, SHIPNAME: "SEASPAN NEW DELHI" },
  { MMSI: 477077700, IMO: 9484338, SHIPNAME: "COSCO HAIFA" },
  { MMSI: 477077800, IMO: 9484326, SHIPNAME: "COSCO GENOA" },
  { MMSI: 477078600, IMO: 9712773, SHIPNAME: "SITC SHANGHAI" },
  { MMSI: 477079500, IMO: 9358008, SHIPNAME: "OSG BOSSTEC" },
  { MMSI: 477083700, IMO: 9525364, SHIPNAME: "MAERSK CHILKA" },
  { MMSI: 477083800, IMO: 9525376, SHIPNAME: "MAERSK CHAMBAL" },
  { MMSI: 477089200, IMO: 9361275, SHIPNAME: "OSG BEAUTEC" },
  { MMSI: 477096300, IMO: 9708473, SHIPNAME: "YM WREATH" },
  { MMSI: 477096400, IMO: 9792632, SHIPNAME: "SINOTRANS KEELUNG" },
  { MMSI: 477097400, IMO: 9301782, SHIPNAME: "SEASPAN DUBAI" },
  { MMSI: 477100300, IMO: 9757840, SHIPNAME: "COSCO HIMALAYAS" },
  { MMSI: 477100400, IMO: 9776195, SHIPNAME: "OOCL JAPAN" },
  { MMSI: 477103800, IMO: 9776200, SHIPNAME: "OOCL UNITED KINGDOM" },
  { MMSI: 477105300, IMO: 9301794, SHIPNAME: "SEASPAN JAKARTA" },
  { MMSI: 477105600, IMO: 9300790, SHIPNAME: "OOCL ASIA" },
  { MMSI: 477106800, IMO: 9301809, SHIPNAME: "SEASPAN SAIGON" },
  { MMSI: 477107200, IMO: 9355551, SHIPNAME: "COSCO PACIFIC" },
  { MMSI: 477107900, IMO: 9367968, SHIPNAME: "SINOTRANS OSAKA" },
  { MMSI: 477108100, IMO: 9472141, SHIPNAME: "COSCO FAITH" },
  { MMSI: 477109800, IMO: 9330769, SHIPNAME: "SINOTRANS HONG KONG" },
  { MMSI: 477111700, IMO: 9385996, SHIPNAME: "MAERSK NANSHA" },
  { MMSI: 477112300, IMO: 9322243, SHIPNAME: "SITC QINZHOU" },
  { MMSI: 477112600, IMO: 9792644, SHIPNAME: "SINOTRANS MANILA" },
  { MMSI: 477117100, IMO: 9666845, SHIPNAME: "MILD CHORUS" },
  { MMSI: 477117400, IMO: 9645877, SHIPNAME: "CSCL WINTER" },
  { MMSI: 477117900, IMO: 9645891, SHIPNAME: "CSCL AUTUMN" },
  { MMSI: 477118100, IMO: 9158862, SHIPNAME: "SWAN LAKE" },
  { MMSI: 477118600, IMO: 9731951, SHIPNAME: "COSCO SHIPPING RHINE" },
  { MMSI: 477121100, IMO: 9632753, SHIPNAME: "KOTA MAKMUR" },
  { MMSI: 477121200, IMO: 9776212, SHIPNAME: "OOCL SCANDINAVIA" },
  { MMSI: 477127900, IMO: 9685358, SHIPNAME: "SEASPAN BREEZE" },
  { MMSI: 477129300, IMO: 9708447, SHIPNAME: "YM WIDTH" },
  { MMSI: 477133700, IMO: 9200823, SHIPNAME: "INTERASIAINSPIRATION" },
  { MMSI: 477135600, IMO: 9472189, SHIPNAME: "COSCO EXCELLENCE" },
  { MMSI: 477136800, IMO: 9355563, SHIPNAME: "COSCO KAOHSIUNG" },
  { MMSI: 477137700, IMO: 9728007, SHIPNAME: "SITC KANTO" },
  { MMSI: 477139100, IMO: 9783459, SHIPNAME: "COSCO TAURUS" },
  { MMSI: 477139800, IMO: 9792656, SHIPNAME: "SINOTRANS BANGKOK" },
  { MMSI: 477139900, IMO: 9757852, SHIPNAME: "COSCO KILIMANJARO" },
  { MMSI: 477143100, IMO: 9332688, SHIPNAME: "MAERSK ROUBAIX" },
  { MMSI: 477143400, IMO: 9332676, SHIPNAME: "MAERSK REGENSBURG" },
  { MMSI: 477144700, IMO: 9386017, SHIPNAME: "MAERSK NADI" },
  { MMSI: 477144800, IMO: 9402615, SHIPNAME: "MAERSK NEWARK" },
  { MMSI: 477147600, IMO: 9776224, SHIPNAME: "OOCL INDONESIA" },
  { MMSI: 477148700, IMO: 9757864, SHIPNAME: "COSCO SHIPPING ALPS" },
  { MMSI: 477148800, IMO: 9783497, SHIPNAME: "COSCO SHIPPING ARIES" },
  { MMSI: 477150700, IMO: 9359715, SHIPNAME: "XIN XIN TIAN 2" },
  { MMSI: 477151200, IMO: 9815666, SHIPNAME: "JOSCO LUCKY" },
  { MMSI: 477154200, IMO: 9793909, SHIPNAME: "SEASPAN FALCON" },
  { MMSI: 477154300, IMO: 9793894, SHIPNAME: "SALVADOR EXPRESS" },
  { MMSI: 477154400, IMO: 9244207, SHIPNAME: "ACACIA HAWK" },
  { MMSI: 477154500, IMO: 9815680, SHIPNAME: "JOSCO REAL" },
  { MMSI: 477154600, IMO: 9815678, SHIPNAME: "JOSCO SHINE" },
  { MMSI: 477154700, IMO: 9783526, SHIPNAME: "COSCO GEMINI" },
  { MMSI: 477157400, IMO: 9795610, SHIPNAME: "COSCO UNIVERSE" },
  { MMSI: 477157800, IMO: 9783502, SHIPNAME: "COSCO SHIPPING LEO" },
  { MMSI: 477158700, IMO: 9307217, SHIPNAME: "XIN LOS ANGELES" },
  { MMSI: 477159600, IMO: 9793923, SHIPNAME: "SEASPAN HARRIER" },
  { MMSI: 477159700, IMO: 9793911, SHIPNAME: "SEASPAN RAPTOR" },
  { MMSI: 477159900, IMO: 9339088, SHIPNAME: "JRS CARINA" },
  { MMSI: 477166500, IMO: 9783461, SHIPNAME: "COSCO SHIPPING VIRGO" },
  { MMSI: 477166600, IMO: 9757876, SHIPNAME: "COSCOSHIPPING DENALI" },
  { MMSI: 477167800, IMO: 9261384, SHIPNAME: "SITC HAIPHONG" },
  { MMSI: 477168400, IMO: 9785744, SHIPNAME: "COSCO SHIPPING PEONY" },
  { MMSI: 477168900, IMO: 9783514, SHIPNAME: "COSCO CAPRICORN" },
  { MMSI: 477172700, IMO: 9783538, SHIPNAME: "COSCO SHIPPING LIBRA" },
  { MMSI: 477174600, IMO: 9525479, SHIPNAME: "MAERSK CAIRO" },
  { MMSI: 477174700, IMO: 9525481, SHIPNAME: "MAERSK CUBANGO" },
  { MMSI: 477174900, IMO: 9525388, SHIPNAME: "MAERSK CHACHAI" },
  { MMSI: 477178200, IMO: 9334662, SHIPNAME: "MAERSK TAIKUNG" },
  { MMSI: 477178400, IMO: 9332511, SHIPNAME: "MAERSK TANJONG" },
  { MMSI: 477178900, IMO: 9355288, SHIPNAME: "MAERSK BINTAN" },
  { MMSI: 477181200, IMO: 9713363, SHIPNAME: "SEASPAN BELLWETHER" },
  { MMSI: 477181900, IMO: 9754769, SHIPNAME: "GLORY SHANGHAI" },
  { MMSI: 477182200, IMO: 9622588, SHIPNAME: "OOCL POLAND" },
  { MMSI: 477182300, IMO: 9622590, SHIPNAME: "OOCL BRUSSELS" },
  { MMSI: 477182400, IMO: 9477892, SHIPNAME: "OOCL MIAMI" },
  { MMSI: 477182500, IMO: 9639660, SHIPNAME: "SITC FANGCHENG" },
  { MMSI: 477183800, IMO: 9789635, SHIPNAME: "COSCO SCORPIO" },
  { MMSI: 477183900, IMO: 9785768, SHIPNAME: "COSCO JASMINE" },
  { MMSI: 477189300, IMO: 9355575, SHIPNAME: "COSCO TAICANG" },
  { MMSI: 477189700, IMO: 9440045, SHIPNAME: "OOCL NORFOLK" },
  { MMSI: 477190600, IMO: 9610547, SHIPNAME: "SITC QINGDAO" },
  { MMSI: 477193400, IMO: 9698381, SHIPNAME: "SUNNY CALLA" },
  { MMSI: 477194100, IMO: 9783473, SHIPNAME: "COSCO SAGITTARIUS" },
  { MMSI: 477194400, IMO: 9795622, SHIPNAME: "COSCO NEBULA" },
  { MMSI: 477194600, IMO: 9757888, SHIPNAME: "COSCO SHIPPING ANDES" },
  { MMSI: 477194800, IMO: 9785809, SHIPNAME: "COSCO SHIPPING ROSE" },
  { MMSI: 477195100, IMO: 9235531, SHIPNAME: "MAERSK VIRGINIA" },
  { MMSI: 477201600, IMO: 9324966, SHIPNAME: "JJ STAR" },
  { MMSI: 477203100, IMO: 9622605, SHIPNAME: "OOCL BERLIN" },
  { MMSI: 477203300, IMO: 9639608, SHIPNAME: "SITC HOCHIMINH" },
  { MMSI: 477203700, IMO: 9639622, SHIPNAME: "SITC YANTAI" },
  { MMSI: 477207300, IMO: 9402627, SHIPNAME: "MAERSK NEW DELHI" },
  { MMSI: 477207400, IMO: 9437385, SHIPNAME: "SEASPAN LONCOMILLA" },
  { MMSI: 477207600, IMO: 9443487, SHIPNAME: "SEASPAN LUMACO" },
  { MMSI: 477207700, IMO: 9407134, SHIPNAME: "SEASPAN EMERALD" },
  { MMSI: 477209100, IMO: 9834014, SHIPNAME: "SITC TOYOHASHI" },
  { MMSI: 477213400, IMO: 9467263, SHIPNAME: "CSCL JUPITER" },
  { MMSI: 477213800, IMO: 9492713, SHIPNAME: "SEASPAN CHIBA" },
  { MMSI: 477214400, IMO: 9328481, SHIPNAME: "GERHARD SCHULTE" },
  { MMSI: 477214700, IMO: 9300805, SHIPNAME: "OOCL EUROPE" },
  { MMSI: 477217400, IMO: 9380257, SHIPNAME: "WAN HAI 171" },
  { MMSI: 477218900, IMO: 9293569, SHIPNAME: "SITC NINGBO" },
  { MMSI: 477219400, IMO: 9633757, SHIPNAME: "SINOTRANS TIANJIN" },
  { MMSI: 477219900, IMO: 9516430, SHIPNAME: "COSCO NETHERLANDS" },
  { MMSI: 477220100, IMO: 9627980, SHIPNAME: "OOCL MALAYSIA" },
  { MMSI: 477220200, IMO: 9627978, SHIPNAME: "OOCL BANGKOK" },
  { MMSI: 477222600, IMO: 9486075, SHIPNAME: "OOCL MEMPHIS" },
  { MMSI: 477222700, IMO: 9622617, SHIPNAME: "OOCL FRANCE" },
  { MMSI: 477224100, IMO: 9656292, SHIPNAME: "NEW MINGZHOU 16" },
  { MMSI: 477225300, IMO: 9380269, SHIPNAME: "WAN HAI 172" },
  { MMSI: 477229400, IMO: 9467275, SHIPNAME: "CSCL MERCURY" },
  { MMSI: 477232200, IMO: 9785794, SHIPNAME: "COSCO SAKURA" },
  { MMSI: 477232800, IMO: 9789647, SHIPNAME: "COSCO PISCES" },
  { MMSI: 477233100, IMO: 9437189, SHIPNAME: "TRADER" },
  { MMSI: 477233200, IMO: 9793959, SHIPNAME: "KOTA PURI" },
  { MMSI: 477233300, IMO: 9785756, SHIPNAME: "COSCO AZALEA" },
  { MMSI: 477233700, IMO: 9834026, SHIPNAME: "SITC TOKUYAMA" },
  { MMSI: 477233800, IMO: 9801548, SHIPNAME: "SITC SENDAI" },
  { MMSI: 477234800, IMO: 9780665, SHIPNAME: "DELPHIS RIGA" },
  { MMSI: 477241800, IMO: 9301811, SHIPNAME: "SEASPAN LAHORE" },
  { MMSI: 477242600, IMO: 9633745, SHIPNAME: "SINOTRANS SHANGHAI" },
  { MMSI: 477250600, IMO: 9610559, SHIPNAME: "SITC BUSAN" },
  { MMSI: 477250700, IMO: 9656280, SHIPNAME: "NEW MINGZHOU 12" },
  { MMSI: 477257100, IMO: 9645853, SHIPNAME: "CSCL SPRING" },
  { MMSI: 477266800, IMO: 9467251, SHIPNAME: "CSCL VENUS" },
  { MMSI: 477266900, IMO: 9448815, SHIPNAME: "COSCO VIETNAM" },
  { MMSI: 477269300, IMO: 9795634, SHIPNAME: "COSCO GALAXY" },
  { MMSI: 477269400, IMO: 9785811, SHIPNAME: "COSCO SHIPPING LOTUS" },
  { MMSI: 477269800, IMO: 9801550, SHIPNAME: "SITC SUBIC" },
  { MMSI: 477271300, IMO: 9645906, SHIPNAME: "CSCL YELLOW SEA" },
  { MMSI: 477271400, IMO: 9516466, SHIPNAME: "COSCO PORTUGAL" },
  { MMSI: 477271900, IMO: 9685322, SHIPNAME: "SEASPAN BRAVO" },
  { MMSI: 477274400, IMO: 9467299, SHIPNAME: "CSCL SATURN" },
  { MMSI: 477274700, IMO: 9198109, SHIPNAME: "OOCL NEW YORK" },
  { MMSI: 477274900, IMO: 9610535, SHIPNAME: "SITC KWANGYANG" },
  { MMSI: 477276600, IMO: 9477880, SHIPNAME: "OOCL CANADA" },
  { MMSI: 477282300, IMO: 9332195, SHIPNAME: "OOCL ZHOUSHAN" },
  { MMSI: 477282900, IMO: 9307231, SHIPNAME: "XIN SHANGHAI" },
  { MMSI: 477286200, IMO: 0, SHIPNAME: "COSCOSHIPPING ORCHID" },
  { MMSI: 477286300, IMO: 9785782, SHIPNAME: "COSCO CAMELLIA" },
  { MMSI: 477286500, IMO: 9793961, SHIPNAME: "KOTA PUSAKA" },
  { MMSI: 477293100, IMO: 9627992, SHIPNAME: "OOCL KOREA" },
  { MMSI: 477293200, IMO: 9628001, SHIPNAME: "OOCL SINGAPORE" },
  { MMSI: 477293900, IMO: 9645865, SHIPNAME: "CSCL SUMMER" },
  { MMSI: 477301900, IMO: 9712371, SHIPNAME: "SITC HEBEI" },
  { MMSI: 477302200, IMO: 9320013, SHIPNAME: "SEASPAN HANNOVER" },
  { MMSI: 477302300, IMO: 9836115, SHIPNAME: "SITC TAICANG" },
  { MMSI: 477302500, IMO: 9439747, SHIPNAME: "KOTA LUKIS" },
  { MMSI: 477302600, IMO: 9439761, SHIPNAME: "KOTA LUMBA" },
  { MMSI: 477302800, IMO: 9494541, SHIPNAME: "KOTA LUMAYAN" },
  { MMSI: 477302900, IMO: 9795646, SHIPNAME: "COSCO SHIPPING SOLAR" },
  { MMSI: 477305900, IMO: 9619476, SHIPNAME: "MSC ANTIGUA" },
  { MMSI: 477307300, IMO: 9465306, SHIPNAME: "MSC RENEE" },
  { MMSI: 477307400, IMO: 9465318, SHIPNAME: "MSC MARGRIT" },
  { MMSI: 477307900, IMO: 9739666, SHIPNAME: "SEASPAN BENEFACTOR" },
  { MMSI: 477308100, IMO: 9789623, SHIPNAME: "COSCO AQUARIUS" },
  { MMSI: 477308400, IMO: 9320001, SHIPNAME: "SEASPAN LOGA" },
  { MMSI: 477311500, IMO: 9484273, SHIPNAME: "COSCO HOUSTON" },
  { MMSI: 477311600, IMO: 9484285, SHIPNAME: "COSCO COLOMBO" },
  { MMSI: 477312800, IMO: 9836086, SHIPNAME: "SITC SHIDAO" },
  { MMSI: 477314100, IMO: 9613599, SHIPNAME: "OOCL HOCHIMINH CITY" },
  { MMSI: 477314300, IMO: 9159854, SHIPNAME: "XINDE KEELUNG" },
  { MMSI: 477314700, IMO: 9684689, SHIPNAME: "YM WINNER" },
  { MMSI: 477318200, IMO: 9795658, SHIPNAME: "COSCO SHIPPING STAR" },
  { MMSI: 477319300, IMO: 9484376, SHIPNAME: "COSCO SANTOS" },
  { MMSI: 477321000, IMO: 9253739, SHIPNAME: "OOCL MONTREAL" },
  { MMSI: 477326200, IMO: 9856048, SHIPNAME: "SUNNY FREESIA" },
  { MMSI: 477333400, IMO: 9742039, SHIPNAME: "GLORY GUANDONG" },
  { MMSI: 477333500, IMO: 9776171, SHIPNAME: "OOCL HONG KONG" },
  { MMSI: 477333900, IMO: 9731937, SHIPNAME: "COSCOSHIPPING THAMES" },
  { MMSI: 477335600, IMO: 9639658, SHIPNAME: "SITC SHIMIZU" },
  { MMSI: 477345900, IMO: 9795660, SHIPNAME: "COSCO PLANET" },
  { MMSI: 477346500, IMO: 9535163, SHIPNAME: "HENRIKA SCHULTE" },
  { MMSI: 477347200, IMO: 9754771, SHIPNAME: "GLORY TIANJIN" },
  { MMSI: 477347400, IMO: 9704635, SHIPNAME: "YM WORTH" },
  { MMSI: 477347900, IMO: 9712761, SHIPNAME: "SITC KEELUNG" },
  { MMSI: 477348300, IMO: 9739678, SHIPNAME: "SEASPAN BEYOND" },
  { MMSI: 477348700, IMO: 9757321, SHIPNAME: "NEW MING ZHOU 60" },
  { MMSI: 477351100, IMO: 9477878, SHIPNAME: "OOCL BEIJING" },
  { MMSI: 477351400, IMO: 9448803, SHIPNAME: "COSCO PRINCE RUPERT" },
  { MMSI: 477360800, IMO: 9285976, SHIPNAME: "CSCL ASIA" },
  { MMSI: 477387600, IMO: 9140061, SHIPNAME: "IAL001" },
  { MMSI: 477390300, IMO: 9630406, SHIPNAME: "SEASPAN ZAMBEZI" },
  { MMSI: 477390400, IMO: 9630391, SHIPNAME: "SEASPAN AMAZON" },
  { MMSI: 477390900, IMO: 9666857, SHIPNAME: "MILD SONATA" },
  { MMSI: 477397800, IMO: 9472177, SHIPNAME: "COSCO HARMONY" },
  { MMSI: 477400800, IMO: 9037214, SHIPNAME: "JI YUAN" },
  { MMSI: 477400900, IMO: 9037252, SHIPNAME: "JI RUN" },
  { MMSI: 477401100, IMO: 9412608, SHIPNAME: "HAO AN" },
  { MMSI: 477401900, IMO: 9845738, SHIPNAME: "SITC BATANGAS" },
  { MMSI: 477406000, IMO: 9130535, SHIPNAME: "UNI ACCORD" },
  { MMSI: 477407600, IMO: 9336270, SHIPNAME: "FENG ZE YUAN" },
  { MMSI: 477407900, IMO: 9630377, SHIPNAME: "SEASPAN THAMES" },
  { MMSI: 477423100, IMO: 9525467, SHIPNAME: "MAERSK CASABLANCA" },
  { MMSI: 477423800, IMO: 9467304, SHIPNAME: "CSCL URANUS" },
  { MMSI: 477424100, IMO: 9112416, SHIPNAME: "DONG FANG XING" },
  { MMSI: 477424600, IMO: 9467287, SHIPNAME: "CSCL MARS" },
  { MMSI: 477427500, IMO: 9516404, SHIPNAME: "COSCO BELGIUM" },
  { MMSI: 477430100, IMO: 9708459, SHIPNAME: "YM WELCOME" },
  { MMSI: 477430500, IMO: 9742015, SHIPNAME: "GLORY ZHENDONG" },
  { MMSI: 477430600, IMO: 9739680, SHIPNAME: "MAERSK GENOA" },
  { MMSI: 477434200, IMO: 9494280, SHIPNAME: "SEASPAN TOKYO" },
  { MMSI: 477439900, IMO: 9751743, SHIPNAME: "NEW MINGZHOU 66" },
  { MMSI: 477441400, IMO: 9630389, SHIPNAME: "SEASPAN YANGTZE" },
  { MMSI: 477441800, IMO: 9645889, SHIPNAME: "CSCL BOHAI SEA" },
  { MMSI: 477444600, IMO: 9639610, SHIPNAME: "SITC DALIAN" },
  { MMSI: 477454100, IMO: 9684665, SHIPNAME: "YM WELLHEAD" },
  { MMSI: 477454400, IMO: 9689940, SHIPNAME: "HIGHLAND CHIEF" },
  { MMSI: 477454700, IMO: 9684677, SHIPNAME: "YM WONDROUS" },
  { MMSI: 477454900, IMO: 9613587, SHIPNAME: "OOCL GENOA" },
  { MMSI: 477455700, IMO: 9848766, SHIPNAME: "MORESBY CHIEF" },
  { MMSI: 477462400, IMO: 9535187, SHIPNAME: "HERMANN SCHULTE" },
  { MMSI: 477463400, IMO: 9484508, SHIPNAME: "COSCO IZMIR" },
  { MMSI: 477463500, IMO: 9518335, SHIPNAME: "COSCO ASHDOD" },
  { MMSI: 477464400, IMO: 9484340, SHIPNAME: "COSCO ISTANBUL" },
  { MMSI: 477464500, IMO: 9484352, SHIPNAME: "COSCO JEDDAH" },
  { MMSI: 477466300, IMO: 9691113, SHIPNAME: "SITC SHANDONG" },
  { MMSI: 477468200, IMO: 9845740, SHIPNAME: "SITC CAGAYAN" },
  { MMSI: 477476800, IMO: 9695157, SHIPNAME: "CSCL INDIAN OCEAN" },
  { MMSI: 477477400, IMO: 9689952, SHIPNAME: "NEW GUINEA CHIEF" },
  { MMSI: 477477700, IMO: 9689964, SHIPNAME: "PAPUAN CHIEF" },
  { MMSI: 477478700, IMO: 9713349, SHIPNAME: "SEASPAN BEAUTY" },
  { MMSI: 477478800, IMO: 9684653, SHIPNAME: "YM WORLD" },
  { MMSI: 477486100, IMO: 9610157, SHIPNAME: "LIVORNO EXPRESS" },
  { MMSI: 477493600, IMO: 9258856, SHIPNAME: "SITC INCHON" },
  { MMSI: 477504900, IMO: 9332200, SHIPNAME: "OOCL AUSTRALIA" },
  { MMSI: 477518600, IMO: 9685346, SHIPNAME: "SEASPAN BRIGHTNESS" },
  { MMSI: 477521300, IMO: 9845752, SHIPNAME: "SITC MAKASSAR" },
  { MMSI: 477524100, IMO: 9704609, SHIPNAME: "YM WITNESS" },
  { MMSI: 477524300, IMO: 9704594, SHIPNAME: "MILD TUNE" },
  { MMSI: 477524700, IMO: 9704611, SHIPNAME: "YM WHOLESOME" },
  { MMSI: 477528400, IMO: 9468293, SHIPNAME: "SEASPAN ADONIS" },
  { MMSI: 477537100, IMO: 9322231, SHIPNAME: "SITC WEIHAI" },
  { MMSI: 477537200, IMO: 9763710, SHIPNAME: "DELPHIS BOTHNIA" },
  { MMSI: 477537400, IMO: 9320025, SHIPNAME: "XIN XIN TIAN 1" },
  { MMSI: 477538900, IMO: 9868431, SHIPNAME: "LAUTOKA CHIEF" },
  { MMSI: 477539600, IMO: 9786736, SHIPNAME: "SEASPAN OSPREY" },
  { MMSI: 477541100, IMO: 9445502, SHIPNAME: "OOCL BRISBANE" },
  { MMSI: 477541900, IMO: 9338307, SHIPNAME: "ACACIA LIBRA" },
  { MMSI: 477547100, IMO: 9407146, SHIPNAME: "ALTAMIRA EXPRESS" },
  { MMSI: 477547400, IMO: 9406831, SHIPNAME: "JJ SUN" },
  { MMSI: 477548100, IMO: 9712369, SHIPNAME: "SITC LIAONING" },
  { MMSI: 477548300, IMO: 9713351, SHIPNAME: "SEASPAN BELIEF" },
  { MMSI: 477548900, IMO: 9712759, SHIPNAME: "SITC HAINAN" },
  { MMSI: 477552100, IMO: 9356115, SHIPNAME: "MAERSK NUBA" },
  { MMSI: 477552300, IMO: 9356086, SHIPNAME: "MAERSK NYASSA" },
  { MMSI: 477552700, IMO: 9356098, SHIPNAME: "MAERSK NARMADA" },
  { MMSI: 477552900, IMO: 9356103, SHIPNAME: "MAERSK NAKURU" },
  { MMSI: 477581100, IMO: 9328493, SHIPNAME: "GOTTFRIED SCHULTE" },
  { MMSI: 477581400, IMO: 9301835, SHIPNAME: "SEASPAN SANTOS" },
  { MMSI: 477585300, IMO: 9666871, SHIPNAME: "MILD JAZZ" },
  { MMSI: 477585400, IMO: 9691125, SHIPNAME: "SITC ZHEJIANG" },
  { MMSI: 477588800, IMO: 9712395, SHIPNAME: "SITC GUANGXI" },
  { MMSI: 477593600, IMO: 9314222, SHIPNAME: "XIN HONG KONG" },
  { MMSI: 477594900, IMO: 9861718, SHIPNAME: "SITC NANSHA" },
  { MMSI: 477598400, IMO: 9467316, SHIPNAME: "CSCL NEPTUNE" },
  { MMSI: 477598800, IMO: 9472165, SHIPNAME: "COSCO HOPE" },
  { MMSI: 477607700, IMO: 9310238, SHIPNAME: "OOCL TOKYO" },
  { MMSI: 477612200, IMO: 9445514, SHIPNAME: "OOCL NEW ZEALAND" },
  { MMSI: 477615200, IMO: 9356799, SHIPNAME: "HF WEALTH" },
  { MMSI: 477616000, IMO: 9169419, SHIPNAME: "OOCL BELGIUM" },
  { MMSI: 477617600, IMO: 9417256, SHIPNAME: "OOCL WASHINGTON" },
  { MMSI: 477617700, IMO: 9417244, SHIPNAME: "OOCL SEOUL" },
  { MMSI: 477624100, IMO: 9447847, SHIPNAME: "MADRID EXPRESS" },
  { MMSI: 477624200, IMO: 9319557, SHIPNAME: "PROS HOPE" },
  { MMSI: 477625300, IMO: 9861885, SHIPNAME: "NADI CHIEF" },
  { MMSI: 477625400, IMO: 9447902, SHIPNAME: "PARIS EXPRESS" },
  { MMSI: 477625600, IMO: 9870836, SHIPNAME: "SITC PORT KLANG" },
  { MMSI: 477627300, IMO: 9407158, SHIPNAME: "SEASPAN EMISSARY" },
  { MMSI: 477627800, IMO: 9445526, SHIPNAME: "OOCL DALIAN" },
  { MMSI: 477627900, IMO: 9445538, SHIPNAME: "OOCL NAGOYA" },
  { MMSI: 477629400, IMO: 9402639, SHIPNAME: "MAERSK NINGBO" },
  { MMSI: 477629500, IMO: 9861720, SHIPNAME: "SITC SHEKOU" },
  { MMSI: 477629900, IMO: 9848780, SHIPNAME: "RABAUL CHIEF" },
  { MMSI: 477630400, IMO: 9385453, SHIPNAME: "A KEIGA" },
  { MMSI: 477632900, IMO: 9870848, SHIPNAME: "SITC PENANG" },
  { MMSI: 477634900, IMO: 9434943, SHIPNAME: "PUERTO LIMON EXPRESS" },
  { MMSI: 477636200, IMO: 9739692, SHIPNAME: "MAERSK GIBRALTAR" },
  { MMSI: 477637600, IMO: 9645920, SHIPNAME: "CSCL SOUTH CHINA SEA" },
  { MMSI: 477639300, IMO: 9484546, SHIPNAME: "BERNHARD SCHULTE" },
  { MMSI: 477642100, IMO: 9872377, SHIPNAME: "EVER ORIENT" },
  { MMSI: 477642200, IMO: 0, SHIPNAME: "SUVA CHIEF" },
  { MMSI: 477642400, IMO: 9220885, SHIPNAME: "NEXOE MAERSK" },
  { MMSI: 477642500, IMO: 9192466, SHIPNAME: "NICOLINE MAERSK" },
  { MMSI: 477642600, IMO: 9192478, SHIPNAME: "NORA MAERSK" },
  { MMSI: 477642700, IMO: 9220897, SHIPNAME: "NYSTED MAERSK" },
  { MMSI: 477642900, IMO: 9192454, SHIPNAME: "NICOLAI MAERSK" },
  { MMSI: 477648100, IMO: 9192442, SHIPNAME: "NELE MAERSK" },
  { MMSI: 477648300, IMO: 9870850, SHIPNAME: "SITC SINGAPORE" },
  { MMSI: 477648700, IMO: 9190781, SHIPNAME: "M/V LUNA MAERSK" },
  { MMSI: 477648800, IMO: 9190755, SHIPNAME: "LEDA MAERSK" },
  { MMSI: 477652300, IMO: 9516428, SHIPNAME: "COSCO ENGLAND" },
  { MMSI: 477654500, IMO: 9760598, SHIPNAME: "MOUNT CAMERON" },
  { MMSI: 477655200, IMO: 9448748, SHIPNAME: "COSCO JAPAN" },
  { MMSI: 477655700, IMO: 9404857, SHIPNAME: "OOCL LE HAVRE" },
  { MMSI: 477655800, IMO: 9461790, SHIPNAME: "OOCL CHARLESTON" },
  { MMSI: 477655900, IMO: 9407160, SHIPNAME: "SEASPAN EMPIRE" },
  { MMSI: 477656100, IMO: 9422574, SHIPNAME: "FAR EAST GRACE" },
  { MMSI: 477664500, IMO: 9872389, SHIPNAME: "EVER OCEAN" },
  { MMSI: 477664600, IMO: 9190731, SHIPNAME: "LOUIS MAERSK" },
  { MMSI: 477665200, IMO: 9190767, SHIPNAME: "LEXA MAERSK" },
  { MMSI: 477680400, IMO: 9102526, SHIPNAME: "JJ TOKYO" },
  { MMSI: 477681800, IMO: 9903463, SHIPNAME: "SUNNY IVY" },
  { MMSI: 477681900, IMO: 9190743, SHIPNAME: "LAUST MAERSK" },
  { MMSI: 477685300, IMO: 9448750, SHIPNAME: "COSCO KOREA" },
  { MMSI: 477690600, IMO: 9301847, SHIPNAME: "SEASPAN RIO DE JANEI" },
  { MMSI: 477690700, IMO: 9314234, SHIPNAME: "CSCL ZEEBRUGGE" },
  { MMSI: 477692700, IMO: 9751755, SHIPNAME: "NEW MINGZHOU 68" },
  { MMSI: 477694200, IMO: 9626041, SHIPNAME: "GENOA EXPRESS" },
  { MMSI: 477694300, IMO: 9626053, SHIPNAME: "BARCELONA EXPRESS" },
  { MMSI: 477696800, IMO: 9301823, SHIPNAME: "RIO GRANDE EXPRESS" },
  { MMSI: 477698400, IMO: 9666869, SHIPNAME: "MILD WALTZ" },
  { MMSI: 477698600, IMO: 9516478, SHIPNAME: "COSCO DENMARK" },
  { MMSI: 477698700, IMO: 9685334, SHIPNAME: "SEASPAN BRILLIANCE" },
  { MMSI: 477702000, IMO: 9252008, SHIPNAME: "OOCL HAMBURG" },
  { MMSI: 477704000, IMO: 9102289, SHIPNAME: "OOCL CALIFORNIA" },
  { MMSI: 477706200, IMO: 9861902, SHIPNAME: "HONIARA CHIEF" },
  { MMSI: 477711300, IMO: 9446104, SHIPNAME: "SANTA MARTA EXPRESS" },
  { MMSI: 477712400, IMO: 9695121, SHIPNAME: "CSCL GLOBE" },
  { MMSI: 477712800, IMO: 9695133, SHIPNAME: "CSCL PACIFIC OCEAN" },
  { MMSI: 477714300, IMO: 9190779, SHIPNAME: "LICA MAERSK" },
  { MMSI: 477714600, IMO: 9461491, SHIPNAME: "ZIM SHENZHEN" },
  { MMSI: 477716100, IMO: 9872391, SHIPNAME: "EVER OASIS" },
  { MMSI: 477717400, IMO: 9872406, SHIPNAME: "EVER ORDER" },
  { MMSI: 477719000, IMO: 9102291, SHIPNAME: "OOCL AMERICA" },
  { MMSI: 477726500, IMO: 9402641, SHIPNAME: "SEASPAN GUAYAQUIL" },
  { MMSI: 477728400, IMO: 9162215, SHIPNAME: "MAERSK KARACHI" },
  { MMSI: 477732700, IMO: 9924077, SHIPNAME: "SITC LICHENG" },
  { MMSI: 477737600, IMO: 9704623, SHIPNAME: "YM WELLNESS" },
  { MMSI: 477737700, IMO: 9713375, SHIPNAME: "MAERSK GUATEMALA" },
  { MMSI: 477737800, IMO: 9727871, SHIPNAME: "MAERSK GUAYAQUIL" },
  { MMSI: 477738200, IMO: 9448762, SHIPNAME: "COSCO PHILIPPINES" },
  { MMSI: 477738500, IMO: 9417268, SHIPNAME: "OOCL LONDON" },
  { MMSI: 477738600, IMO: 9417270, SHIPNAME: "OOCL LUXEMBOURG" },
  { MMSI: 477738700, IMO: 9404869, SHIPNAME: "OOCL GUANGZHOU" },
  { MMSI: 477738800, IMO: 9404883, SHIPNAME: "OOCL JAKARTA" },
  { MMSI: 477738900, IMO: 9404871, SHIPNAME: "OOCL SAVANNAH" },
  { MMSI: 477744200, IMO: 9443475, SHIPNAME: "SEASPAN LINGUE" },
  { MMSI: 477745500, IMO: 9926099, SHIPNAME: "SITC DECHENG" },
  { MMSI: 477745800, IMO: 9926104, SHIPNAME: "SITC YUNCHENG" },
  { MMSI: 477748500, IMO: 9435038, SHIPNAME: "SEASPAN CALICANTO" },
  { MMSI: 477752300, IMO: 9518347, SHIPNAME: "COSCO SURABAYA" },
  { MMSI: 477752400, IMO: 9518359, SHIPNAME: "COSCO AQABA" },
  { MMSI: 477754600, IMO: 9344681, SHIPNAME: "MAERSK VENEZIA" },
  { MMSI: 477754800, IMO: 9329629, SHIPNAME: "MAERSK INCHEON" },
  { MMSI: 477759700, IMO: 9535149, SHIPNAME: "HEDWIG SCHULTE" },
  { MMSI: 477764600, IMO: 9472153, SHIPNAME: "COSCO PRIDE" },
  { MMSI: 477765800, IMO: 9484261, SHIPNAME: "COSCO AUCKLAND" },
  { MMSI: 477765900, IMO: 9484003, SHIPNAME: "COSCO ADEN" },
  { MMSI: 477767500, IMO: 9631632, SHIPNAME: "SINOTRANS QINGDAO" },
  { MMSI: 477767600, IMO: 9631644, SHIPNAME: "SINOTRANS DALIAN" },
  { MMSI: 477768100, IMO: 9314246, SHIPNAME: "XIN BEIJING" },
  { MMSI: 477769600, IMO: 9708435, SHIPNAME: "YM WINDOW" },
  { MMSI: 477770200, IMO: 9352042, SHIPNAME: "MAERSK STOCKHOLM" },
  { MMSI: 477770300, IMO: 9352030, SHIPNAME: "MAERSK SALINA" },
  { MMSI: 477770700, IMO: 9448774, SHIPNAME: "COSCO MALAYSIA" },
  { MMSI: 477776100, IMO: 9630365, SHIPNAME: "SEASPAN GANGES" },
  { MMSI: 477776200, IMO: 9516442, SHIPNAME: "COSCO SPAIN" },
  { MMSI: 477776300, IMO: 9610169, SHIPNAME: "DETROIT EXPRESS" },
  { MMSI: 477792900, IMO: 9525455, SHIPNAME: "MAERSK CABO VERDE" },
  { MMSI: 477793200, IMO: 9918688, SHIPNAME: "SITC JIADE" },
  { MMSI: 477793400, IMO: 9367190, SHIPNAME: "OOCL OAKLAND" },
  { MMSI: 477793900, IMO: 9367176, SHIPNAME: "OOCL KUALA LUMPUR" },
  { MMSI: 477795300, IMO: 9466245, SHIPNAME: "COSCO GLORY" },
  { MMSI: 477798200, IMO: 9926116, SHIPNAME: "SITC MINGCHENG" },
  { MMSI: 477798300, IMO: 9926128, SHIPNAME: "SITC XINCHENG" },
  { MMSI: 477798400, IMO: 9918690, SHIPNAME: "SITC RENDE" },
  { MMSI: 477798900, IMO: 9923968, SHIPNAME: "KANWAY FORTUNE" },
  { MMSI: 477800200, IMO: 9113161, SHIPNAME: "JJ NAGOYA" },
  { MMSI: 477806000, IMO: 9199268, SHIPNAME: "OOCL SAN FRANCISCO" },
  { MMSI: 477809100, IMO: 9941104, SHIPNAME: "XIN AN" },
  { MMSI: 477809500, IMO: 9937347, SHIPNAME: "ONE MAGDALENA" },
  { MMSI: 477809800, IMO: 9935947, SHIPNAME: "CUL NANSHA" },
  { MMSI: 477810500, IMO: 9741982, SHIPNAME: "GLORY GUANGZHOU" },
  { MMSI: 477811100, IMO: 9367205, SHIPNAME: "OOCL ITALY" },
  { MMSI: 477811500, IMO: 9931501, SHIPNAME: "JOSCO ALMA" },
  { MMSI: 477811700, IMO: 9918705, SHIPNAME: "SITC LIDE" },
  { MMSI: 477813700, IMO: 9639593, SHIPNAME: "SITC MOJI" },
  { MMSI: 477815200, IMO: 9351608, SHIPNAME: "SEASPAN FRASER" },
  { MMSI: 477817400, IMO: 9198111, SHIPNAME: "OOCL SHANGHAI" },
  { MMSI: 477825000, IMO: 9199270, SHIPNAME: "OOCL CHICAGO" },
  { MMSI: 477829700, IMO: 9310240, SHIPNAME: "OOCL SOUTHAMPTON" },
  { MMSI: 477831200, IMO: 9448786, SHIPNAME: "COSCO INDONESIA" },
  { MMSI: 477832200, IMO: 9484388, SHIPNAME: "COSCO SAO PAULO" },
  { MMSI: 477832300, IMO: 9484390, SHIPNAME: "COSCO VALENCIA" },
  { MMSI: 477832400, IMO: 9622629, SHIPNAME: "OOCL CHONGQING" },
  { MMSI: 477832500, IMO: 9622631, SHIPNAME: "OOCL EGYPT" },
  { MMSI: 477833500, IMO: 9931513, SHIPNAME: "JOSCO JANE" },
  { MMSI: 477833700, IMO: 9935959, SHIPNAME: "CUL YANGPU" },
  { MMSI: 477833800, IMO: 9918717, SHIPNAME: "SITC SHANGDE" },
  { MMSI: 477833900, IMO: 9918729, SHIPNAME: "SITC RUNDE" },
  { MMSI: 477836400, IMO: 9941116, SHIPNAME: "HONG AN" },
  { MMSI: 477836500, IMO: 9927108, SHIPNAME: "SITC MINGDE" },
  { MMSI: 477836900, IMO: 9937311, SHIPNAME: "ONE ORINOCO" },
  { MMSI: 477842200, IMO: 9937323, SHIPNAME: "ONE AMAZON" },
  { MMSI: 477842400, IMO: 9945473, SHIPNAME: "ZIM USA" },
  { MMSI: 477842500, IMO: 9945485, SHIPNAME: "ZIM CANADA" },
  { MMSI: 477842600, IMO: 9931525, SHIPNAME: "JOSCO HELEN" },
  { MMSI: 477842700, IMO: 9932828, SHIPNAME: "SITC CHUNMING" },
  { MMSI: 477842900, IMO: 9927110, SHIPNAME: "SITC XINGDE" },
  { MMSI: 477845600, IMO: 9516454, SHIPNAME: "COSCO ITALY" },
  { MMSI: 477846200, IMO: 9918731, SHIPNAME: "SITC WENDE" },
  { MMSI: 477846300, IMO: 9941805, SHIPNAME: "CUL BANGKOK" },
  { MMSI: 477846400, IMO: 9941817, SHIPNAME: "CUL LAEMCHABANG" },
  { MMSI: 477846600, IMO: 9934357, SHIPNAME: "ONE FREEDOM" },
  { MMSI: 477846900, IMO: 9932830, SHIPNAME: "SITC QIUMING" },
  { MMSI: 477848700, IMO: 9645918, SHIPNAME: "CSCL EAST CHINA SEA" },
  { MMSI: 477849400, IMO: 9318319, SHIPNAME: "MAERSK MAKUTU" },
  { MMSI: 477849500, IMO: 9712785, SHIPNAME: "SITC MACAO" },
  { MMSI: 477850500, IMO: 9780653, SHIPNAME: "DELPHIS GDANSK" },
  { MMSI: 477855200, IMO: 9245043, SHIPNAME: "SITC PYEONGTAEK" },
  { MMSI: 477855900, IMO: 9700952, SHIPNAME: "SITC GUANGDONG" },
  { MMSI: 477856100, IMO: 9958860, SHIPNAME: "ASL HONG KONG" },
  { MMSI: 477856400, IMO: 9932842, SHIPNAME: "SITC ZHAOMING" },
  { MMSI: 477856600, IMO: 9940215, SHIPNAME: "SITC SHENGDE" },
  { MMSI: 477856700, IMO: 9935868, SHIPNAME: "ASL BAUHINIA" },
  { MMSI: 477856800, IMO: 9934369, SHIPNAME: "ONE FRIENDSHIP" },
  { MMSI: 477858700, IMO: 9763722, SHIPNAME: "DELPHIS FINLAND" },
  { MMSI: 477858800, IMO: 9731913, SHIPNAME: "COSCOSHIPPING DANUBE" },
  { MMSI: 477859400, IMO: 9932854, SHIPNAME: "SITC CHENMING" },
  { MMSI: 477859600, IMO: 9940203, SHIPNAME: "SITC XIANDE" },
  { MMSI: 477859800, IMO: 9947380, SHIPNAME: "SHENG AN" },
  { MMSI: 477866200, IMO: 9492696, SHIPNAME: "SEASPAN KYOTO" },
  { MMSI: 477866300, IMO: 9492701, SHIPNAME: "SEASPAN KOBE" },
  { MMSI: 477867100, IMO: 9943592, SHIPNAME: "SITC PINGHE" },
  { MMSI: 477867200, IMO: 9932866, SHIPNAME: "SITC YUANMING" },
  { MMSI: 477867600, IMO: 9936408, SHIPNAME: "MAERSK CLEVELAND" },
  { MMSI: 477867800, IMO: 9934371, SHIPNAME: "ONE FRUITION" },
  { MMSI: 477867900, IMO: 9934383, SHIPNAME: "ONE FRONTIER" },
  { MMSI: 477871200, IMO: 9936381, SHIPNAME: "ONE FANTASTIC" },
  { MMSI: 477871300, IMO: 9936379, SHIPNAME: "MAERSK CHARLESTON" },
  { MMSI: 477871400, IMO: 9931109, SHIPNAME: "ZIM MOUNT EVEREST" },
  { MMSI: 477871500, IMO: 9931094, SHIPNAME: "ZIM SAMMY OFER" },
  { MMSI: 477871600, IMO: 9908126, SHIPNAME: "OOCL SPAIN" },
  { MMSI: 477871700, IMO: 9943607, SHIPNAME: "SITC ANHE" },
  { MMSI: 477871800, IMO: 9932878, SHIPNAME: "SITC QIMING" },
  { MMSI: 477871900, IMO: 9935870, SHIPNAME: "ASL PEONY" },
  { MMSI: 477883300, IMO: 9329526, SHIPNAME: "OOCL KOBE" },
  { MMSI: 477883500, IMO: 9412828, SHIPNAME: "BELAWAN" },
  { MMSI: 477883900, IMO: 9314258, SHIPNAME: "CSCL LONG BEACH" },
  { MMSI: 477884200, IMO: 9796509, SHIPNAME: "KUO LONG" },
  { MMSI: 477884600, IMO: 9742027, SHIPNAME: "GLORY SHENGDONG" },
  { MMSI: 477885100, IMO: 9940239, SHIPNAME: "SITC SHUNDE" },
  { MMSI: 477885900, IMO: 9941051, SHIPNAME: "SITC CHANGMING" },
  { MMSI: 477886300, IMO: 9908097, SHIPNAME: "OOCL PIRAEUS" },
  { MMSI: 477887200, IMO: 9941063, SHIPNAME: "SITC SHENGMING" },
  { MMSI: 477887600, IMO: 9943619, SHIPNAME: "SITC FENGHE" },
  { MMSI: 477887700, IMO: 9353228, SHIPNAME: "NEWNEW STAR" },
  { MMSI: 477887800, IMO: 9908138, SHIPNAME: "OOCL TURKIYE" },
  { MMSI: 477887900, IMO: 9940227, SHIPNAME: "SITC HENGDE" },
  { MMSI: 477890100, IMO: 0, SHIPNAME: "ZIM MOUNT BLANC" },
  { MMSI: 477890300, IMO: 9958872, SHIPNAME: "ASL TAIPEI" },
  { MMSI: 477890500, IMO: 9352016, SHIPNAME: "MAERSK SALALAH" },
  { MMSI: 477890700, IMO: 9922512, SHIPNAME: "OOCL FELIXSTOWE" },
  { MMSI: 477890900, IMO: 9941075, SHIPNAME: "SITC HUIMING" },
  { MMSI: 477891200, IMO: 9359002, SHIPNAME: "GERNER MAERSK" },
  { MMSI: 477891500, IMO: 9931123, SHIPNAME: "ZIM MOUNT DENALI" },
  { MMSI: 477891600, IMO: 9943621, SHIPNAME: "SITC YUHE" },
  { MMSI: 477891700, IMO: 9352004, SHIPNAME: "MAERSK STEPNICA" },
  { MMSI: 477891800, IMO: 9941087, SHIPNAME: "SITC RUIMING" },
  { MMSI: 477891900, IMO: 9957074, SHIPNAME: "SHENG LI JI" },
  { MMSI: 477893100, IMO: 9434931, SHIPNAME: "CAUCEDO EXPRESS" },
  { MMSI: 477893200, IMO: 9352028, SHIPNAME: "MAERSK SAVANNAH" },
  { MMSI: 477893300, IMO: 9936886, SHIPNAME: "SNL NANJING" },
  { MMSI: 477893400, IMO: 9388340, SHIPNAME: "ONE COSMOS" },
  { MMSI: 477893700, IMO: 9359014, SHIPNAME: "GUNDE MAERSK" },
  { MMSI: 477893800, IMO: 9313204, SHIPNAME: "NEWNEW POLAR BEAR" },
  { MMSI: 477893900, IMO: 9936898, SHIPNAME: "SNL NANTONG" },
  { MMSI: 477895200, IMO: 9388352, SHIPNAME: "ONE CONTINUITY" },
  { MMSI: 477895400, IMO: 9908102, SHIPNAME: "OOCL GDYNIA" },
  { MMSI: 477895500, IMO: 9908140, SHIPNAME: "OOCL ZEEBRUGGE" },
  { MMSI: 477895900, IMO: 9936862, SHIPNAME: "SNL HAIKOU" },
  { MMSI: 477898700, IMO: 9329538, SHIPNAME: "OOCL YOKOHAMA" },
  { MMSI: 477906100, IMO: 9943633, SHIPNAME: "SITC RENHE" },
  { MMSI: 477906300, IMO: 9936874, SHIPNAME: "SNL ZHANGJIAGANG" },
  { MMSI: 477907100, IMO: 9931135, SHIPNAME: "ZIM MOUNT RAINIER" },
  { MMSI: 477907200, IMO: 9952696, SHIPNAME: "ONE REASSURANCE" },
  { MMSI: 477907300, IMO: 9952684, SHIPNAME: "ONE READINESS" },
  { MMSI: 477907600, IMO: 9967952, SHIPNAME: "ZIM AMBER" },
  { MMSI: 477909200, IMO: 9967964, SHIPNAME: "ZIM CORAL" },
  { MMSI: 477909300, IMO: 9208150, SHIPNAME: "JY BONITO" },
  { MMSI: 477909400, IMO: 9329643, SHIPNAME: "NEWNEW STAR 2" },
  { MMSI: 477909500, IMO: 9945411, SHIPNAME: "MILD ORCHID" },
  { MMSI: 477909700, IMO: 9359026, SHIPNAME: "GUNHILDE MAERSK" },
  { MMSI: 477909800, IMO: 9315965, SHIPNAME: "NEWNEW PANDA 1" },
  { MMSI: 477915200, IMO: 9757319, SHIPNAME: "NEW MINGZHOU 28" },
  { MMSI: 477920300, IMO: 9285005, SHIPNAME: "OOCL ATLANTA" },
  { MMSI: 477921600, IMO: 9932490, SHIPNAME: "ZIMMOUNT KILIMANJARO" },
  { MMSI: 477921700, IMO: 9945837, SHIPNAME: "COSCOSHIPPING BRAZIL" },
  { MMSI: 477921800, IMO: 9967976, SHIPNAME: "ZIM EMERALD" },
  { MMSI: 477922100, IMO: 9359040, SHIPNAME: "GUTHORM MAERSK" },
  { MMSI: 477922300, IMO: 9952701, SHIPNAME: "ONE RECOGNITION" },
  { MMSI: 477922400, IMO: 9943645, SHIPNAME: "SITC YIHE" },
  { MMSI: 477926700, IMO: 9122306, SHIPNAME: "NAGALEADER" },
  { MMSI: 477928900, IMO: 9224362, SHIPNAME: "BOHAI STAR" },
  { MMSI: 477929100, IMO: 9932517, SHIPNAME: "ZIM MOUNT FUJI" },
  { MMSI: 477931900, IMO: 9945423, SHIPNAME: "MILD PEONY" },
  { MMSI: 477942700, IMO: 9207572, SHIPNAME: "SITC MANILA" },
  { MMSI: 477943000, IMO: 9131022, SHIPNAME: "OSG ADMIRAL" },
  { MMSI: 477946000, IMO: 9251999, SHIPNAME: "OOCL ROTTERDAM" },
  { MMSI: 477947100, IMO: 9535204, SHIPNAME: "HUBERT SCHULTE" },
  { MMSI: 477947600, IMO: 9484297, SHIPNAME: "COSCO DURBAN" },
  { MMSI: 477947700, IMO: 9484302, SHIPNAME: "COSCO FOS" },
  { MMSI: 477948200, IMO: 9712383, SHIPNAME: "SITC JIANGSU" },
  { MMSI: 477948800, IMO: 9704647, SHIPNAME: "YM WARMTH" },
  { MMSI: 477950300, IMO: 9472139, SHIPNAME: "COSCO DEVELOPMENT" },
  { MMSI: 477958300, IMO: 9355757, SHIPNAME: "OOCL HOUSTON" },
  { MMSI: 477958500, IMO: 9175781, SHIPNAME: "MAERSK ANTWERP" },
  { MMSI: 477958900, IMO: 9164275, SHIPNAME: "MAERSK AVON" },
  { MMSI: 477959200, IMO: 9448798, SHIPNAME: "COSCO THAILAND" },
  { MMSI: 477961600, IMO: 9486116, SHIPNAME: "SEASPAN OSAKA" },
  { MMSI: 477963800, IMO: 9466867, SHIPNAME: "CSCL STAR" },
  { MMSI: 477967300, IMO: 9700938, SHIPNAME: "SITC FUJIAN" },
  { MMSI: 477967600, IMO: 9248679, SHIPNAME: "LAKONIA" },
  { MMSI: 477967700, IMO: 9695169, SHIPNAME: "CSCL ARCTIC OCEAN" },
  { MMSI: 477967800, IMO: 9695145, SHIPNAME: "CSCL ATLANTIC OCEAN" },
  { MMSI: 477995162, IMO: 9037496, SHIPNAME: "CHAI WAN" },
  { MMSI: 477995437, IMO: 9708277, SHIPNAME: "CLEAN HARBOUR 1" },
  { MMSI: 477995441, IMO: 9708289, SHIPNAME: "CLEAN HARBOUR 2" },
  { MMSI: 477997028, IMO: 9148867, SHIPNAME: "NGON SHUEN" },
  { MMSI: 477997030, IMO: 9037501, SHIPNAME: "NIM WAN" },
  { MMSI: 477997036, IMO: 9148879, SHIPNAME: "LAI WAN" },
  { MMSI: 477997039, IMO: 9127112, SHIPNAME: "MO SING LENG" },
  { MMSI: 477997040, IMO: 9127100, SHIPNAME: "TSING CHAU" },
  { MMSI: 511100005, IMO: 8907931, SHIPNAME: "S PYLOS" },
  { MMSI: 511100373, IMO: 9128544, SHIPNAME: "HACI MUSA UCAK" },
  { MMSI: 511100380, IMO: 9122227, SHIPNAME: "ADMIRAL DANIAL" },
  { MMSI: 511100739, IMO: 9180750, SHIPNAME: "BAWEAN" },
  { MMSI: 511100746, IMO: 9237931, SHIPNAME: "HAJ YEHIA" },
  { MMSI: 511100833, IMO: 9186388, SHIPNAME: "CETUS" },
  { MMSI: 511100889, IMO: 9141340, SHIPNAME: "NK PEARL" },
  { MMSI: 511101140, IMO: 9377561, SHIPNAME: "INTERSEA TRAVELER" },
  { MMSI: 511101214, IMO: 8913021, SHIPNAME: "MANASSA M" },
  { MMSI: 511101259, IMO: 9070163, SHIPNAME: "XIN FENG" },
  { MMSI: 511101278, IMO: 9077288, SHIPNAME: "GUANGZHOU" },
  { MMSI: 511873000, IMO: 9509097, SHIPNAME: "GEMMA" },
  { MMSI: 512011151, IMO: 9519327, SHIPNAME: "TAKUTAI CHIEF" },
  { MMSI: 512143000, IMO: 9516741, SHIPNAME: "MOANA CHIEF" },
  { MMSI: 525003263, IMO: 976332000, SHIPNAME: "ICON CORINTUS" },
  { MMSI: 525003620, IMO: 9204984, SHIPNAME: "MV.ORIENTAL DIAMOND" },
  { MMSI: 525003624, IMO: 9120920, SHIPNAME: "ORIENTAL GALAXY" },
  { MMSI: 525005174, IMO: 9190810, SHIPNAME: "HIJAU SEGAR" },
  { MMSI: 525005318, IMO: 9167382, SHIPNAME: "HIJAU JELITA" },
  { MMSI: 525005319, IMO: 9155523, SHIPNAME: "MV.HIJAU SEJUK" },
  { MMSI: 525005339, IMO: 9109988, SHIPNAME: "VERIZON" },
  { MMSI: 525005397, IMO: 9136591, SHIPNAME: "MV. ORIENTAL GOLD" },
  { MMSI: 525007115, IMO: 9674579, SHIPNAME: "MV.LINTAS BENGKULU" },
  { MMSI: 525007163, IMO: 9671890, SHIPNAME: "KM. KANAL MAS" },
  { MMSI: 525007400, IMO: 9783875, SHIPNAME: "MV TELUK MAS" },
  { MMSI: 525007401, IMO: 9783887, SHIPNAME: "MV KISIK MAS" },
  { MMSI: 525009349, IMO: 9371098, SHIPNAME: "MV SINAR BANDA" },
  { MMSI: 525013013, IMO: 9056519, SHIPNAME: "TANTO SETIA" },
  { MMSI: 525013014, IMO: 9003196, SHIPNAME: "TANTO BERSAMA" },
  { MMSI: 525013016, IMO: 9035515, SHIPNAME: "TANTO BERSATU" },
  { MMSI: 525013021, IMO: 9169653, SHIPNAME: "TANTO TERANG" },
  { MMSI: 525013022, IMO: 9169665, SHIPNAME: "TANTO TANGGUH" },
  { MMSI: 525013024, IMO: 9168570, SHIPNAME: "MV TANTO SEMANGAT" },
  { MMSI: 525013026, IMO: 9118408, SHIPNAME: "TANTO PRATAMA" },
  { MMSI: 525013027, IMO: 9085699, SHIPNAME: "TANTO ALAM" },
  { MMSI: 525013028, IMO: 9085704, SHIPNAME: "MV.TANTO AMAN" },
  { MMSI: 525013033, IMO: 9055503, SHIPNAME: "MV TANTO SUBUR II" },
  { MMSI: 525013036, IMO: 9192040, SHIPNAME: "MV.TANTO TENANG" },
  { MMSI: 525013040, IMO: 9786592, SHIPNAME: "TANTO LANCAR" },
  { MMSI: 525013042, IMO: 9787522, SHIPNAME: "TANTO LUAS" },
  { MMSI: 525013043, IMO: 9796353, SHIPNAME: "TANTO LANGGENG" },
  { MMSI: 525015248, IMO: 9031727, SHIPNAME: "MV ARMADA SEJATI" },
  { MMSI: 525015323, IMO: 8912730, SHIPNAME: "CTP DELTA" },
  { MMSI: 525015399, IMO: 9551076, SHIPNAME: "KM.TELUK BINTUNI" },
  { MMSI: 525015479, IMO: 9077202, SHIPNAME: "MV SELAT MAS" },
  { MMSI: 525015536, IMO: 9084786, SHIPNAME: "MV.ARMADA PERMATA" },
  { MMSI: 525015658, IMO: 9181730, SHIPNAME: "MV.CTP FORTUNE" },
  { MMSI: 525015706, IMO: 9159098, SHIPNAME: "CTP GOLDEN" },
  { MMSI: 525015924, IMO: 8902125, SHIPNAME: "MV.ORIENTAL RUBY" },
  { MMSI: 525015943, IMO: 9126962, SHIPNAME: "MV.LUZON" },
  { MMSI: 525015962, IMO: 9146077, SHIPNAME: "MV.ORIENTAL EMERALD" },
  { MMSI: 525015965, IMO: 9000663, SHIPNAME: "MV.ARMADA SEGARA" },
  { MMSI: 525015973, IMO: 9146089, SHIPNAME: "ORIENTAL SILVER" },
  { MMSI: 525016130, IMO: 8504674, SHIPNAME: "KM TANTO SURYA" },
  { MMSI: 525016466, IMO: 9491630, SHIPNAME: "LUMOSO SELAMAT" },
  { MMSI: 525016539, IMO: 9491642, SHIPNAME: "LUMOSO BAHAGIA" },
  { MMSI: 525016549, IMO: 9103154, SHIPNAME: "TANTO EXPRESS" },
  { MMSI: 525016550, IMO: 9088641, SHIPNAME: "KM TANTO PERMAI" },
  { MMSI: 525016584, IMO: 9179505, SHIPNAME: "MV. TANTO JAYA" },
  { MMSI: 525016609, IMO: 9491654, SHIPNAME: "LUMOSO GEMBIRA" },
  { MMSI: 525018171, IMO: 9065431, SHIPNAME: "TMS GLORY" },
  { MMSI: 525019019, IMO: 9672399, SHIPNAME: "MV HILIR MAS" },
  { MMSI: 525019050, IMO: 9672375, SHIPNAME: "MV TELAGA MAS" },
  { MMSI: 525019100, IMO: 9672351, SHIPNAME: "MV WARIH MAS" },
  { MMSI: 525019274, IMO: 9672363, SHIPNAME: "MV BELIK MAS" },
  { MMSI: 525019398, IMO: 8705424, SHIPNAME: "MV.STRAIT MAS" },
  { MMSI: 525019527, IMO: 9562051, SHIPNAME: "MV.KALI MAS" },
  { MMSI: 525022408, IMO: 9791755, SHIPNAME: "MV.PALUNG MAS" },
  { MMSI: 525023419, IMO: 9791779, SHIPNAME: "CURUG MAS" },
  { MMSI: 525023420, IMO: 9791781, SHIPNAME: "MV BAHAR MAS" },
  { MMSI: 525024397, IMO: 9178549, SHIPNAME: "MV SUNGAI MAS" },
  { MMSI: 525025049, IMO: 9154830, SHIPNAME: "MERATUS MANADO" },
  { MMSI: 525025054, IMO: 9146651, SHIPNAME: "MERATUS MEDAN 1" },
  { MMSI: 525025067, IMO: 9014092, SHIPNAME: "MERATUS MEDAN 2" },
  { MMSI: 525025076, IMO: 9106649, SHIPNAME: "MERATUS MAMIRI" },
  { MMSI: 525025077, IMO: 0, SHIPNAME: "MERATUS DILI" },
  { MMSI: 525025082, IMO: 9202895, SHIPNAME: "MERATUS GORONTALO" },
  { MMSI: 525025085, IMO: 9155511, SHIPNAME: "MERATUS KUPANG" },
  { MMSI: 525025088, IMO: 9157973, SHIPNAME: "MERATUS KELIMUTU" },
  { MMSI: 525025089, IMO: 9106625, SHIPNAME: "MERATUS MALINO" },
  { MMSI: 525025090, IMO: 9157961, SHIPNAME: "MERATUS KALABAHI" },
  { MMSI: 525025101, IMO: 9760330, SHIPNAME: "MERATUS KAHAYAN" },
  { MMSI: 525025102, IMO: 9760342, SHIPNAME: "MERATUS KATINGAN" },
  { MMSI: 525100294, IMO: 9816440, SHIPNAME: "SPIL HANA" },
  { MMSI: 525100392, IMO: 9821665, SHIPNAME: "SPIL HAPSRI" },
  { MMSI: 525100468, IMO: 9499022, SHIPNAME: "MCC ANDALAS" },
  { MMSI: 525100478, IMO: 9823780, SHIPNAME: "SPIL HASYA" },
  { MMSI: 525100552, IMO: 9353931, SHIPNAME: "SELATAN DAMAI" },
  { MMSI: 525100704, IMO: 9829253, SHIPNAME: "SPIL HAYU" },
  { MMSI: 525101050, IMO: 0, SHIPNAME: "KENDHAGA NUSANTARA 1" },
  { MMSI: 525101066, IMO: 9828285, SHIPNAME: "KENDHAGA NUSANTARA 9" },
  { MMSI: 525101067, IMO: 9828273, SHIPNAME: "KENDHAGA NUSANTARA 7" },
  { MMSI: 525101097, IMO: 9816969, SHIPNAME: "KENDAGA NUSANTARA15" },
  { MMSI: 525101118, IMO: 9838711, SHIPNAME: "KENDHAGA NUSANTARA 3" },
  { MMSI: 525101128, IMO: 9838747, SHIPNAME: "KENDHAGA NUSANTARA10" },
  { MMSI: 525101129, IMO: 9838735, SHIPNAME: "KENDHAGA NUSANTARA 8" },
  { MMSI: 525101130, IMO: 0, SHIPNAME: "KENDHAGA NUSANTARA 5" },
  { MMSI: 525101131, IMO: 9838759, SHIPNAME: "KENDHAGA NUSANTARA12" },
  { MMSI: 525113002, IMO: 0, SHIPNAME: "TANTO MITRA" },
  { MMSI: 525113004, IMO: 9810109, SHIPNAME: "MV TANTO MANDIRI" },
  { MMSI: 525113008, IMO: 9816464, SHIPNAME: "TANTO SIAP" },
  { MMSI: 525113009, IMO: 9816476, SHIPNAME: "MV.TANTO SUKSES" },
  { MMSI: 525113012, IMO: 9852389, SHIPNAME: "MV TANTO TERIMA" },
  { MMSI: 525113013, IMO: 9852391, SHIPNAME: "TANTO KASIH" },
  { MMSI: 525113016, IMO: 9863883, SHIPNAME: "TANTO SALAM" },
  { MMSI: 525113019, IMO: 9888390, SHIPNAME: "TANTO KAWAN" },
  { MMSI: 525113026, IMO: 9915375, SHIPNAME: "TANTO KARYA" },
  { MMSI: 525113028, IMO: 9915387, SHIPNAME: "TANTO KHARISMA" },
  { MMSI: 525119004, IMO: 9813175, SHIPNAME: "MUARA MAS" },
  { MMSI: 525119005, IMO: 9813151, SHIPNAME: "AYER MAS" },
  { MMSI: 525119011, IMO: 9813199, SHIPNAME: "KEDUNG MAS" },
  { MMSI: 525119012, IMO: 9813187, SHIPNAME: "KAWA MAS" },
  { MMSI: 525119161, IMO: 9252369, SHIPNAME: "STRAIT MAS" },
  { MMSI: 525119170, IMO: 9362322, SHIPNAME: "CMA CGM HOMERE" },
  { MMSI: 525119189, IMO: 9164249, SHIPNAME: "IZUMI MAS" },
  { MMSI: 525119190, IMO: 9164251, SHIPNAME: "IFAMA MAS" },
  { MMSI: 525120018, IMO: 9181742, SHIPNAME: "MV.CTP MAKASSAR" },
  { MMSI: 525125001, IMO: 9810070, SHIPNAME: "MERATUS KARIANGAU" },
  { MMSI: 525125002, IMO: 0, SHIPNAME: "MERATUS KARIMATA" },
  { MMSI: 525125008, IMO: 9821500, SHIPNAME: "MERATUS LABUAN BAJO" },
  { MMSI: 525125010, IMO: 9821512, SHIPNAME: "MERATUS LARANTUKA" },
  { MMSI: 525125011, IMO: 9821524, SHIPNAME: "MERATUS LEMBAR" },
  { MMSI: 525125012, IMO: 9821536, SHIPNAME: "MERATUS LEMBATA" },
  { MMSI: 525125014, IMO: 9838888, SHIPNAME: "MERATUS WAINGAPU" },
  { MMSI: 525125018, IMO: 9849227, SHIPNAME: "MV MERATUS WAKATOBI" },
  { MMSI: 525125029, IMO: 9808481, SHIPNAME: "MERATUS SAMARINDA" },
  { MMSI: 525125030, IMO: 9808493, SHIPNAME: "MERATUS SAMPIT" },
  { MMSI: 525200098, IMO: 9385386, SHIPNAME: "KM. HASIL BAHARI 8" },
  { MMSI: 525290672, IMO: 8107610, SHIPNAME: "SURYA SAMUDRA V" },
  { MMSI: 525300044, IMO: 9291054, SHIPNAME: "MULYA SENTOSA II" },
  { MMSI: 525301388, IMO: 9925540, SHIPNAME: "SPIL RETNO" },
  { MMSI: 525400142, IMO: 9886811, SHIPNAME: "SPIL RATNA" },
  { MMSI: 525400885, IMO: 9044607, SHIPNAME: "MV.MITRA KENDARI" },
  { MMSI: 525600354, IMO: 9127875, SHIPNAME: "FATIMA II" },
  { MMSI: 533000522, IMO: 9438080, SHIPNAME: "DANUM 159" },
  { MMSI: 533052300, IMO: 9176503, SHIPNAME: "HARBOUR EXPRESS" },
  { MMSI: 533130813, IMO: 9101106, SHIPNAME: "MTT KUCHING DUA" },
  { MMSI: 533131021, IMO: 9813852, SHIPNAME: "MTT SAISUNEE" },
  { MMSI: 533131052, IMO: 9322889, SHIPNAME: "MTT PENGERANG" },
  { MMSI: 533131059, IMO: 9813876, SHIPNAME: "MTT SENARI" },
  { MMSI: 533132056, IMO: 9912191, SHIPNAME: "MTT SIBU" },
  { MMSI: 533132065, IMO: 9813864, SHIPNAME: "MTT SAMALAJU" },
  { MMSI: 533132066, IMO: 9912206, SHIPNAME: "MTT SARIKEI" },
  { MMSI: 533132075, IMO: 9813888, SHIPNAME: "MTT SEMPORNA" },
  { MMSI: 533132085, IMO: 9245225, SHIPNAME: "SALAM MAJU" },
  { MMSI: 533132087, IMO: 9872236, SHIPNAME: "MTT SAPANGAR" },
  { MMSI: 533132142, IMO: 9131060, SHIPNAME: "MTT SINGAPORE" },
  { MMSI: 533132161, IMO: 9872248, SHIPNAME: "MTT SANDAKAN" },
  { MMSI: 533132436, IMO: 9945265, SHIPNAME: "MTT RAJANG" },
  { MMSI: 533132646, IMO: 9255529, SHIPNAME: "MTT HAIPHONG" },
  { MMSI: 533167000, IMO: 9121675, SHIPNAME: "RMN AUX 5" },
  { MMSI: 533170079, IMO: 9477268, SHIPNAME: "DANUM 160" },
  { MMSI: 533170319, IMO: 9101651, SHIPNAME: "HARBOUR ZENITH" },
  { MMSI: 533170482, IMO: 9238870, SHIPNAME: "DANUM 168" },
  { MMSI: 533170531, IMO: 9159086, SHIPNAME: "DANUM 171" },
  { MMSI: 533170536, IMO: 9151424, SHIPNAME: "DANUM 172" },
  { MMSI: 533170554, IMO: 9242584, SHIPNAME: "HARBOUR STAR" },
  { MMSI: 533170578, IMO: 9236341, SHIPNAME: "HARBOUR NEPTUNE" },
  { MMSI: 533170614, IMO: 9242601, SHIPNAME: "HARBOUR GALAXY" },
  { MMSI: 533170635, IMO: 9406271, SHIPNAME: "DANUM 173" },
  { MMSI: 533170679, IMO: 9248930, SHIPNAME: "DANUM 175" },
  { MMSI: 533170729, IMO: 9242613, SHIPNAME: "HARBOUR HORIZON" },
  { MMSI: 533180127, IMO: 9146065, SHIPNAME: "MTT PULAU PINANG" },
  { MMSI: 533180146, IMO: 9216731, SHIPNAME: "MTT MUARA" },
  { MMSI: 533180218, IMO: 9148532, SHIPNAME: "MTT BINTULU" },
  { MMSI: 538001652, IMO: 9220079, SHIPNAME: "HARRISON" },
  { MMSI: 538001941, IMO: 9248954, SHIPNAME: "PRESIDIO" },
  { MMSI: 538002485, IMO: 9324162, SHIPNAME: "CAPE FLORES" },
  { MMSI: 538002591, IMO: 9347712, SHIPNAME: "CAPE FARO" },
  { MMSI: 538002624, IMO: 9347724, SHIPNAME: "CAPE FLINT" },
  { MMSI: 538002666, IMO: 9356842, SHIPNAME: "CAPE FORBY" },
  { MMSI: 538002734, IMO: 9324837, SHIPNAME: "NAVIOS VERMILION" },
  { MMSI: 538002747, IMO: 9359301, SHIPNAME: "CAPE FRANKLIN" },
  { MMSI: 538002823, IMO: 9359313, SHIPNAME: "CAPE FULMAR" },
  { MMSI: 538002955, IMO: 9302578, SHIPNAME: "SC MARA" },
  { MMSI: 538002961, IMO: 9403619, SHIPNAME: "MOL PROFICIENCY" },
  { MMSI: 538003035, IMO: 9324875, SHIPNAME: "NAVIOS INDIGO" },
  { MMSI: 538003104, IMO: 9308027, SHIPNAME: "NAVIOS SPRING" },
  { MMSI: 538003120, IMO: 9379363, SHIPNAME: "CAPE FAWLEY" },
  { MMSI: 538003181, IMO: 9379375, SHIPNAME: "CAPE FELTON" },
  { MMSI: 538003221, IMO: 9359325, SHIPNAME: "CAPE FERROL" },
  { MMSI: 538003252, IMO: 9352391, SHIPNAME: "WADI BANI KHALID" },
  { MMSI: 538003274, IMO: 9352406, SHIPNAME: "MATSON OAHU" },
  { MMSI: 538003278, IMO: 9352418, SHIPNAME: "NAVIOS DESTINY" },
  { MMSI: 538003407, IMO: 9352420, SHIPNAME: "NAVIOS DEVOTION" },
  { MMSI: 538003580, IMO: 9440150, SHIPNAME: "CAPE MALE" },
  { MMSI: 538003648, IMO: 9424900, SHIPNAME: "ONE MAGNIFICENCE" },
  { MMSI: 538003651, IMO: 9431680, SHIPNAME: "NAVIOS LAPIS" },
  { MMSI: 538003717, IMO: 9431692, SHIPNAME: "TANJA" },
  { MMSI: 538003729, IMO: 9424912, SHIPNAME: "ONE MAJESTY" },
  { MMSI: 538003810, IMO: 9424924, SHIPNAME: "ONE MATRIX" },
  { MMSI: 538003886, IMO: 9424936, SHIPNAME: "ONE MAXIM" },
  { MMSI: 538003993, IMO: 9475612, SHIPNAME: "ONE MARVEL" },
  { MMSI: 538004117, IMO: 9475624, SHIPNAME: "ONE MOTIVATOR" },
  { MMSI: 538004165, IMO: 9445916, SHIPNAME: "CAPE MOSS" },
  { MMSI: 538004202, IMO: 9475650, SHIPNAME: "ONE MISSION" },
  { MMSI: 538004203, IMO: 9475636, SHIPNAME: "ONE MODERN" },
  { MMSI: 538004612, IMO: 9314997, SHIPNAME: "CUSSLER" },
  { MMSI: 538004894, IMO: 9650078, SHIPNAME: "STARSHIP URSA" },
  { MMSI: 538004895, IMO: 9656759, SHIPNAME: "STARSHIP PEGASUS" },
  { MMSI: 538005024, IMO: 9212034, SHIPNAME: "RUTH BORCHARD" },
  { MMSI: 538005042, IMO: 9365829, SHIPNAME: "MUKADDES KALKAVAN" },
  { MMSI: 538005057, IMO: 9603594, SHIPNAME: "SAFEEN PRIZE" },
  { MMSI: 538005064, IMO: 9212010, SHIPNAME: "RACHEL BORCHARD" },
  { MMSI: 538005112, IMO: 9603609, SHIPNAME: "SAFEEN PROSPER" },
  { MMSI: 538005224, IMO: 9603611, SHIPNAME: "SAFEEN PRISM" },
  { MMSI: 538005242, IMO: 9593517, SHIPNAME: "SAFEEN PRESTIGE" },
  { MMSI: 538005313, IMO: 9685061, SHIPNAME: "PEGASUS TERA" },
  { MMSI: 538005592, IMO: 9308417, SHIPNAME: "JEJU ISLAND" },
  { MMSI: 538005624, IMO: 9694529, SHIPNAME: "WIDE ALPHA" },
  { MMSI: 538005625, IMO: 9694531, SHIPNAME: "STEPHANIE C" },
  { MMSI: 538005686, IMO: 9224336, SHIPNAME: "HONGKONG BRIDGE" },
  { MMSI: 538005732, IMO: 9698628, SHIPNAME: "SAN FELIPE" },
  { MMSI: 538005733, IMO: 9698630, SHIPNAME: "SAN FELIX" },
  { MMSI: 538005734, IMO: 9698642, SHIPNAME: "SAN FERNANDO" },
  { MMSI: 538005735, IMO: 9698654, SHIPNAME: "SAN FRANCISCA" },
  { MMSI: 538005750, IMO: 9694593, SHIPNAME: "WIDE HOTEL" },
  { MMSI: 538005751, IMO: 9698252, SHIPNAME: "WIDE INDIA" },
  { MMSI: 538005752, IMO: 9698264, SHIPNAME: "WIDE JULIET" },
  { MMSI: 538005877, IMO: 9110822, SHIPNAME: "ARAFURA LILY" },
  { MMSI: 538005895, IMO: 9693927, SHIPNAME: "HEUNG-A JANICE" },
  { MMSI: 538005896, IMO: 9693939, SHIPNAME: "HEUNG-A HAIPHONG" },
  { MMSI: 538005897, IMO: 9693941, SHIPNAME: "HEUNG-A XIAMEN" },
  { MMSI: 538005898, IMO: 9693953, SHIPNAME: "HEUNG-A AKITA" },
  { MMSI: 538005929, IMO: 9565340, SHIPNAME: "AS NORA" },
  { MMSI: 538005938, IMO: 9282962, SHIPNAME: "TIANJIN BRIDGE" },
  { MMSI: 538005956, IMO: 9302645, SHIPNAME: "SEAMAX WESTPORT" },
  { MMSI: 538006111, IMO: 9189342, SHIPNAME: "TASMAN" },
  { MMSI: 538006141, IMO: 9623855, SHIPNAME: "SEAMAX STAMFORD" },
  { MMSI: 538006207, IMO: 9263320, SHIPNAME: "HOPE ISLAND" },
  { MMSI: 538006257, IMO: 9341110, SHIPNAME: "DELOS WAVE" },
  { MMSI: 538006605, IMO: 9332860, SHIPNAME: "MH HAMBURG" },
  { MMSI: 538006767, IMO: 9732606, SHIPNAME: "SEAMAX MYSTIC" },
  { MMSI: 538006980, IMO: 9760287, SHIPNAME: "HEUNG-A BANGKOK" },
  { MMSI: 538006981, IMO: 9760299, SHIPNAME: "HEUNGA HOCHIMINH" },
  { MMSI: 538007063, IMO: 9290464, SHIPNAME: "SEAMAX NORWALK" },
  { MMSI: 538007097, IMO: 9699115, SHIPNAME: "ZIM WILMINGTON" },
  { MMSI: 538007157, IMO: 9760304, SHIPNAME: "INSPIRE" },
  { MMSI: 538007209, IMO: 9231494, SHIPNAME: "EM CORFU" },
  { MMSI: 538007259, IMO: 9385001, SHIPNAME: "HYUNDAI TACOMA" },
  { MMSI: 538007311, IMO: 9792498, SHIPNAME: "STARSHIP TAURUS" },
  { MMSI: 538007312, IMO: 9792503, SHIPNAME: "STARSHIP AQUILA" },
  { MMSI: 538007360, IMO: 9769271, SHIPNAME: "ONE TRIUMPH" },
  { MMSI: 538007378, IMO: 9769283, SHIPNAME: "ONE TRUST" },
  { MMSI: 538007386, IMO: 9730854, SHIPNAME: "INTEGRA" },
  { MMSI: 538007457, IMO: 9730866, SHIPNAME: "INFINITY" },
  { MMSI: 538007477, IMO: 9346304, SHIPNAME: "HYUNDAI BRAVE" },
  { MMSI: 538007479, IMO: 9347542, SHIPNAME: "HYUNDAI COURAGE" },
  { MMSI: 538007480, IMO: 9347554, SHIPNAME: "HYUNDAI FAITH" },
  { MMSI: 538007481, IMO: 9347566, SHIPNAME: "HYUNDAI FORCE" },
  { MMSI: 538007482, IMO: 9330707, SHIPNAME: "HYUNDAI FORWARD" },
  { MMSI: 538007483, IMO: 9330719, SHIPNAME: "HYUNDAI UNITY" },
  { MMSI: 538007484, IMO: 9330721, SHIPNAME: "HYUNDAI GRACE" },
  { MMSI: 538007485, IMO: 9347578, SHIPNAME: "HYUNDAI DYNASTY" },
  { MMSI: 538007486, IMO: 9347580, SHIPNAME: "HYUNDAI VOYAGER" },
  { MMSI: 538007487, IMO: 9347619, SHIPNAME: "HYUNDAI SUPREME" },
  { MMSI: 538007503, IMO: 9793806, SHIPNAME: "KMTC HAIPHONG" },
  { MMSI: 538007518, IMO: 9784233, SHIPNAME: "TS BANGKOK" },
  { MMSI: 538007547, IMO: 9448437, SHIPNAME: "STAR CLIPPER" },
  { MMSI: 538007548, IMO: 9448839, SHIPNAME: "STAR SKIPPER" },
  { MMSI: 538007610, IMO: 9324851, SHIPNAME: "NAVIOS AZURE" },
  { MMSI: 538007617, IMO: 9308015, SHIPNAME: "NAVIOS VERANO" },
  { MMSI: 538007622, IMO: 9324863, SHIPNAME: "NAVIOS VERDE" },
  { MMSI: 538007628, IMO: 9334143, SHIPNAME: "MATSON LANAI" },
  { MMSI: 538007641, IMO: 9423035, SHIPNAME: "BFAD ATLANTIC" },
  { MMSI: 538007662, IMO: 9784245, SHIPNAME: "TS TOKYO" },
  { MMSI: 538007677, IMO: 9243590, SHIPNAME: "ATLANTIC DISCOVERER" },
  { MMSI: 538007680, IMO: 9401075, SHIPNAME: "MELINA" },
  { MMSI: 538007681, IMO: 9453365, SHIPNAME: "SPYROS V" },
  { MMSI: 538007683, IMO: 9461623, SHIPNAME: "CONSTANTINOS P II" },
  { MMSI: 538007684, IMO: 9397585, SHIPNAME: "ELENI T" },
  { MMSI: 538007698, IMO: 9305491, SHIPNAME: "SEAMAX STRATFORD" },
  { MMSI: 538007711, IMO: 9790892, SHIPNAME: "INGENUITY" },
  { MMSI: 538007728, IMO: 9810068, SHIPNAME: "TS KAOHSIUNG" },
  { MMSI: 538007806, IMO: 9790907, SHIPNAME: "INSIGHT" },
  { MMSI: 538007820, IMO: 9810111, SHIPNAME: "TS OSAKA" },
  { MMSI: 538007905, IMO: 9742168, SHIPNAME: "HMM PROMISE" },
  { MMSI: 538007938, IMO: 9294159, SHIPNAME: "CAPTAIN THANASIS I" },
  { MMSI: 538008132, IMO: 9717204, SHIPNAME: "CAP SAN JUAN" },
  { MMSI: 538008133, IMO: 9717228, SHIPNAME: "CAP SAN VINCENT" },
  { MMSI: 538008134, IMO: 9717216, SHIPNAME: "CAP SAN LAZARO" },
  { MMSI: 538008152, IMO: 9308003, SHIPNAME: "NAVIOS SUMMER" },
  { MMSI: 538008542, IMO: 9463310, SHIPNAME: "BALTIC NORTH" },
  { MMSI: 538008563, IMO: 9409041, SHIPNAME: "BALTIC WEST" },
  { MMSI: 538008564, IMO: 9463281, SHIPNAME: "BALTIC SOUTH" },
  { MMSI: 538008593, IMO: 9295414, SHIPNAME: "SC MONTREUX" },
  { MMSI: 538008635, IMO: 9822724, SHIPNAME: "INVICTA" },
  { MMSI: 538008691, IMO: 9825398, SHIPNAME: "INCEDA" },
  { MMSI: 538008755, IMO: 9450571, SHIPNAME: "SYNERGY BUSAN" },
  { MMSI: 538008756, IMO: 9443580, SHIPNAME: "SYNERGY ANTWERP" },
  { MMSI: 538008811, IMO: 9833371, SHIPNAME: "INCRES" },
  { MMSI: 538008834, IMO: 9879363, SHIPNAME: "SAWASDEE PACIFIC" },
  { MMSI: 538008845, IMO: 9464687, SHIPNAME: "TS SINGAPORE" },
  { MMSI: 538008859, IMO: 9870977, SHIPNAME: "STAR EXPLORER" },
  { MMSI: 538008860, IMO: 9884289, SHIPNAME: "STAR FRONTIER" },
  { MMSI: 538008946, IMO: 9825415, SHIPNAME: "INFERRO" },
  { MMSI: 538008980, IMO: 9786085, SHIPNAME: "MAERSK ZAMBEZI" },
  { MMSI: 538009116, IMO: 9832999, SHIPNAME: "INESSA" },
  { MMSI: 538009125, IMO: 9215311, SHIPNAME: "W KAMPALA" },
  { MMSI: 538009126, IMO: 9211494, SHIPNAME: "W KYRENIA" },
  { MMSI: 538009143, IMO: 9211482, SHIPNAME: "W KLAIPEDA" },
  { MMSI: 538009144, IMO: 9215323, SHIPNAME: "W KITHIRA" },
  { MMSI: 538009162, IMO: 9322310, SHIPNAME: "TS NINGBO" },
  { MMSI: 538009167, IMO: 9833008, SHIPNAME: "INDURO" },
  { MMSI: 538009208, IMO: 9865855, SHIPNAME: "CONSISTENCE" },
  { MMSI: 538009216, IMO: 9243394, SHIPNAME: "SEAMAX DARIEN" },
  { MMSI: 538009224, IMO: 9448827, SHIPNAME: "GH TRAMONTANE" },
  { MMSI: 538009244, IMO: 9744661, SHIPNAME: "TS LAEMCHABANG" },
  { MMSI: 538009272, IMO: 9865867, SHIPNAME: "CONSCIENCE" },
  { MMSI: 538009273, IMO: 9865879, SHIPNAME: "CONFIDENCE" },
  { MMSI: 538009274, IMO: 9907249, SHIPNAME: "CONTESSA" },
  { MMSI: 538009275, IMO: 9907251, SHIPNAME: "CONCERTO" },
  { MMSI: 538009276, IMO: 9907263, SHIPNAME: "CONSERO" },
  { MMSI: 538009277, IMO: 9449118, SHIPNAME: "GH MAESTRO" },
  { MMSI: 538009287, IMO: 9440796, SHIPNAME: "BALTIMORE STAR" },
  { MMSI: 538009289, IMO: 9509176, SHIPNAME: "BFAD SOUTHERN" },
  { MMSI: 538009290, IMO: 9315965, SHIPNAME: "TS KELANG" },
  { MMSI: 538009319, IMO: 9377573, SHIPNAME: "GH BORA" },
  { MMSI: 538009350, IMO: 9303778, SHIPNAME: "CELSIUS LONDON" },
  { MMSI: 538009373, IMO: 9477385, SHIPNAME: "TS SYDNEY" },
  { MMSI: 538009390, IMO: 9377559, SHIPNAME: "GH FOEHN" },
  { MMSI: 538009400, IMO: 9401271, SHIPNAME: "GFS GALAXY" },
  { MMSI: 538009416, IMO: 9445904, SHIPNAME: "SC HOUSTON" },
  { MMSI: 538009437, IMO: 9330496, SHIPNAME: "CELSIUS NICOSIA" },
  { MMSI: 538009457, IMO: 9352688, SHIPNAME: "SCO SHANGHAI" },
  { MMSI: 538009458, IMO: 9216353, SHIPNAME: "MAYFIELD" },
  { MMSI: 538009489, IMO: 9220067, SHIPNAME: "MORAGA" },
  { MMSI: 538009514, IMO: 9536973, SHIPNAME: "SHANGHAI VOYAGER" },
  { MMSI: 538009520, IMO: 9757541, SHIPNAME: "TS KWANGYANG" },
  { MMSI: 538009523, IMO: 9312432, SHIPNAME: "GABRIELA A" },
  { MMSI: 538009559, IMO: 9869681, SHIPNAME: "DEL MONTE VALIANT" },
  { MMSI: 538009560, IMO: 9849643, SHIPNAME: "DEL MONTE GOLD" },
  { MMSI: 538009561, IMO: 9869679, SHIPNAME: "DEL MONTE SPIRIT" },
  { MMSI: 538009562, IMO: 9849655, SHIPNAME: "DEL MONTE ROSE" },
  { MMSI: 538009563, IMO: 9869693, SHIPNAME: "DEL MONTE PRIDE" },
  { MMSI: 538009564, IMO: 9869667, SHIPNAME: "DEL MONTE HARVESTER" },
  { MMSI: 538009596, IMO: 9128099, SHIPNAME: "MANILA VOYAGER" },
  { MMSI: 538009601, IMO: 9238791, SHIPNAME: "CELSIUS NAIROBI" },
  { MMSI: 538009602, IMO: 9400136, SHIPNAME: "CELSIUS NAPLES" },
  { MMSI: 538009630, IMO: 9907380, SHIPNAME: "CONSIGNIA" },
  { MMSI: 538009631, IMO: 9907392, SHIPNAME: "CONSILIA" },
  { MMSI: 538009632, IMO: 9907407, SHIPNAME: "CONTRIVIA" },
  { MMSI: 538009693, IMO: 9915818, SHIPNAME: "STAR CHASER" },
  { MMSI: 538009694, IMO: 9915820, SHIPNAME: "STAR RANGER" },
  { MMSI: 538009707, IMO: 9406752, SHIPNAME: "NYK LAURA" },
  { MMSI: 538009719, IMO: 9506540, SHIPNAME: "SAFEEN PRIDE" },
  { MMSI: 538009749, IMO: 9348857, SHIPNAME: "TS DALIAN" },
  { MMSI: 538009760, IMO: 9520039, SHIPNAME: "MANZANILLO EXPRESS" },
  { MMSI: 538009762, IMO: 9520053, SHIPNAME: "ITAJAI EXPRESS" },
  { MMSI: 538009764, IMO: 9932488, SHIPNAME: "BUENAVENTURA EXPRESS" },
  { MMSI: 538009830, IMO: 9321029, SHIPNAME: "PRESTIGE" },
  { MMSI: 538009831, IMO: 9321031, SHIPNAME: "GOOD PROSPECT" },
  { MMSI: 538009877, IMO: 9377690, SHIPNAME: "CELSIUS NELSON" },
  { MMSI: 538009878, IMO: 9412842, SHIPNAME: "CELSIUS NEW ORLEANS" },
  { MMSI: 538009884, IMO: 9344552, SHIPNAME: "FAYSTON FARMS" },
  { MMSI: 538009897, IMO: 9339856, SHIPNAME: "ATLANTIC IBIS" },
  { MMSI: 538009915, IMO: 9919242, SHIPNAME: "TS NAGOYA" },
  { MMSI: 538009929, IMO: 9940758, SHIPNAME: "SAWASDEE CHITTAGONG" },
  { MMSI: 538009940, IMO: 9640994, SHIPNAME: "SAFEEN PIONEER" },
  { MMSI: 538010005, IMO: 9939292, SHIPNAME: "STARSHIP DRACO" },
  { MMSI: 538010006, IMO: 9939307, SHIPNAME: "PEGASUS PROTO" },
  { MMSI: 538010008, IMO: 9940760, SHIPNAME: "SAWASDEE SHANGHAI" },
  { MMSI: 538010028, IMO: 9301859, SHIPNAME: "RENA P" },
  { MMSI: 538010029, IMO: 9290127, SHIPNAME: "EMMANUEL P" },
  { MMSI: 538010031, IMO: 9301122, SHIPNAME: "EAGLE II" },
  { MMSI: 538010037, IMO: 9239886, SHIPNAME: "CF ATHENA" },
  { MMSI: 538010046, IMO: 9940772, SHIPNAME: "SAWASDEE XIAMEN" },
  { MMSI: 538010048, IMO: 9940784, SHIPNAME: "SAWASDEE INCHEON" },
  { MMSI: 538010060, IMO: 9919254, SHIPNAME: "TS XIAMEN" },
  { MMSI: 538010061, IMO: 9943774, SHIPNAME: "SAWASDEE SIRIUS" },
  { MMSI: 538010092, IMO: 9419656, SHIPNAME: "NYK SILVIA" },
  { MMSI: 538010110, IMO: 9928621, SHIPNAME: "TS TIANJIN" },
  { MMSI: 538010120, IMO: 9955533, SHIPNAME: "GREGOS" },
  { MMSI: 538010122, IMO: 9955545, SHIPNAME: "TERATAKI" },
  { MMSI: 538010124, IMO: 9932933, SHIPNAME: "MARLA TIGER" },
  { MMSI: 538010153, IMO: 9539482, SHIPNAME: "SAFEEN POWER" },
  { MMSI: 538010170, IMO: 9928633, SHIPNAME: "TS JAKARTA" },
  { MMSI: 538010174, IMO: 9914151, SHIPNAME: "TS HOCHIMINH" },
  { MMSI: 538010175, IMO: 9868182, SHIPNAME: "TS KOBE" },
  { MMSI: 538010176, IMO: 9914149, SHIPNAME: "TS NANSHA" },
  { MMSI: 538010219, IMO: 9943786, SHIPNAME: "SAWASDEE VEGA" },
  { MMSI: 538010265, IMO: 9932945, SHIPNAME: "MARLA BULL" },
  { MMSI: 538010267, IMO: 9946348, SHIPNAME: "TS GUANGZHOU" },
  { MMSI: 538010322, IMO: 9939876, SHIPNAME: "VEGA COLIGNY" },
  { MMSI: 538010323, IMO: 9939888, SHIPNAME: "VEGA DAYTONA" },
  { MMSI: 538010343, IMO: 9947720, SHIPNAME: "REMAH" },
  { MMSI: 538010344, IMO: 9952397, SHIPNAME: "TS SHEKOU" },
  { MMSI: 538010372, IMO: 9953561, SHIPNAME: "IRENES RULE" },
  { MMSI: 538010373, IMO: 9953391, SHIPNAME: "IRENES WISDOM" },
  { MMSI: 538010399, IMO: 9943798, SHIPNAME: "SAWASDEE CAPELLA" },
  { MMSI: 538010433, IMO: 9947653, SHIPNAME: "TS MAWEI" },
  { MMSI: 538010485, IMO: 9950064, SHIPNAME: "VENTO DI BORA" },
  { MMSI: 538010486, IMO: 9950076, SHIPNAME: "CAPE BRUNO" },
  { MMSI: 538010501, IMO: 9943803, SHIPNAME: "SAWASDEE RIGEL" },
  { MMSI: 538010505, IMO: 9946635, SHIPNAME: "SAWASDEE ALTAIR" },
  { MMSI: 538010544, IMO: 9950088, SHIPNAME: "CAPE BONAVISTA" },
  { MMSI: 538010545, IMO: 9950090, SHIPNAME: "CAPE BYRON" },
  { MMSI: 538010558, IMO: 9947677, SHIPNAME: "TS CHIBA" },
  { MMSI: 538010559, IMO: 9947665, SHIPNAME: "TS HAKATA" },
  { MMSI: 538010572, IMO: 9946647, SHIPNAME: "SAWASDEE SPICA" },
  { MMSI: 538010641, IMO: 9359727, SHIPNAME: "TEH TAICHUNG" },
  { MMSI: 538010654, IMO: 9947689, SHIPNAME: "TS INCHEON" },
  { MMSI: 538010690, IMO: 9951721, SHIPNAME: "TS VANCOUVER" },
  { MMSI: 538010693, IMO: 9951733, SHIPNAME: "TS TACOMA" },
  { MMSI: 538010740, IMO: 9953834, SHIPNAME: "TS MUNDRA" },
  { MMSI: 538010741, IMO: 9960784, SHIPNAME: "TS MELBOURNE" },
  { MMSI: 538010745, IMO: 9946659, SHIPNAME: "SAWASDEE DENEB" },
  { MMSI: 538010758, IMO: 9948865, SHIPNAME: "TS LIANYUNGANG" },
  { MMSI: 538010770, IMO: 9464314, SHIPNAME: "GULF BARAKAH" },
  { MMSI: 538010776, IMO: 9419785, SHIPNAME: "WADI DUKA" },
  { MMSI: 538010783, IMO: 9854844, SHIPNAME: "TS PUSAN" },
  { MMSI: 538010784, IMO: 9854832, SHIPNAME: "TS QINGDAO" },
  { MMSI: 538010785, IMO: 9868170, SHIPNAME: "TS SHENZHEN" },
  { MMSI: 538010792, IMO: 9955430, SHIPNAME: "TS JOHOR" },
  { MMSI: 538010793, IMO: 9953846, SHIPNAME: "TS CHENNAI" },
  { MMSI: 538010794, IMO: 9322334, SHIPNAME: "VANCOUVER STAR" },
  { MMSI: 538010796, IMO: 9322322, SHIPNAME: "SHEKOU STAR" },
  { MMSI: 538010806, IMO: 9290414, SHIPNAME: "OOCL ST.LAWRENCE" },
  { MMSI: 538010808, IMO: 9322346, SHIPNAME: "YOKOHAMA STAR" },
  { MMSI: 538010813, IMO: 9948877, SHIPNAME: "TS PENANG" },
  { MMSI: 538010837, IMO: 9318163, SHIPNAME: "QINGDAO STAR" },
  { MMSI: 538010845, IMO: 9946661, SHIPNAME: "SAWASDEE MIMOSA" },
  { MMSI: 538010946, IMO: 9955442, SHIPNAME: "TS SURABAYA" },
  { MMSI: 538010956, IMO: 9491604, SHIPNAME: "SAFEEN PEARL" },
  { MMSI: 538090562, IMO: 9445899, SHIPNAME: "CALIDRIS" },
  { MMSI: 538090571, IMO: 9357781, SHIPNAME: "HANSA COLOMBO" },
  { MMSI: 538090582, IMO: 9459400, SHIPNAME: "HANSA ASIA" },
  { MMSI: 538090583, IMO: 9459436, SHIPNAME: "HANSA AUSTRALIA" },
  { MMSI: 538090584, IMO: 9456953, SHIPNAME: "SPIRIT OF HONG KONG" },
  { MMSI: 538090596, IMO: 9301081, SHIPNAME: "VEGA ALPHA" },
  { MMSI: 538090605, IMO: 9765574, SHIPNAME: "OLYMPIA" },
  { MMSI: 538090606, IMO: 9765586, SHIPNAME: "OPHELIA" },
  { MMSI: 538090625, IMO: 9734214, SHIPNAME: "LOA HARMONY" },
  { MMSI: 538090639, IMO: 9420136, SHIPNAME: "XING LUO 7" },
  { MMSI: 538090641, IMO: 9401178, SHIPNAME: "AS NINA" },
  { MMSI: 548184500, IMO: 9005522, SHIPNAME: "MV OCEAN ZENITH" },
  { MMSI: 548256500, IMO: 8203646, SHIPNAME: "SPAN ASIA 32" },
  { MMSI: 548258500, IMO: 8914568, SHIPNAME: "MV SPAN ASIA 31" },
  { MMSI: 548263500, IMO: 9327592, SHIPNAME: "IRIS PAOAY" },
  { MMSI: 548321500, IMO: 9065297, SHIPNAME: "MC HUNTER" },
  { MMSI: 548350500, IMO: 9116759, SHIPNAME: "MERIDIAN OCHO" },
  { MMSI: 548390100, IMO: 9200017, SHIPNAME: "MV LORCON DUMAGUETE" },
  { MMSI: 548581300, IMO: 9141053, SHIPNAME: "SAN LORENZO RUIZ UNO" },
  { MMSI: 548614200, IMO: 9264752, SHIPNAME: "OCEAN OPPORTUNITY" },
  { MMSI: 548636500, IMO: 9117777, SHIPNAME: "M/V TRANS-ASIA 15" },
  { MMSI: 548699200, IMO: 9332690, SHIPNAME: "MCC BATANES" },
  { MMSI: 548740200, IMO: 9100243, SHIPNAME: "SPAN ASIA 25" },
  { MMSI: 548770200, IMO: 8914556, SHIPNAME: "MV SPAN ASIA 27" },
  { MMSI: 548819200, IMO: 7824613, SHIPNAME: "MV SPAN ASIA 3" },
  { MMSI: 553111742, IMO: 9436874, SHIPNAME: "GAZELLE COAST" },
  { MMSI: 553111747, IMO: 9408451, SHIPNAME: "BOUGAINVILLE COAST" },
  { MMSI: 553111867, IMO: 9436886, SHIPNAME: "NIUGINI COAST" },
  { MMSI: 553111941, IMO: 9196931, SHIPNAME: "MV ELSIE" },
  { MMSI: 563000400, IMO: 9289922, SHIPNAME: "MAERSK SANA" },
  { MMSI: 563000500, IMO: 9289958, SHIPNAME: "MAERSK SYDNEY" },
  { MMSI: 563000600, IMO: 9308649, SHIPNAME: "MAERSK SINGAPORE" },
  { MMSI: 563000700, IMO: 9299927, SHIPNAME: "MAERSK SEVILLE" },
  { MMSI: 563000800, IMO: 9289946, SHIPNAME: "MAERSK SARNIA" },
  { MMSI: 563000900, IMO: 9289934, SHIPNAME: "MAERSK SANTANA" },
  { MMSI: 563001100, IMO: 9299939, SHIPNAME: "MAERSK SHEERNESS" },
  { MMSI: 563001200, IMO: 9308637, SHIPNAME: "MAERSK SOFIA" },
  { MMSI: 563001700, IMO: 9726671, SHIPNAME: "MAERSK STADELHORN" },
  { MMSI: 563002100, IMO: 9725718, SHIPNAME: "MAERSK SIRAC" },
  { MMSI: 563004200, IMO: 9697428, SHIPNAME: "DALI" },
  { MMSI: 563004400, IMO: 9611034, SHIPNAME: "CHATTANOOGA" },
  { MMSI: 563004500, IMO: 9611046, SHIPNAME: "TALLAHASSEE" },
  { MMSI: 563004600, IMO: 9611058, SHIPNAME: "KALAMAZOO" },
  { MMSI: 563004900, IMO: 9300441, SHIPNAME: "EVER STRONG" },
  { MMSI: 563005400, IMO: 9444948, SHIPNAME: "INTERASIA ADVANCE" },
  { MMSI: 563006800, IMO: 9726669, SHIPNAME: "MAERSK SHAMS" },
  { MMSI: 563011000, IMO: 9461635, SHIPNAME: "KOTA NANHAI" },
  { MMSI: 563017800, IMO: 9784257, SHIPNAME: "MAERSK HONG KONG" },
  { MMSI: 563020400, IMO: 9737474, SHIPNAME: "TOKYO TRIUMPH" },
  { MMSI: 563024600, IMO: 9743473, SHIPNAME: "MIMMI SCHULTE" },
  { MMSI: 563024700, IMO: 9743485, SHIPNAME: "MOLLY SCHULTE" },
  { MMSI: 563025600, IMO: 9312810, SHIPNAME: "NYK VIRGO" },
  { MMSI: 563026700, IMO: 9784269, SHIPNAME: "MAERSK HORSBURGH" },
  { MMSI: 563029900, IMO: 9737498, SHIPNAME: "TAIPEI TRIUMPH" },
  { MMSI: 563030500, IMO: 9784271, SHIPNAME: "MAERSK HALIFAX" },
  { MMSI: 563032600, IMO: 9350147, SHIPNAME: "WAN HAI 173" },
  { MMSI: 563032700, IMO: 9303534, SHIPNAME: "MAERSK SAIGON" },
  { MMSI: 563032800, IMO: 9303522, SHIPNAME: "MAERSK STRALSUND" },
  { MMSI: 563032900, IMO: 9306550, SHIPNAME: "MAERSK SEOUL" },
  { MMSI: 563033000, IMO: 9629110, SHIPNAME: "EVER LOVELY" },
  { MMSI: 563033800, IMO: 9786712, SHIPNAME: "KOTA PAHLAWAN" },
  { MMSI: 563034800, IMO: 9786724, SHIPNAME: "PARANAGUA EXPRESS" },
  { MMSI: 563034900, IMO: 9784283, SHIPNAME: "MAERSK HIDALGO" },
  { MMSI: 563035100, IMO: 9299032, SHIPNAME: "MAERSK DOUALA" },
  { MMSI: 563035200, IMO: 9302449, SHIPNAME: "MAERSK DAKAR" },
  { MMSI: 563035400, IMO: 9389394, SHIPNAME: "ALS FLORA" },
  { MMSI: 563039700, IMO: 9784295, SHIPNAME: "MAERSK HANOI" },
  { MMSI: 563041500, IMO: 9350159, SHIPNAME: "WAN HAI 175" },
  { MMSI: 563042200, IMO: 9784300, SHIPNAME: "MAERSK HANGZHOU" },
  { MMSI: 563042400, IMO: 9786748, SHIPNAME: "KOTA PELANGI" },
  { MMSI: 563044300, IMO: 9356165, SHIPNAME: "MAERSK NAIROBI" },
  { MMSI: 563048400, IMO: 9312987, SHIPNAME: "ONE OLYMPUS" },
  { MMSI: 563049100, IMO: 9444998, SHIPNAME: "INTERASIA FORWARD" },
  { MMSI: 563050100, IMO: 9273923, SHIPNAME: "MAERSK CAP CARMEL" },
  { MMSI: 563050400, IMO: 9348065, SHIPNAME: "MAERSK MONTE ALEGRE" },
  { MMSI: 563050500, IMO: 9786774, SHIPNAME: "POLAR ECUADOR" },
  { MMSI: 563051400, IMO: 9283215, SHIPNAME: "MONTE ROSA" },
  { MMSI: 563051500, IMO: 9786786, SHIPNAME: "POLAR COSTA RICA" },
  { MMSI: 563051700, IMO: 9348053, SHIPNAME: "MAERSK MONTE AZUL" },
  { MMSI: 563051800, IMO: 9283239, SHIPNAME: "MONTE VERDE" },
  { MMSI: 563051900, IMO: 9273961, SHIPNAME: "ALIANCA MANAUS" },
  { MMSI: 563052100, IMO: 9357949, SHIPNAME: "MONTE TAMARO" },
  { MMSI: 563052200, IMO: 9357951, SHIPNAME: "MAERSK RIO DELTA" },
  { MMSI: 563052300, IMO: 9357975, SHIPNAME: "MAERSK RIO NEGRO" },
  { MMSI: 563052600, IMO: 9484560, SHIPNAME: "CAP JACKSON" },
  { MMSI: 563052700, IMO: 9484572, SHIPNAME: "CAP JERVIS" },
  { MMSI: 563052900, IMO: 9357963, SHIPNAME: "MAERSK RIO INDIA" },
  { MMSI: 563053300, IMO: 9784312, SHIPNAME: "MAERSK HAMBURG" },
  { MMSI: 563053700, IMO: 9316335, SHIPNAME: "INTERASIA PROGRESS" },
  { MMSI: 563054900, IMO: 9301093, SHIPNAME: "X PRESS GANGES" },
  { MMSI: 563058900, IMO: 9325441, SHIPNAME: "X-PRESS MACHU PICCHU" },
  { MMSI: 563059200, IMO: 9784324, SHIPNAME: "MAERSK HERRERA" },
  { MMSI: 563060800, IMO: 9354533, SHIPNAME: "CAPITAINE TASMAN" },
  { MMSI: 563061300, IMO: 9313008, SHIPNAME: "ONE ORPHEUS" },
  { MMSI: 563062200, IMO: 9725706, SHIPNAME: "MAERSK SALTORO" },
  { MMSI: 563062300, IMO: 9697416, SHIPNAME: "CEZANNE" },
  { MMSI: 563062600, IMO: 9525950, SHIPNAME: "ALS VESTA" },
  { MMSI: 563062700, IMO: 9525948, SHIPNAME: "ALS VENUS" },
  { MMSI: 563066900, IMO: 9786750, SHIPNAME: "POLAR MEXICO" },
  { MMSI: 563068900, IMO: 9786827, SHIPNAME: "EVER GIFTED" },
  { MMSI: 563069900, IMO: 9784336, SHIPNAME: "MAERSK HAVANA" },
  { MMSI: 563071300, IMO: 9793727, SHIPNAME: "X-PRESS KABRU" },
  { MMSI: 563073500, IMO: 9757216, SHIPNAME: "YM WISDOM" },
  { MMSI: 563073700, IMO: 9348651, SHIPNAME: "MAERSK MEMPHIS" },
  { MMSI: 563073800, IMO: 9193240, SHIPNAME: "MAERSK KENTUCKY" },
  { MMSI: 563075510, IMO: 9085596, SHIPNAME: "PSA AGILITY" },
  { MMSI: 563075940, IMO: 9105968, SHIPNAME: "PSA CONNECTIVITY" },
  { MMSI: 563076200, IMO: 9235543, SHIPNAME: "MAERSK GATESHEAD" },
  { MMSI: 563076300, IMO: 9235555, SHIPNAME: "MAERSK GIRONDE" },
  { MMSI: 563077400, IMO: 9235579, SHIPNAME: "MAERSK GARONNE" },
  { MMSI: 563077800, IMO: 9300427, SHIPNAME: "EVER SUPERB" },
  { MMSI: 563078200, IMO: 9848948, SHIPNAME: "MAERSK HUACHO" },
  { MMSI: 563078300, IMO: 9848950, SHIPNAME: "MAERSK HOUSTON" },
  { MMSI: 563078400, IMO: 9235567, SHIPNAME: "MAERSK GAIRLOCH" },
  { MMSI: 563079000, IMO: 9416989, SHIPNAME: "NYK ROMULUS" },
  { MMSI: 563082900, IMO: 9494632, SHIPNAME: "KOTA NEBULA" },
  { MMSI: 563083400, IMO: 9840702, SHIPNAME: "MAERSK BINTULU" },
  { MMSI: 563084400, IMO: 9219800, SHIPNAME: "CHASTINE MAERSK" },
  { MMSI: 563084500, IMO: 9198587, SHIPNAME: "CORNELIUS MAERSK" },
  { MMSI: 563084800, IMO: 9300439, SHIPNAME: "EVER STEADY" },
  { MMSI: 563087600, IMO: 9840714, SHIPNAME: "MAERSK SIHANOUKVILLE" },
  { MMSI: 563088700, IMO: 9836658, SHIPNAME: "CNC NEPTUNE" },
  { MMSI: 563090200, IMO: 9398436, SHIPNAME: "MAERSK YUKON" },
  { MMSI: 563090300, IMO: 9501239, SHIPNAME: "MAERSK EUREKA" },
  { MMSI: 563090400, IMO: 9502910, SHIPNAME: "MAERSK EMERALD" },
  { MMSI: 563090500, IMO: 9502867, SHIPNAME: "MAERSK EDIRNE" },
  { MMSI: 563091100, IMO: 9840726, SHIPNAME: "MAERSK SONGKHLA" },
  { MMSI: 563095400, IMO: 9840738, SHIPNAME: "MAERSK MONGLA" },
  { MMSI: 563095500, IMO: 9326782, SHIPNAME: "SHIMIN" },
  { MMSI: 563095600, IMO: 9865958, SHIPNAME: "GREEN STAR" },
  { MMSI: 563095700, IMO: 9865960, SHIPNAME: "GREEN OCEAN" },
  { MMSI: 563095800, IMO: 9865972, SHIPNAME: "GREEN FOREST" },
  { MMSI: 563096400, IMO: 9697014, SHIPNAME: "MAERSK BERMUDA" },
  { MMSI: 563096500, IMO: 9697026, SHIPNAME: "MAERSK BAHAMAS" },
  { MMSI: 563096700, IMO: 9300465, SHIPNAME: "EVER SAFETY" },
  { MMSI: 563098400, IMO: 9836660, SHIPNAME: "CNC MARS" },
  { MMSI: 563101600, IMO: 9632480, SHIPNAME: "MAERSK RUBICON" },
  { MMSI: 563101900, IMO: 9865984, SHIPNAME: "GREEN EARTH" },
  { MMSI: 563102000, IMO: 9394870, SHIPNAME: "MAERSK BALI" },
  { MMSI: 563102100, IMO: 9865996, SHIPNAME: "GREEN SEA" },
  { MMSI: 563102400, IMO: 9300477, SHIPNAME: "EVER SALUTE" },
  { MMSI: 563102800, IMO: 9290452, SHIPNAME: "SHINA" },
  { MMSI: 563103200, IMO: 9786762, SHIPNAME: "POLAR COLOMBIA" },
  { MMSI: 563103400, IMO: 9398395, SHIPNAME: "MAERSK AMAZON" },
  { MMSI: 563103800, IMO: 9866005, SHIPNAME: "GREEN POLE" },
  { MMSI: 563103900, IMO: 9443358, SHIPNAME: "PAC SEGINUS" },
  { MMSI: 563105800, IMO: 9793935, SHIPNAME: "NAVEGANTES EXPRESS" },
  { MMSI: 563107100, IMO: 9858709, SHIPNAME: "MAERSK QINZHOU" },
  { MMSI: 563109200, IMO: 9858711, SHIPNAME: "MAERSK DAVAO" },
  { MMSI: 563110300, IMO: 9867724, SHIPNAME: "GREEN WAVE" },
  { MMSI: 563110400, IMO: 9867712, SHIPNAME: "GREEN DAWN" },
  { MMSI: 563110800, IMO: 9400825, SHIPNAME: "INTERASIA MOMENTUM" },
  { MMSI: 563111100, IMO: 9871440, SHIPNAME: "WAN HAI 321" },
  { MMSI: 563111200, IMO: 9866469, SHIPNAME: "SINAR SUNDA" },
  { MMSI: 563111300, IMO: 9858723, SHIPNAME: "MAERSK XIAMEN" },
  { MMSI: 563113200, IMO: 9871452, SHIPNAME: "WAN HAI 322" },
  { MMSI: 563114100, IMO: 9757553, SHIPNAME: "INTERASIA PURSUIT" },
  { MMSI: 563115100, IMO: 9757565, SHIPNAME: "INTERASIA VISION" },
  { MMSI: 563115300, IMO: 9455909, SHIPNAME: "GFS RUBY" },
  { MMSI: 563115400, IMO: 9349045, SHIPNAME: "GFS PRIDE" },
  { MMSI: 563115900, IMO: 9303819, SHIPNAME: "GFS PRESTIGE" },
  { MMSI: 563116500, IMO: 9871311, SHIPNAME: "WAN HAI 283" },
  { MMSI: 563116700, IMO: 9866471, SHIPNAME: "SINAR SORONG" },
  { MMSI: 563118100, IMO: 9875355, SHIPNAME: "X-PRESS MEKONG" },
  { MMSI: 563118300, IMO: 9871464, SHIPNAME: "WAN HAI 323" },
  { MMSI: 563118500, IMO: 9402768, SHIPNAME: "ASIATIC SUN" },
  { MMSI: 563118600, IMO: 9392913, SHIPNAME: "ASIATIC QUEST" },
  { MMSI: 563119000, IMO: 9387449, SHIPNAME: "NYK JOANNA" },
  { MMSI: 563119400, IMO: 9871476, SHIPNAME: "WAN HAI 325" },
  { MMSI: 563120900, IMO: 9404728, SHIPNAME: "ASIATIC REUNION" },
  { MMSI: 563121100, IMO: 9404730, SHIPNAME: "ASIATIC PRIDE" },
  { MMSI: 563121900, IMO: 9833383, SHIPNAME: "WAN HAI 277" },
  { MMSI: 563122100, IMO: 9344954, SHIPNAME: "MAERSK VALENCIA" },
  { MMSI: 563122300, IMO: 9862736, SHIPNAME: "X-PRESS SAGARMALA" },
  { MMSI: 563123500, IMO: 9400186, SHIPNAME: "WAN HAI 521" },
  { MMSI: 563124200, IMO: 9871488, SHIPNAME: "WAN HAI 326" },
  { MMSI: 563124300, IMO: 9871490, SHIPNAME: "WAN HAI 327" },
  { MMSI: 563124400, IMO: 9817353, SHIPNAME: "MH GREEN" },
  { MMSI: 563125200, IMO: 9334519, SHIPNAME: "BOTANY" },
  { MMSI: 563125500, IMO: 9357858, SHIPNAME: "JT GLORY" },
  { MMSI: 563126500, IMO: 9440784, SHIPNAME: "WAN HAI 522" },
  { MMSI: 563126700, IMO: 9307035, SHIPNAME: "WAN HAI 622" },
  { MMSI: 563126900, IMO: 9871323, SHIPNAME: "WAN HAI 285" },
  { MMSI: 563127400, IMO: 9308039, SHIPNAME: "GFS PERFECT" },
  { MMSI: 563127500, IMO: 9656137, SHIPNAME: "X-PRESS ODYSSEY" },
  { MMSI: 563127900, IMO: 9398424, SHIPNAME: "MAERSK ATHABASCA" },
  { MMSI: 563128200, IMO: 9871505, SHIPNAME: "WAN HAI 328" },
  { MMSI: 563128900, IMO: 9871517, SHIPNAME: "WAN HAI 329" },
  { MMSI: 563129600, IMO: 9470739, SHIPNAME: "ANDERSON DRAGON" },
  { MMSI: 563129900, IMO: 9871335, SHIPNAME: "WAN HAI 286" },
  { MMSI: 563130100, IMO: 9622019, SHIPNAME: "MEDITERRANEAN EXPRES" },
  { MMSI: 563130400, IMO: 9398242, SHIPNAME: "WAN HAI 721" },
  { MMSI: 563131500, IMO: 9706748, SHIPNAME: "ONE MANCHESTER" },
  { MMSI: 563131600, IMO: 9433066, SHIPNAME: "A DAISEN" },
  { MMSI: 563131800, IMO: 9400198, SHIPNAME: "WAN HAI 523" },
  { MMSI: 563132400, IMO: 9398230, SHIPNAME: "WAN HAI 722" },
  { MMSI: 563132500, IMO: 9871347, SHIPNAME: "WAN HAI 287" },
  { MMSI: 563133100, IMO: 9308405, SHIPNAME: "WHUTTHI BHUM" },
  { MMSI: 563133400, IMO: 9322308, SHIPNAME: "KOTA LAGU" },
  { MMSI: 563133500, IMO: 9351048, SHIPNAME: "KOTA LARIS" },
  { MMSI: 563133800, IMO: 9744659, SHIPNAME: "X-PRESS NILWALA" },
  { MMSI: 563134400, IMO: 9871359, SHIPNAME: "WAN HAI 288" },
  { MMSI: 563135300, IMO: 9298997, SHIPNAME: "WAN HAI 625" },
  { MMSI: 563135700, IMO: 9459424, SHIPNAME: "MAERSK NACALA" },
  { MMSI: 563135800, IMO: 9307011, SHIPNAME: "AKA BHUM" },
  { MMSI: 563136800, IMO: 9403621, SHIPNAME: "BHUDTHI BHUM" },
  { MMSI: 563137500, IMO: 9278909, SHIPNAME: "KOTA HENING" },
  { MMSI: 563137600, IMO: 9205677, SHIPNAME: "KOTA JAYA" },
  { MMSI: 563137700, IMO: 9272981, SHIPNAME: "KOTA HANDAL" },
  { MMSI: 563137800, IMO: 9494620, SHIPNAME: "KOTA NASRAT" },
  { MMSI: 563137900, IMO: 9439759, SHIPNAME: "KOTA LAYANG" },
  { MMSI: 563138100, IMO: 9359686, SHIPNAME: "KOTA RAKAN" },
  { MMSI: 563138600, IMO: 9871361, SHIPNAME: "WAN HAI 289" },
  { MMSI: 563139600, IMO: 9871373, SHIPNAME: "WAN HAI 290" },
  { MMSI: 563139700, IMO: 9315862, SHIPNAME: "GANTA BHUM" },
  { MMSI: 563139800, IMO: 9348194, SHIPNAME: "X PRESS NETRAVATI" },
  { MMSI: 563141200, IMO: 9789972, SHIPNAME: "YM TRUST" },
  { MMSI: 563141600, IMO: 9470765, SHIPNAME: "X-PRESS KAVERI" },
  { MMSI: 563142100, IMO: 9300453, SHIPNAME: "EVER SUMMIT" },
  { MMSI: 563142500, IMO: 9305697, SHIPNAME: "WAN HAI 626" },
  { MMSI: 563142800, IMO: 9398254, SHIPNAME: "WAN HAI 723" },
  { MMSI: 563143000, IMO: 9629031, SHIPNAME: "EVER LIVING" },
  { MMSI: 563143100, IMO: 9871385, SHIPNAME: "WAN HAI 291" },
  { MMSI: 563143500, IMO: 9356658, SHIPNAME: "ATLANTIC MONACO" },
  { MMSI: 563143600, IMO: 9396622, SHIPNAME: "ATLANTIC SILVER" },
  { MMSI: 563144200, IMO: 9470741, SHIPNAME: "INTERASIA RESILIENCE" },
  { MMSI: 563144500, IMO: 9412816, SHIPNAME: "WHITE DRAGON" },
  { MMSI: 563144600, IMO: 9384899, SHIPNAME: "SUNRISE DRAGON" },
  { MMSI: 563145100, IMO: 9419668, SHIPNAME: "CAPITAINE BARET" },
  { MMSI: 563145300, IMO: 9733832, SHIPNAME: "WAN HAI 176" },
  { MMSI: 563146500, IMO: 9193264, SHIPNAME: "MAERSK IDAHO" },
  { MMSI: 563146600, IMO: 9367188, SHIPNAME: "BREMEN BELLE" },
  { MMSI: 563147400, IMO: 9433937, SHIPNAME: "PACIFIC SINGAPORE" },
  { MMSI: 563147900, IMO: 9871397, SHIPNAME: "WAN HAI 292" },
  { MMSI: 563148100, IMO: 9459632, SHIPNAME: "MAERSK WALLIS" },
  { MMSI: 563148200, IMO: 9362401, SHIPNAME: "MAERSK WILLEMSTADT" },
  { MMSI: 563148300, IMO: 9391660, SHIPNAME: "MAERSK WELLINGTON" },
  { MMSI: 563149500, IMO: 9494606, SHIPNAME: "KOTA CABAR" },
  { MMSI: 563149800, IMO: 9494589, SHIPNAME: "KOTA CAHAYA" },
  { MMSI: 563149900, IMO: 9494591, SHIPNAME: "KOTA CANTIK" },
  { MMSI: 563150100, IMO: 9494577, SHIPNAME: "KOTA CARUM" },
  { MMSI: 563150700, IMO: 9817901, SHIPNAME: "WAN HAI 276" },
  { MMSI: 563152200, IMO: 9616852, SHIPNAME: "KOTA GABUNG" },
  { MMSI: 563152300, IMO: 9628324, SHIPNAME: "KOTA LIHAT" },
  { MMSI: 563152400, IMO: 9307425, SHIPNAME: "KOTA KARIM" },
  { MMSI: 563152500, IMO: 9616802, SHIPNAME: "KOTA GAYA" },
  { MMSI: 563152600, IMO: 9439709, SHIPNAME: "KOTA LAWA" },
  { MMSI: 563152800, IMO: 9299513, SHIPNAME: "X-PRESS IRRAWADDY" },
  { MMSI: 563153200, IMO: 9871402, SHIPNAME: "WAN HAI 293" },
  { MMSI: 563154500, IMO: 9833369, SHIPNAME: "MAERSK VALLETTA" },
  { MMSI: 563155100, IMO: 9629419, SHIPNAME: "X-PRESS MOY" },
  { MMSI: 563155200, IMO: 9789996, SHIPNAME: "YM TROPHY" },
  { MMSI: 563155500, IMO: 9893979, SHIPNAME: "EVER APEX" },
  { MMSI: 563155800, IMO: 9330848, SHIPNAME: "ATLANTIC GENEVA" },
  { MMSI: 563156200, IMO: 9760639, SHIPNAME: "X-PRESS DHAULAGIRI" },
  { MMSI: 563156600, IMO: 9761011, SHIPNAME: "MAERSK NUSANTARA" },
  { MMSI: 563156700, IMO: 9761023, SHIPNAME: "MAERSK CHATTOGRAM" },
  { MMSI: 563156900, IMO: 9378931, SHIPNAME: "PACIFIC BEIJING" },
  { MMSI: 563157300, IMO: 9246542, SHIPNAME: "PACIFIC GENEVA" },
  { MMSI: 563157400, IMO: 9414125, SHIPNAME: "PACIFIC BUSAN" },
  { MMSI: 563157600, IMO: 9236597, SHIPNAME: "ATLANTIC NORTH" },
  { MMSI: 563157700, IMO: 9396610, SHIPNAME: "ATLANTIC WEST" },
  { MMSI: 563158200, IMO: 9414137, SHIPNAME: "ATLANTIC SOUTH" },
  { MMSI: 563158300, IMO: 9330862, SHIPNAME: "PACIFIC SHENZHEN" },
  { MMSI: 563158400, IMO: 9378943, SHIPNAME: "PACIFIC CARRIER" },
  { MMSI: 563158600, IMO: 9498690, SHIPNAME: "PACIFIC TIANJIN" },
  { MMSI: 563158700, IMO: 9433444, SHIPNAME: "ATLANTIC EAST" },
  { MMSI: 563158800, IMO: 9348900, SHIPNAME: "WAN HAI 309" },
  { MMSI: 563158900, IMO: 9436173, SHIPNAME: "MAERSK VERACRUZ" },
  { MMSI: 563159100, IMO: 9339064, SHIPNAME: "PACIFIC NINGBO" },
  { MMSI: 563159300, IMO: 9330850, SHIPNAME: "ATLANTIC EXPRESS" },
  { MMSI: 563159400, IMO: 9339090, SHIPNAME: "PACIFIC DALIAN" },
  { MMSI: 563159700, IMO: 9871414, SHIPNAME: "WAN HAI 295" },
  { MMSI: 563160100, IMO: 9850563, SHIPNAME: "EVER FORTUNE" },
  { MMSI: 563160200, IMO: 9850599, SHIPNAME: "EVER FUTURE" },
  { MMSI: 563160400, IMO: 9433054, SHIPNAME: "MAERSK VALPARAISO" },
  { MMSI: 563160800, IMO: 9536985, SHIPNAME: "WAN HAI 308" },
  { MMSI: 563161200, IMO: 9850551, SHIPNAME: "EVER FORWARD" },
  { MMSI: 563161400, IMO: 9251846, SHIPNAME: "HUNSA BHUM" },
  { MMSI: 563161700, IMO: 9833010, SHIPNAME: "WAN HAI 278" },
  { MMSI: 563161800, IMO: 9360245, SHIPNAME: "MAERSK JIANGYIN" },
  { MMSI: 563161900, IMO: 9436185, SHIPNAME: "MAERSK VICTORIA" },
  { MMSI: 563163300, IMO: 9295945, SHIPNAME: "WAN HAI 627" },
  { MMSI: 563163400, IMO: 9850587, SHIPNAME: "EVER FRANK" },
  { MMSI: 563163500, IMO: 9871426, SHIPNAME: "WAN HAI 296" },
  { MMSI: 563164800, IMO: 9436434, SHIPNAME: "GFS PRECIOUS" },
  { MMSI: 563165200, IMO: 9466984, SHIPNAME: "MAERSK SHEKOU" },
  { MMSI: 563165500, IMO: 9553763, SHIPNAME: "WAN HAI 351" },
  { MMSI: 563166600, IMO: 9306990, SHIPNAME: "ARAYA BHUM" },
  { MMSI: 563166700, IMO: 9799915, SHIPNAME: "CRYSTAL A" },
  { MMSI: 563167500, IMO: 9292149, SHIPNAME: "API BHUM" },
  { MMSI: 563168100, IMO: 9875367, SHIPNAME: "X-PRESS ANTARES" },
  { MMSI: 563168200, IMO: 9875379, SHIPNAME: "X-PRESS ALTAIR" },
  { MMSI: 563168300, IMO: 9862748, SHIPNAME: "X-PRESS SALWEEN" },
  { MMSI: 563168600, IMO: 9410301, SHIPNAME: "CAPITAINE TUPAIA" },
  { MMSI: 563169200, IMO: 9771664, SHIPNAME: "INTERASIA ENGAGE" },
  { MMSI: 563169400, IMO: 9399806, SHIPNAME: "WINDSWEPT" },
  { MMSI: 563169900, IMO: 9771652, SHIPNAME: "INTERASIA ENHANCE" },
  { MMSI: 563170300, IMO: 9555096, SHIPNAME: "WAN HAI A07" },
  { MMSI: 563170500, IMO: 9555228, SHIPNAME: "A GORYU" },
  { MMSI: 563170600, IMO: 9553995, SHIPNAME: "WAN HAI 352" },
  { MMSI: 563171800, IMO: 9930375, SHIPNAME: "WAN HAI 355" },
  { MMSI: 563172200, IMO: 9625413, SHIPNAME: "KOTA LEGIT" },
  { MMSI: 563172300, IMO: 9625401, SHIPNAME: "KOTA LEKAS" },
  { MMSI: 563172700, IMO: 9555101, SHIPNAME: "WAN HAI A08" },
  { MMSI: 563173400, IMO: 9793715, SHIPNAME: "INTERASIA ELEVATE" },
  { MMSI: 563173600, IMO: 9554054, SHIPNAME: "WAN HAI 353" },
  { MMSI: 563174100, IMO: 9930387, SHIPNAME: "WAN HAI 357" },
  { MMSI: 563175600, IMO: 9412866, SHIPNAME: "BRIGHT LAEM CHABANG" },
  { MMSI: 563176600, IMO: 9943267, SHIPNAME: "EVER ACME" },
  { MMSI: 563176800, IMO: 9554078, SHIPNAME: "WAN HAI 356" },
  { MMSI: 563177100, IMO: 9555230, SHIPNAME: "CAT LAI EXPRESS" },
  { MMSI: 563178400, IMO: 9963736, SHIPNAME: "CNC SULAWESI" },
  { MMSI: 563178500, IMO: 9362310, SHIPNAME: "KOTA NAZAR" },
  { MMSI: 563178900, IMO: 9784647, SHIPNAME: "CNC SERVAL" },
  { MMSI: 563179100, IMO: 9937476, SHIPNAME: "EA GANNET" },
  { MMSI: 563180800, IMO: 9554080, SHIPNAME: "WAN HAI 358" },
  { MMSI: 563181000, IMO: 9236561, SHIPNAME: "RACHA BHUM" },
  { MMSI: 563181400, IMO: 9935088, SHIPNAME: "WAN HAI A01" },
  { MMSI: 563181600, IMO: 9930399, SHIPNAME: "WAN HAI 360" },
  { MMSI: 563182200, IMO: 9555113, SHIPNAME: "WAN HAI A09" },
  { MMSI: 563182400, IMO: 9554092, SHIPNAME: "WAN HAI 359" },
  { MMSI: 563182800, IMO: 9937488, SHIPNAME: "EA NODDY" },
  { MMSI: 563183700, IMO: 9313216, SHIPNAME: "BALTIC PETREL" },
  { MMSI: 563183800, IMO: 9228564, SHIPNAME: "LOBIVIA" },
  { MMSI: 563183900, IMO: 9875381, SHIPNAME: "X-PRESS ANTLIA" },
  { MMSI: 563185700, IMO: 9951355, SHIPNAME: "WAN HAI 331" },
  { MMSI: 563186500, IMO: 9938303, SHIPNAME: "ALS CERES" },
  { MMSI: 563186600, IMO: 9288394, SHIPNAME: "PL GERMANY" },
  { MMSI: 563187400, IMO: 9555125, SHIPNAME: "WAN HAI A10" },
  { MMSI: 563187500, IMO: 9554688, SHIPNAME: "WAN HAI 361" },
  { MMSI: 563187900, IMO: 9935090, SHIPNAME: "WAN HAI A02" },
  { MMSI: 563188500, IMO: 9550357, SHIPNAME: "MAERSK WAKAYAMA" },
  { MMSI: 563190100, IMO: 9954450, SHIPNAME: "WAN HAI 365" },
  { MMSI: 563190300, IMO: 9930404, SHIPNAME: "WAN HAI 363" },
  { MMSI: 563190500, IMO: 9935208, SHIPNAME: "EVER MAX" },
  { MMSI: 563190600, IMO: 9555254, SHIPNAME: "VIKING ORCA" },
  { MMSI: 563191100, IMO: 9366500, SHIPNAME: "CFS HORIZON" },
  { MMSI: 563191200, IMO: 9938315, SHIPNAME: "ALS FIDES" },
  { MMSI: 563191300, IMO: 9290115, SHIPNAME: "ONE ATLAS" },
  { MMSI: 563192300, IMO: 9554808, SHIPNAME: "WAN HAI 362" },
  { MMSI: 563192500, IMO: 9412854, SHIPNAME: "SOL RELIANCE" },
  { MMSI: 563192600, IMO: 9936989, SHIPNAME: "TIGER CHENNAI" },
  { MMSI: 563192700, IMO: 9967419, SHIPNAME: "X-PRESS CAPELLA" },
  { MMSI: 563192800, IMO: 9761449, SHIPNAME: "PIYA BHUM" },
  { MMSI: 563192900, IMO: 9937775, SHIPNAME: "NATTHA BHUM" },
  { MMSI: 563193300, IMO: 9234135, SHIPNAME: "CMA CGM GUARANI" },
  { MMSI: 563193500, IMO: 9550369, SHIPNAME: "MAERSK WALVIS BAY" },
  { MMSI: 563193600, IMO: 9945344, SHIPNAME: "CNC PANTHER" },
  { MMSI: 563193900, IMO: 9346005, SHIPNAME: "SSF DREAM" },
  { MMSI: 563194100, IMO: 9329631, SHIPNAME: "SSF DYNAMIC" },
  { MMSI: 563194900, IMO: 9935105, SHIPNAME: "WAN HAI A03" },
  { MMSI: 563195600, IMO: 9951367, SHIPNAME: "WAN HAI 332" },
  { MMSI: 563195900, IMO: 9945356, SHIPNAME: "CNC LEOPARD" },
  { MMSI: 563196200, IMO: 9942287, SHIPNAME: "SINAR BAJO" },
  { MMSI: 563196300, IMO: 9941128, SHIPNAME: "WAN HAI A11" },
  { MMSI: 563197800, IMO: 9954462, SHIPNAME: "WAN HAI 367" },
  { MMSI: 563197900, IMO: 9967433, SHIPNAME: "MH PERSEUS" },
  { MMSI: 563198300, IMO: 9969390, SHIPNAME: "ALS LUNA" },
  { MMSI: 563198500, IMO: 9967421, SHIPNAME: "MH PEGASUS" },
  { MMSI: 563199500, IMO: 9967445, SHIPNAME: "X-PRESS CARINA" },
  { MMSI: 563199600, IMO: 9935210, SHIPNAME: "EVER MACH" },
  { MMSI: 563199800, IMO: 9942299, SHIPNAME: "SINAR BUKITTINGGI" },
  { MMSI: 563201400, IMO: 9967457, SHIPNAME: "X-PRESS CASSIOPEIA" },
  { MMSI: 563201600, IMO: 9935117, SHIPNAME: "WAN HAI A05" },
  { MMSI: 563201800, IMO: 9963748, SHIPNAME: "CNC JAWA" },
  { MMSI: 563202300, IMO: 9967483, SHIPNAME: "EA CHARA" },
  { MMSI: 563202400, IMO: 9936991, SHIPNAME: "X-PRESS AQUARIUS" },
  { MMSI: 563202500, IMO: 9967469, SHIPNAME: "EA CENTAURUS" },
  { MMSI: 563202800, IMO: 9967471, SHIPNAME: "X-PRESS PHOENIX" },
  { MMSI: 563204200, IMO: 9951379, SHIPNAME: "WAN HAI 333" },
  { MMSI: 563204900, IMO: 9941130, SHIPNAME: "WAN HAI A12" },
  { MMSI: 563205000, IMO: 9941142, SHIPNAME: "WAN HAI A13" },
  { MMSI: 563205200, IMO: 9966116, SHIPNAME: "MAERSK YOKOHAMA" },
  { MMSI: 563205400, IMO: 9958523, SHIPNAME: "CUL QINGDAO" },
  { MMSI: 563205700, IMO: 9954474, SHIPNAME: "WAN HAI 368" },
  { MMSI: 563207600, IMO: 9943073, SHIPNAME: "MAERSK OLYMPIC" },
  { MMSI: 563209300, IMO: 9967524, SHIPNAME: "X-PRESS PISCES" },
  { MMSI: 563209500, IMO: 9964596, SHIPNAME: "CUL SHEKOU" },
  { MMSI: 563210600, IMO: 0, SHIPNAME: "WAN HAI A06" },
  { MMSI: 563211100, IMO: 9951381, SHIPNAME: "WAN HAI 335" },
  { MMSI: 563211300, IMO: 9964601, SHIPNAME: "CUL HOCHIMINH" },
  { MMSI: 563213800, IMO: 9950765, SHIPNAME: "EVER CHAMP" },
  { MMSI: 563214600, IMO: 9675822, SHIPNAME: "NPDL CALIFORNIA" },
  { MMSI: 563214700, IMO: 9675808, SHIPNAME: "NPDL TAHITI" },
  { MMSI: 563217100, IMO: 9954486, SHIPNAME: "WAN HAI 369" },
  { MMSI: 563217600, IMO: 9941142, SHIPNAME: "WAN HAI A13" },
  { MMSI: 563231000, IMO: 9632014, SHIPNAME: "APL MERLION" },
  { MMSI: 563234000, IMO: 9632026, SHIPNAME: "APL FULLERTON" },
  { MMSI: 563247000, IMO: 9632038, SHIPNAME: "APL ESPLANADE" },
  { MMSI: 563253000, IMO: 9681273, SHIPNAME: "KOTA SEMPENA" },
  { MMSI: 563265000, IMO: 9628348, SHIPNAME: "KOTA LESTARI" },
  { MMSI: 563266000, IMO: 9234109, SHIPNAME: "APL CAIRO" },
  { MMSI: 563267000, IMO: 9628336, SHIPNAME: "KOTA LOCENG" },
  { MMSI: 563313000, IMO: 9224506, SHIPNAME: "WAN HAI 611" },
  { MMSI: 563361000, IMO: 9638977, SHIPNAME: "KOTA CEPAT" },
  { MMSI: 563362000, IMO: 9638965, SHIPNAME: "KOTA CEMPAKA" },
  { MMSI: 563377000, IMO: 9665592, SHIPNAME: "NORFOLK EXPRESS" },
  { MMSI: 563379000, IMO: 9360556, SHIPNAME: "X-PRESS MEGHNA" },
  { MMSI: 563382000, IMO: 9294848, SHIPNAME: "WAN HAI 501" },
  { MMSI: 563400000, IMO: 9116589, SHIPNAME: "EVER UNITED" },
  { MMSI: 563403000, IMO: 9694402, SHIPNAME: "MERIDIAN" },
  { MMSI: 563433000, IMO: 9222118, SHIPNAME: "MAERSK NORTHWOOD" },
  { MMSI: 563455000, IMO: 9355343, SHIPNAME: "MAERSK BULAN" },
  { MMSI: 563458000, IMO: 9215907, SHIPNAME: "MAERSK NESTON" },
  { MMSI: 563460000, IMO: 9215878, SHIPNAME: "MAERSK NEWCASTLE" },
  { MMSI: 563471000, IMO: 9665671, SHIPNAME: "CLEMENS SCHULTE" },
  { MMSI: 563479000, IMO: 9665669, SHIPNAME: "CHRISTA SCHULTE" },
  { MMSI: 563485000, IMO: 9248681, SHIPNAME: "WAN HAI 311" },
  { MMSI: 563487000, IMO: 9665683, SHIPNAME: "CARL SCHULTE" },
  { MMSI: 563491000, IMO: 9665657, SHIPNAME: "CHARLOTTE SCHULTE" },
  { MMSI: 563498000, IMO: 9234111, SHIPNAME: "APL JEDDAH" },
  { MMSI: 563508000, IMO: 9394894, SHIPNAME: "MAERSK BRATAN" },
  { MMSI: 563514000, IMO: 9394882, SHIPNAME: "MAERSK BOGOR" },
  { MMSI: 563538000, IMO: 9294161, SHIPNAME: "MAERSK JALAN" },
  { MMSI: 563545000, IMO: 9694426, SHIPNAME: "MIEKE SCHULTE" },
  { MMSI: 563549000, IMO: 9694438, SHIPNAME: "MAJESTIC" },
  { MMSI: 563587000, IMO: 9412488, SHIPNAME: "YERUPAJA" },
  { MMSI: 563589000, IMO: 9482938, SHIPNAME: "NYK FUJI" },
  { MMSI: 563625000, IMO: 9296286, SHIPNAME: "KOTA RAJIN" },
  { MMSI: 563675000, IMO: 9439711, SHIPNAME: "INTERASIA CATALYST" },
  { MMSI: 563677000, IMO: 9362308, SHIPNAME: "KOTA NALURI" },
  { MMSI: 563726000, IMO: 9218662, SHIPNAME: "APL SCOTLAND" },
  { MMSI: 563750000, IMO: 9182631, SHIPNAME: "PSA SUSTAINABILITY" },
  { MMSI: 563754000, IMO: 9218674, SHIPNAME: "APL HOLLAND" },
  { MMSI: 563757000, IMO: 9305300, SHIPNAME: "MAERSK UTAH" },
  { MMSI: 563758000, IMO: 9416991, SHIPNAME: "NYK RUMINA" },
  { MMSI: 563808000, IMO: 9294850, SHIPNAME: "WAN HAI 502" },
  { MMSI: 563812000, IMO: 9294862, SHIPNAME: "WAN HAI 503" },
  { MMSI: 563839000, IMO: 9348912, SHIPNAME: "X-PRESS KAILASH" },
  { MMSI: 563841000, IMO: 9460071, SHIPNAME: "ISARA BHUM" },
  { MMSI: 563842000, IMO: 9348924, SHIPNAME: "X-PRESS KARAKORAM" },
  { MMSI: 563886000, IMO: 9182033, SHIPNAME: "KOTA AZAM" },
  { MMSI: 563887000, IMO: 9175602, SHIPNAME: "KOTA ANGGUN" },
  { MMSI: 563916000, IMO: 9296298, SHIPNAME: "KOTA RANCAK" },
  { MMSI: 563955000, IMO: 9281346, SHIPNAME: "KOTA TAMPAN" },
  { MMSI: 563961000, IMO: 9351050, SHIPNAME: "INTERASIA HORIZON" },
  { MMSI: 563982000, IMO: 9604134, SHIPNAME: "EVER LIVELY" },
  { MMSI: 564029000, IMO: 9112686, SHIPNAME: "CHANA BHUM" },
  { MMSI: 564030000, IMO: 9112698, SHIPNAME: "DANU BHUM" },
  { MMSI: 564050000, IMO: 9412476, SHIPNAME: "ORINOCO" },
  { MMSI: 564058000, IMO: 9244972, SHIPNAME: "KOTA HIDAYAH" },
  { MMSI: 564169000, IMO: 9143453, SHIPNAME: "M.V KITI BHUM" },
  { MMSI: 564226000, IMO: 9145281, SHIPNAME: "ORA BHUM" },
  { MMSI: 564241000, IMO: 9143465, SHIPNAME: "LILA BHUM" },
  { MMSI: 564246000, IMO: 9240330, SHIPNAME: "SATTHA BHUM" },
  { MMSI: 564262000, IMO: 9399117, SHIPNAME: "X-PRESS HOOGLY" },
  { MMSI: 564264000, IMO: 9681285, SHIPNAME: "KOTA SETIA" },
  { MMSI: 564305000, IMO: 9525285, SHIPNAME: "MAERSK CONAKRY" },
  { MMSI: 564306000, IMO: 9525297, SHIPNAME: "MAERSK COTONOU" },
  { MMSI: 564316000, IMO: 9145293, SHIPNAME: "PIRA BHUM" },
  { MMSI: 564341000, IMO: 9240469, SHIPNAME: "NITHI BHUM" },
  { MMSI: 564345000, IMO: 9629067, SHIPNAME: "EVER LUCENT" },
  { MMSI: 564354000, IMO: 9240457, SHIPNAME: "METHI BHUM" },
  { MMSI: 564360000, IMO: 9525302, SHIPNAME: "MAERSK CALABAR" },
  { MMSI: 564391000, IMO: 9153070, SHIPNAME: "ALS SUMIRE" },
  { MMSI: 564398000, IMO: 9402029, SHIPNAME: "MAERSK BATUR" },
  { MMSI: 564399000, IMO: 9409352, SHIPNAME: "MAERSK BRANI" },
  { MMSI: 564440000, IMO: 9238167, SHIPNAME: "INTERASIA MOTIVATION" },
  { MMSI: 564442000, IMO: 9196955, SHIPNAME: "EVER ULYSSES" },
  { MMSI: 564519000, IMO: 9167423, SHIPNAME: "KOTA RAJA" },
  { MMSI: 564524000, IMO: 9238179, SHIPNAME: "WAN HAI 303" },
  { MMSI: 564559000, IMO: 9167435, SHIPNAME: "KOTA RATU" },
  { MMSI: 564588000, IMO: 9167447, SHIPNAME: "KOTA RATNA" },
  { MMSI: 564599000, IMO: 9167459, SHIPNAME: "KOTA RUKUN" },
  { MMSI: 564619000, IMO: 9234123, SHIPNAME: "APL PUSAN" },
  { MMSI: 564622000, IMO: 9238636, SHIPNAME: "KOTA HARUM" },
  { MMSI: 564639000, IMO: 9645451, SHIPNAME: "KOTA SABAS" },
  { MMSI: 564640000, IMO: 9238181, SHIPNAME: "WAN HAI 305" },
  { MMSI: 564726000, IMO: 9694579, SHIPNAME: "MAERSK DANUBE" },
  { MMSI: 564757000, IMO: 9681314, SHIPNAME: "KOTA SINGA" },
  { MMSI: 564768000, IMO: 9237084, SHIPNAME: "WAN HAI 306" },
  { MMSI: 564796000, IMO: 9681326, SHIPNAME: "KOTA SURIA" },
  { MMSI: 564800000, IMO: 9251157, SHIPNAME: "KOTA TENAGA" },
  { MMSI: 564820000, IMO: 9220304, SHIPNAME: "SINAR BANGKA" },
  { MMSI: 564822000, IMO: 9411379, SHIPNAME: "MAERSK VARNA" },
  { MMSI: 564837000, IMO: 9411381, SHIPNAME: "MAERSK VALLVIK" },
  { MMSI: 564868000, IMO: 9237096, SHIPNAME: "WAN HAI 307" },
  { MMSI: 564880000, IMO: 9238612, SHIPNAME: "KOTA HALUS" },
  { MMSI: 564908000, IMO: 9238624, SHIPNAME: "KOTA HAPAS" },
  { MMSI: 564909000, IMO: 9238600, SHIPNAME: "KOTA HAKIM" },
  { MMSI: 564938000, IMO: 9387437, SHIPNAME: "NYK ISABEL" },
  { MMSI: 564979000, IMO: 9411367, SHIPNAME: "MAERSK VISBY" },
  { MMSI: 564995000, IMO: 9196967, SHIPNAME: "EVER UNICORN" },
  { MMSI: 565004000, IMO: 9294874, SHIPNAME: "WAN HAI 505" },
  { MMSI: 565005000, IMO: 9294886, SHIPNAME: "WAN HAI 506" },
  { MMSI: 565021000, IMO: 9645463, SHIPNAME: "KOTA SAHABAT" },
  { MMSI: 565032000, IMO: 9248693, SHIPNAME: "WAN HAI 312" },
  { MMSI: 565050000, IMO: 9296339, SHIPNAME: "KOTA RIA" },
  { MMSI: 565110000, IMO: 9645475, SHIPNAME: "KOTA SALAM" },
  { MMSI: 565127000, IMO: 9296341, SHIPNAME: "KOTA RAKYAT" },
  { MMSI: 565128000, IMO: 9158850, SHIPNAME: "WAN HAI 165" },
  { MMSI: 565132000, IMO: 9248708, SHIPNAME: "WAN HAI 313" },
  { MMSI: 565133000, IMO: 9307413, SHIPNAME: "KOTA KAMIL" },
  { MMSI: 565156000, IMO: 9550395, SHIPNAME: "SWAN RIVER BRIDGE" },
  { MMSI: 565196000, IMO: 9694581, SHIPNAME: "MAERSK GANGES" },
  { MMSI: 565213000, IMO: 9301926, SHIPNAME: "LUCIE SCHULTE" },
  { MMSI: 565220000, IMO: 9302695, SHIPNAME: "WAN HAI 315" },
  { MMSI: 565222000, IMO: 9301938, SHIPNAME: "HANNAH SCHULTE" },
  { MMSI: 565253000, IMO: 9193276, SHIPNAME: "GRASMERE MAERSK" },
  { MMSI: 565283000, IMO: 9330915, SHIPNAME: "CAPT.KATTELMANN" },
  { MMSI: 565334000, IMO: 9645487, SHIPNAME: "KOTA SATRIA" },
  { MMSI: 565357000, IMO: 9681235, SHIPNAME: "KOTA SEGAR" },
  { MMSI: 565376000, IMO: 9676709, SHIPNAME: "MATHILDE SCHULTE" },
  { MMSI: 565380000, IMO: 9676711, SHIPNAME: "MAX SCHULTE" },
  { MMSI: 565382000, IMO: 9676723, SHIPNAME: "MELCHIOR SCHULTE" },
  { MMSI: 565385000, IMO: 9676735, SHIPNAME: "MIA SCHULTE" },
  { MMSI: 565402000, IMO: 9340764, SHIPNAME: "MATSON MAUI" },
  { MMSI: 565413000, IMO: 9345910, SHIPNAME: "SOL SINGAPORE" },
  { MMSI: 565417000, IMO: 9483475, SHIPNAME: "KOTA DUNIA" },
  { MMSI: 565425000, IMO: 9342700, SHIPNAME: "WAN HAI 316" },
  { MMSI: 565448000, IMO: 9315226, SHIPNAME: "MAERSK SEMBAWANG" },
  { MMSI: 565449000, IMO: 9315214, SHIPNAME: "MAERSK SERANGOON" },
  { MMSI: 565451000, IMO: 9315238, SHIPNAME: "MAERSK SEBAROK" },
  { MMSI: 565473000, IMO: 9215919, SHIPNAME: "MAERSK NORTHAMPTON" },
  { MMSI: 565475000, IMO: 9215880, SHIPNAME: "MAERSK NEWHAVEN" },
  { MMSI: 565482000, IMO: 9315240, SHIPNAME: "MAERSK SENANG" },
  { MMSI: 565485000, IMO: 9315252, SHIPNAME: "MAERSK SEMAKAU" },
  { MMSI: 565533000, IMO: 9408956, SHIPNAME: "MAERSK VILNIUS" },
  { MMSI: 565536000, IMO: 9326407, SHIPNAME: "WAN HAI 507" },
  { MMSI: 565547000, IMO: 9330927, SHIPNAME: "GFS SAPPHIRE" },
  { MMSI: 565564000, IMO: 9345922, SHIPNAME: "BLPL BLESSING" },
  { MMSI: 565565000, IMO: 9321017, SHIPNAME: "WAN HAI 623" },
  { MMSI: 565567000, IMO: 9326419, SHIPNAME: "WAN HAI 508" },
  { MMSI: 565570000, IMO: 9342499, SHIPNAME: "MAERSK ALTAIR" },
  { MMSI: 565615000, IMO: 9365685, SHIPNAME: "NAWATA BHUM" },
  { MMSI: 565616000, IMO: 9365673, SHIPNAME: "MITRA BHUM" },
  { MMSI: 565626000, IMO: 9366469, SHIPNAME: "ASIATIC NEPTUNE" },
  { MMSI: 565653000, IMO: 9326421, SHIPNAME: "WAN HAI 509" },
  { MMSI: 565661000, IMO: 9342504, SHIPNAME: "MAERSK ANTARES" },
  { MMSI: 565688000, IMO: 9390240, SHIPNAME: "KOTA NAZIM" },
  { MMSI: 565696000, IMO: 9338319, SHIPNAME: "OTANA BHUM" },
  { MMSI: 565697000, IMO: 9338321, SHIPNAME: "PANJA BHUM" },
  { MMSI: 565706000, IMO: 9681247, SHIPNAME: "KOTA SEJARAH" },
  { MMSI: 565730000, IMO: 9359674, SHIPNAME: "KOTA RAHMAT" },
  { MMSI: 565741000, IMO: 9326433, SHIPNAME: "WAN HAI 510" },
  { MMSI: 565747000, IMO: 9342516, SHIPNAME: "MAERSK ALFIRK" },
  { MMSI: 565760000, IMO: 9264207, SHIPNAME: "CAPITAINE WALLIS" },
  { MMSI: 565762000, IMO: 9334674, SHIPNAME: "MAERSK TAURUS" },
  { MMSI: 565771000, IMO: 9681259, SHIPNAME: "KOTA SEJATI" },
  { MMSI: 565772000, IMO: 9408449, SHIPNAME: "KOTA DAHLIA" },
  { MMSI: 565777000, IMO: 9342712, SHIPNAME: "WAN HAI 317" },
  { MMSI: 565780000, IMO: 9483487, SHIPNAME: "KOTA DUTA" },
  { MMSI: 565795000, IMO: 9356830, SHIPNAME: "KOTA NABIL" },
  { MMSI: 565807000, IMO: 9355408, SHIPNAME: "NYK CLARA" },
  { MMSI: 565810000, IMO: 9355331, SHIPNAME: "MAERSK BATAM" },
  { MMSI: 565819000, IMO: 9342528, SHIPNAME: "MAERSK ALGOL" },
  { MMSI: 565825000, IMO: 9334686, SHIPNAME: "MAERSK TUKANG" },
  { MMSI: 565887000, IMO: 9360609, SHIPNAME: "ACX DIAMOND" },
  { MMSI: 565893000, IMO: 9362293, SHIPNAME: "KOTA NAGA" },
  { MMSI: 565904000, IMO: 9392169, SHIPNAME: "KOTA RESTU" },
  { MMSI: 565909000, IMO: 9351024, SHIPNAME: "KOTA LAMBAI" },
  { MMSI: 565936000, IMO: 9343077, SHIPNAME: "MAERSK JABAL" },
  { MMSI: 565944000, IMO: 9351036, SHIPNAME: "KOTA LAMBANG" },
  { MMSI: 565953000, IMO: 9202792, SHIPNAME: "SINAR SOLO" },
  { MMSI: 565963000, IMO: 9355410, SHIPNAME: "NYK DANIELLA" },
  { MMSI: 565967000, IMO: 9401697, SHIPNAME: "MAERSK VIGO" },
  { MMSI: 565970000, IMO: 9435686, SHIPNAME: "LUDWIG SCHULTE" },
  { MMSI: 565971000, IMO: 9392925, SHIPNAME: "MAERSK BUTON" },
  { MMSI: 566002000, IMO: 9487524, SHIPNAME: "NYK FUTAGO" },
  { MMSI: 566055000, IMO: 9525314, SHIPNAME: "MAERSK CAPE COAST" },
  { MMSI: 566056000, IMO: 9525326, SHIPNAME: "MAERSK CAMEROUN" },
  { MMSI: 566093000, IMO: 9525338, SHIPNAME: "MAERSK CHENNAI" },
  { MMSI: 566118000, IMO: 9593696, SHIPNAME: "KOTA NIPAH" },
  { MMSI: 566127000, IMO: 9352432, SHIPNAME: "SINAR BANDUNG" },
  { MMSI: 566145000, IMO: 9525340, SHIPNAME: "MAERSK CONGO" },
  { MMSI: 566158000, IMO: 9561485, SHIPNAME: "MAERSK CUNENE" },
  { MMSI: 566187000, IMO: 9525352, SHIPNAME: "MAERSK CAPE TOWN" },
  { MMSI: 566235000, IMO: 9493250, SHIPNAME: "WAN HAI 271" },
  { MMSI: 566259000, IMO: 9487952, SHIPNAME: "NYK FURANO" },
  { MMSI: 566278000, IMO: 9231470, SHIPNAME: "MAERSK NEWBURY" },
  { MMSI: 566282000, IMO: 9681261, SHIPNAME: "KOTA SELAMAT" },
  { MMSI: 566318000, IMO: 9461867, SHIPNAME: "APL CHONGQING" },
  { MMSI: 566319000, IMO: 9461879, SHIPNAME: "APL GWANGYANG" },
  { MMSI: 566335000, IMO: 9487964, SHIPNAME: "NYK FUSHIMI" },
  { MMSI: 566340000, IMO: 9493262, SHIPNAME: "WAN HAI 272" },
  { MMSI: 566406000, IMO: 9493274, SHIPNAME: "WAN HAI 273" },
  { MMSI: 566407000, IMO: 9461881, SHIPNAME: "APL LE HAVRE" },
  { MMSI: 566409000, IMO: 9462017, SHIPNAME: "APL SOUTHAMPTON" },
  { MMSI: 566410000, IMO: 9462029, SHIPNAME: "APL SALALAH" },
  { MMSI: 566431000, IMO: 9366433, SHIPNAME: "SOL PIONEER" },
  { MMSI: 566446000, IMO: 9704659, SHIPNAME: "GFS GENESIS" },
  { MMSI: 566456000, IMO: 9704661, SHIPNAME: "GFS GISELLE" },
  { MMSI: 566460000, IMO: 9249219, SHIPNAME: "EVER PEARL" },
  { MMSI: 566482000, IMO: 9462031, SHIPNAME: "APL YANG SHAN" },
  { MMSI: 566483000, IMO: 9462043, SHIPNAME: "APL BARCELONA" },
  { MMSI: 566490000, IMO: 9561497, SHIPNAME: "MAERSK CUANZA" },
  { MMSI: 566491000, IMO: 9525493, SHIPNAME: "MAERSK CABINDA" },
  { MMSI: 566492000, IMO: 9525390, SHIPNAME: "MAERSK COLOMBO" },
  { MMSI: 566493000, IMO: 9525405, SHIPNAME: "MAERSK COPENHAGEN" },
  { MMSI: 566510000, IMO: 9455296, SHIPNAME: "WAN HAI 511" },
  { MMSI: 566642000, IMO: 9457622, SHIPNAME: "WAN HAI 512" },
  { MMSI: 566653000, IMO: 9493303, SHIPNAME: "WAN HAI 102" },
  { MMSI: 566673000, IMO: 9595474, SHIPNAME: "EVER LAUREL" },
  { MMSI: 566704000, IMO: 9601302, SHIPNAME: "APL PARIS" },
  { MMSI: 566705000, IMO: 9601314, SHIPNAME: "APL DUBLIN" },
  { MMSI: 566730000, IMO: 9457634, SHIPNAME: "WAN HAI 513" },
  { MMSI: 566732000, IMO: 9595498, SHIPNAME: "EVER LAWFUL" },
  { MMSI: 566755000, IMO: 9526459, SHIPNAME: "MAERSK CADIZ" },
  { MMSI: 566756000, IMO: 9529255, SHIPNAME: "MAERSK CARDIFF" },
  { MMSI: 566760000, IMO: 9596789, SHIPNAME: "WAN HAI 105" },
  { MMSI: 566773000, IMO: 9632741, SHIPNAME: "KOTA MANIS" },
  { MMSI: 566779000, IMO: 9348170, SHIPNAME: "MAERSK INNOSHIMA" },
  { MMSI: 566780000, IMO: 9348168, SHIPNAME: "MAERSK IZMIR" },
  { MMSI: 566781000, IMO: 9348156, SHIPNAME: "MAERSK INVERNESS" },
  { MMSI: 566794000, IMO: 9595503, SHIPNAME: "EVER LEADER" },
  { MMSI: 566796000, IMO: 9631955, SHIPNAME: "APL TEMASEK" },
  { MMSI: 566797000, IMO: 9597472, SHIPNAME: "APL VANCOUVER" },
  { MMSI: 566828000, IMO: 9457646, SHIPNAME: "WAN HAI 515" },
  { MMSI: 566853000, IMO: 9595515, SHIPNAME: "EVER LEGACY" },
  { MMSI: 566858000, IMO: 9622318, SHIPNAME: "KOTA LEMBAH" },
  { MMSI: 566864000, IMO: 9632765, SHIPNAME: "KOTA MEGAH" },
  { MMSI: 566879000, IMO: 9597484, SHIPNAME: "APL NEW YORK" },
  { MMSI: 566880000, IMO: 9631967, SHIPNAME: "APL LION CITY" },
  { MMSI: 566881000, IMO: 9631979, SHIPNAME: "APL RAFFLES" },
  { MMSI: 566882000, IMO: 9631981, SHIPNAME: "APL CHANGI" },
  { MMSI: 566886000, IMO: 9457658, SHIPNAME: "WAN HAI 516" },
  { MMSI: 566894000, IMO: 9632777, SHIPNAME: "KOTA MACHAN" },
  { MMSI: 566941000, IMO: 9626429, SHIPNAME: "KOTA GADANG" },
  { MMSI: 566942000, IMO: 9457660, SHIPNAME: "WAN HAI 517" },
  { MMSI: 566949000, IMO: 9604093, SHIPNAME: "EVER LEGEND" },
  { MMSI: 566950000, IMO: 9597496, SHIPNAME: "APL BOSTON" },
  { MMSI: 566951000, IMO: 9597501, SHIPNAME: "APL PHOENIX" },
  { MMSI: 566952000, IMO: 9597513, SHIPNAME: "APL SAVANNAH" },
  { MMSI: 566953000, IMO: 9597525, SHIPNAME: "APL COLUMBUS" },
  { MMSI: 566954000, IMO: 9631993, SHIPNAME: "APL VANDA" },
  { MMSI: 566955000, IMO: 9632002, SHIPNAME: "APL SINGAPURA" },
  { MMSI: 566956000, IMO: 9597537, SHIPNAME: "APL HOUSTON" },
  { MMSI: 566957000, IMO: 9597549, SHIPNAME: "APL MIAMI" },
  { MMSI: 566958000, IMO: 9597551, SHIPNAME: "APL CHARLESTON" },
  { MMSI: 566959000, IMO: 9597563, SHIPNAME: "APL SANTIAGO" },
  { MMSI: 566960000, IMO: 9632208, SHIPNAME: "APL DETROIT" },
  { MMSI: 566961000, IMO: 9632210, SHIPNAME: "APL MEXICO CITY" },
  { MMSI: 566970000, IMO: 9604110, SHIPNAME: "EVER LEGION" },
  { MMSI: 566981000, IMO: 9626431, SHIPNAME: "KOTA GANDING" },
  { MMSI: 566999000, IMO: 9385025, SHIPNAME: "WAN HAI 621" },
  { MMSI: 567002370, IMO: 8791497, SHIPNAME: "NP CHANTHABURI" },
  { MMSI: 567002380, IMO: 8791485, SHIPNAME: "NP TRAT" },
  { MMSI: 567002520, IMO: 9571058, SHIPNAME: "NP CHUMPHON" },
  { MMSI: 567072300, IMO: 8577279, SHIPNAME: "HARI BHUM" },
  { MMSI: 567303000, IMO: 9293234, SHIPNAME: "URU BHUM" },
  { MMSI: 567304000, IMO: 9293246, SHIPNAME: "VIRA BHUM" },
  { MMSI: 567318000, IMO: 9308675, SHIPNAME: "XUTRA BHUM" },
  { MMSI: 567477000, IMO: 9491719, SHIPNAME: "SIRI BHUM" },
  { MMSI: 567483000, IMO: 9491721, SHIPNAME: "INTRA BHUM" },
  { MMSI: 567571000, IMO: 9796274, SHIPNAME: "JARU BHUM" },
  { MMSI: 567574000, IMO: 9796286, SHIPNAME: "KHUNA BHUM" },
  { MMSI: 567584000, IMO: 9801500, SHIPNAME: "LALIT BHUM" },
  { MMSI: 567589000, IMO: 9801512, SHIPNAME: "MAKHA BHUM" },
  { MMSI: 567609000, IMO: 9936513, SHIPNAME: "ZIM THAILAND" },
  { MMSI: 567614000, IMO: 9936525, SHIPNAME: "ZIM BANGKOK" },
  { MMSI: 572158220, IMO: 9155016, SHIPNAME: "X PRESS KOHIMA" },
  { MMSI: 574000120, IMO: 9352676, SHIPNAME: "GOLDEN OCEAN 26" },
  { MMSI: 574001250, IMO: 9434618, SHIPNAME: "NEW VISION" },
  { MMSI: 574001370, IMO: 9352664, SHIPNAME: "GOLDEN OCEAN 25" },
  { MMSI: 574001430, IMO: 9560883, SHIPNAME: "NASICO SKY" },
  { MMSI: 574001630, IMO: 9503691, SHIPNAME: "HAIAN MIND" },
  { MMSI: 574001980, IMO: 9430583, SHIPNAME: "VINASHIN EXPRESS 01" },
  { MMSI: 574002120, IMO: 9141041, SHIPNAME: "TAN CANG PIONEER" },
  { MMSI: 574002190, IMO: 9138367, SHIPNAME: "VINAFCO 26" },
  { MMSI: 574002240, IMO: 10256136, SHIPNAME: "HAIAN PARK" },
  { MMSI: 574002270, IMO: 9264738, SHIPNAME: "TRUONG HAI STAR 3" },
  { MMSI: 574002540, IMO: 9318905, SHIPNAME: "PHUC KHANH" },
  { MMSI: 574002850, IMO: 9245158, SHIPNAME: "HAIAN TIME" },
  { MMSI: 574002930, IMO: 9334105, SHIPNAME: "TAN CANG GLORY" },
  { MMSI: 574003500, IMO: 9248942, SHIPNAME: "HAIAN BELL" },
  { MMSI: 574003640, IMO: 9441752, SHIPNAME: "GREEN PACIFIC" },
  { MMSI: 574003760, IMO: 9441764, SHIPNAME: "PRIDE PACIFIC" },
  { MMSI: 574003870, IMO: 9318242, SHIPNAME: "TC SYMPHONY" },
  { MMSI: 574004000, IMO: 9128984, SHIPNAME: "VINAFCO 28" },
  { MMSI: 574004050, IMO: 9522788, SHIPNAME: "HAIAN LINK" },
  { MMSI: 574004260, IMO: 9358527, SHIPNAME: "PHUC THAI" },
  { MMSI: 574004280, IMO: 9299331, SHIPNAME: "PREMIER" },
  { MMSI: 574004660, IMO: 9383510, SHIPNAME: "PROGRESS" },
  { MMSI: 574004780, IMO: 9470727, SHIPNAME: "HAIAN VIEW" },
  { MMSI: 574005100, IMO: 9363144, SHIPNAME: "HAIAN EAST" },
  { MMSI: 574005160, IMO: 9357860, SHIPNAME: "HAIAN WEST" },
  { MMSI: 574005410, IMO: 9383522, SHIPNAME: "PRIME" },
  { MMSI: 574005610, IMO: 9444950, SHIPNAME: "ANBIEN BAY" },
  { MMSI: 574005800, IMO: 9444962, SHIPNAME: "HAIAN ROSE" },
  { MMSI: 574005870, IMO: 9522776, SHIPNAME: "TRANSIMEX SUN" },
  { MMSI: 574005940, IMO: 9967043, SHIPNAME: "HAIAN ALFA" },
  { MMSI: 574117000, IMO: 9129017, SHIPNAME: "HOA MAI 16" },
  { MMSI: 574134000, IMO: 9146780, SHIPNAME: "MORNING VINAFCO" },
  { MMSI: 574260000, IMO: 9279214, SHIPNAME: "BIENDONG MARINER" },
  { MMSI: 574269000, IMO: 9131008, SHIPNAME: "ATLANTIC OCEAN" },
  { MMSI: 574387000, IMO: 9228289, SHIPNAME: "BIENDONG STAR" },
  { MMSI: 574409000, IMO: 9181637, SHIPNAME: "FORTUNE NAVIGATOR" },
  { MMSI: 574410000, IMO: 9168520, SHIPNAME: "FORTUNE FREIGHTER" },
  { MMSI: 574453000, IMO: 9167514, SHIPNAME: "VIMC PIONEER" },
  { MMSI: 574480000, IMO: 9167851, SHIPNAME: "PACIFIC EXPRESS" },
  { MMSI: 577111001, IMO: 9089302, SHIPNAME: "LADY-DEFNE" },
  { MMSI: 577127000, IMO: 9387669, SHIPNAME: "ATA 2" },
  { MMSI: 577165000, IMO: 9199787, SHIPNAME: "DUYGU" },
  { MMSI: 577508000, IMO: 9148805, SHIPNAME: "MICRONESIAN PRIDE" },
  { MMSI: 600000000, IMO: 0, SHIPNAME: "SUGANYU09458" },
  { MMSI: 605086020, IMO: 9760615, SHIPNAME: "TAMANRASSET" },
  { MMSI: 605086060, IMO: 9845051, SHIPNAME: "CIRTA" },
  { MMSI: 605086090, IMO: 9845063, SHIPNAME: "DJANET" },
  { MMSI: 605136720, IMO: 9459125, SHIPNAME: "IMEDGHASSEN" },
  { MMSI: 613003732, IMO: 8967149, SHIPNAME: "YEKTA 2" },
  { MMSI: 613139804, IMO: 9212515, SHIPNAME: "PSL EAGLE" },
  { MMSI: 613139808, IMO: 0, SHIPNAME: "PSL TIGER" },
  { MMSI: 613426201, IMO: 9121895, SHIPNAME: "AURELIA" },
  { MMSI: 613519203, IMO: 9137844, SHIPNAME: "DELPHINUS" },
  { MMSI: 613699603, IMO: 8921664, SHIPNAME: "HALCYON" },
  { MMSI: 620040000, IMO: 8401523, SHIPNAME: "PROFESSOR B" },
  { MMSI: 620620000, IMO: 9056739, SHIPNAME: "MV OCEAN TRADER" },
  { MMSI: 620724000, IMO: 9141106, SHIPNAME: "TARLAN" },
  { MMSI: 620743000, IMO: 8919788, SHIPNAME: "ANNABELLA" },
  { MMSI: 620999272, IMO: 9180047, SHIPNAME: "RACE I" },
  { MMSI: 620999274, IMO: 9117131, SHIPNAME: "SILK" },
  { MMSI: 621819050, IMO: 9359105, SHIPNAME: "AFRICA SUN" },
  { MMSI: 622110051, IMO: 9236286, SHIPNAME: "PAN GG" },
  { MMSI: 622113175, IMO: 9127538, SHIPNAME: "EGY FUTURE" },
  { MMSI: 622113176, IMO: 9105970, SHIPNAME: "EGY GLORY" },
  { MMSI: 622113177, IMO: 9366483, SHIPNAME: "EGY SKY" },
  { MMSI: 622113180, IMO: 9216107, SHIPNAME: "EGY CROWN" },
  { MMSI: 622121427, IMO: 9208875, SHIPNAME: "WADI ALRAYAN" },
  { MMSI: 636000000, IMO: 9912452, SHIPNAME: "TAI LE" },
  { MMSI: 636009008, IMO: 9979254, SHIPNAME: "OOCL BREMERHAVEN" },
  { MMSI: 636012252, IMO: 9278088, SHIPNAME: "YM WEALTH" },
  { MMSI: 636012253, IMO: 9278090, SHIPNAME: "YM FOUNTAIN" },
  { MMSI: 636012254, IMO: 9294800, SHIPNAME: "YM SUCCESS" },
  { MMSI: 636012794, IMO: 9319143, SHIPNAME: "YM INCREMENT" },
  { MMSI: 636012795, IMO: 9319155, SHIPNAME: "YM INCEPTION" },
  { MMSI: 636012796, IMO: 9319088, SHIPNAME: "YM IMAGE" },
  { MMSI: 636012797, IMO: 9319090, SHIPNAME: "YM INITIATIVE" },
  { MMSI: 636012798, IMO: 9319105, SHIPNAME: "YM INVENTIVE" },
  { MMSI: 636012807, IMO: 9302619, SHIPNAME: "MSC UNITED VIII" },
  { MMSI: 636012808, IMO: 9302621, SHIPNAME: "MSC UTMOST VIII" },
  { MMSI: 636012905, IMO: 9311763, SHIPNAME: "LORRAINE" },
  { MMSI: 636013118, IMO: 9331086, SHIPNAME: "YM INSTRUCTION" },
  { MMSI: 636013119, IMO: 9333993, SHIPNAME: "YM INTERACTION" },
  { MMSI: 636013121, IMO: 9334014, SHIPNAME: "YM IMPROVEMENT" },
  { MMSI: 636013143, IMO: 9318187, SHIPNAME: "MSC CAGLIARI IV" },
  { MMSI: 636013289, IMO: 9256212, SHIPNAME: "COLOMBO" },
  { MMSI: 636013437, IMO: 9278117, SHIPNAME: "DERBY D" },
  { MMSI: 636013521, IMO: 9350018, SHIPNAME: "APL MINNESOTA" },
  { MMSI: 636013522, IMO: 9350020, SHIPNAME: "APL NEW JERSEY" },
  { MMSI: 636013530, IMO: 9256224, SHIPNAME: "SINGAPORE" },
  { MMSI: 636013644, IMO: 9278105, SHIPNAME: "TONGALA" },
  { MMSI: 636013689, IMO: 9337444, SHIPNAME: "MSC UBERTY VIII" },
  { MMSI: 636013690, IMO: 9337456, SHIPNAME: "NILEDUTCH LION" },
  { MMSI: 636013691, IMO: 9337468, SHIPNAME: "YM UPWARD" },
  { MMSI: 636013692, IMO: 9337470, SHIPNAME: "YM UTILITY" },
  { MMSI: 636013693, IMO: 9337482, SHIPNAME: "YM UNIFORM" },
  { MMSI: 636013698, IMO: 9353280, SHIPNAME: "YM EFFICIENCY" },
  { MMSI: 636013699, IMO: 9353292, SHIPNAME: "YM ETERNITY" },
  { MMSI: 636013898, IMO: 9231482, SHIPNAME: "EVRIDIKI G" },
  { MMSI: 636014018, IMO: 9353280, SHIPNAME: "M -FJICIENCY" },
  { MMSI: 636014059, IMO: 9386471, SHIPNAME: "KASSIAKOS" },
  { MMSI: 636014168, IMO: 9386483, SHIPNAME: "AITOLIKOS" },
  { MMSI: 636014169, IMO: 9397602, SHIPNAME: "CMA CGM TOPAZ" },
  { MMSI: 636014199, IMO: 9386495, SHIPNAME: "CMA CGM LAPIS" },
  { MMSI: 636014200, IMO: 9397614, SHIPNAME: "IONIKOS" },
  { MMSI: 636014224, IMO: 9398462, SHIPNAME: "TIANJIN" },
  { MMSI: 636014557, IMO: 9438523, SHIPNAME: "YM MANDATE" },
  { MMSI: 636014558, IMO: 9438535, SHIPNAME: "YM MATURITY" },
  { MMSI: 636014603, IMO: 9473626, SHIPNAME: "GSL AFRICA" },
  { MMSI: 636014644, IMO: 9222302, SHIPNAME: "MSC PETRA" },
  { MMSI: 636014699, IMO: 9146314, SHIPNAME: "DIAMANTIS P" },
  { MMSI: 636014740, IMO: 9315850, SHIPNAME: "IRENES REMEDY" },
  { MMSI: 636014890, IMO: 9354648, SHIPNAME: "ATOUT" },
  { MMSI: 636014955, IMO: 9403413, SHIPNAME: "EM SPETSES" },
  { MMSI: 636014956, IMO: 9338967, SHIPNAME: "EM HYDRA" },
  { MMSI: 636014965, IMO: 9454357, SHIPNAME: "HISTORY ELIZABETH" },
  { MMSI: 636014968, IMO: 9236535, SHIPNAME: "MARIANETTA" },
  { MMSI: 636014971, IMO: 9210098, SHIPNAME: "MSC SINES R" },
  { MMSI: 636014996, IMO: 0, SHIPNAME: "YM MUTUALITY" },
  { MMSI: 636014997, IMO: 9457737, SHIPNAME: "YM MOBILITY" },
  { MMSI: 636015002, IMO: 9472103, SHIPNAME: "ERATO" },
  { MMSI: 636015019, IMO: 9484936, SHIPNAME: "EXPRESS ROME" },
  { MMSI: 636015020, IMO: 9484948, SHIPNAME: "EXPRESS ATHENS" },
  { MMSI: 636015031, IMO: 9210050, SHIPNAME: "MSC STAR R" },
  { MMSI: 636015131, IMO: 9464247, SHIPNAME: "MALIAKOS" },
  { MMSI: 636015181, IMO: 9294824, SHIPNAME: "MSC DURBAN IV" },
  { MMSI: 636015182, IMO: 9484998, SHIPNAME: "YM MILESTONE" },
  { MMSI: 636015183, IMO: 9485007, SHIPNAME: "YM MASCULINITY" },
  { MMSI: 636015233, IMO: 9275397, SHIPNAME: "MSC MANYA" },
  { MMSI: 636015404, IMO: 9256755, SHIPNAME: "METHONI" },
  { MMSI: 636015465, IMO: 9459101, SHIPNAME: "NOBILITY" },
  { MMSI: 636015467, IMO: 9484534, SHIPNAME: "ROSA" },
  { MMSI: 636015480, IMO: 9626558, SHIPNAME: "KESTREL" },
  { MMSI: 636015481, IMO: 9626560, SHIPNAME: "PELICAN" },
  { MMSI: 636015496, IMO: 9231779, SHIPNAME: "MSC MEXICO V" },
  { MMSI: 636015506, IMO: 9465265, SHIPNAME: "MSC VEGA" },
  { MMSI: 636015524, IMO: 9227273, SHIPNAME: "IRENES RESOLVE" },
  { MMSI: 636015604, IMO: 9465277, SHIPNAME: "MSC ALTAIR" },
  { MMSI: 636015646, IMO: 9334351, SHIPNAME: "EM KEA" },
  { MMSI: 636015659, IMO: 9644990, SHIPNAME: "MATE" },
  { MMSI: 636015664, IMO: 9619426, SHIPNAME: "MSC ALTAMIRA" },
  { MMSI: 636015665, IMO: 9619438, SHIPNAME: "MSC ALBANY" },
  { MMSI: 636015666, IMO: 9619440, SHIPNAME: "MSC ANCHORAGE" },
  { MMSI: 636015667, IMO: 9631101, SHIPNAME: "CALI" },
  { MMSI: 636015685, IMO: 9280641, SHIPNAME: "GSL CHRISTEL ELISABE" },
  { MMSI: 636015686, IMO: 9280653, SHIPNAME: "GSL VINIA" },
  { MMSI: 636015743, IMO: 9315379, SHIPNAME: "MSC ARCHIMIDIS" },
  { MMSI: 636015744, IMO: 9315381, SHIPNAME: "AGAMEMNON" },
  { MMSI: 636015754, IMO: 9385611, SHIPNAME: "ARGOLIKOS" },
  { MMSI: 636015775, IMO: 9216092, SHIPNAME: "LUEBECK" },
  { MMSI: 636015779, IMO: 9142942, SHIPNAME: "BIG GEORGE" },
  { MMSI: 636015806, IMO: 9625528, SHIPNAME: "HYUNDAI PRESTIGE" },
  { MMSI: 636015873, IMO: 9233844, SHIPNAME: "NAGOYA TOWER" },
  { MMSI: 636015876, IMO: 9625530, SHIPNAME: "HYUNDAI PREMIUM" },
  { MMSI: 636015899, IMO: 9127540, SHIPNAME: "MSC HIMANSHI" },
  { MMSI: 636015912, IMO: 9532800, SHIPNAME: "ANL GIPPSLAND" },
  { MMSI: 636015930, IMO: 9233832, SHIPNAME: "QINGDAO TOWER" },
  { MMSI: 636015950, IMO: 9625542, SHIPNAME: "HYUNDAI PARAMOUNT" },
  { MMSI: 636015952, IMO: 9260914, SHIPNAME: "ADAMS" },
  { MMSI: 636015975, IMO: 9349605, SHIPNAME: "AGIOS DIMITRIOS" },
  { MMSI: 636015980, IMO: 9627899, SHIPNAME: "TOCONAO" },
  { MMSI: 636016003, IMO: 9625554, SHIPNAME: "HYUNDAI PRIVILEGE" },
  { MMSI: 636016014, IMO: 9166651, SHIPNAME: "MSC ALIX 3" },
  { MMSI: 636016038, IMO: 9637155, SHIPNAME: "HYUNDAI PLATINUM" },
  { MMSI: 636016066, IMO: 9074042, SHIPNAME: "MSC POLARIS" },
  { MMSI: 636016074, IMO: 9629380, SHIPNAME: "TOKYO BAY" },
  { MMSI: 636016106, IMO: 9247546, SHIPNAME: "ENSENADA" },
  { MMSI: 636016118, IMO: 9204477, SHIPNAME: "JOANNA" },
  { MMSI: 636016125, IMO: 9627904, SHIPNAME: "TRAIGUEN" },
  { MMSI: 636016126, IMO: 9629445, SHIPNAME: "CAP ANDREAS" },
  { MMSI: 636016177, IMO: 9627916, SHIPNAME: "TRANCURA" },
  { MMSI: 636016179, IMO: 9706281, SHIPNAME: "BOX ENDEAVOUR" },
  { MMSI: 636016185, IMO: 9629457, SHIPNAME: "CARDIFF" },
  { MMSI: 636016204, IMO: 9280861, SHIPNAME: "MSC PRATITI" },
  { MMSI: 636016231, IMO: 9629469, SHIPNAME: "SWANSEA" },
  { MMSI: 636016242, IMO: 9664885, SHIPNAME: "YM MODESTY" },
  { MMSI: 636016247, IMO: 9631137, SHIPNAME: "LOUISE" },
  { MMSI: 636016305, IMO: 9695016, SHIPNAME: "MSC ALINA" },
  { MMSI: 636016306, IMO: 9695028, SHIPNAME: "MSC SOFIA PAZ" },
  { MMSI: 636016321, IMO: 9627928, SHIPNAME: "TYNDALL" },
  { MMSI: 636016415, IMO: 9210086, SHIPNAME: "MSC CAPUCINE R" },
  { MMSI: 636016417, IMO: 9210062, SHIPNAME: "MSC GLORY R" },
  { MMSI: 636016423, IMO: 9297876, SHIPNAME: "MSC BHAVYA V" },
  { MMSI: 636016424, IMO: 9297864, SHIPNAME: "MSC ANYA" },
  { MMSI: 636016429, IMO: 9238741, SHIPNAME: "MSC MARGARITA" },
  { MMSI: 636016430, IMO: 9227338, SHIPNAME: "MSC JULIA R." },
  { MMSI: 636016431, IMO: 9227340, SHIPNAME: "MSC VAISHNAVI R." },
  { MMSI: 636016432, IMO: 9244881, SHIPNAME: "MSC ARUSHI R." },
  { MMSI: 636016433, IMO: 9238739, SHIPNAME: "MSC VIDHI" },
  { MMSI: 636016434, IMO: 9227297, SHIPNAME: "MSC ANISHA R." },
  { MMSI: 636016435, IMO: 9227302, SHIPNAME: "MSC KATYA R." },
  { MMSI: 636016436, IMO: 9227314, SHIPNAME: "MSC ZLATA R" },
  { MMSI: 636016437, IMO: 9227326, SHIPNAME: "MSC VIDISHA R." },
  { MMSI: 636016457, IMO: 9154206, SHIPNAME: "MSC ISHYKA" },
  { MMSI: 636016458, IMO: 9154220, SHIPNAME: "MSC CAPRI" },
  { MMSI: 636016476, IMO: 9141297, SHIPNAME: "MSC ROCHELLE" },
  { MMSI: 636016483, IMO: 9222467, SHIPNAME: "AREOPOLIS" },
  { MMSI: 636016492, IMO: 9147071, SHIPNAME: "MSC SAO PAULO V" },
  { MMSI: 636016493, IMO: 9238741, SHIPNAME: "MSC MARGARITA" },
  { MMSI: 636016502, IMO: 9733820, SHIPNAME: "BOX ENDURANCE" },
  { MMSI: 636016574, IMO: 9162277, SHIPNAME: "MSC SKY II" },
  { MMSI: 636016619, IMO: 9678630, SHIPNAME: "X-PRESS NUPTSE" },
  { MMSI: 636016647, IMO: 9344679, SHIPNAME: "GALEN" },
  { MMSI: 636016649, IMO: 9347255, SHIPNAME: "GARWOOD" },
  { MMSI: 636016672, IMO: 9267649, SHIPNAME: "MSC PHOENIX" },
  { MMSI: 636016677, IMO: 9678642, SHIPNAME: "X-PRESS LHOTSE" },
  { MMSI: 636016702, IMO: 9373917, SHIPNAME: "CONTSHIP OAK" },
  { MMSI: 636016703, IMO: 9496604, SHIPNAME: "YM ENLIGHTENMENT" },
  { MMSI: 636016704, IMO: 9496616, SHIPNAME: "YM EXCELLENCE" },
  { MMSI: 636016705, IMO: 9496628, SHIPNAME: "YM EXPRESS" },
  { MMSI: 636016770, IMO: 9315824, SHIPNAME: "ODYSSEUS" },
  { MMSI: 636016775, IMO: 9194866, SHIPNAME: "MSC CHERYL 3" },
  { MMSI: 636016805, IMO: 9149328, SHIPNAME: "MSC NADRIELY" },
  { MMSI: 636016814, IMO: 9123221, SHIPNAME: "MSC ELSA3" },
  { MMSI: 636016850, IMO: 9306184, SHIPNAME: "ESL SANA" },
  { MMSI: 636016851, IMO: 9275036, SHIPNAME: "MSC JULIANA III" },
  { MMSI: 636016852, IMO: 9309277, SHIPNAME: "MONA LISA" },
  { MMSI: 636016854, IMO: 9138276, SHIPNAME: "PANTHER" },
  { MMSI: 636016855, IMO: 9306160, SHIPNAME: "ESL WAFA" },
  { MMSI: 636016856, IMO: 9306287, SHIPNAME: "ESL DANA" },
  { MMSI: 636016858, IMO: 9465241, SHIPNAME: "MSC CRISTINA" },
  { MMSI: 636016895, IMO: 9303754, SHIPNAME: "ALE" },
  { MMSI: 636016940, IMO: 9238789, SHIPNAME: "MSC MANU" },
  { MMSI: 636016943, IMO: 9235402, SHIPNAME: "MSC RADIANT III" },
  { MMSI: 636016973, IMO: 9408774, SHIPNAME: "MSC OLIA" },
  { MMSI: 636016975, IMO: 9395551, SHIPNAME: "SEABOARD PATRIOT" },
  { MMSI: 636016980, IMO: 9189500, SHIPNAME: "IAN H" },
  { MMSI: 636016983, IMO: 9710220, SHIPNAME: "ZIM NORFOLK" },
  { MMSI: 636016985, IMO: 9710232, SHIPNAME: "ZIM XIAMEN" },
  { MMSI: 636016986, IMO: 9710244, SHIPNAME: "ANTHEA Y" },
  { MMSI: 636016999, IMO: 9293179, SHIPNAME: "MSC MIRELLA R" },
  { MMSI: 636017000, IMO: 9293167, SHIPNAME: "MSC RONIT R" },
  { MMSI: 636017006, IMO: 9038907, SHIPNAME: "MSC ROBERTA V" },
  { MMSI: 636017007, IMO: 9124512, SHIPNAME: "MSC CARLA III" },
  { MMSI: 636017016, IMO: 9253002, SHIPNAME: "ANGELIKI" },
  { MMSI: 636017022, IMO: 9224049, SHIPNAME: "MSC SHIRLEY" },
  { MMSI: 636017023, IMO: 9224051, SHIPNAME: "MSC RHIANNON" },
  { MMSI: 636017038, IMO: 9429314, SHIPNAME: "SPECTRUM N" },
  { MMSI: 636017045, IMO: 9301457, SHIPNAME: "HSL NIKE" },
  { MMSI: 636017055, IMO: 9062996, SHIPNAME: "MSC MILA 3" },
  { MMSI: 636017056, IMO: 9062984, SHIPNAME: "MSC HINA" },
  { MMSI: 636017102, IMO: 9732591, SHIPNAME: "MAERSK SARAT" },
  { MMSI: 636017103, IMO: 9740457, SHIPNAME: "MAERSK SKARSTIND" },
  { MMSI: 636017104, IMO: 9728253, SHIPNAME: "MAERSK SHIVLING" },
  { MMSI: 636017116, IMO: 9134490, SHIPNAME: "MSC JANIS 3" },
  { MMSI: 636017153, IMO: 9383261, SHIPNAME: "SEALAND MANZANILLO" },
  { MMSI: 636017154, IMO: 9383235, SHIPNAME: "SEALAND LOS ANGELES" },
  { MMSI: 636017155, IMO: 9383247, SHIPNAME: "SEALAND PHILADELPHIA" },
  { MMSI: 636017164, IMO: 9226815, SHIPNAME: "ARKADIA" },
  { MMSI: 636017173, IMO: 9229300, SHIPNAME: "MSC CATHERINE VI" },
  { MMSI: 636017175, IMO: 9327786, SHIPNAME: "ALS KRONOS" },
  { MMSI: 636017191, IMO: 9275373, SHIPNAME: "MSC GRENADA III" },
  { MMSI: 636017218, IMO: 9304758, SHIPNAME: "CHIQUITA EXPRESS" },
  { MMSI: 636017219, IMO: 9304760, SHIPNAME: "CHIQUITA TRADER" },
  { MMSI: 636017239, IMO: 9316359, SHIPNAME: "MSC SPRING III" },
  { MMSI: 636017244, IMO: 9232656, SHIPNAME: "MSC KATALIN II" },
  { MMSI: 636017245, IMO: 9139086, SHIPNAME: "MSC POLO II" },
  { MMSI: 636017306, IMO: 9229312, SHIPNAME: "MSC LEO VI" },
  { MMSI: 636017309, IMO: 9246310, SHIPNAME: "GROTON" },
  { MMSI: 636017346, IMO: 9036002, SHIPNAME: "MSC SHAULA" },
  { MMSI: 636017352, IMO: 9399777, SHIPNAME: "ROBIN 5" },
  { MMSI: 636017374, IMO: 9347786, SHIPNAME: "CONTSHIP MAX" },
  { MMSI: 636017404, IMO: 9164263, SHIPNAME: "TYGRA" },
  { MMSI: 636017405, IMO: 9138305, SHIPNAME: "MYDJIMEI" },
  { MMSI: 636017408, IMO: 9647473, SHIPNAME: "MSC VENICE" },
  { MMSI: 636017433, IMO: 9755933, SHIPNAME: "MSC DIANA" },
  { MMSI: 636017434, IMO: 9755945, SHIPNAME: "MSC INGY" },
  { MMSI: 636017435, IMO: 9755957, SHIPNAME: "MSC ELOANE" },
  { MMSI: 636017440, IMO: 9412787, SHIPNAME: "CALA PAGURO" },
  { MMSI: 636017505, IMO: 9330501, SHIPNAME: "LIDIA" },
  { MMSI: 636017506, IMO: 9762326, SHIPNAME: "MSC JADE" },
  { MMSI: 636017514, IMO: 9426817, SHIPNAME: "MSC CORNELIA" },
  { MMSI: 636017516, IMO: 9762338, SHIPNAME: "MSC MIRJA" },
  { MMSI: 636017520, IMO: 9275048, SHIPNAME: "GARDINER" },
  { MMSI: 636017534, IMO: 9235608, SHIPNAME: "MSC JUANITA F" },
  { MMSI: 636017537, IMO: 9606326, SHIPNAME: "MSC ISTANBUL" },
  { MMSI: 636017548, IMO: 9057173, SHIPNAME: "MSC AGATA II" },
  { MMSI: 636017569, IMO: 9754953, SHIPNAME: "MSC DITTE" },
  { MMSI: 636017577, IMO: 9754965, SHIPNAME: "MSC REEF" },
  { MMSI: 636017581, IMO: 9169122, SHIPNAME: "MSC ANNICK" },
  { MMSI: 636017582, IMO: 9116369, SHIPNAME: "MSC SENA" },
  { MMSI: 636017605, IMO: 9301550, SHIPNAME: "MSC CLEMENTINA F" },
  { MMSI: 636017610, IMO: 9491836, SHIPNAME: "CITRUS VITA BRASIL" },
  { MMSI: 636017643, IMO: 9085522, SHIPNAME: "KURE" },
  { MMSI: 636017683, IMO: 9430868, SHIPNAME: "PERITO MORENO" },
  { MMSI: 636017684, IMO: 9767376, SHIPNAME: "MSC MIRJAM" },
  { MMSI: 636017685, IMO: 9767388, SHIPNAME: "MSC RIFAYA" },
  { MMSI: 636017686, IMO: 9767390, SHIPNAME: "MSC LEANNE" },
  { MMSI: 636017724, IMO: 9772228, SHIPNAME: "KMTC BANGKOK" },
  { MMSI: 636017726, IMO: 9755191, SHIPNAME: "MSC ERICA" },
  { MMSI: 636017727, IMO: 9777204, SHIPNAME: "MSC ANNA" },
  { MMSI: 636017730, IMO: 9357080, SHIPNAME: "CHIQUITA VENTURE" },
  { MMSI: 636017731, IMO: 9357119, SHIPNAME: "CHIQUITA PROGRESS" },
  { MMSI: 636017749, IMO: 9767388, SHIPNAME: "MSC RIFAYA" },
  { MMSI: 636017768, IMO: 9772230, SHIPNAME: "KMTC LAEM CHABANG" },
  { MMSI: 636017802, IMO: 9509140, SHIPNAME: "GSL ELEFTHERIA" },
  { MMSI: 636017803, IMO: 9509152, SHIPNAME: "GSL MELINA" },
  { MMSI: 636017852, IMO: 9388338, SHIPNAME: "CONTSHIP RAY" },
  { MMSI: 636017867, IMO: 9762340, SHIPNAME: "MSC TINA" },
  { MMSI: 636017878, IMO: 9127021, SHIPNAME: "MSC IZMIR F" },
  { MMSI: 636017882, IMO: 9777216, SHIPNAME: "MSC VIVIANA" },
  { MMSI: 636017894, IMO: 9347815, SHIPNAME: "CONTSHIP SUN" },
  { MMSI: 636017907, IMO: 9509164, SHIPNAME: "GSL ALICE" },
  { MMSI: 636017916, IMO: 9502908, SHIPNAME: "MSC TOPAZ" },
  { MMSI: 636017921, IMO: 9503732, SHIPNAME: "MSC PERLE" },
  { MMSI: 636017929, IMO: 9275024, SHIPNAME: "MSC SHEFFIELD III" },
  { MMSI: 636017944, IMO: 9186390, SHIPNAME: "QUEENSLAND" },
  { MMSI: 636017958, IMO: 9178290, SHIPNAME: "TB FENGZE" },
  { MMSI: 636017971, IMO: 9275062, SHIPNAME: "HALLEY" },
  { MMSI: 636017993, IMO: 9419773, SHIPNAME: "BOMAR PRAIA" },
  { MMSI: 636017994, IMO: 9419785, SHIPNAME: "TRF PARTICI" },
  { MMSI: 636017995, IMO: 9419797, SHIPNAME: "PESCARA" },
  { MMSI: 636018000, IMO: 9395616, SHIPNAME: "CONTSHIP TOP" },
  { MMSI: 636018001, IMO: 9379026, SHIPNAME: "CONTSHIP UNO" },
  { MMSI: 636018018, IMO: 9290476, SHIPNAME: "MAERSK KOWLOON" },
  { MMSI: 636018026, IMO: 9232632, SHIPNAME: "MSC ALICE II" },
  { MMSI: 636018028, IMO: 9408865, SHIPNAME: "ATHENIAN" },
  { MMSI: 636018035, IMO: 9399753, SHIPNAME: "AS CASPRIA" },
  { MMSI: 636018036, IMO: 9392561, SHIPNAME: "MSC SHANVI III" },
  { MMSI: 636018037, IMO: 9406738, SHIPNAME: "ATHOS" },
  { MMSI: 636018038, IMO: 9408877, SHIPNAME: "NAVIOS CONSTELLATION" },
  { MMSI: 636018056, IMO: 9433145, SHIPNAME: "FS IPANEMA" },
  { MMSI: 636018057, IMO: 9461465, SHIPNAME: "LOTUS A" },
  { MMSI: 636018058, IMO: 9461477, SHIPNAME: "CYPRESS" },
  { MMSI: 636018059, IMO: 9461489, SHIPNAME: "KOI" },
  { MMSI: 636018068, IMO: 9756729, SHIPNAME: "MSC AVNI" },
  { MMSI: 636018069, IMO: 9408853, SHIPNAME: "NAVIOS UNISON" },
  { MMSI: 636018070, IMO: 9408841, SHIPNAME: "ARISTOMENIS" },
  { MMSI: 636018071, IMO: 9235581, SHIPNAME: "MSC ADITI" },
  { MMSI: 636018075, IMO: 9304447, SHIPNAME: "MSC ROMA" },
  { MMSI: 636018079, IMO: 9304459, SHIPNAME: "MSC LISBON" },
  { MMSI: 636018081, IMO: 9152923, SHIPNAME: "BELITAKI" },
  { MMSI: 636018097, IMO: 9401051, SHIPNAME: "SM MUMBAI" },
  { MMSI: 636018102, IMO: 9318060, SHIPNAME: "BELITA" },
  { MMSI: 636018105, IMO: 9477309, SHIPNAME: "MSC BEIRA IV" },
  { MMSI: 636018106, IMO: 9756731, SHIPNAME: "MSC CARLOTTA" },
  { MMSI: 636018167, IMO: 9492751, SHIPNAME: "CONTSHIP ECO" },
  { MMSI: 636018185, IMO: 9395599, SHIPNAME: "CONTSHIP VOW" },
  { MMSI: 636018189, IMO: 9337676, SHIPNAME: "MSC ROSHNEY V" },
  { MMSI: 636018191, IMO: 9299020, SHIPNAME: "MSC PORTO III" },
  { MMSI: 636018192, IMO: 9302437, SHIPNAME: "PORTSMOUTH" },
  { MMSI: 636018193, IMO: 9311830, SHIPNAME: "SC MONTREAL" },
  { MMSI: 636018194, IMO: 9311842, SHIPNAME: "SC MONTANA" },
  { MMSI: 636018204, IMO: 9232773, SHIPNAME: "MSC GIADA III" },
  { MMSI: 636018222, IMO: 9337652, SHIPNAME: "DELPHINUS C" },
  { MMSI: 636018223, IMO: 9406764, SHIPNAME: "MARIA C" },
  { MMSI: 636018224, IMO: 9360623, SHIPNAME: "ACX PEARL" },
  { MMSI: 636018237, IMO: 9305465, SHIPNAME: "KOTA MANZANILLO" },
  { MMSI: 636018240, IMO: 9231250, SHIPNAME: "MSC DENMARK VI" },
  { MMSI: 636018242, IMO: 9231262, SHIPNAME: "MSC SWEDEN VI" },
  { MMSI: 636018262, IMO: 9198264, SHIPNAME: "YM PLUM" },
  { MMSI: 636018263, IMO: 9198276, SHIPNAME: "YM ORCHID" },
  { MMSI: 636018264, IMO: 0, SHIPNAME: "YM COSMOS" },
  { MMSI: 636018268, IMO: 9135638, SHIPNAME: "MSC JEANNE" },
  { MMSI: 636018269, IMO: 9135638, SHIPNAME: "MSC JEANNE" },
  { MMSI: 636018276, IMO: 9770763, SHIPNAME: "MSC GAYANE" },
  { MMSI: 636018311, IMO: 9404209, SHIPNAME: "NAVIOS TEMPO" },
  { MMSI: 636018318, IMO: 9161297, SHIPNAME: "MSC PAOLA" },
  { MMSI: 636018319, IMO: 9154191, SHIPNAME: "MSC POSITANO" },
  { MMSI: 636018320, IMO: 9181675, SHIPNAME: "MSC SARAH V" },
  { MMSI: 636018321, IMO: 9169055, SHIPNAME: "MSC ADRIANA II" },
  { MMSI: 636018322, IMO: 9162631, SHIPNAME: "MSC ASLI" },
  { MMSI: 636018323, IMO: 9169043, SHIPNAME: "MSC CAITLIN" },
  { MMSI: 636018324, IMO: 9169029, SHIPNAME: "MSC EDITH II" },
  { MMSI: 636018335, IMO: 9161297, SHIPNAME: "MSC PAOLA" },
  { MMSI: 636018346, IMO: 9797187, SHIPNAME: "POLAR CHILE" },
  { MMSI: 636018364, IMO: 9345960, SHIPNAME: "NAVIOS CHRYSALIS" },
  { MMSI: 636018378, IMO: 9337274, SHIPNAME: "GSL MERCER" },
  { MMSI: 636018382, IMO: 9256377, SHIPNAME: "BIG DOG" },
  { MMSI: 636018385, IMO: 9463035, SHIPNAME: "MSC NATASHA XIII" },
  { MMSI: 636018389, IMO: 9084607, SHIPNAME: "MSC NITA" },
  { MMSI: 636018471, IMO: 9395953, SHIPNAME: "ZIM BALTIMORE" },
  { MMSI: 636018490, IMO: 9242625, SHIPNAME: "MSC VANQUISH II" },
  { MMSI: 636018491, IMO: 9242637, SHIPNAME: "MSC KAYLA" },
  { MMSI: 636018502, IMO: 9337030, SHIPNAME: "AS ALVA" },
  { MMSI: 636018523, IMO: 9797199, SHIPNAME: "POLAR PERU" },
  { MMSI: 636018529, IMO: 9357535, SHIPNAME: "SEOUL GLOW" },
  { MMSI: 636018541, IMO: 9679907, SHIPNAME: "CMA CGM MISSISSIPPI" },
  { MMSI: 636018542, IMO: 9679919, SHIPNAME: "CMA CGM MISSOURI" },
  { MMSI: 636018553, IMO: 9565338, SHIPNAME: "GSL ROSSI" },
  { MMSI: 636018560, IMO: 9383259, SHIPNAME: "CHARM C" },
  { MMSI: 636018561, IMO: 9326835, SHIPNAME: "MSC HUSUM III" },
  { MMSI: 636018562, IMO: 9336165, SHIPNAME: "MSC PALATIUM III" },
  { MMSI: 636018563, IMO: 9431707, SHIPNAME: "NAVIOS DORADO" },
  { MMSI: 636018564, IMO: 9679892, SHIPNAME: "CMA CGM CONGO" },
  { MMSI: 636018571, IMO: 9463047, SHIPNAME: "MSC EMMA" },
  { MMSI: 636018572, IMO: 9463059, SHIPNAME: "MSC ELISA XIII" },
  { MMSI: 636018573, IMO: 9113446, SHIPNAME: "MSC CLAUDIA" },
  { MMSI: 636018593, IMO: 9350317, SHIPNAME: "MSC HERMES" },
  { MMSI: 636018594, IMO: 9374595, SHIPNAME: "MSC ARIA III" },
  { MMSI: 636018597, IMO: 9363405, SHIPNAME: "HAPPY LUCKY" },
  { MMSI: 636018603, IMO: 9316373, SHIPNAME: "MSC TANIA" },
  { MMSI: 636018659, IMO: 9398228, SHIPNAME: "MSC NOA" },
  { MMSI: 636018660, IMO: 9398216, SHIPNAME: "MSC ANTONIA" },
  { MMSI: 636018680, IMO: 9444950, SHIPNAME: "PUTNAM" },
  { MMSI: 636018693, IMO: 9315874, SHIPNAME: "GSL VALERIE" },
  { MMSI: 636018704, IMO: 9683491, SHIPNAME: "CONTSHIP ZEN" },
  { MMSI: 636018712, IMO: 9683477, SHIPNAME: "CONTSHIP CUB" },
  { MMSI: 636018715, IMO: 9377145, SHIPNAME: "BUXMELODY" },
  { MMSI: 636018729, IMO: 9374571, SHIPNAME: "MSC EXPRESS III" },
  { MMSI: 636018732, IMO: 9326823, SHIPNAME: "MSC EMDEN III" },
  { MMSI: 636018739, IMO: 9436458, SHIPNAME: "BACH" },
  { MMSI: 636018744, IMO: 9470973, SHIPNAME: "AS ALEXANDRIA" },
  { MMSI: 636018745, IMO: 9470961, SHIPNAME: "AS ANITA" },
  { MMSI: 636018768, IMO: 9436082, SHIPNAME: "HANSA HOMBURG" },
  { MMSI: 636018769, IMO: 9516739, SHIPNAME: "FITZ ROY" },
  { MMSI: 636018800, IMO: 9085558, SHIPNAME: "KARLSKRONA" },
  { MMSI: 636018807, IMO: 9445576, SHIPNAME: "DEBUSSY" },
  { MMSI: 636018846, IMO: 9516777, SHIPNAME: "ELA" },
  { MMSI: 636018863, IMO: 9498688, SHIPNAME: "PACIFIC QINGDAO" },
  { MMSI: 636018904, IMO: 9445588, SHIPNAME: "NAVIOS MIAMI" },
  { MMSI: 636018912, IMO: 9085546, SHIPNAME: "KLEVEN" },
  { MMSI: 636018931, IMO: 9810915, SHIPNAME: "SEATRADE GREEN" },
  { MMSI: 636018933, IMO: 9085534, SHIPNAME: "KOTKA" },
  { MMSI: 636018964, IMO: 9196838, SHIPNAME: "MSC YORK VII" },
  { MMSI: 636019021, IMO: 9770749, SHIPNAME: "MSC BIANCA" },
  { MMSI: 636019053, IMO: 9308613, SHIPNAME: "CONTSHIP FUN" },
  { MMSI: 636019057, IMO: 9854492, SHIPNAME: "SKY SUNSHINE" },
  { MMSI: 636019058, IMO: 9395941, SHIPNAME: "NAVIOS BAHAMAS" },
  { MMSI: 636019061, IMO: 9395927, SHIPNAME: "CARMEL I" },
  { MMSI: 636019088, IMO: 9312949, SHIPNAME: "SM YANTIAN" },
  { MMSI: 636019163, IMO: 9517434, SHIPNAME: "CONTSHIP GIN" },
  { MMSI: 636019174, IMO: 9820922, SHIPNAME: "EVER GENTLE" },
  { MMSI: 636019197, IMO: 9509126, SHIPNAME: "ETE N" },
  { MMSI: 636019206, IMO: 9842061, SHIPNAME: "MSC JOSSELINE" },
  { MMSI: 636019207, IMO: 9842073, SHIPNAME: "MSC JEWEL" },
  { MMSI: 636019213, IMO: 9842085, SHIPNAME: "MSC FAITH" },
  { MMSI: 636019214, IMO: 9842102, SHIPNAME: "MSC KANOKO" },
  { MMSI: 636019215, IMO: 9842097, SHIPNAME: "MSC ALIYA" },
  { MMSI: 636019221, IMO: 9306237, SHIPNAME: "CONTSHIP RUN" },
  { MMSI: 636019225, IMO: 9306213, SHIPNAME: "CONTSHIP SEA" },
  { MMSI: 636019234, IMO: 9786839, SHIPNAME: "EVER GLORY" },
  { MMSI: 636019245, IMO: 9347956, SHIPNAME: "CONTSHIP DON" },
  { MMSI: 636019256, IMO: 9349368, SHIPNAME: "LASALLE" },
  { MMSI: 636019289, IMO: 9309411, SHIPNAME: "MSC HARMONY III" },
  { MMSI: 636019292, IMO: 9330226, SHIPNAME: "MSC ABIGAIL F" },
  { MMSI: 636019331, IMO: 9256470, SHIPNAME: "MSC QINGDAO" },
  { MMSI: 636019332, IMO: 9285471, SHIPNAME: "MSC TIANJIN" },
  { MMSI: 636019336, IMO: 9845037, SHIPNAME: "FSL SINGAPORE" },
  { MMSI: 636019337, IMO: 9845049, SHIPNAME: "FSL KELANG" },
  { MMSI: 636019341, IMO: 9236652, SHIPNAME: "ROB" },
  { MMSI: 636019342, IMO: 9285677, SHIPNAME: "GSL ELENI" },
  { MMSI: 636019348, IMO: 9770751, SHIPNAME: "MSC AINO" },
  { MMSI: 636019364, IMO: 9358905, SHIPNAME: "MSC TAMISHKA F" },
  { MMSI: 636019366, IMO: 9349617, SHIPNAME: "GSL SUSAN" },
  { MMSI: 636019395, IMO: 9232890, SHIPNAME: "MSC ENGLAND" },
  { MMSI: 636019407, IMO: 9256482, SHIPNAME: "GSL NINGBO" },
  { MMSI: 636019425, IMO: 9306471, SHIPNAME: "GALLOWAY" },
  { MMSI: 636019434, IMO: 9477610, SHIPNAME: "MSC ROWAN" },
  { MMSI: 636019443, IMO: 9240873, SHIPNAME: "MSC RESILIENT III" },
  { MMSI: 636019446, IMO: 9329564, SHIPNAME: "ALOPO" },
  { MMSI: 636019493, IMO: 9285653, SHIPNAME: "GSL GRANIA" },
  { MMSI: 636019507, IMO: 9623843, SHIPNAME: "X-PRESS ANGLESEY" },
  { MMSI: 636019523, IMO: 9418652, SHIPNAME: "LISA" },
  { MMSI: 636019532, IMO: 9348625, SHIPNAME: "CONTSHIP JET" },
  { MMSI: 636019536, IMO: 9285689, SHIPNAME: "GSL KALLIOPI" },
  { MMSI: 636019562, IMO: 9348637, SHIPNAME: "CONTSHIP ACE" },
  { MMSI: 636019574, IMO: 9550371, SHIPNAME: "CALYPSO" },
  { MMSI: 636019582, IMO: 9619452, SHIPNAME: "MSC ARICA" },
  { MMSI: 636019587, IMO: 9477323, SHIPNAME: "X PRD3S BARDSEY" },
  { MMSI: 636019606, IMO: 9429326, SHIPNAME: "VIVALDI" },
  { MMSI: 636019617, IMO: 9477323, SHIPNAME: "X PRESS BARDSEY" },
  { MMSI: 636019647, IMO: 9365843, SHIPNAME: "ADMIRAL GALAXY" },
  { MMSI: 636019671, IMO: 9413509, SHIPNAME: "FAR EAST CHEER" },
  { MMSI: 636019673, IMO: 9229348, SHIPNAME: "GSL NICOLETTA" },
  { MMSI: 636019698, IMO: 9309150, SHIPNAME: "ASIAN ACE" },
  { MMSI: 636019700, IMO: 9858735, SHIPNAME: "MAERSK HAI PHONG" },
  { MMSI: 636019716, IMO: 9406180, SHIPNAME: "VELA" },
  { MMSI: 636019717, IMO: 9430777, SHIPNAME: "VOLANS" },
  { MMSI: 636019718, IMO: 9430789, SHIPNAME: "VULPECULA" },
  { MMSI: 636019746, IMO: 9306249, SHIPNAME: "CONTSHIP MED" },
  { MMSI: 636019759, IMO: 9229336, SHIPNAME: "MYNY" },
  { MMSI: 636019760, IMO: 9229324, SHIPNAME: "GSL CHRISTEN" },
  { MMSI: 636019762, IMO: 9306263, SHIPNAME: "MSC DIYA F" },
  { MMSI: 636019770, IMO: 9224958, SHIPNAME: "MANET" },
  { MMSI: 636019794, IMO: 9631125, SHIPNAME: "LORI" },
  { MMSI: 636019821, IMO: 9858747, SHIPNAME: "MAERSK KWANGYANG" },
  { MMSI: 636019841, IMO: 9228576, SHIPNAME: "MSC NILA II" },
  { MMSI: 636019861, IMO: 9224946, SHIPNAME: "MSC IDA II" },
  { MMSI: 636019862, IMO: 9225782, SHIPNAME: "KETA" },
  { MMSI: 636019863, IMO: 9225770, SHIPNAME: "JULIE" },
  { MMSI: 636019868, IMO: 9222297, SHIPNAME: "CMA CGM BERLIOZ" },
  { MMSI: 636019875, IMO: 9293765, SHIPNAME: "KOTA SANTOS" },
  { MMSI: 636019887, IMO: 9306275, SHIPNAME: "MSC ANDREA F" },
  { MMSI: 636019892, IMO: 9864502, SHIPNAME: "YM CELEBRITY" },
  { MMSI: 636019893, IMO: 9864514, SHIPNAME: "YM CONTINENT" },
  { MMSI: 636019894, IMO: 9864526, SHIPNAME: "YM CREDENTIAL" },
  { MMSI: 636019895, IMO: 9864538, SHIPNAME: "YM CENTENNIAL" },
  { MMSI: 636019896, IMO: 9864540, SHIPNAME: "YM CAPACITY" },
  { MMSI: 636019897, IMO: 9864552, SHIPNAME: "YM CERTAINTY" },
  { MMSI: 636019898, IMO: 9864564, SHIPNAME: "YM CREDIBILITY" },
  { MMSI: 636019899, IMO: 9864576, SHIPNAME: "YM CONSTANCY" },
  { MMSI: 636019900, IMO: 9864588, SHIPNAME: "YM COOPERATION" },
  { MMSI: 636019901, IMO: 9864590, SHIPNAME: "YM CONTINUITY" },
  { MMSI: 636019931, IMO: 9509138, SHIPNAME: "FLEUR N" },
  { MMSI: 636019982, IMO: 9860908, SHIPNAME: "YM TRIUMPH" },
  { MMSI: 636019983, IMO: 9860910, SHIPNAME: "YM TRUTH" },
  { MMSI: 636019984, IMO: 9860922, SHIPNAME: "YM TOTALITY" },
  { MMSI: 636019985, IMO: 9860934, SHIPNAME: "YM TARGET" },
  { MMSI: 636019986, IMO: 9860946, SHIPNAME: "YM TIPTOP" },
  { MMSI: 636020153, IMO: 9357872, SHIPNAME: "JONATHAN P" },
  { MMSI: 636020157, IMO: 9231157, SHIPNAME: "ZEBRA" },
  { MMSI: 636020168, IMO: 9344693, SHIPNAME: "SAN AMERIGO" },
  { MMSI: 636020191, IMO: 9344667, SHIPNAME: "SAN ALFONSO" },
  { MMSI: 636020192, IMO: 9344643, SHIPNAME: "SAN ALBERTO" },
  { MMSI: 636020198, IMO: 9403396, SHIPNAME: "MSC CANCUN" },
  { MMSI: 636020202, IMO: 9307279, SHIPNAME: "SCORPIUS" },
  { MMSI: 636020213, IMO: 9882217, SHIPNAME: "KMTC YOKOHAMA" },
  { MMSI: 636020215, IMO: 9215672, SHIPNAME: "MSC JESSENIA R" },
  { MMSI: 636020220, IMO: 9893589, SHIPNAME: "KMTC XIAMEN" },
  { MMSI: 636020221, IMO: 9893591, SHIPNAME: "KMTC SHIMIZU" },
  { MMSI: 636020226, IMO: 9347267, SHIPNAME: "SEABOARD EXPLORER" },
  { MMSI: 636020240, IMO: 9193513, SHIPNAME: "BLACK PEARL" },
  { MMSI: 636020252, IMO: 9348675, SHIPNAME: "LONG BEACH EXPRESS" },
  { MMSI: 636020254, IMO: 9882229, SHIPNAME: "KMTC DALIAN" },
  { MMSI: 636020257, IMO: 9348687, SHIPNAME: "SEATTLE EXPRESS" },
  { MMSI: 636020258, IMO: 9348699, SHIPNAME: "FOS EXPRESS" },
  { MMSI: 636020265, IMO: 9168192, SHIPNAME: "TB BRIGHT CITY" },
  { MMSI: 636020266, IMO: 9216987, SHIPNAME: "ZOI" },
  { MMSI: 636020280, IMO: 9360984, SHIPNAME: "ADMIRAL MARS" },
  { MMSI: 636020307, IMO: 9338278, SHIPNAME: "CONTSHIP KEY" },
  { MMSI: 636020326, IMO: 9450387, SHIPNAME: "BREMEN" },
  { MMSI: 636020327, IMO: 9450375, SHIPNAME: "C HAMBURG" },
  { MMSI: 636020332, IMO: 9869162, SHIPNAME: "HMM NURI" },
  { MMSI: 636020334, IMO: 9293442, SHIPNAME: "MSC SHRISTI" },
  { MMSI: 636020343, IMO: 9217565, SHIPNAME: "MSC PANAYA" },
  { MMSI: 636020345, IMO: 9400215, SHIPNAME: "NEOKASTRO" },
  { MMSI: 636020348, IMO: 9216999, SHIPNAME: "TONSBERG" },
  { MMSI: 636020349, IMO: 9162227, SHIPNAME: "MARIANNA I" },
  { MMSI: 636020350, IMO: 9153862, SHIPNAME: "EPAMINONDAS" },
  { MMSI: 636020352, IMO: 9399789, SHIPNAME: "SONGA PUMA" },
  { MMSI: 636020353, IMO: 9399739, SHIPNAME: "ROBIN 2" },
  { MMSI: 636020354, IMO: 9399765, SHIPNAME: "MSC PASSION III" },
  { MMSI: 636020360, IMO: 9203538, SHIPNAME: "MSC MALENA" },
  { MMSI: 636020361, IMO: 9449106, SHIPNAME: "MENDELSSOHN" },
  { MMSI: 636020371, IMO: 9850862, SHIPNAME: "EVER FAR" },
  { MMSI: 636020383, IMO: 9869174, SHIPNAME: "HMM GAON" },
  { MMSI: 636020384, IMO: 9869186, SHIPNAME: "HMM GARAM" },
  { MMSI: 636020401, IMO: 9406960, SHIPNAME: "OKEE CUNO" },
  { MMSI: 636020436, IMO: 9333072, SHIPNAME: "NATAL" },
  { MMSI: 636020437, IMO: 9322504, SHIPNAME: "IRENES RAY" },
  { MMSI: 636020438, IMO: 9449120, SHIPNAME: "CHOPIN" },
  { MMSI: 636020440, IMO: 9236585, SHIPNAME: "MSC KANU F" },
  { MMSI: 636020456, IMO: 9850874, SHIPNAME: "EVER FAST" },
  { MMSI: 636020468, IMO: 9295165, SHIPNAME: "MSC DAISY" },
  { MMSI: 636020475, IMO: 9262728, SHIPNAME: "ARIES" },
  { MMSI: 636020476, IMO: 9262716, SHIPNAME: "ARGUS" },
  { MMSI: 636020483, IMO: 9280811, SHIPNAME: "STAMATIS B" },
  { MMSI: 636020494, IMO: 9318058, SHIPNAME: "MSC TEXAS" },
  { MMSI: 636020495, IMO: 9305489, SHIPNAME: "MSC TIANPING" },
  { MMSI: 636020497, IMO: 9318046, SHIPNAME: "MSC TOKYO" },
  { MMSI: 636020498, IMO: 9285665, SHIPNAME: "MSC YOKOHAMA" },
  { MMSI: 636020499, IMO: 9285691, SHIPNAME: "MSC VANCOUVER" },
  { MMSI: 636020500, IMO: 9383223, SHIPNAME: "MSC LIDIA" },
  { MMSI: 636020501, IMO: 9305477, SHIPNAME: "MSC TIANSHAN" },
  { MMSI: 636020516, IMO: 9322853, SHIPNAME: "PAFILIA" },
  { MMSI: 636020524, IMO: 9307047, SHIPNAME: "BIGLI" },
  { MMSI: 636020532, IMO: 9429223, SHIPNAME: "MUNKSUND" },
  { MMSI: 636020533, IMO: 9429235, SHIPNAME: "TUNADAL" },
  { MMSI: 636020536, IMO: 9850886, SHIPNAME: "EVER FAIR" },
  { MMSI: 636020539, IMO: 9322516, SHIPNAME: "TORO" },
  { MMSI: 636020542, IMO: 9896983, SHIPNAME: "MSC APOLLINE" },
  { MMSI: 636020543, IMO: 9896995, SHIPNAME: "MSC AMELIA" },
  { MMSI: 636020574, IMO: 9330238, SHIPNAME: "MSC CHARLOTTE" },
  { MMSI: 636020581, IMO: 9437244, SHIPNAME: "ADMIRAL SUN" },
  { MMSI: 636020582, IMO: 9437220, SHIPNAME: "ADMIRAL MOON" },
  { MMSI: 636020583, IMO: 9302097, SHIPNAME: "GLEN CANYON" },
  { MMSI: 636020585, IMO: 9346562, SHIPNAME: "CONTSHIP LEX" },
  { MMSI: 636020589, IMO: 9400306, SHIPNAME: "GIALOVA" },
  { MMSI: 636020595, IMO: 9504592, SHIPNAME: "GSL MAREN" },
  { MMSI: 636020598, IMO: 9897004, SHIPNAME: "MSC DILETTA" },
  { MMSI: 636020599, IMO: 9897016, SHIPNAME: "MSC MICHELLE" },
  { MMSI: 636020600, IMO: 9897028, SHIPNAME: "MSC ALLEGRA" },
  { MMSI: 636020609, IMO: 9307009, SHIPNAME: "YOGI" },
  { MMSI: 636020610, IMO: 9431769, SHIPNAME: "ANDROUSA" },
  { MMSI: 636020615, IMO: 9869198, SHIPNAME: "HMM MIR" },
  { MMSI: 636020646, IMO: 9105994, SHIPNAME: "VASI STAR" },
  { MMSI: 636020648, IMO: 9431719, SHIPNAME: "ZIM IBERIA" },
  { MMSI: 636020659, IMO: 9400112, SHIPNAME: "PINOCCHIO" },
  { MMSI: 636020661, IMO: 9330276, SHIPNAME: "SC PHILLY" },
  { MMSI: 636020662, IMO: 9236274, SHIPNAME: "SC POTOMAC" },
  { MMSI: 636020663, IMO: 9236262, SHIPNAME: "SC PHOENIX" },
  { MMSI: 636020666, IMO: 9275115, SHIPNAME: "SC MARIGOT" },
  { MMSI: 636020667, IMO: 9275103, SHIPNAME: "SC MEDFORD" },
  { MMSI: 636020668, IMO: 9324174, SHIPNAME: "SC MEMPHIS" },
  { MMSI: 636020684, IMO: 9850898, SHIPNAME: "EVER FEAT" },
  { MMSI: 636020695, IMO: 9334832, SHIPNAME: "MSC EMILIE" },
  { MMSI: 636020700, IMO: 9222106, SHIPNAME: "MSC SAMU" },
  { MMSI: 636020701, IMO: 9334844, SHIPNAME: "MSC KYMEA II" },
  { MMSI: 636020702, IMO: 9270828, SHIPNAME: "MSC TIPHAINE II" },
  { MMSI: 636020707, IMO: 9869203, SHIPNAME: "HMM HANBADA" },
  { MMSI: 636020712, IMO: 9158525, SHIPNAME: "MSC DAR ES SALAAM 3" },
  { MMSI: 636020728, IMO: 9146479, SHIPNAME: "MSC VILDA X" },
  { MMSI: 636020729, IMO: 9166780, SHIPNAME: "MSC ELLEN" },
  { MMSI: 636020730, IMO: 9166778, SHIPNAME: "MSC ABY" },
  { MMSI: 636020743, IMO: 9869227, SHIPNAME: "HMM DAON" },
  { MMSI: 636020744, IMO: 9869239, SHIPNAME: "HMM HANUL" },
  { MMSI: 636020746, IMO: 9869215, SHIPNAME: "HMM RAON" },
  { MMSI: 636020758, IMO: 9241487, SHIPNAME: "BERTIE" },
  { MMSI: 636020769, IMO: 9222974, SHIPNAME: "GSL DOROTHEA" },
  { MMSI: 636020770, IMO: 9222986, SHIPNAME: "GSL TEGEA" },
  { MMSI: 636020772, IMO: 9214214, SHIPNAME: "GSL ARCADIA" },
  { MMSI: 636020773, IMO: 9213583, SHIPNAME: "GSL MYNY" },
  { MMSI: 636020774, IMO: 9214226, SHIPNAME: "GSL MELITA" },
  { MMSI: 636020775, IMO: 9231236, SHIPNAME: "GSL MARIA" },
  { MMSI: 636020776, IMO: 9214202, SHIPNAME: "GSL VIOLETTA" },
  { MMSI: 636020781, IMO: 9439735, SHIPNAME: "MSC LANGSAR" },
  { MMSI: 636020782, IMO: 9471238, SHIPNAME: "MSC SHAHAR" },
  { MMSI: 636020783, IMO: 9315915, SHIPNAME: "MSC MATTINA" },
  { MMSI: 636020787, IMO: 9436484, SHIPNAME: "STAR" },
  { MMSI: 636020811, IMO: 9160982, SHIPNAME: "MSC DHANTIA F" },
  { MMSI: 636020813, IMO: 9313242, SHIPNAME: "ZAGOR" },
  { MMSI: 636020814, IMO: 9308596, SHIPNAME: "CONTSHIP ANA" },
  { MMSI: 636020824, IMO: 9440813, SHIPNAME: "NORFOLK" },
  { MMSI: 636020846, IMO: 9435246, SHIPNAME: "POLYNESIA" },
  { MMSI: 636020868, IMO: 9261451, SHIPNAME: "PORTO KAGIO" },
  { MMSI: 636020869, IMO: 9221839, SHIPNAME: "PORTO CHELI" },
  { MMSI: 636020870, IMO: 9260902, SHIPNAME: "PORTO GERMENO" },
  { MMSI: 636020871, IMO: 9144134, SHIPNAME: "MSC RIONA" },
  { MMSI: 636020878, IMO: 9088524, SHIPNAME: "AEOLUS" },
  { MMSI: 636020886, IMO: 9233856, SHIPNAME: "HOLSATIA" },
  { MMSI: 636020898, IMO: 9301328, SHIPNAME: "MSC DORINE" },
  { MMSI: 636020899, IMO: 9287900, SHIPNAME: "MSC MARIA CLARA" },
  { MMSI: 636020902, IMO: 9232644, SHIPNAME: "MSC NIKOLETA II" },
  { MMSI: 636020910, IMO: 9292125, SHIPNAME: "KONRAD" },
  { MMSI: 636020918, IMO: 9302085, SHIPNAME: "MSC ANAHITA" },
  { MMSI: 636020919, IMO: 9108398, SHIPNAME: "MSC TIANA F" },
  { MMSI: 636020928, IMO: 9786097, SHIPNAME: "MAERSK PANGANI" },
  { MMSI: 636020930, IMO: 9240328, SHIPNAME: "MSC PATNAREE III" },
  { MMSI: 636020936, IMO: 9369734, SHIPNAME: "MSC BREMEN" },
  { MMSI: 636020937, IMO: 9344564, SHIPNAME: "MSC YURIDA III" },
  { MMSI: 636020938, IMO: 9243162, SHIPNAME: "VELIKA" },
  { MMSI: 636020941, IMO: 9243174, SHIPNAME: "MIRADOR EXPRESS" },
  { MMSI: 636020949, IMO: 9238882, SHIPNAME: "MSC MANASA F" },
  { MMSI: 636020952, IMO: 9434462, SHIPNAME: "MSC SAMIRA III" },
  { MMSI: 636020953, IMO: 9215892, SHIPNAME: "MSC ELIZABETH III" },
  { MMSI: 636020959, IMO: 9358890, SHIPNAME: "SKYVIEW" },
  { MMSI: 636020962, IMO: 9261827, SHIPNAME: "MSC ANA CAMILA III" },
  { MMSI: 636020978, IMO: 9230775, SHIPNAME: "MSC TRADER II" },
  { MMSI: 636020979, IMO: 9305506, SHIPNAME: "MSC KUMSAL" },
  { MMSI: 636020983, IMO: 9407885, SHIPNAME: "GSL KITHIRA" },
  { MMSI: 636020984, IMO: 9437050, SHIPNAME: "GSL TINOS" },
  { MMSI: 636020985, IMO: 9437062, SHIPNAME: "GSL SYROS" },
  { MMSI: 636020986, IMO: 9437048, SHIPNAME: "GSL TRIPOLI" },
  { MMSI: 636020990, IMO: 9337250, SHIPNAME: "ADDISON" },
  { MMSI: 636020991, IMO: 9337262, SHIPNAME: "HALSTED" },
  { MMSI: 636020992, IMO: 9338058, SHIPNAME: "ARCHER" },
  { MMSI: 636020994, IMO: 9850836, SHIPNAME: "EVER FASHION" },
  { MMSI: 636021002, IMO: 9354167, SHIPNAME: "HAKUNA MATATA" },
  { MMSI: 636021004, IMO: 9330525, SHIPNAME: "GSL LALO" },
  { MMSI: 636021006, IMO: 9308429, SHIPNAME: "GSL ELIZABETH" },
  { MMSI: 636021011, IMO: 9419644, SHIPNAME: "MSC RINI III" },
  { MMSI: 636021012, IMO: 9785445, SHIPNAME: "MSC CAROLE" },
  { MMSI: 636021013, IMO: 9635688, SHIPNAME: "SFL MAUI" },
  { MMSI: 636021017, IMO: 9336359, SHIPNAME: "MSC OLGA F" },
  { MMSI: 636021031, IMO: 9315836, SHIPNAME: "MSC VAIGA III" },
  { MMSI: 636021035, IMO: 9665607, SHIPNAME: "SAVANNAH EXPRESS" },
  { MMSI: 636021040, IMO: 9679555, SHIPNAME: "SFL HAWAII" },
  { MMSI: 636021055, IMO: 9243186, SHIPNAME: "ANTIBES EXPRESS" },
  { MMSI: 636021056, IMO: 9243203, SHIPNAME: "SPARTEL TRADER" },
  { MMSI: 636021061, IMO: 9307839, SHIPNAME: "MSC NAISHA III" },
  { MMSI: 636021078, IMO: 9403437, SHIPNAME: "MSC AZURIT F" },
  { MMSI: 636021079, IMO: 9330240, SHIPNAME: "MSC SIGMA F" },
  { MMSI: 636021093, IMO: 9929235, SHIPNAME: "SUSTAINABLE EARTH" },
  { MMSI: 636021094, IMO: 9929247, SHIPNAME: "HARMONIZED EARTH" },
  { MMSI: 636021095, IMO: 9929259, SHIPNAME: "INTEGRATIVE EARTH" },
  { MMSI: 636021096, IMO: 9929261, SHIPNAME: "PROACTIVE EARTH" },
  { MMSI: 636021097, IMO: 9929273, SHIPNAME: "REGAINING EARTH" },
  { MMSI: 636021101, IMO: 9307023, SHIPNAME: "RANTANPLAN" },
  { MMSI: 636021104, IMO: 9059121, SHIPNAME: "UGL HONGKONG" },
  { MMSI: 636021108, IMO: 9472579, SHIPNAME: "SEABREEZE" },
  { MMSI: 636021122, IMO: 9785457, SHIPNAME: "MSC RAYSHMI" },
  { MMSI: 636021123, IMO: 9785483, SHIPNAME: "MSC ALANYA" },
  { MMSI: 636021128, IMO: 9471226, SHIPNAME: "MSC NASSAU" },
  { MMSI: 636021129, IMO: 9227285, SHIPNAME: "MSC YANG R" },
  { MMSI: 636021136, IMO: 9903255, SHIPNAME: "EVER CERTAIN" },
  { MMSI: 636021139, IMO: 9259381, SHIPNAME: "MSC TARA III" },
  { MMSI: 636021140, IMO: 9305934, SHIPNAME: "X-PRESS MONTE ROSA" },
  { MMSI: 636021142, IMO: 9295505, SHIPNAME: "MIZAR" },
  { MMSI: 636021150, IMO: 9253014, SHIPNAME: "MSC CORDELIA III" },
  { MMSI: 636021151, IMO: 9294989, SHIPNAME: "MSC MUNDRA VIII" },
  { MMSI: 636021153, IMO: 9236224, SHIPNAME: "MSC REET II" },
  { MMSI: 636021154, IMO: 9259393, SHIPNAME: "MSC SOTIRIA III" },
  { MMSI: 636021155, IMO: 9294991, SHIPNAME: "MSC MUMBAI VIII" },
  { MMSI: 636021160, IMO: 9231822, SHIPNAME: "MSC SHANGHAI V" },
  { MMSI: 636021161, IMO: 9231810, SHIPNAME: "MSC HONG KONG V" },
  { MMSI: 636021166, IMO: 9339600, SHIPNAME: "ALIOTH" },
  { MMSI: 636021184, IMO: 9127007, SHIPNAME: "JAGUAR" },
  { MMSI: 636021190, IMO: 9850848, SHIPNAME: "EVER FOND" },
  { MMSI: 636021200, IMO: 9903267, SHIPNAME: "EVER COMMAND" },
  { MMSI: 636021202, IMO: 9307396, SHIPNAME: "STONEWELL GLORY" },
  { MMSI: 636021229, IMO: 9426324, SHIPNAME: "SONGA LIONESS" },
  { MMSI: 636021230, IMO: 9405100, SHIPNAME: "MELANESIAN CHIEF" },
  { MMSI: 636021231, IMO: 9332717, SHIPNAME: "LILA CANADA" },
  { MMSI: 636021234, IMO: 9501772, SHIPNAME: "SONGA LEOPARD" },
  { MMSI: 636021244, IMO: 9789984, SHIPNAME: "YM TOGETHER" },
  { MMSI: 636021246, IMO: 9239903, SHIPNAME: "MSC PAMIRA III" },
  { MMSI: 636021247, IMO: 9366421, SHIPNAME: "MSC MOON F" },
  { MMSI: 636021249, IMO: 9433157, SHIPNAME: "MSC JENNIFER II" },
  { MMSI: 636021250, IMO: 9222120, SHIPNAME: "MSC ANDRIANA III" },
  { MMSI: 636021251, IMO: 9706310, SHIPNAME: "MSC ADONIS" },
  { MMSI: 636021252, IMO: 9241475, SHIPNAME: "MSC BALTIC III" },
  { MMSI: 636021256, IMO: 9137703, SHIPNAME: "SURABAYA VOYAGER" },
  { MMSI: 636021266, IMO: 9719862, SHIPNAME: "SIMBA" },
  { MMSI: 636021289, IMO: 9238698, SHIPNAME: "MARSA ZENITH" },
  { MMSI: 636021296, IMO: 9440605, SHIPNAME: "NINA A" },
  { MMSI: 636021302, IMO: 9357107, SHIPNAME: "MSC GEORGIA II" },
  { MMSI: 636021306, IMO: 9415844, SHIPNAME: "TIMON" },
  { MMSI: 636021307, IMO: 9532343, SHIPNAME: "ANITA A" },
  { MMSI: 636021316, IMO: 9326976, SHIPNAME: "ELI A" },
  { MMSI: 636021320, IMO: 9348663, SHIPNAME: "SHIJING" },
  { MMSI: 636021321, IMO: 9320685, SHIPNAME: "UTE" },
  { MMSI: 636021322, IMO: 9244180, SHIPNAME: "ELBE" },
  { MMSI: 636021323, IMO: 9292151, SHIPNAME: "MOANA" },
  { MMSI: 636021329, IMO: 9302566, SHIPNAME: "PUMBA" },
  { MMSI: 636021337, IMO: 9924182, SHIPNAME: "MAERSK CAMBRIDGE" },
  { MMSI: 636021338, IMO: 9924194, SHIPNAME: "MAERSK CAMDEN" },
  { MMSI: 636021339, IMO: 9924209, SHIPNAME: "MAERSK CAMPBELL" },
  { MMSI: 636021356, IMO: 9415296, SHIPNAME: "MSC GRETA III" },
  { MMSI: 636021360, IMO: 9267156, SHIPNAME: "TINA I" },
  { MMSI: 636021362, IMO: 9440837, SHIPNAME: "ZIM PACIFIC" },
  { MMSI: 636021363, IMO: 9785469, SHIPNAME: "MSC EUGENIA" },
  { MMSI: 636021364, IMO: 9785471, SHIPNAME: "MSC CASSANDRE" },
  { MMSI: 636021365, IMO: 9908047, SHIPNAME: "MSC WASHINGTON" },
  { MMSI: 636021366, IMO: 9908059, SHIPNAME: "MSC VIRGINIA" },
  { MMSI: 636021387, IMO: 9850850, SHIPNAME: "EVER FAVOR" },
  { MMSI: 636021392, IMO: 9380403, SHIPNAME: "DYROS" },
  { MMSI: 636021393, IMO: 9286231, SHIPNAME: "MSC EVEREST VIII" },
  { MMSI: 636021396, IMO: 9366445, SHIPNAME: "ZIM AUSTRALIA" },
  { MMSI: 636021400, IMO: 9341122, SHIPNAME: "MSC ALDI III" },
  { MMSI: 636021408, IMO: 9120853, SHIPNAME: "MSC FIE X" },
  { MMSI: 636021412, IMO: 9236444, SHIPNAME: "MSC EMILY II" },
  { MMSI: 636021427, IMO: 9374454, SHIPNAME: "SEABOARD PIONEER" },
  { MMSI: 636021438, IMO: 9449687, SHIPNAME: "MSC AMIHAN F" },
  { MMSI: 636021440, IMO: 9294513, SHIPNAME: "SEABOARD PRIDE" },
  { MMSI: 636021448, IMO: 9327803, SHIPNAME: "MSC LONG BEACH VI" },
  { MMSI: 636021449, IMO: 9327798, SHIPNAME: "MSC LOS ANGELES" },
  { MMSI: 636021462, IMO: 9850903, SHIPNAME: "EVER FULL" },
  { MMSI: 636021470, IMO: 9932892, SHIPNAME: "MSC MARA" },
  { MMSI: 636021471, IMO: 9932907, SHIPNAME: "MSC VICTORIA" },
  { MMSI: 636021472, IMO: 9440801, SHIPNAME: "ZIM ATLANTIC" },
  { MMSI: 636021476, IMO: 9946233, SHIPNAME: "H CYGNUS" },
  { MMSI: 636021480, IMO: 9106144, SHIPNAME: "STONEFISH" },
  { MMSI: 636021484, IMO: 9491616, SHIPNAME: "MSC SAGITTARIUS F" },
  { MMSI: 636021492, IMO: 9241451, SHIPNAME: "MSC SARYA III" },
  { MMSI: 636021506, IMO: 9366471, SHIPNAME: "ZIM NEW ZEALAND" },
  { MMSI: 636021517, IMO: 9304746, SHIPNAME: "MSC ANTWERP III" },
  { MMSI: 636021529, IMO: 9301990, SHIPNAME: "MSC ALDEBARAN III" },
  { MMSI: 636021547, IMO: 9246322, SHIPNAME: "TEX" },
  { MMSI: 636021578, IMO: 9792682, SHIPNAME: "YM TRANQUILITY" },
  { MMSI: 636021595, IMO: 9919486, SHIPNAME: "EVER ORIGIN" },
  { MMSI: 636021597, IMO: 9232761, SHIPNAME: "MSC COLETTE III" },
  { MMSI: 636021614, IMO: 9261815, SHIPNAME: "MSC HAILEY ANN III" },
  { MMSI: 636021617, IMO: 9946245, SHIPNAME: "H MERCURY" },
  { MMSI: 636021649, IMO: 9629914, SHIPNAME: "ONE CONTRIBUTION" },
  { MMSI: 636021654, IMO: 9364203, SHIPNAME: "BFAD PACIFIC" },
  { MMSI: 636021663, IMO: 9930935, SHIPNAME: "MSC BIANCA SILVIA" },
  { MMSI: 636021664, IMO: 9464716, SHIPNAME: "ZIM ASIA" },
  { MMSI: 636021665, IMO: 9930947, SHIPNAME: "MSC BERANGERE" },
  { MMSI: 636021666, IMO: 9930959, SHIPNAME: "MSC DARLENE" },
  { MMSI: 636021667, IMO: 9930961, SHIPNAME: "MSC C. MONTAINE" },
  { MMSI: 636021669, IMO: 9913884, SHIPNAME: "EVER OUTDO" },
  { MMSI: 636021690, IMO: 9792618, SHIPNAME: "YM TUTORIAL" },
  { MMSI: 636021693, IMO: 9792694, SHIPNAME: "YM THRONE" },
  { MMSI: 636021736, IMO: 9300142, SHIPNAME: "BIG BREEZY" },
  { MMSI: 636021739, IMO: 9389382, SHIPNAME: "ZIM CHINA" },
  { MMSI: 636021760, IMO: 9317925, SHIPNAME: "MSC TAMPA" },
  { MMSI: 636021761, IMO: 9224348, SHIPNAME: "MSC SINGAPORE IV" },
  { MMSI: 636021768, IMO: 9927251, SHIPNAME: "MSC FATMA" },
  { MMSI: 636021770, IMO: 9927263, SHIPNAME: "MSC AAYA" },
  { MMSI: 636021771, IMO: 9927275, SHIPNAME: "MSC SOFIA" },
  { MMSI: 636021776, IMO: 9227015, SHIPNAME: "ALGECIRAS EXPRESS" },
  { MMSI: 636021790, IMO: 9332729, SHIPNAME: "MSC FORTUNE F" },
  { MMSI: 636021813, IMO: 9555137, SHIPNAME: "WAN HAI 177" },
  { MMSI: 636021814, IMO: 9555175, SHIPNAME: "WAN HAI 178" },
  { MMSI: 636021830, IMO: 9571325, SHIPNAME: "CUL JAKARTA" },
  { MMSI: 636021831, IMO: 9571313, SHIPNAME: "CUL MANILA" },
  { MMSI: 636021855, IMO: 9384887, SHIPNAME: "KYOTO TOWER" },
  { MMSI: 636021857, IMO: 9318151, SHIPNAME: "XIAMEN" },
  { MMSI: 636021859, IMO: 9347970, SHIPNAME: "MSC TOKATA F" },
  { MMSI: 636021870, IMO: 9499010, SHIPNAME: "MSC ALBA F" },
  { MMSI: 636021888, IMO: 9301330, SHIPNAME: "MSC FREEPORT" },
  { MMSI: 636021913, IMO: 9326768, SHIPNAME: "MSC NISHA V" },
  { MMSI: 636021914, IMO: 9256315, SHIPNAME: "MSC NORDEROOG F" },
  { MMSI: 636021955, IMO: 9913896, SHIPNAME: "EVER ONWARD" },
  { MMSI: 636021978, IMO: 9328613, SHIPNAME: "F LANA" },
  { MMSI: 636021991, IMO: 9315886, SHIPNAME: "MSC NIMISHA III" },
  { MMSI: 636021992, IMO: 9200677, SHIPNAME: "MSC SYDNEY VI" },
  { MMSI: 636021993, IMO: 9200689, SHIPNAME: "MSC DARWIN VI" },
  { MMSI: 636021994, IMO: 9196864, SHIPNAME: "MSC MICHIGAN VII" },
  { MMSI: 636021995, IMO: 9197545, SHIPNAME: "MSC ILLINOIS VII" },
  { MMSI: 636022006, IMO: 9267651, SHIPNAME: "KOTA LIMA" },
  { MMSI: 636022007, IMO: 9230311, SHIPNAME: "SUEZ CANAL" },
  { MMSI: 636022022, IMO: 9141285, SHIPNAME: "MSC YUVIKA V" },
  { MMSI: 636022027, IMO: 0, SHIPNAME: "YM TRILLION" },
  { MMSI: 636022044, IMO: 9186405, SHIPNAME: "DEPE" },
  { MMSI: 636022046, IMO: 9220328, SHIPNAME: "MSC SOMYA III" },
  { MMSI: 636022047, IMO: 9256327, SHIPNAME: "MSC SUEDEROOG F" },
  { MMSI: 636022077, IMO: 9295177, SHIPNAME: "MSC TRACY V" },
  { MMSI: 636022078, IMO: 9346017, SHIPNAME: "MSC AMEERA III" },
  { MMSI: 636022102, IMO: 9930038, SHIPNAME: "MSC TESSA" },
  { MMSI: 636022103, IMO: 9930052, SHIPNAME: "MSC RAYA" },
  { MMSI: 636022111, IMO: 9244946, SHIPNAME: "MSC KALAMATA VII" },
  { MMSI: 636022112, IMO: 9244934, SHIPNAME: "ZIM AMERICA" },
  { MMSI: 636022113, IMO: 9244922, SHIPNAME: "ZIM VIETNAM" },
  { MMSI: 636022114, IMO: 9400289, SHIPNAME: "NAVARINO" },
  { MMSI: 636022116, IMO: 9196852, SHIPNAME: "MSC ALMA VII" },
  { MMSI: 636022118, IMO: 9243306, SHIPNAME: "ULSAN" },
  { MMSI: 636022173, IMO: 9951135, SHIPNAME: "ASTERIOS" },
  { MMSI: 636022174, IMO: 9951147, SHIPNAME: "ADAMASTOS" },
  { MMSI: 636022176, IMO: 9951161, SHIPNAME: "AMOUREUX" },
  { MMSI: 636022177, IMO: 9951173, SHIPNAME: "ARIONAS" },
  { MMSI: 636022178, IMO: 9951185, SHIPNAME: "ADRASTOS" },
  { MMSI: 636022181, IMO: 9961441, SHIPNAME: "ANDROKLIS" },
  { MMSI: 636022182, IMO: 9961453, SHIPNAME: "AVIOS" },
  { MMSI: 636022196, IMO: 9950519, SHIPNAME: "BLOOMING EARTH" },
  { MMSI: 636022214, IMO: 9936446, SHIPNAME: "MAERSK PHUKET" },
  { MMSI: 636022215, IMO: 9936458, SHIPNAME: "MAERSK PELEPAS" },
  { MMSI: 636022261, IMO: 9477359, SHIPNAME: "JPO AQUILA" },
  { MMSI: 636022277, IMO: 9401685, SHIPNAME: "MSC ORTOLAN II" },
  { MMSI: 636022293, IMO: 9465253, SHIPNAME: "MSC BENEDETTA XIII" },
  { MMSI: 636022307, IMO: 9327683, SHIPNAME: "MSC MAKALU III" },
  { MMSI: 636022328, IMO: 9348998, SHIPNAME: "HISTORY MARIA" },
  { MMSI: 636022342, IMO: 9983396, SHIPNAME: "MATOYA BAY" },
  { MMSI: 636022344, IMO: 9983401, SHIPNAME: "MAYA BAY" },
  { MMSI: 636022412, IMO: 9970428, SHIPNAME: "SINAR SANUR" },
  { MMSI: 636022441, IMO: 9927287, SHIPNAME: "MSC DARIA" },
  { MMSI: 636022442, IMO: 9927299, SHIPNAME: "MSC KAYLEY" },
  { MMSI: 636022443, IMO: 9932036, SHIPNAME: "MSC CALYPSO" },
  { MMSI: 636022447, IMO: 9932048, SHIPNAME: "MSC TAYLOR" },
  { MMSI: 636022448, IMO: 9932050, SHIPNAME: "MSC MONICA CRISTINA" },
  { MMSI: 636022449, IMO: 9932062, SHIPNAME: "MSC FREYA" },
  { MMSI: 636022450, IMO: 9540065, SHIPNAME: "MSC AUDREY" },
  { MMSI: 636022451, IMO: 9540077, SHIPNAME: "MSC MARTINA MARIA" },
  { MMSI: 636022489, IMO: 9959046, SHIPNAME: "ASL QINGDAO" },
  { MMSI: 636022495, IMO: 9130157, SHIPNAME: "KAPITAN MASLOV" },
  { MMSI: 636022497, IMO: 9130133, SHIPNAME: "KAPITAN AFANASYEV" },
  { MMSI: 636022498, IMO: 9324942, SHIPNAME: "FESCO ASKOLD" },
  { MMSI: 636022499, IMO: 9365295, SHIPNAME: "FESCO DIOMID" },
  { MMSI: 636022500, IMO: 9237486, SHIPNAME: "FESCO DALNEGORSK" },
  { MMSI: 636022501, IMO: 9237503, SHIPNAME: "FESCO SOFIA" },
  { MMSI: 636022516, IMO: 9930040, SHIPNAME: "MSC CELESTINOMARESCA" },
  { MMSI: 636022523, IMO: 9919498, SHIPNAME: "EVER OMNI" },
  { MMSI: 636022572, IMO: 9961130, SHIPNAME: "STARSHIP JUPITER" },
  { MMSI: 636022573, IMO: 9961142, SHIPNAME: "STARSHIP NEPTUNE" },
  { MMSI: 636022600, IMO: 9934735, SHIPNAME: "MSC LORETO" },
  { MMSI: 636022601, IMO: 9929429, SHIPNAME: "MSC IRINA" },
  { MMSI: 636022603, IMO: 9936616, SHIPNAME: "MSC GEMMA" },
  { MMSI: 636022604, IMO: 9929431, SHIPNAME: "MSC MICHELCAPPELLINI" },
  { MMSI: 636022605, IMO: 9930064, SHIPNAME: "MSC NICOLA MASTRO" },
  { MMSI: 636022606, IMO: 9946843, SHIPNAME: "MSC NOA ARIELA" },
  { MMSI: 636022611, IMO: 9253038, SHIPNAME: "MSC CARPATHIA III" },
  { MMSI: 636022628, IMO: 9970430, SHIPNAME: "SINAR SIGLI" },
  { MMSI: 636022632, IMO: 9344708, SHIPNAME: "MSC GENERAL IV" },
  { MMSI: 636022635, IMO: 9311787, SHIPNAME: "HUTTON" },
  { MMSI: 636022640, IMO: 9947732, SHIPNAME: "SONGA PANTHER" },
  { MMSI: 636022657, IMO: 9120841, SHIPNAME: "MSC DOMNA X" },
  { MMSI: 636022658, IMO: 9146467, SHIPNAME: "MSC YUKTA X" },
  { MMSI: 636022663, IMO: 9936460, SHIPNAME: "KAPITAN SHCHETININA" },
  { MMSI: 636022664, IMO: 9936472, SHIPNAME: "MOSKVA" },
  { MMSI: 636022665, IMO: 9936484, SHIPNAME: "SANKT-PETERBURG" },
  { MMSI: 636022687, IMO: 9982483, SHIPNAME: "G.CROWN" },
  { MMSI: 636022688, IMO: 9982495, SHIPNAME: "G.DRAGON" },
  { MMSI: 636022708, IMO: 9450325, SHIPNAME: "MSC PRELUDE V" },
  { MMSI: 636022765, IMO: 9504059, SHIPNAME: "SEABOARD BLUE" },
  { MMSI: 636022776, IMO: 9304978, SHIPNAME: "CHIQUITA VOYAGER" },
  { MMSI: 636022787, IMO: 9986685, SHIPNAME: "CA TOKYO" },
  { MMSI: 636022795, IMO: 9290488, SHIPNAME: "MSC NIOVI VIII" },
  { MMSI: 636022799, IMO: 9444273, SHIPNAME: "MOL PRESENCE" },
  { MMSI: 636022809, IMO: 9237149, SHIPNAME: "AKHISAR" },
  { MMSI: 636022812, IMO: 9237498, SHIPNAME: "MSC LIPSIA III" },
  { MMSI: 636022814, IMO: 9900000, SHIPNAME: "LECANGS DOLPHIN" },
  { MMSI: 636022815, IMO: 9970442, SHIPNAME: "SINAR SIANTAR" },
  { MMSI: 636022871, IMO: 9293466, SHIPNAME: "MSC ADU V" },
  { MMSI: 636022873, IMO: 9618305, SHIPNAME: "MSC ATHENS" },
  { MMSI: 636022875, IMO: 9618317, SHIPNAME: "MSC ATHOS" },
  { MMSI: 636022879, IMO: 9947110, SHIPNAME: "MSC CHIYO" },
  { MMSI: 636022889, IMO: 9939137, SHIPNAME: "ONE INNOVATION" },
  { MMSI: 636022893, IMO: 9304966, SHIPNAME: "CHIQUITA EXPLORER" },
  { MMSI: 636022899, IMO: 9950076, SHIPNAME: "CAPE BRUNO" },
  { MMSI: 636022903, IMO: 9967407, SHIPNAME: "PELION" },
  { MMSI: 636022920, IMO: 9934747, SHIPNAME: "MSC MARIELLA" },
  { MMSI: 636022921, IMO: 9936628, SHIPNAME: "MSC CLAUDE GIRARDET" },
  { MMSI: 636022941, IMO: 9330068, SHIPNAME: "MSC SURABAYA VIII" },
  { MMSI: 636022942, IMO: 9153850, SHIPNAME: "MSC GIOVANNA VII" },
  { MMSI: 636022963, IMO: 9933119, SHIPNAME: "ONE INTEGRITY" },
  { MMSI: 636022973, IMO: 9970947, SHIPNAME: "SEABOARD GLOBE" },
  { MMSI: 636022977, IMO: 9238686, SHIPNAME: "LILA HAREN" },
  { MMSI: 636022978, IMO: 9117131, SHIPNAME: "SILK" },
  { MMSI: 636022980, IMO: 9163192, SHIPNAME: "MSC UMA" },
  { MMSI: 636022984, IMO: 9168843, SHIPNAME: "MSC UNIFIC VI" },
  { MMSI: 636023002, IMO: 9933004, SHIPNAME: "ONE INFINITY" },
  { MMSI: 636023015, IMO: 9970454, SHIPNAME: "SINAR SABA" },
  { MMSI: 636023016, IMO: 9193680, SHIPNAME: "MSC TIA II" },
  { MMSI: 636023036, IMO: 9200093, SHIPNAME: "BMI EAGLE" },
  { MMSI: 636023055, IMO: 9175039, SHIPNAME: "TJ ORHAN" },
  { MMSI: 636023059, IMO: 9260433, SHIPNAME: "GSL EFFIE" },
  { MMSI: 636023060, IMO: 9260457, SHIPNAME: "GSL ALEXANDRA" },
  { MMSI: 636023061, IMO: 9260421, SHIPNAME: "GSL SOFIA" },
  { MMSI: 636023062, IMO: 9260469, SHIPNAME: "ALBERT MAERSK" },
  { MMSI: 636023063, IMO: 9260419, SHIPNAME: "GSL LYDIA" },
  { MMSI: 636023065, IMO: 9248148, SHIPNAME: "MSC PILAR VI" },
  { MMSI: 636023083, IMO: 0, SHIPNAME: "MSC VALENTINA" },
  { MMSI: 636023084, IMO: 9936630, SHIPNAME: "MSC METTE" },
  { MMSI: 636023087, IMO: 9936642, SHIPNAME: "MSC CHINA" },
  { MMSI: 636023098, IMO: 9931290, SHIPNAME: "MSC MICOL" },
  { MMSI: 636023099, IMO: 9931288, SHIPNAME: "MSC TURKIYE" },
  { MMSI: 636023100, IMO: 9966934, SHIPNAME: "MSC JIANI" },
  { MMSI: 636023101, IMO: 9947122, SHIPNAME: "MSC VIVIENNE" },
  { MMSI: 636023126, IMO: 9596301, SHIPNAME: "XH DOLPHIN" },
  { MMSI: 636023142, IMO: 9744673, SHIPNAME: "VICTORIA FORTUNE" },
  { MMSI: 636023145, IMO: 9252541, SHIPNAME: "MSC MAGNUM VII" },
  { MMSI: 636023157, IMO: 9259379, SHIPNAME: "MSC ANCONA III" },
  { MMSI: 636023159, IMO: 9256365, SHIPNAME: "MSC TURIN II" },
  { MMSI: 636023175, IMO: 9960502, SHIPNAME: "ZIM EAGLE" },
  { MMSI: 636023185, IMO: 9256391, SHIPNAME: "MSC PAXI II" },
  { MMSI: 636023213, IMO: 9400148, SHIPNAME: "MSC LYSE V" },
  { MMSI: 636023243, IMO: 9980356, SHIPNAME: "LITTLE EMMA" },
  { MMSI: 636023250, IMO: 9757230, SHIPNAME: "YM WELLSPRING" },
  { MMSI: 636023251, IMO: 9820908, SHIPNAME: "YM WELLBEING" },
  { MMSI: 636023252, IMO: 9757228, SHIPNAME: "YM WARRANTY" },
  { MMSI: 636023265, IMO: 9947146, SHIPNAME: "MSC ANITA" },
  { MMSI: 636023266, IMO: 9946867, SHIPNAME: "MSC VICTORINE" },
  { MMSI: 636023267, IMO: 9946714, SHIPNAME: "MSC CANDIDA" },
  { MMSI: 636023268, IMO: 9947134, SHIPNAME: "MSC GIUSY" },
  { MMSI: 636023273, IMO: 9252230, SHIPNAME: "MSC REN V" },
  { MMSI: 636023290, IMO: 9933016, SHIPNAME: "ONE INGENUITY" },
  { MMSI: 636023293, IMO: 9969089, SHIPNAME: "CMA CGM MERCANTOUR" },
  { MMSI: 636023303, IMO: 9969091, SHIPNAME: "CMA CGM KRUGER" },
  { MMSI: 636023306, IMO: 9323493, SHIPNAME: "MSC IRA II" },
  { MMSI: 636023310, IMO: 9933121, SHIPNAME: "ONE INTELLIGENCE" },
  { MMSI: 636023312, IMO: 9955789, SHIPNAME: "MSC NAHARA" },
  { MMSI: 636023342, IMO: 9979254, SHIPNAME: "OOCL BREMERHAVEN" },
  { MMSI: 636023346, IMO: 9478494, SHIPNAME: "NAVIOS DOMINO" },
  { MMSI: 636023348, IMO: 9939149, SHIPNAME: "ONE INSPIRATION" },
  { MMSI: 636023398, IMO: 9946726, SHIPNAME: "MSC ORSOLA" },
  { MMSI: 636023415, IMO: 9936496, SHIPNAME: "KAPITAN ABONOSIMOV" },
  { MMSI: 636023420, IMO: 9252553, SHIPNAME: "MSC MAGNITUDE VII" },
  { MMSI: 636023421, IMO: 9605243, SHIPNAME: "MSC NAIROBI X" },
  { MMSI: 636023433, IMO: 9961960, SHIPNAME: "LOG-IN EVOLUTION" },
  { MMSI: 636023449, IMO: 9449845, SHIPNAME: "CONTSHIP BOX" },
  { MMSI: 636023461, IMO: 9149885, SHIPNAME: "FIONA" },
  { MMSI: 636023491, IMO: 9219379, SHIPNAME: "MSC POLONIA III" },
  { MMSI: 636023534, IMO: 9224518, SHIPNAME: "WAN HAI 612" },
  { MMSI: 636023535, IMO: 9224520, SHIPNAME: "WAN HAI 613" },
  { MMSI: 636023562, IMO: 9957347, SHIPNAME: "MSC THAIS" },
  { MMSI: 636023564, IMO: 9957359, SHIPNAME: "MSC ROSE" },
  { MMSI: 636023581, IMO: 9820910, SHIPNAME: "YM WONDERLAND" },
  { MMSI: 636023600, IMO: 9237096, SHIPNAME: "TB QUANZHOU" },
  { MMSI: 636090654, IMO: 9253296, SHIPNAME: "NORTHERN DECENCY" },
  { MMSI: 636090756, IMO: 9155391, SHIPNAME: "HANSA BREITENBURG" },
  { MMSI: 636090799, IMO: 9253026, SHIPNAME: "ANNIE B" },
  { MMSI: 636090856, IMO: 9246695, SHIPNAME: "LUTETIA" },
  { MMSI: 636090862, IMO: 9297840, SHIPNAME: "JPO LIBRA" },
  { MMSI: 636090863, IMO: 9297852, SHIPNAME: "JPO PISCES" },
  { MMSI: 636090967, IMO: 9314961, SHIPNAME: "MONACO" },
  { MMSI: 636090971, IMO: 9314973, SHIPNAME: "MONTPELLIER" },
  { MMSI: 636091031, IMO: 9298648, SHIPNAME: "DAPHNE" },
  { MMSI: 636091088, IMO: 9311880, SHIPNAME: "LETO" },
  { MMSI: 636091116, IMO: 9295361, SHIPNAME: "SANTA VANESSA" },
  { MMSI: 636091125, IMO: 9283708, SHIPNAME: "AS PETRA" },
  { MMSI: 636091130, IMO: 9283693, SHIPNAME: "AS PALINA" },
  { MMSI: 636091232, IMO: 9314985, SHIPNAME: "MARTINIQUE" },
  { MMSI: 636091273, IMO: 9326706, SHIPNAME: "NAJADE" },
  { MMSI: 636091308, IMO: 9333046, SHIPNAME: "BSG BARBADOS" },
  { MMSI: 636091384, IMO: 9348455, SHIPNAME: "NORTHERN GUARD" },
  { MMSI: 636091385, IMO: 9348467, SHIPNAME: "NORTHERN GUILD" },
  { MMSI: 636091400, IMO: 9373486, SHIPNAME: "HANSA HORNEBURG" },
  { MMSI: 636091401, IMO: 9373498, SHIPNAME: "HANSA WOLFSBURG" },
  { MMSI: 636091410, IMO: 9430155, SHIPNAME: "VICTORIA L" },
  { MMSI: 636091448, IMO: 9345984, SHIPNAME: "NORTHERN DEPENDANT" },
  { MMSI: 636091473, IMO: 9349801, SHIPNAME: "MATSON WAIKIKI" },
  { MMSI: 636091487, IMO: 9334521, SHIPNAME: "PONTRESINA" },
  { MMSI: 636091526, IMO: 9391799, SHIPNAME: "NORTHERN DISCOVERY" },
  { MMSI: 636091527, IMO: 9405033, SHIPNAME: "NORTHERN DIAMOND" },
  { MMSI: 636091575, IMO: 9373474, SHIPNAME: "HANSA AUGSBURG" },
  { MMSI: 636091619, IMO: 9372860, SHIPNAME: "ELBSPIRIT" },
  { MMSI: 636091654, IMO: 9383297, SHIPNAME: "SEABOARD RANGER" },
  { MMSI: 636091717, IMO: 9450301, SHIPNAME: "NORTHERN PRACTISE" },
  { MMSI: 636091731, IMO: 9383285, SHIPNAME: "SEABOARD OCEAN" },
  { MMSI: 636091744, IMO: 9400071, SHIPNAME: "ESL WASL" },
  { MMSI: 636091765, IMO: 9431812, SHIPNAME: "JACK LONDON" },
  { MMSI: 636091783, IMO: 9353230, SHIPNAME: "NORTHERN DIPLOMAT" },
  { MMSI: 636091784, IMO: 9391787, SHIPNAME: "NORTHERN DEMOCRAT" },
  { MMSI: 636091785, IMO: 9235816, SHIPNAME: "BUXLINK" },
  { MMSI: 636091823, IMO: 9357846, SHIPNAME: "HANSA RATZEBURG" },
  { MMSI: 636091835, IMO: 9467055, SHIPNAME: "NORTHERN POWER" },
  { MMSI: 636091883, IMO: 9456977, SHIPNAME: "MERKUR ARCHIPELAGO" },
  { MMSI: 636091916, IMO: 9481520, SHIPNAME: "PORTO" },
  { MMSI: 636091959, IMO: 9460356, SHIPNAME: "MSC SAVONA" },
  { MMSI: 636091995, IMO: 9294020, SHIPNAME: "JPO GEMINI" },
  { MMSI: 636092040, IMO: 9406829, SHIPNAME: "OPS HAMBURG" },
  { MMSI: 636092101, IMO: 9179828, SHIPNAME: "JAN RITSCHER" },
  { MMSI: 636092140, IMO: 9307841, SHIPNAME: "TIGER" },
  { MMSI: 636092181, IMO: 9481532, SHIPNAME: "HAMMONIA BALTICA" },
  { MMSI: 636092188, IMO: 9461441, SHIPNAME: "MSC BARI" },
  { MMSI: 636092240, IMO: 9301201, SHIPNAME: "MASTERY D" },
  { MMSI: 636092247, IMO: 9475258, SHIPNAME: "MSC TARANTO" },
  { MMSI: 636092268, IMO: 9484431, SHIPNAME: "MSC RAVENNA" },
  { MMSI: 636092270, IMO: 9480198, SHIPNAME: "MSC MADRID" },
  { MMSI: 636092271, IMO: 9484455, SHIPNAME: "MSC RAPALLO" },
  { MMSI: 636092391, IMO: 9349796, SHIPNAME: "MSC MONTEREY" },
  { MMSI: 636092424, IMO: 9539688, SHIPNAME: "JAKARTA EXPRESS" },
  { MMSI: 636092477, IMO: 9535101, SHIPNAME: "HANSA FRESENBURG" },
  { MMSI: 636092500, IMO: 9495777, SHIPNAME: "MOMBASA EXPRESS" },
  { MMSI: 636092570, IMO: 9436094, SHIPNAME: "HANSA STEINBURG" },
  { MMSI: 636092572, IMO: 9401673, SHIPNAME: "HANSA ROTENBURG" },
  { MMSI: 636092574, IMO: 9401661, SHIPNAME: "HANSA SIEGBURG" },
  { MMSI: 636092635, IMO: 9226372, SHIPNAME: "MAIKE D" },
  { MMSI: 636092678, IMO: 9612870, SHIPNAME: "TOLTEN" },
  { MMSI: 636092679, IMO: 9295957, SHIPNAME: "CHACABUCO" },
  { MMSI: 636092680, IMO: 9612882, SHIPNAME: "TIRUA" },
  { MMSI: 636092681, IMO: 9306196, SHIPNAME: "PALENA" },
  { MMSI: 636092682, IMO: 9400100, SHIPNAME: "MEHUIN" },
  { MMSI: 636092683, IMO: 9339612, SHIPNAME: "ALGOL" },
  { MMSI: 636092696, IMO: 9539664, SHIPNAME: "DACHAN BAY EXPRESS" },
  { MMSI: 636092697, IMO: 9495765, SHIPNAME: "ARICA EXPRESS" },
  { MMSI: 636092708, IMO: 9690092, SHIPNAME: "SEATRADE ORANGE" },
  { MMSI: 636092711, IMO: 9690107, SHIPNAME: "SEATRADE RED" },
  { MMSI: 636092712, IMO: 9756092, SHIPNAME: "SEATRADE WHITE" },
  { MMSI: 636092713, IMO: 9756107, SHIPNAME: "SEATRADE BLUE" },
  { MMSI: 636092764, IMO: 9502960, SHIPNAME: "MSC RUBY" },
  { MMSI: 636092765, IMO: 9401063, SHIPNAME: "ALS APOLLO" },
  { MMSI: 636092766, IMO: 9240328, SHIPNAME: "MSC PATNAREE III" },
  { MMSI: 636092775, IMO: 9687526, SHIPNAME: "COPIAPO" },
  { MMSI: 636092778, IMO: 9290440, SHIPNAME: "BELLAVIA" },
  { MMSI: 636092780, IMO: 9447897, SHIPNAME: "TEMPANOS" },
  { MMSI: 636092781, IMO: 9447859, SHIPNAME: "TENO" },
  { MMSI: 636092782, IMO: 9447914, SHIPNAME: "TORRENTE" },
  { MMSI: 636092783, IMO: 9447873, SHIPNAME: "TUBUL" },
  { MMSI: 636092784, IMO: 9569970, SHIPNAME: "TUCAPEL" },
  { MMSI: 636092786, IMO: 9687552, SHIPNAME: "CAUQUENES" },
  { MMSI: 636092792, IMO: 9687538, SHIPNAME: "CAUTIN" },
  { MMSI: 636092793, IMO: 9687540, SHIPNAME: "COCHRANE" },
  { MMSI: 636092798, IMO: 9687564, SHIPNAME: "CORCOVADO" },
  { MMSI: 636092799, IMO: 9687576, SHIPNAME: "CISNES" },
  { MMSI: 636092810, IMO: 9256389, SHIPNAME: "HANSA FREYBURG" },
  { MMSI: 636092814, IMO: 9687588, SHIPNAME: "COYHAIQUE" },
  { MMSI: 636092824, IMO: 9410284, SHIPNAME: "AS SVENJA" },
  { MMSI: 636092825, IMO: 9410260, SHIPNAME: "AS SAMANTA" },
  { MMSI: 636092826, IMO: 9387463, SHIPNAME: "AS SABRINA" },
  { MMSI: 636092827, IMO: 9410296, SHIPNAME: "AS SUSANNA" },
  { MMSI: 636092837, IMO: 9292436, SHIPNAME: "AS FREYA" },
  { MMSI: 636092840, IMO: 9292450, SHIPNAME: "AS FENJA" },
  { MMSI: 636092848, IMO: 9400083, SHIPNAME: "MAIPO" },
  { MMSI: 636092854, IMO: 9357523, SHIPNAME: "TORRES STRAIT" },
  { MMSI: 636092855, IMO: 9351218, SHIPNAME: "TASMAN STRAIT" },
  { MMSI: 636092860, IMO: 9312418, SHIPNAME: "AS PAOLA" },
  { MMSI: 636092863, IMO: 9294537, SHIPNAME: "AS PENELOPE" },
  { MMSI: 636092866, IMO: 9444417, SHIPNAME: "ALS CLIVIA" },
  { MMSI: 636092869, IMO: 9459412, SHIPNAME: "HANSA EUROPE" },
  { MMSI: 636092878, IMO: 9623673, SHIPNAME: "RDO FORTUNE" },
  { MMSI: 636092879, IMO: 9623661, SHIPNAME: "RDO FAVOUR" },
  { MMSI: 636092883, IMO: 9286255, SHIPNAME: "CONTI ANNAPURNA" },
  { MMSI: 636092884, IMO: 9200689, SHIPNAME: "CONTI DARWIN" },
  { MMSI: 636092895, IMO: 9718947, SHIPNAME: "ATACAMA" },
  { MMSI: 636092896, IMO: 9683867, SHIPNAME: "CCNI ANGOL" },
  { MMSI: 636092897, IMO: 9683843, SHIPNAME: "CCNI ARAUCO" },
  { MMSI: 636092898, IMO: 9718935, SHIPNAME: "CCNI ANDES" },
  { MMSI: 636092906, IMO: 9516765, SHIPNAME: "ANL KOKODA" },
  { MMSI: 636092911, IMO: 9302102, SHIPNAME: "RDO ENDEAVOUR" },
  { MMSI: 636092914, IMO: 9334818, SHIPNAME: "OKEE ALICIA" },
  { MMSI: 636092919, IMO: 9401283, SHIPNAME: "RDO CONCORD" },
  { MMSI: 636092927, IMO: 9286243, SHIPNAME: "CONTI MAKALU" },
  { MMSI: 636092930, IMO: 9306158, SHIPNAME: "CHENNAI EXPRESS" },
  { MMSI: 636092949, IMO: 9155365, SHIPNAME: "MV HANSA FLENSBURG" },
  { MMSI: 636092974, IMO: 9357793, SHIPNAME: "HANSA LANKA" },
  { MMSI: 636092984, IMO: 9349875, SHIPNAME: "PONA" },
  { MMSI: 636092986, IMO: 9330070, SHIPNAME: "LUANDA EXPRESS" },
  { MMSI: 636092995, IMO: 9339595, SHIPNAME: "CHIQUITA CENTURY" },
  { MMSI: 636092997, IMO: 9435222, SHIPNAME: "HANSA HARBURG" },
  { MMSI: 636092998, IMO: 9456989, SHIPNAME: "MERKUR HORIZOND" },
  { MMSI: 636093006, IMO: 9893852, SHIPNAME: "MAERSK NORBERG" },
  { MMSI: 636093009, IMO: 9893864, SHIPNAME: "MAERSK NACKA" },
  { MMSI: 636093010, IMO: 9893876, SHIPNAME: "MAERSK NASSJO" },
  { MMSI: 636093026, IMO: 9456989, SHIPNAME: "MERKUR HORIZON" },
  { MMSI: 636093027, IMO: 9303766, SHIPNAME: "BSG BONAIRE" },
  { MMSI: 636093030, IMO: 9333058, SHIPNAME: "BSG BIMINI" },
  { MMSI: 636093031, IMO: 9439498, SHIPNAME: "BSG BAHAMAS" },
  { MMSI: 636093041, IMO: 9293818, SHIPNAME: "CONTI CONQUEST" },
  { MMSI: 636093042, IMO: 9293789, SHIPNAME: "CONTI COURAGE" },
  { MMSI: 636093051, IMO: 9320427, SHIPNAME: "BUXWAVE" },
  { MMSI: 636093054, IMO: 9308390, SHIPNAME: "AS CONSTANTINA" },
  { MMSI: 636093055, IMO: 9335812, SHIPNAME: "AS SERENA" },
  { MMSI: 636093069, IMO: 9442172, SHIPNAME: "BF GIANT" },
  { MMSI: 636093081, IMO: 9405112, SHIPNAME: "MATADI EXPRESS" },
  { MMSI: 636093082, IMO: 9332872, SHIPNAME: "LE HAVRE EXPRESS" },
  { MMSI: 636093083, IMO: 9238765, SHIPNAME: "TEMA EXPRESS" },
  { MMSI: 636093085, IMO: 9344631, SHIPNAME: "OKEE GUSTAV" },
  { MMSI: 636093086, IMO: 9347279, SHIPNAME: "OKEE HENRI" },
  { MMSI: 636093093, IMO: 9293791, SHIPNAME: "CONTI CHIVALRY" },
  { MMSI: 636093094, IMO: 9293820, SHIPNAME: "CONTI CRYSTAL" },
  { MMSI: 636093095, IMO: 9449833, SHIPNAME: "WARNOW MASTER" },
  { MMSI: 636093100, IMO: 9332858, SHIPNAME: "SUAPE EXPRESS" },
  { MMSI: 636093108, IMO: 9403463, SHIPNAME: "DOUALA EXPRESS" },
  { MMSI: 636093117, IMO: 9400095, SHIPNAME: "KALAHARI EXPRESS" },
  { MMSI: 636093121, IMO: 9193288, SHIPNAME: "DALLAS EXPRESS" },
  { MMSI: 636093131, IMO: 9193305, SHIPNAME: "SEOUL EXPRESS" },
  { MMSI: 636093132, IMO: 9193317, SHIPNAME: "AMSTERDAM EXPRESS" },
  { MMSI: 636093137, IMO: 9732321, SHIPNAME: "AL JASRAH" },
  { MMSI: 636093139, IMO: 9349540, SHIPNAME: "JAZAN" },
  { MMSI: 636093140, IMO: 9349538, SHIPNAME: "AL MANAMAH" },
  { MMSI: 636093143, IMO: 9440825, SHIPNAME: "DUBAI EXPRESS" },
  { MMSI: 636093145, IMO: 9193290, SHIPNAME: "TOKYO EXPRESS" },
  { MMSI: 636093146, IMO: 9295256, SHIPNAME: "KYOTO EXPRESS" },
  { MMSI: 636093148, IMO: 9525900, SHIPNAME: "TANGIER EXPRESS" },
  { MMSI: 636093149, IMO: 9525924, SHIPNAME: "AL QIBLA EXPRESS" },
  { MMSI: 636093151, IMO: 9343716, SHIPNAME: "HANOVER EXPRESS" },
  { MMSI: 636093154, IMO: 9229843, SHIPNAME: "NINGBO EXPRESS" },
  { MMSI: 636093155, IMO: 9229829, SHIPNAME: "DALIAN EXPRESS" },
  { MMSI: 636093156, IMO: 9343728, SHIPNAME: "BREMEN EXPRESS" },
  { MMSI: 636093157, IMO: 9431343, SHIPNAME: "ALEXANDER L" },
  { MMSI: 636093158, IMO: 9779771, SHIPNAME: "RDO ACE" },
  { MMSI: 636093159, IMO: 9330549, SHIPNAME: "AS CLAUDIA" },
  { MMSI: 636093160, IMO: 9349497, SHIPNAME: "AL SAFAT" },
  { MMSI: 636093161, IMO: 9229831, SHIPNAME: "YANTIAN EXPRESS" },
  { MMSI: 636093164, IMO: 9525912, SHIPNAME: "AL RIFFA" },
  { MMSI: 636093166, IMO: 9732307, SHIPNAME: "AL DHAIL" },
  { MMSI: 636093167, IMO: 9295244, SHIPNAME: "COLOMBO EXPRESS" },
  { MMSI: 636093169, IMO: 9708796, SHIPNAME: "SALAHUDDIN" },
  { MMSI: 636093170, IMO: 9229855, SHIPNAME: "KIEL EXPRESS" },
  { MMSI: 636093171, IMO: 9708837, SHIPNAME: "AL MURABBA" },
  { MMSI: 636093172, IMO: 9525883, SHIPNAME: "ALULA EXPRESS" },
  { MMSI: 636093174, IMO: 9525936, SHIPNAME: "JEBEL ALI" },
  { MMSI: 636093175, IMO: 9525895, SHIPNAME: "TAYMA EXPRESS" },
  { MMSI: 636093176, IMO: 9525871, SHIPNAME: "UNAYZAH EXPRESS" },
  { MMSI: 636093177, IMO: 9320702, SHIPNAME: "TSINGTAO EXPRESS" },
  { MMSI: 636093178, IMO: 9320697, SHIPNAME: "OSAKA EXPRESS" },
  { MMSI: 636093181, IMO: 9732319, SHIPNAME: "AL MASHRAB" },
  { MMSI: 636093182, IMO: 0, SHIPNAME: "AIN SNAN EXPRESS" },
  { MMSI: 636093183, IMO: 9701279, SHIPNAME: "FRIDA RUSS" },
  { MMSI: 636093186, IMO: 9525857, SHIPNAME: "UMM SALAL" },
  { MMSI: 636093193, IMO: 9450430, SHIPNAME: "BUDAPEST EXPRESS" },
  { MMSI: 636093194, IMO: 9450416, SHIPNAME: "VIENNA EXPRESS" },
  { MMSI: 636093198, IMO: 9766047, SHIPNAME: "SAN VICENTE EXPRESS" },
  { MMSI: 636093210, IMO: 9813826, SHIPNAME: "AS SILJE" },
  { MMSI: 636093211, IMO: 9700251, SHIPNAME: "AS ANNE" },
  { MMSI: 636093212, IMO: 9813802, SHIPNAME: "AS SABINE" },
  { MMSI: 636093213, IMO: 9825403, SHIPNAME: "AS STINE" },
  { MMSI: 636093214, IMO: 9813814, SHIPNAME: "AS SIMONE" },
  { MMSI: 636093217, IMO: 9675810, SHIPNAME: "SCION MAFALDA" },
  { MMSI: 636093219, IMO: 9450428, SHIPNAME: "NAGOYA EXPRESS" },
  { MMSI: 636093220, IMO: 9332860, SHIPNAME: "BF HAMBURG" },
  { MMSI: 636093224, IMO: 9435674, SHIPNAME: "UAFL LIBERTY" },
  { MMSI: 636093250, IMO: 9345996, SHIPNAME: "NORTHERN DEXTERITY" },
  { MMSI: 667001467, IMO: 9121039, SHIPNAME: "VINCENT 2" },
  { MMSI: 667001869, IMO: 9130145, SHIPNAME: "VLADIVOSTOK" },
  { MMSI: 667002053, IMO: 8318128, SHIPNAME: "AMANY QUEEN" },
  { MMSI: 667002216, IMO: 8317978, SHIPNAME: "AB MOLY" },
  { MMSI: 671274100, IMO: 9100061, SHIPNAME: "KORMORAN" },
  { MMSI: 671302100, IMO: 9167083, SHIPNAME: "SERRANO" },
  { MMSI: 671315100, IMO: 7034878, SHIPNAME: "MARGI" },
  { MMSI: 671319100, IMO: 8714114, SHIPNAME: "JOE1" },
  { MMSI: 671390100, IMO: 9152909, SHIPNAME: "REYFA" },
  { MMSI: 671659000, IMO: 7041053, SHIPNAME: "PACIFIC M" },
  { MMSI: 677020010, IMO: 9121883, SHIPNAME: "S.J. RIMA" },
  { MMSI: 677020300, IMO: 8434453, SHIPNAME: "WAN LIMA" },
  { MMSI: 677026000, IMO: 9141900, SHIPNAME: "MIREMBE JUDITH" },
  { MMSI: 677026400, IMO: 9116711, SHIPNAME: "J.PIONEER" },
  { MMSI: 677053700, IMO: 9034688, SHIPNAME: "FARAHI 2" },
  { MMSI: 677060800, IMO: 9199775, SHIPNAME: "AL FILK" },
  { MMSI: 701104000, IMO: 9262871, SHIPNAME: "ARGENTINOII" },
  { MMSI: 710000200, IMO: 9356141, SHIPNAME: "MERCOSUL SUAPE" },
  { MMSI: 710000596, IMO: 9602875, SHIPNAME: "SEBASTIAO CABOTO" },
  { MMSI: 710000646, IMO: 9603219, SHIPNAME: "PEDRO ALVARES CABRAL" },
  { MMSI: 710000675, IMO: 9603221, SHIPNAME: "FERNAO DE MAGALHAES" },
  { MMSI: 710000872, IMO: 9625384, SHIPNAME: "BARTOLOMEU DIAS" },
  { MMSI: 710001270, IMO: 9356153, SHIPNAME: "MERCOSUL SANTOS" },
  { MMSI: 710003483, IMO: 9292137, SHIPNAME: "ALIANCA LEBLON" },
  { MMSI: 710003559, IMO: 9283227, SHIPNAME: "MONTE SARMIENTO" },
  { MMSI: 710003840, IMO: 9351799, SHIPNAME: "LOG IN PANTANAL" },
  { MMSI: 710004394, IMO: 9852365, SHIPNAME: "LOG-IN POLARIS" },
  { MMSI: 710004636, IMO: 9571296, SHIPNAME: "LOG-IN ENDURANCE" },
  { MMSI: 710006293, IMO: 9506394, SHIPNAME: "LOG-IN DISCOVERY" },
  { MMSI: 710006297, IMO: 9309289, SHIPNAME: "MSC BELMONTE III" },
  { MMSI: 710006643, IMO: 9294161, SHIPNAME: "MAERSK JALAN" },
  { MMSI: 710006930, IMO: 9471886, SHIPNAME: "LOG-IN JACARANDA" },
  { MMSI: 710009040, IMO: 9471898, SHIPNAME: "LOG-IN JATOBA" },
  { MMSI: 710027240, IMO: 9697002, SHIPNAME: "MERCOSUL ITAJAI" },
  { MMSI: 710033540, IMO: 9649835, SHIPNAME: "CMA CGM SANTOS" },
  { MMSI: 710033550, IMO: 9418377, SHIPNAME: "CMA CGM VERACRUZ" },
  { MMSI: 710074009, IMO: 9625396, SHIPNAME: "VICENTE PINZON" },
  { MMSI: 710074011, IMO: 9603233, SHIPNAME: "AMERICO VESPUCIO" },
  { MMSI: 710098000, IMO: 9327669, SHIPNAME: "LOG-IN RESILIENTE" },
  { MMSI: 725003474, IMO: 0, SHIPNAME: "ISLA TAUTIL" },
  { MMSI: 725017200, IMO: 9197351, SHIPNAME: "MAPOCHO" },
  { MMSI: 725017700, IMO: 9191632, SHIPNAME: "CONDOR" },
  { MMSI: 725019910, IMO: 9232981, SHIPNAME: "COPIHUE" },
  { MMSI: 776025700, IMO: 8820925, SHIPNAME: "VENUS .B" },
  { MMSI: 636015607, IMO: 9475703, SHIPNAME: "AMBITION" },
  { MMSI: 352002643, IMO: 9216729, SHIPNAME: "SHUI SPIRIT" },
  { MMSI: 357622000, IMO: 9242572, SHIPNAME: "BANYAS 2" },
  { MMSI: 574280000, IMO: 9279226, SHIPNAME: "BIENDONG NAVIGATOR" },
  { MMSI: 563051200, IMO: 9283186, SHIPNAME: "MAERSK MONTE LINZOR" },
  { MMSI: 477552800, IMO: 9294381, SHIPNAME: "MAERSK NOMAZWE" },
  { MMSI: 563052400, IMO: 9348077, SHIPNAME: "MAERSK MONTE LASCAR" },
  { MMSI: 477211100, IMO: 9355355, SHIPNAME: "MAERSK BAYETE" },
  { MMSI: 477883100, IMO: 9403011, SHIPNAME: "SEASPAN YINGKOU" },
  { MMSI: 477521800, IMO: 9404510, SHIPNAME: "CUL HAIPHONG" },
  { MMSI: 477714100, IMO: 9461506, SHIPNAME: "CHARLESTON" },
  { MMSI: 241312000, IMO: 9475686, SHIPNAME: "KOTA PLUMBAGO" },
  { MMSI: 212018000, IMO: 9483669, SHIPNAME: "X-PRESEAN<BE" },
  { MMSI: 255806505, IMO: 9584487, SHIPNAME: "HELENA" },
  { MMSI: 636018700, IMO: 9667150, SHIPNAME: "HOUSTON EXPRESS" },
  { MMSI: 563052800, IMO: 9699206, SHIPNAME: "MAERSK SAN VICENTE" },
  { MMSI: 563143400, IMO: 9354404, SHIPNAME: "ATLANTIC GREEN" },
  { MMSI: 248473000, IMO: 9729104, SHIPNAME: "CMA CGM J JOSEPH" },
  { MMSI: 563131700, IMO: 9744685, SHIPNAME: "TIGER PLATA" },
  { MMSI: 563166100, IMO: 9893993, SHIPNAME: "EVER A OP" },
  { MMSI: 503789400, IMO: 0, SHIPNAME: "RUMBA" },
  { MMSI: 373485000, IMO: 11139246, SHIPNAME: "ONE HOUSZON" },
  { MMSI: 413337240, IMO: 0, SHIPNAME: "XIN MING ZHOU96" },
  { MMSI: 413459620, IMO: 0, SHIPNAME: "XIN MING ZHOU 80" },
  { MMSI: 440009000, IMO: 9637246, SHIPNAME: "HMM DRIVE" },
  { MMSI: 574491000, IMO: 0, SHIPNAME: "STAR TC" },
  { MMSI: 355028000, IMO: 9008603, SHIPNAME: "MSC ORAN III" },
  { MMSI: 548612300, IMO: 9050101, SHIPNAME: "M/V DON ALFONSO SR.2" },
  { MMSI: 356167000, IMO: 9064750, SHIPNAME: "MSC ELEONORA III" },
  { MMSI: 525016244, IMO: 9070278, SHIPNAME: "KM TANTO ABADI" },
  { MMSI: 255915714, IMO: 9202077, SHIPNAME: "ILHA DA MADEIRA" },
  { MMSI: 477281000, IMO: 9078464, SHIPNAME: "JI YU" },
  { MMSI: 525003667, IMO: 9101560, SHIPNAME: "MV.HIJAU SAMUDRA" },
  { MMSI: 357455000, IMO: 9102722, SHIPNAME: "MSC CANBERRA III" },
  { MMSI: 310133000, IMO: 9108130, SHIPNAME: "VALENCIA EXPRESS" },
  { MMSI: 371443000, IMO: 9110975, SHIPNAME: "MSC JAPAN III" },
  { MMSI: 533170323, IMO: 9117820, SHIPNAME: "MV HARBOUR RUBY" },
  { MMSI: 371243000, IMO: 9123154, SHIPNAME: "MSC KOREA III" },
  { MMSI: 511100639, IMO: 9132399, SHIPNAME: "GAIA" },
  { MMSI: 256339000, IMO: 9985942, SHIPNAME: "ECO MAESTRO" },
  { MMSI: 351218000, IMO: 9134684, SHIPNAME: "BOAT BLESSING" },
  { MMSI: 413301510, IMO: 9142265, SHIPNAME: "RONG XING 1" },
  { MMSI: 314292000, IMO: 9143829, SHIPNAME: "ARCTIC COMMANDER" },
  { MMSI: 373314000, IMO: 9147227, SHIPNAME: "MSC SUPARNA F" },
  { MMSI: 538010670, IMO: 9149897, SHIPNAME: "AYDOGAN" },
  { MMSI: 538009951, IMO: 9150406, SHIPNAME: "TB LINHAI" },
  { MMSI: 636018325, IMO: 9162643, SHIPNAME: "MSC LEA II" },
  { MMSI: 503095010, IMO: 0, SHIPNAME: "ATLANTIS" },
  { MMSI: 376609000, IMO: 9142447, SHIPNAME: "ALLEGRO" },
  { MMSI: 636092880, IMO: 9162679, SHIPNAME: "KINGSTON TRADER" },
  { MMSI: 356578000, IMO: 9168245, SHIPNAME: "PANDA ALTAIR" },
  { MMSI: 636022985, IMO: 9168831, SHIPNAME: "MSC FLORIANA VI" },
  { MMSI: 636018326, IMO: 9169067, SHIPNAME: "MSC MIA SUMMER II" },
  { MMSI: 357077000, IMO: 9169158, SHIPNAME: "MSC UNITY VI" },
  { MMSI: 405000309, IMO: 9175614, SHIPNAME: "HR HERA" },
  { MMSI: 368011050, IMO: 0, SHIPNAME: "MYRIAD" },
  { MMSI: 503094380, IMO: 0, SHIPNAME: "RELIANCE" },
  { MMSI: 352002302, IMO: 9179983, SHIPNAME: "ERK IZMIR" },
  { MMSI: 538003191, IMO: 9364930, SHIPNAME: "MARVEL" },
  { MMSI: 677044200, IMO: 8101434, SHIPNAME: "MUSE" },
  { MMSI: 352002621, IMO: 9182021, SHIPNAME: "LUCKY BLESSING" },
  { MMSI: 636016979, IMO: 9189354, SHIPNAME: "DIMITRIS Y" },
  { MMSI: 636016978, IMO: 9189366, SHIPNAME: "MSC TAVVISHI VI" },
  { MMSI: 525003018, IMO: 9193965, SHIPNAME: "ALEXINDO 1" },
  { MMSI: 636019355, IMO: 9196840, SHIPNAME: "TAMPA I" },
  { MMSI: 636092953, IMO: 9200706, SHIPNAME: "PUSAN" },
  { MMSI: 357131000, IMO: 9202156, SHIPNAME: "MYD NINGBO" },
  { MMSI: 636018927, IMO: 9507788, SHIPNAME: "STAR BRIGHT" },
  { MMSI: 357787000, IMO: 9202168, SHIPNAME: "MYD TIANJIN" },
  { MMSI: 357962000, IMO: 9202170, SHIPNAME: "CAIRO PYRAMID" },
  { MMSI: 416459000, IMO: 9202247, SHIPNAME: "UNI PROSPER" },
  { MMSI: 525113005, IMO: 9202900, SHIPNAME: "KM TANTO BERSINAR" },
  { MMSI: 538005039, IMO: 9212022, SHIPNAME: "CACHALOT" },
  { MMSI: 636020351, IMO: 9218650, SHIPNAME: "TORRANCE" },
  { MMSI: 636019864, IMO: 9220847, SHIPNAME: "MSC AKITETA II" },
  { MMSI: 636019825, IMO: 9222285, SHIPNAME: "MSC SUAPE VII" },
  { MMSI: 351702000, IMO: 9224350, SHIPNAME: "OCEAN BREEZE I" },
  { MMSI: 636092910, IMO: 9225615, SHIPNAME: "SAN FRANCISCO" },
  { MMSI: 636092932, IMO: 9225641, SHIPNAME: "ILONA" },
  { MMSI: 636092976, IMO: 9225653, SHIPNAME: "ALESSIA" },
  { MMSI: 563007700, IMO: 9230787, SHIPNAME: "PFL MATAI" },
  { MMSI: 305299000, IMO: 9231834, SHIPNAME: "BJORG" },
  { MMSI: 352986168, IMO: 9232412, SHIPNAME: "MV.SPIL NISAKA" },
  { MMSI: 215424000, IMO: 9238076, SHIPNAME: "ONUR G A" },
  { MMSI: 636015897, IMO: 9238777, SHIPNAME: "MSC BAY IV" },
  { MMSI: 353089000, IMO: 9241009, SHIPNAME: "HE SHUN" },
  { MMSI: 636022865, IMO: 9241205, SHIPNAME: "MSC LARA III" },
  { MMSI: 356162000, IMO: 9241918, SHIPNAME: "TSS AMBER" },
  { MMSI: 636020939, IMO: 9243198, SHIPNAME: "SOURCE BLESSING" },
  { MMSI: 636015321, IMO: 9243617, SHIPNAME: "TOR" },
  { MMSI: 209423000, IMO: 9247754, SHIPNAME: "MARIA H" },
  { MMSI: 255805676, IMO: 9248162, SHIPNAME: "PANDA 008" },
  { MMSI: 563984000, IMO: 9250957, SHIPNAME: "MV SINAR BINTAN" },
  { MMSI: 256075000, IMO: 9250971, SHIPNAME: "SAVANNAH" },
  { MMSI: 255806107, IMO: 9252797, SHIPNAME: "LARS D" },
  { MMSI: 304019000, IMO: 9255763, SHIPNAME: "COLOMBO TRADER" },
  { MMSI: 636023058, IMO: 9260445, SHIPNAME: "MARIA Y" },
  { MMSI: 636014573, IMO: 9261449, SHIPNAME: "MSC IKARIA VI" },
  { MMSI: 548471100, IMO: 9264233, SHIPNAME: "LORCON GEN SANTOS" },
  { MMSI: 636016673, IMO: 9267637, SHIPNAME: "MSC PEGASUS VII" },
  { MMSI: 352948000, IMO: 9278155, SHIPNAME: "MSC NERISSA V" },
  { MMSI: 355502000, IMO: 9278985, SHIPNAME: "SOL PRIME" },
  { MMSI: 636019515, IMO: 9282168, SHIPNAME: "AMU JAMEEL" },
  { MMSI: 563052500, IMO: 9283198, SHIPNAME: "MAERSK MONTE OLIVIA" },
  { MMSI: 563051300, IMO: 9283203, SHIPNAME: "MAERSK MONTE PASCOAL" },
  { MMSI: 566415000, IMO: 9289180, SHIPNAME: "MAERSK NAMIBIA" },
  { MMSI: 636016853, IMO: 9289556, SHIPNAME: "MSC MANHATTAN V" },
  { MMSI: 477020200, IMO: 9290103, SHIPNAME: "SEASPAN SYDNEY" },
  { MMSI: 477070800, IMO: 9290139, SHIPNAME: "SEASPAN BRISBANE" },
  { MMSI: 255806255, IMO: 9290799, SHIPNAME: "ESL OMAN" },
  { MMSI: 255915647, IMO: 9290945, SHIPNAME: "ESL SHEKOU" },
  { MMSI: 353346000, IMO: 9293557, SHIPNAME: "AYA 1" },
  { MMSI: 477552200, IMO: 9294393, SHIPNAME: "MAERSK NOKWANDA" },
  { MMSI: 255805778, IMO: 9294812, SHIPNAME: "PANDA 009" },
  { MMSI: 256938000, IMO: 9305582, SHIPNAME: "YANTIAN I" },
  { MMSI: 636019697, IMO: 9309162, SHIPNAME: "MSC ACE II" },
  { MMSI: 525197000, IMO: 9128312, SHIPNAME: "KM HAPPY STAR 1" },
  { MMSI: 525015026, IMO: 9310501, SHIPNAME: "MV CJ.III MULIANIM" },
  { MMSI: 538009605, IMO: 9315343, SHIPNAME: "BRICKELL" },
  { MMSI: 538009604, IMO: 9315355, SHIPNAME: "BOSTON" },
  { MMSI: 255803260, IMO: 9315381, SHIPNAME: "MSC AGAMEMNON VIII" },
  { MMSI: 538009388, IMO: 9320142, SHIPNAME: "ELISABETH P." },
  { MMSI: 441399000, IMO: 9323508, SHIPNAME: "HMM COLOMBO" },
  { MMSI: 477940500, IMO: 9932505, SHIPNAME: "ZIM MOUNT OLYMPUS" },
  { MMSI: 441343000, IMO: 9323510, SHIPNAME: "HMM BANGKOK" },
  { MMSI: 413284260, IMO: 9330757, SHIPNAME: "JIANGYUANYUANBO" },
  { MMSI: 215640000, IMO: 9335202, SHIPNAME: "GSL CHATEAU D IF" },
  { MMSI: 636022636, IMO: 9336177, SHIPNAME: "BEROLINA C" },
  { MMSI: 636021005, IMO: 9338084, SHIPNAME: "GSL MAMITSA" },
  { MMSI: 413703630, IMO: 9339026, SHIPNAME: "HAI SU 6" },
  { MMSI: 636017112, IMO: 9339583, SHIPNAME: "SHIRIN M" },
  { MMSI: 413230310, IMO: 9342683, SHIPNAME: "JIANG YUAN XING FU" },
  { MMSI: 304978000, IMO: 9347774, SHIPNAME: "MAKRELL" },
  { MMSI: 563050200, IMO: 9348089, SHIPNAME: "MAERSK RIO BLANCO" },
  { MMSI: 255805686, IMO: 9354428, SHIPNAME: "SARA BORCHARD" },
  { MMSI: 477552400, IMO: 9355367, SHIPNAME: "MAERSK BENGUELA" },
  { MMSI: 563185300, IMO: 9366495, SHIPNAME: "WIND" },
  { MMSI: 352980000, IMO: 9378010, SHIPNAME: "ATA 1" },
  { MMSI: 215418000, IMO: 9379351, SHIPNAME: "JEAN PIERRE A" },
  { MMSI: 440062000, IMO: 9385013, SHIPNAME: "HMM OAKLAND" },
  { MMSI: 357842000, IMO: 9385439, SHIPNAME: "SUNWIN" },
  { MMSI: 477111800, IMO: 9385984, SHIPNAME: "SEASPAN MONTEVIDEO" },
  { MMSI: 477141200, IMO: 9386005, SHIPNAME: "SEASPAN LIMA" },
  { MMSI: 305978000, IMO: 9387592, SHIPNAME: "MARBURG" },
  { MMSI: 249509000, IMO: 9389708, SHIPNAME: "MONACO" },
  { MMSI: 305079000, IMO: 9390472, SHIPNAME: "IREMOS" },
  { MMSI: 255803480, IMO: 9393307, SHIPNAME: "MSC RIDA VIII" },
  { MMSI: 255915660, IMO: 9396634, SHIPNAME: "EVI" },
  { MMSI: 636014219, IMO: 9398400, SHIPNAME: "NINGBO" },
  { MMSI: 636014221, IMO: 9398412, SHIPNAME: "SAN DIEGO" },
  { MMSI: 636014220, IMO: 9398448, SHIPNAME: "ANTWERP" },
  { MMSI: 636014222, IMO: 9398450, SHIPNAME: "ROTTERDAM" },
  { MMSI: 636017360, IMO: 9399741, SHIPNAME: "MSC MELANI III" },
  { MMSI: 477690800, IMO: 9403009, SHIPNAME: "SEASPAN FUZHOU" },
  { MMSI: 636017425, IMO: 9409170, SHIPNAME: "CORNEILLE" },
  { MMSI: 255806313, IMO: 9412517, SHIPNAME: "KILIA" },
  { MMSI: 636017515, IMO: 9426805, SHIPNAME: "HT CAPRICORN" },
  { MMSI: 273612530, IMO: 9430052, SHIPNAME: "TRANSIT SHAMORA" },
  { MMSI: 636091766, IMO: 9431824, SHIPNAME: "ANL WAIKATO" },
  { MMSI: 305412000, IMO: 9440590, SHIPNAME: "SUNSET X" },
  { MMSI: 636023450, IMO: 9444974, SHIPNAME: "SEA STAR 1" },
  { MMSI: 574005640, IMO: 9445007, SHIPNAME: "HAIAN DELL" },
  { MMSI: 209056000, IMO: 9448671, SHIPNAME: "NORDIC PORTO" },
  { MMSI: 441111000, IMO: 9463085, SHIPNAME: "HMM VANCOUVER" },
  { MMSI: 255715000, IMO: 9470894, SHIPNAME: "BALDUR" },
  { MMSI: 636015514, IMO: 9473731, SHIPNAME: "KOTA PEONY" },
  { MMSI: 636015516, IMO: 9475674, SHIPNAME: "KOTA PRIMROSE" },
  { MMSI: 241313000, IMO: 9475698, SHIPNAME: "SPEED" },
  { MMSI: 305741000, IMO: 9492505, SHIPNAME: "BERNHARD SCHEPERS" },
  { MMSI: 538005308, IMO: 9504607, SHIPNAME: "AL RAWDAH" },
  { MMSI: 304509000, IMO: 9508407, SHIPNAME: "BBC EVEREST" },
  { MMSI: 255806099, IMO: 9509774, SHIPNAME: "MSC AGNA II" },
  { MMSI: 209140000, IMO: 9509786, SHIPNAME: "CONTSHIP DAY" },
  { MMSI: 209425000, IMO: 9509803, SHIPNAME: "CONTSHIP CUP" },
  { MMSI: 477802700, IMO: 9531909, SHIPNAME: "HANS SHULTE" },
  { MMSI: 525015347, IMO: 9545754, SHIPNAME: "MV.PULAU LAYANG" },
  { MMSI: 219023731, IMO: 0, SHIPNAME: "MARY" },
  { MMSI: 265807290, IMO: 0, SHIPNAME: "ALEXANDRA" },
  { MMSI: 367704150, IMO: 0, SHIPNAME: "KRISTINA" },
  { MMSI: 367161940, IMO: 7914585, SHIPNAME: "KATHERINE" },
  { MMSI: 525015393, IMO: 9551088, SHIPNAME: "MV TELUK FLAMINGGO" },
  { MMSI: 525019465, IMO: 9558488, SHIPNAME: "MV ESTUARI MAS" },
  { MMSI: 525019510, IMO: 9564061, SHIPNAME: "MV.MARE MAS" },
  { MMSI: 477699600, IMO: 9630418, SHIPNAME: "YOKOHAMA EXPRESS" },
  { MMSI: 477790500, IMO: 9630420, SHIPNAME: "XIAMEN EXPRESS" },
  { MMSI: 636093114, IMO: 9631113, SHIPNAME: "RDO LIBERTY" },
  { MMSI: 368004430, IMO: 0, SHIPNAME: "ALEXIS" },
  { MMSI: 229949000, IMO: 9634646, SHIPNAME: "ST. JOHN" },
  { MMSI: 441981000, IMO: 9637222, SHIPNAME: "HMM DREAM" },
  { MMSI: 440176000, IMO: 9637234, SHIPNAME: "HMM HOPE" },
  { MMSI: 441754000, IMO: 9637258, SHIPNAME: "HMM VICTORY" },
  { MMSI: 367775550, IMO: 0, SHIPNAME: "LADYJANE" },
  { MMSI: 441486000, IMO: 9637260, SHIPNAME: "HMM PRIDE" },
  { MMSI: 538004893, IMO: 9650066, SHIPNAME: "ERASMUS LEO" },
  { MMSI: 636021036, IMO: 9665621, SHIPNAME: "BALTIMORE EXPRESS" },
  { MMSI: 564575000, IMO: 9665633, SHIPNAME: "LOS ANGELES EXPRESS" },
  { MMSI: 636093018, IMO: 9665645, SHIPNAME: "CHARLESTON EXPRESS" },
  { MMSI: 636018702, IMO: 9667162, SHIPNAME: "ATLANTA EXPRESS" },
  { MMSI: 636018703, IMO: 9667174, SHIPNAME: "OAKLAND EXPRESS" },
  { MMSI: 636018701, IMO: 9667186, SHIPNAME: "VANCOUVER EXPRESS" },
  { MMSI: 525019381, IMO: 9670274, SHIPNAME: "TASIK MAS" },
  { MMSI: 636015872, IMO: 9677026, SHIPNAME: "PANDA 006" },
  { MMSI: 636023127, IMO: 9683489, SHIPNAME: "VENTO DI LEVANTE" },
  { MMSI: 538005959, IMO: 9686912, SHIPNAME: "JAMAICA EXPRESS" },
  { MMSI: 367458640, IMO: 8333207, SHIPNAME: "RIO BRAVO" },
  { MMSI: 538005519, IMO: 9691761, SHIPNAME: "SKY JADE" },
  { MMSI: 538005520, IMO: 9691773, SHIPNAME: "SKY MOON" },
  { MMSI: 563417000, IMO: 9694414, SHIPNAME: "MARY SCHULTE" },
  { MMSI: 710128712, IMO: 0, SHIPNAME: "RIO MADEIRA" },
  { MMSI: 538005747, IMO: 9694567, SHIPNAME: "EUPHRATES" },
  { MMSI: 636093197, IMO: 9699127, SHIPNAME: "SCI DELHI" },
  { MMSI: 563049900, IMO: 9699189, SHIPNAME: "MAERSK SAN CLEMENTE" },
  { MMSI: 563051100, IMO: 9699191, SHIPNAME: "MAERSK SANCHRISTOBAL" },
  { MMSI: 215499000, IMO: 9706308, SHIPNAME: "CMA CGM AMAZON" },
  { MMSI: 413346480, IMO: 9714915, SHIPNAME: "AN TONG DA LIAN" },
  { MMSI: 413209990, IMO: 9714927, SHIPNAME: "AN TONG FU ZHOU" },
  { MMSI: 413495140, IMO: 9736547, SHIPNAME: "ZHONG GU GUANG ZHOU" },
  { MMSI: 563024800, IMO: 9743497, SHIPNAME: "MATTHEW SCHULTE" },
  { MMSI: 563024900, IMO: 9743502, SHIPNAME: "MAGDALENA SCHULTE" },
  { MMSI: 636023143, IMO: 9744661, SHIPNAME: "ANDREI TITOV" },
  { MMSI: 477157700, IMO: 9767998, SHIPNAME: "VUNG TAU EXPRESS" },
  { MMSI: 477166700, IMO: 9768007, SHIPNAME: "LAEM CHABANG EXPRESS" },
  { MMSI: 477166300, IMO: 9778129, SHIPNAME: "HAIPHONG EXPRESS" },
  { MMSI: 477159100, IMO: 9778131, SHIPNAME: "NANSHA EXPRESS" },
  { MMSI: 620999325, IMO: 9133147, SHIPNAME: "BLUE STAR 3" },
  { MMSI: 265685890, IMO: 0, SHIPNAME: "SOFIA 3" },
  { MMSI: 215137000, IMO: 9780897, SHIPNAME: "CMA CGM J.MADISON" },
  { MMSI: 477455200, IMO: 9848754, SHIPNAME: "LAE CHIEF" },
  { MMSI: 477615600, IMO: 9848778, SHIPNAME: "MOTUKEA CHIEF" },
  { MMSI: 525113017, IMO: 9863895, SHIPNAME: "MV TANTO SEJAHTERA" },
  { MMSI: 477928600, IMO: 9922598, SHIPNAME: "OOCL VALENCIA" },
  { MMSI: 525301156, IMO: 9925538, SHIPNAME: "MV SPIL RAHAYU :)" },
  { MMSI: 538007771, IMO: 9793753, SHIPNAME: "FOLEGANDROS" },
  { MMSI: 255805270, IMO: 9932921, SHIPNAME: "MSC ADYA" },
  { MMSI: 367565520, IMO: 0, SHIPNAME: "LANA" },
  { MMSI: 636022175, IMO: 9951159, SHIPNAME: "SEABOARD GENESIS" },
  { MMSI: 255903590, IMO: 9954151, SHIPNAME: "DANUBE" },
  { MMSI: 255994000, IMO: 9954163, SHIPNAME: "GANGES" },
  { MMSI: 563220900, IMO: 9958092, SHIPNAME: "WAN HAI 370" },
  { MMSI: 636023074, IMO: 9960497, SHIPNAME: "SPARROW" },
  { MMSI: 538010876, IMO: 9960796, SHIPNAME: "TS COLOMBO" },
  { MMSI: 256708000, IMO: 9961283, SHIPNAME: "CMA CGM MERMAID" },
  { MMSI: 477929700, IMO: 9967988, SHIPNAME: "ZIM OPAL" },
  { MMSI: 636023371, IMO: 9969625, SHIPNAME: "SEABOARD GEMINI" },
  { MMSI: 636022345, IMO: 9983413, SHIPNAME: "PALU BAY" },
  { MMSI: 413123456, IMO: 413123456, SHIPNAME: "1234" },
  { MMSI: 525015583, IMO: 8902137, SHIPNAME: "ORIENTAL JADE" },
  { MMSI: 422026500, IMO: 9270658, SHIPNAME: "M.V SHAMIM" },
  { MMSI: 215426000, IMO: 9305908, SHIPNAME: "HILDE A" },
  { MMSI: 636013001, IMO: 9318175, SHIPNAME: "MSC KILIMANJARO IV" },
  { MMSI: 525019101, IMO: 9672387, SHIPNAME: "MV UMBUL MAS" },
  { MMSI: 352004964, IMO: 9905497, SHIPNAME: "NEWNEW MOON" },
  { MMSI: 319058900, IMO: 1011238, SHIPNAME: "CALLIOPE" },
  { MMSI: 413381290, IMO: 9810276, SHIPNAME: "ZHONG GU BEI JING" },
  { MMSI: 525100889, IMO: 9843194, SHIPNAME: "MV.ICON DANIEL" },
  { MMSI: 205568590, IMO: 0, SHIPNAME: "BEETHOVEN" },
  { MMSI: 525013031, IMO: 9055498, SHIPNAME: "TANTO SUBUR 1" },
  { MMSI: 525013018, IMO: 9167526, SHIPNAME: "KM TANTO RAYA" },
  { MMSI: 636016575, IMO: 9173135, SHIPNAME: "MSC WIND II" },
  { MMSI: 525006403, IMO: 9720665, SHIPNAME: "WGM 256T" },
  { MMSI: 477867500, IMO: 9936410, SHIPNAME: "MAERSK CINCINNATI" },
  { MMSI: 525013029, IMO: 9683506, SHIPNAME: "TANTO KARUNIA II" },
  { MMSI: 525022407, IMO: 9791767, SHIPNAME: "MV GULF MAS" },
  { MMSI: 525101470, IMO: 9834533, SHIPNAME: "KM SPIL RENATA" },
  { MMSI: 357737000, IMO: 9168867, SHIPNAME: "MSC URSULA VI" },
  { MMSI: 374629000, IMO: 9367944, SHIPNAME: "TRADEWIND" },
  { MMSI: 244058988, IMO: 0, SHIPNAME: "ELIZABETH" },
  { MMSI: 518999319, IMO: 9270517, SHIPNAME: "CINDY" },
  { MMSI: 525113006, IMO: 9816452, SHIPNAME: "KM TANTO SELALU" },
  { MMSI: 636017608, IMO: 9358436, SHIPNAME: "MSC RENAISSANCE III" },
  { MMSI: 525019417, IMO: 9562063, SHIPNAME: "KM JALES MAS" },
  { MMSI: 372800000, IMO: 9472567, SHIPNAME: "SPARKLE" },
  { MMSI: 312637000, IMO: 1039424, SHIPNAME: "RUN DA 8" },
  { MMSI: 209705000, IMO: 9364356, SHIPNAME: "SAFESEA RAMA" },
  { MMSI: 538090601, IMO: 9306823, SHIPNAME: "KAMDHENU" },
  { MMSI: 565849000, IMO: 9318266, SHIPNAME: "SSF LILY" },
  { MMSI: 567317000, IMO: 9308663, SHIPNAME: "ABAB" },
  { MMSI: 357562000, IMO: 8201636, SHIPNAME: "MALIN" },
  { MMSI: 416335000, IMO: 9208174, SHIPNAME: "233" },
  { MMSI: 416337000, IMO: 9208186, SHIPNAME: "GHIBLI" },
  { MMSI: 636091848, IMO: 9150212, SHIPNAME: "TB KAIYUAN" },
  { MMSI: 538005687, IMO: 9224324, SHIPNAME: "SFT SAUDI" },
  { MMSI: 357897000, IMO: 9129756, SHIPNAME: "RAJ_ RANI.__ ." },
  { MMSI: 636092887, IMO: 9222273, SHIPNAME: "MSC BRASILIA VII" },
  { MMSI: 265525270, IMO: 0, SHIPNAME: "CIMBRIA" },
  { MMSI: 357493000, IMO: 9168855, SHIPNAME: "URANUS" },
  { MMSI: 636023280, IMO: 9230218, SHIPNAME: "WANDE" },
  { MMSI: 636092672, IMO: 739103445, SHIPNAME: "AS PAULINE" },
  { MMSI: 255806458, IMO: 9321897, SHIPNAME: "MSC NADIA IV" },
  { MMSI: 725001547, IMO: 9612789, SHIPNAME: "NC BRISA" },
  { MMSI: 256651000, IMO: 9938327, SHIPNAME: "PRESIDENT JQ ADAMS" },
  { MMSI: 538090563, IMO: 9151917, SHIPNAME: "YC BARRIER" },
  { MMSI: 636016186, IMO: 9367542, SHIPNAME: "TANTO NUSANTARA" },
  { MMSI: 414909000, IMO: 9978602, SHIPNAME: "XIANG YU 093" },
  { MMSI: 249570000, IMO: 9315032, SHIPNAME: "BALTIC SHARK" },
  { MMSI: 533130646, IMO: 9165449, SHIPNAME: "MANIS 7" },
  { MMSI: 725001534, IMO: 9612777, SHIPNAME: "NC BRUMA" },
  { MMSI: 620999357, IMO: 0, SHIPNAME: "PSL TIGER" },
  { MMSI: 620999523, IMO: 9168532, SHIPNAME: "S J BOSS" },
  { MMSI: 414861000, IMO: 9962574, SHIPNAME: "MSC MARIE" },
  { MMSI: 636090636, IMO: 9155377, SHIPNAME: "SLS AZURE" },
];
