/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import ScheduleLogiWorkLCL from "./lcl/ScheduleLogiWorkLCL";
import ScheduleLogiWorkFCL from "./fcl/ScheduleLogiWorkFCL";
import ScheduleLogiWorkAIR from "./air/ScheduleLogiWorkAIR";
import ScheduleLogiWorkBookList from "./bookList/ScheduleLogiWorkBookList";

import Loading from "../../../lib/Loading";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import Utilities from "../../../lib/Utilities";
import Oceanlook from "../../../lib/oceanlook";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();
const oceanlook = new Oceanlook();

const _mClassName = "ScheduleLogiWork";

const pages = [
  {
    key: "schedule_lcl",
    name: "해상 LCL",
    page: (params) => <ScheduleLogiWorkLCL {...params} />,
  },
  {
    key: "schedule_fcl",
    name: "해상 FCL",
    page: (params) => <ScheduleLogiWorkFCL {...params} />,
  },
  {
    key: "schedule_air",
    name: "항공",
    page: (params) => <ScheduleLogiWorkAIR {...params} />,
  },
];

let isLoading = true;
let login_info = null;

export default function ScheduleLogiWork() {
  const params = useParams();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [portList, setPortList] = useState([]);
  const [airportList, setAirportList] = useState([]);
  const [bCompList, setBCompList] = useState([]);
  const [isBookListOpen, setIsBookListOpen] = useState(false);
  const [expImp, setExpImp] = useState("");
  const [isPromo, setIsPromo] = useState("");
  const [isShowOceanLook, setIsShowOceanLook] = useState(false);
  const [pageOpen, setPageOpen] = useState({
    schedule_lcl: true,
    schedule_fcl: false,
    schedule_air: false,
  });
  const [vessel, setVessel] = useState(null);

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData && userData["returnData"].length > 0) {
      login_info = userData["returnData"][0];
      setIsNeedUpdate(!isNeedUpdate);
    }

    if (params.hasOwnProperty("type")) {
      if (params.type !== "EXP" && params.type !== "IMP") {
        let temp = {};
        for (let key in pageOpen) {
          let open_key = key.split("_")[1].toUpperCase();
          temp[key] = open_key === params.type.toUpperCase();
        }

        setPageOpen(temp);
      } else {
        setExpImp(params.type);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (beforeData, newData) => {
        if (newData.ret) {
          login_info = newData["returnData"][0];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_CHANGED_LOGIWORK_OCEAN_LOOK_URL,
      _mClassName,
      (b, n) => {
        if (!isShowOceanLook) {
          setIsShowOceanLook(true);
        }
        setVessel(n);
      }
    );

    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setPortList(newV);
        }
      );
    } else {
      setPortList(codeList.codeCountryFullName);
    }

    if (codeList.codeAirCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
        _mClassName,
        (oldV, newV) => {
          setAirportList(newV);
        }
      );
    } else {
      setAirportList(codeList.codeAirCountryFullName);
    }

    requestBCompList();
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    isLoading = false;
    setIsNeedUpdate(!isNeedUpdate);
  }, [portList, airportList, bCompList]);

  const requestBCompList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/b_logo/JS_b_logo_select.php",
      msgID: msgID,
      data: [],
      USE_YN: "Y",
      blSchDisplay: "Y",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setBCompList(newData["returnData"]);
        }
      }
    });
  };

  const changePage = (openKey) => {
    let temp = {};
    for (let key in pageOpen) {
      temp[key] = key === openKey;
    }
    setPageOpen(temp);
  };

  const requestUserReserve = () => {
    let type = "lcl";
    let exp_imp = params.type;

    for (let key in pageOpen) {
      if (pageOpen[key]) {
        type = key.split("_")[1];
        break;
      }
    }

    window.open(
      `/freiscop/ScheduleLogiWorkReserveMemo/${exp_imp}/${type}`,
      "schedule_reserve",
      "width=600, height=800"
    );
  };

  const openCostPopup = (type, sm_IDX, realTime) => {
    window.open(
      `/freiscop/ScheduleLogiWorkCost/${type}/${sm_IDX}/${realTime || 0}`,
      "schedule_cost",
      "width=1000, height=1200"
    );
  };

  const ButtonType = ({ item, pageOpen, changePage }) => {
    let btn_class = "btn_1 py-1 px-3 outline mr-2";
    if (pageOpen[item.key]) {
      btn_class = "btn_1 py-1 px-3 mr-2";
    }
    if (expImp === "IMP" && item.key === "schedule_air") {
      return <></>;
    }
    return (
      <button className={btn_class} onClick={() => changePage(item.key)}>
        {pageOpen[item.key] && (
          <span className="font-size-09">
            <i className="fa-solid fa-cart-flatbed" />{" "}
          </span>
        )}
        {item.name}
      </button>
    );
  };

  const MappedComponent = useCallback(
    ({
      item,
      index,
      expImp,
      isPromo,
      setIsPromo,
      login_info,
      pageOpen,
      portList,
      airportList,
      bCompList,
    }) => {
      if (expImp === "IMP" && item.key === "schedule_air") {
        return <></>;
      }
      return (
        <MappedComponentMemo
          index={index}
          item={item}
          expImp={expImp}
          pageOpen={pageOpen}
          login_info={login_info}
          isPromo={isPromo}
          setIsPromo={setIsPromo}
          portList={portList}
          airportList={airportList}
          bCompList={bCompList}
          openCostPopup={openCostPopup}
        />
      );
    },
    []
  );

  const MappedComponentMemo = React.memo(
    ({
      item,
      index,
      login_info,
      expImp,
      isPromo,
      setIsPromo,
      pageOpen,
      portList,
      airportList,
      bCompList,
      openCostPopup,
    }) => {
      const isOpen = pageOpen[item.key];
      return (
        <div className={`${isOpen ? "d-block" : "d-none"}`}>
          {item.page({
            login_info,
            expImp,
            isPromo,
            setIsPromo,
            portList,
            airportList,
            bCompList,
            openCostPopup,
          })}
        </div>
      );
    }
  );

  const ScheduleLogiWorkBookListMemo = useCallback(
    React.memo((props) => {
      return (
        <div className="px-3">
          <ScheduleLogiWorkBookList {...props} />
        </div>
      );
    }),
    []
  );

  return (
    <>
      {portList.length === 0 && airportList.length === 0 && <Loading />}
      {!isLoading && (
        <main
          className="vh-100 d-flex flex-column overflow_scroll-Y freiscopplus bg-schedule"
          style={{ backgroundColor: "#eceef1" }}
        >
          {login_info && login_info.comp_gb !== "B" && (
            <div
              className="bg-logiwork py-2 px-3 position-fixed vw-100"
              style={{ zIndex: 2 }}
            >
              <div>
                <button className="btn_1 mr-2" onClick={requestUserReserve}>
                  BOOKING 요청
                </button>
                <button
                  className="btn_1 bg-white outline"
                  onClick={() => {
                    setIsBookListOpen(!isBookListOpen);
                  }}
                >
                  {isBookListOpen ? "스케쥴보기" : "BOOKING 관리"}
                </button>
              </div>
            </div>
          )}
          <div
            className="flex-grow-1 vw-100"
            style={{
              marginTop: !login_info || login_info.comp_gb === "B" ? 0 : 43,
            }}
          >
            <div className={isBookListOpen ? "d-block" : "d-none"}>
              <ScheduleLogiWorkBookListMemo
                expImp={expImp}
                login_info={login_info}
                portList={portList}
                airportList={airportList}
              />
            </div>
            <div className={isBookListOpen ? "d-none" : "d-block"}>
              <div
                className={`pl-3 ${
                  pageOpen.schedule_air ? "" : "d-flex flex-row"
                }`}
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    marginRight: pageOpen.schedule_air
                      ? 0
                      : isShowOceanLook
                      ? 400
                      : 0,
                  }}
                  className="flex-grow-1 pr-3 h-100"
                >
                  {portList.length > 0 && airportList.length > 0 && (
                    <div>
                      <div className="d-flex flex-row justify-content-between align-items-center my-2">
                        <div className="d-flex flex-row align-items-center">
                          <p className="h5 font-weight-bold m-0 mr-3">
                            화물유형
                          </p>
                          <div className="pt-2">
                            {pages.map((item) => {
                              return (
                                <ButtonType
                                  key={item.key}
                                  item={item}
                                  pageOpen={pageOpen}
                                  changePage={changePage}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {pages.map((item, index) => {
                        return (
                          <MappedComponent
                            key={item.key}
                            index={index}
                            item={item}
                            expImp={expImp}
                            isPromo={isPromo}
                            setIsPromo={setIsPromo}
                            login_info={login_info}
                            pageOpen={pageOpen}
                            portList={portList}
                            airportList={airportList}
                            bCompList={bCompList}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                {!pageOpen.schedule_air && isShowOceanLook && (
                  <OceanLookElement vessel={vessel} key={vessel} />
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

const OceanLookElement = React.memo(({ vessel }) => {
  useEffect(() => {
    const containerId = "one-vessel";
    if (document.getElementById(containerId)) {
      oceanlook.embed(containerId, vessel);
    }
  }, []);

  const top_margin = !login_info || login_info.comp_gb === "B" ? 0 : 43;
  return (
    <div
      id="oceanlook_wrap"
      style={{
        width: 400,
        position: "fixed",
        top: top_margin,
        right: 0,
        height: `calc(100vh - ${top_margin}px)`,
      }}
    >
      <div
        className="d-flex w-100 h-100 justify-content-center align-items-center position-absolute"
        style={{ zIndex: 0 }}
      >
        <div className="loading">
          <i className="fa-solid fa-circle-notch" />
        </div>
      </div>

      <div id="one-vessel" className="position-absolute w-100 h-100 border" />
    </div>
  );
});
