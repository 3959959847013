import React, { useCallback, useEffect, useRef, useState } from "react";
import $ from "jquery";

import AlarmTalkManageToolbar from "./components/AlarmTalkManageToolbar";
import EXPAlarmTalkManage from "./components/EXPAlarmTalkManage";
import IMPAlarmTalkManage from "./components/IMPAlarmTalkManage";

import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const pages = [
  // {
  //   key: "exp",
  //   name: "수출알림",
  //   component: (props) => <EXPAlarmTalkManage {...props} />,
  // },
  {
    key: "imp",
    name: "수입알림",
    component: (props) => <IMPAlarmTalkManage {...props} />,
  },
];

let noti_set = {
  EXP: [],
  IMP: [],
};

export default function AlarmTalkManage() {
  const [userInfo, setUserInfo] = useState(null);
  const [activePage, setActivePage] = useState("imp");
  const [isTimeShow, setIsTimeShow] = useState(false);
  const [notiSet, setNotiSet] = useState({
    EXP: [],
    IMP: [],
  });
  const controller = {
    start_hour: useRef(),
    start_minute: useRef(),
    end_hour: useRef(),
    end_minute: useRef(),
  };

  useEffect(() => {
    window.addEventListener("message", receiveUserInfo);
    receiveUserInfo();
    return () => {
      window.removeEventListener("message", receiveUserInfo);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      getUserNotiSet();
    }
  }, [userInfo]);

  function receiveUserInfo(event) {
    if (!event || event.origin !== "https://system.gbts.co.kr") {
      return;
    }
    setUserInfo(event.data);
  }

  function getUserNotiSet() {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/logiwork/noti_set/JS_select_user_noti_set.php",
      msgID: msgID,
      data: [],
      cunsCOMPCD: userInfo.comp_cd,
      cunsUSERID: userInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"]) {
          const data = newData["returnData"][0];
          let days = data["cunsDAY"]
            ? data["cunsDAY"].split("")
            : ["1", "2", "3", "4", "5"];

          if (data["cunsSET"]) {
            const [startHour, startMinute] = data["cunsTIME_START"].split(":");
            const [endHour, endMinute] = data["cunsTIME_END"].split(":");
            controller.start_hour.current.value = startHour;
            controller.start_minute.current.value = startMinute;
            controller.end_hour.current.value = endHour;
            controller.end_minute.current.value = endMinute;
            noti_set = data["cunsSET"];
            setNotiSet(noti_set);
            setIsTimeShow(data["cunsTIMEON"] === "Y");
          }

          days.forEach((day) => {
            $("input[name='cunsDAY'][value='" + day + "']").prop(
              "checked",
              true
            );
          });
        }
      }
    });
  }

  function saveUserNotiSet(key, data) {
    noti_set[key] = data;

    let cunsDAY = [];
    const daysInput = Array.from($("input[name='cunsDAY']"));
    daysInput.forEach((e) => {
      if (e.checked) {
        cunsDAY.push(e.value);
      }
    });
    cunsDAY = cunsDAY.join("");

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/logiwork/noti_set/JS_insert_user_noti_set.php",
      msgID: msgID,
      data: [],
      cunsCOMPCD: userInfo.comp_cd,
      cunsUSERID: userInfo.user_id,
      cunsSET: JSON.stringify(noti_set),
      cunsDAY: cunsDAY,
      cunsTIMEON: isTimeShow ? "Y" : "N",
      cunsTIME_START: `${controller.start_hour.current.value}:${controller.start_minute.current.value}:00`,
      cunsTIME_END: `${controller.end_hour.current.value}:${controller.end_minute.current.value}:00`,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        codeList.Modal.current.alert(newData["MSG"]);
      }
    });
  }

  function handleChangePage(page) {
    setActivePage(page.key);
  }

  const MappedPage = useCallback(
    React.memo((props) => {
      const { activePage, page } = props;
      return (
        <div
          className={`flex-grow-1 pt-1 ${
            activePage !== page.key ? "d-none" : ""
          }`}
        >
          {page.component(props)}
        </div>
      );
    }),
    [notiSet, activePage]
  );

  if (!userInfo) return <></>;

  return (
    <div
      className="vh-100 d-flex flex-column"
      style={{ background: "#CCCCCC", maxHeight: "100vh" }}
    >
      <AlarmTalkManageToolbar
        pages={pages}
        activePage={activePage}
        handleChangePage={handleChangePage}
      />
      <div className="position-relative">
        <div
          className="position-absolute d-flex flex-row align-items-start"
          style={{ top: ".8rem", left: ".5rem" }}
        >
          <div className="d-flex flex-row align-items-center">
            <span className="font-weight-bold mr-2">알림 시간 설정 </span>
            <div
              className={`cursor_pointer mr-3 border border-secondary rounded-pill bg-light position-relative`}
              style={{ width: 48, height: 28 }}
              onClick={() => {
                setIsTimeShow(!isTimeShow);
              }}
            >
              <span
                className={`transition-2 d-inline-flex flex-row justify-content-center align-items-center border border-white bg-${
                  isTimeShow ? "gbts" : "secondary"
                } rounded-circle font-weight-bold text-white font-size-08 position-absolute`}
                style={{ height: 26, width: 26, left: isTimeShow ? 20 : 0 }}
              >
                {isTimeShow ? "ON" : "OFF"}
              </span>
            </div>
          </div>
          <div className={isTimeShow ? "" : "d-none"}>
            <div className="d-flex flex-row justify-content-between align-items-center">
              {codeList.publicVar.days.map((day) => {
                return (
                  <label
                    key={day.value}
                    style={{ minWidth: 40 }}
                    className="m-0 p-0 d-inline-flex flex-row align-items-center"
                  >
                    <input
                      type="checkbox"
                      value={day.value}
                      className="mr-1"
                      name="cunsDAY"
                    />{" "}
                    {day.name}
                  </label>
                );
              })}

              <div className="input-group">
                <select
                  className="form-control border border-right-0"
                  style={{ height: 28 }}
                  defaultValue={"09"}
                  ref={controller.start_hour}
                >
                  {codeList.publicVar.hours.map((hour) => {
                    return (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="input-group-append rounded-0"
                  style={{ height: 28 }}
                >
                  <span className="input-group-text border-left-0 border-right-0 bg-white px-0 pl-2 font-weight-bold">
                    :
                  </span>
                </span>
                <select
                  className="form-control border border-left-0"
                  style={{ height: 28 }}
                  ref={controller.start_minute}
                >
                  {codeList.publicVar.minutes.map((minute) => {
                    return (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="input-group-append rounded-0"
                  style={{ height: 28 }}
                >
                  <span className="input-group-text border-right-0">-</span>
                </span>
                <select
                  className="form-control border border-right-0"
                  style={{ height: 28 }}
                  ref={controller.end_hour}
                  defaultValue={"18"}
                >
                  {codeList.publicVar.hours.map((hour) => {
                    return (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="input-group-append rounded-0"
                  style={{ height: 28 }}
                >
                  <span className="input-group-text border-left-0 border-right-0 bg-white px-0 pl-2 font-weight-bold">
                    :
                  </span>
                </span>
                <select
                  className="form-control border border-left-0"
                  style={{ height: 28 }}
                  ref={controller.end_minute}
                >
                  {codeList.publicVar.minutes.map((minute) => {
                    return (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <p className="font-weight-bold text-secondary">
              알림 받을 요일 및 시간을 설정해주세요.
            </p>
          </div>
        </div>
      </div>
      {pages.map((page, index) => {
        return (
          <MappedPage
            key={page.key}
            page={page}
            activePage={activePage}
            userInfo={userInfo}
            notiSet={notiSet}
            saveUserNotiSet={saveUserNotiSet}
          />
        );
      })}
    </div>
  );
}
