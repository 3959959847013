/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import Oceanlook from "../../lib/oceanlook";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const oceanlook = new Oceanlook();

const useStyles = makeStyles((theme) => ({
  ...codeList.publicVar.tableDefaultStyle(theme),
}));
const useRowStyles = makeStyles({
  ...codeList.publicVar.tableRowDefaultStyle(),
});
const headCells = [
  {
    field: "sm_UID",
    headerName: "스케줄 관리번호",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sb_IDX",
    headerName: "예약 No.",
    numeric: false,
    disablePadding: false,
  },

  {
    field: "sb_PRODUCT_NAME",
    headerName: "품명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sb_COMP_NM",
    headerName: "상호명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "sb_CREATEDATE",
    headerName: "요청일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "sb_DOC_CLOSE",
    headerName: "서류마감",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          {util.getDateToFormat(params.data, "YYYY-MM-DD")}
          <br />
          {params.currentData.sb_DOC_CLOSE_TIME || ""}
        </span>
      );
    },
  },
  {
    field: "sb_CARGO_CLOSE",
    headerName: "화물마감",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          {util.getDateToFormat(params.data, "YYYY-MM-DD")}
          <br />
          {params.currentData.sb_CARGO_CLOSE_TIME
            ? params.currentData.sb_CARGO_CLOSE_TIME
            : ""}
        </span>
      );
    },
  },
  {
    field: "sb_POL_NAME",
    headerName: "출발항(공항) 및 일자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sb_DEPARTURE_DATE}
          </p>
        </div>
      );
    },
  },
  {
    field: "sb_POD_NAME",
    headerName: "도착항(공항) 및 일자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sb_ARRIVE_DATE}
          </p>
        </div>
      );
    },
  },

  {
    field: "sb_STATUS",
    headerName: "처리상태",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let text_color = "text-secondary";
      let desc = "예약요청";
      if (params.data === "N") {
        text_color = "text-secondary";
        desc = "예약요청";
      }
      if (params.data === "Y") {
        text_color = "text-info";
        desc = "예약확정";
      }
      if (params.data === "B") {
        text_color = "text-primary";
        desc = "B/L발행";
      }
      if (params.data === "C") {
        text_color = "text-danger";
        desc = "예약취소";
      }

      return (
        <span
          className={`text-truncate text-wrap text-break font-weight-bold ${text_color}`}
        >
          {desc}
        </span>
      );
    },
  },
];
const _mClassName = "ScheduleBookingManageTable";

export default function ScheduleBookingManageTable(props) {
  const {
    isServerPaging = true,
    isServerSort = true,

    loginInfo,
  } = props;

  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sb_IDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "sb_IDX",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestReserveLCLList();
  }, [page, sortModel, filters]);

  //스케쥴 예약정보 받아오기
  const requestReserveLCLList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_booking_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      isManager: loginInfo.isAdmin,
      USER_ID: loginInfo.user_id,
      COMP_CD: loginInfo.comp_cd,
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  //스케쥴 예약정보 업데이트
  function updateReserveLCLData(e, params, msg) {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_booking_update.php",
      msgID: msgID,
      data: {},
      ...params,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          let renderMSG = msg ? msg : "스케줄의 정보가 변경되었습니다.";
          codeList.Modal.current.alert(renderMSG);
          requestReserveLCLList();
        }
      }
    });
  }

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  //테이블 페이지 변경
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //테이블 정렬 방식 변경
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowParams = (row, index) => {
    return {
      row: row,
      index: index,
      loginInfo: loginInfo,
      updateReserveLCLData: updateReserveLCLData,
      requestReserveLCLList: requestReserveLCLList,
    };
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row key={index} {...rowParams(row, index)} />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

ScheduleBookingManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const { row, requestReserveLCLList, updateReserveLCLData, loginInfo } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate cursor_pointer"].join(" ")}
        hover
        onClick={() => setOpen(!open)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  updateReserveLCLData: updateReserveLCLData,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  updateReserveLCLData: updateReserveLCLData,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={headCells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                <ScheduleBookingDetail
                  rowData={row}
                  loginInfo={loginInfo}
                  requestReserveLCLList={requestReserveLCLList}
                  updateReserveLCLData={updateReserveLCLData}
                />
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({}).isRequired,
};

const ScheduleBookingDetail = (props) => {
  const { rowData, requestReserveLCLList, updateReserveLCLData, loginInfo } =
    props;
  const [cfsData, setCfsData] = useState("");
  const selectRef = useRef();
  const adminStatus = useRef();
  const $controller = {
    sb_VESSEL: useRef(),
    sb_VOY: useRef(),
    sb_ORIGIN: useRef(),
    sb_DEPARTURE_DATE: useRef(),
    sb_DEST: useRef(),
    sb_ARRIVE_DATE: useRef(),
    sb_DOC_CLOSE: useRef(),
    sb_DOC_CLOSE_TIME: useRef(),
    sb_CARGO_CLOSE: useRef(),
    sb_CARGO_CLOSE_TIME: useRef(),
    //------------------------------
    sb_PRODUCT_NAME: useRef(),
    sb_COMP_NM: useRef(),
    sb_USER_NM: useRef(),
    sb_CNT: useRef(),
    sb_QUANTITIES: useRef(),
    //------------------------------
    sb_BOOK_NO: useRef(),
    sb_CFS: useRef(),
    sb_BLNO: useRef(),

    //------------------------------
    linkUrl: useRef(),
  };

  let files = [];
  if (rowData.sb_FILE) {
    files = rowData.sb_FILE.split("|");
  }

  useEffect(() => {
    if (rowData.sb_CFS) {
      SEARCH_CFS_DETAIL(rowData.sb_CFS);
    }
  }, []);

  //창고정보 받아오기
  const SEARCH_CFS_DETAIL = (key) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_booking_cfs_select.php",
      msgID: msgID,
      data: {},
      smKey: key,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length) {
            let data = newData["returnData"][0];
            setCfsData(data);
          } else {
            codeList.Modal.current.alert(
              "CFS CODE가 빈 값이거나 존재하지 않는 CFS CODE입니다."
            );
          }
        }
      }
    });
  };

  //예약 첨부파일 업로드
  const handleUploadFile = (value) => {
    if (!value.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...files];
    //파일명?파일주소
    dataList.push(`${value.realFileName}?${value.fileName}`);
    files = dataList;
    updateScheduleFiles(dataList, "첨부파일이 등록 되었습니다.");
  };

  //첨부된 파일 삭제
  const handleDeleteUploadFile = (index, fileName) => {
    codeList.Modal.current.confirm(`${fileName}을 삭제하기겠습니까?`, (ret) => {
      if (ret) {
        let dataList = [];
        for (let i = 0; i < files.length; i++) {
          if (i !== index) {
            dataList.push(files[i]);
          }
        }
        files = dataList;
        updateScheduleFiles(dataList, "첨부파일이 삭제 되었습니다.");
      }
    });
  };

  //파일 업로드
  const updateScheduleFiles = (fileList, msg) => {
    const data = {
      sb_IDX: rowData.sb_IDX,
      sb_FILE: files.join("|"),
    };

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_booking_update.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert(msg, () => {});
          requestReserveLCLList();
        }
      }
    });
  };

  //첨부파일 다운로드
  const fileDownload = (url, name) => {
    codeList.Modal.current.confirm(
      "첨부된 파일을 다운로드하시겠습니까?",
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  //입력값 검사
  const checkValidate = () => {
    if (
      $controller.sb_VESSEL.current &&
      $controller.sb_VESSEL.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("VESSEL정보를 입력해주세요");
      return false;
    }

    if (
      $controller.sb_VOY.current &&
      $controller.sb_VOY.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("항차정보를 입력해주세요");
      return false;
    }

    if (
      $controller.sb_DEPARTURE_DATE.current &&
      $controller.sb_DEPARTURE_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("POL의 출발일자를 입력해주세요");
      return false;
    }

    if (
      $controller.sb_ARRIVE_DATE.current &&
      $controller.sb_ARRIVE_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("POD의 도착일자를 입력해주세요");
      return false;
    }
    if (
      $controller.sb_PRODUCT_NAME.current &&
      $controller.sb_PRODUCT_NAME.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("품명을 입력해주세요");
      return false;
    }

    if (
      $controller.sb_COMP_NM.current &&
      $controller.sb_COMP_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상호명을 입력해주세요");
      return false;
    }

    if (
      $controller.sb_USER_NM.current &&
      $controller.sb_USER_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("H/BL NO을 입력해주세요");
      return false;
    }

    if (
      $controller.sb_CNT.current &&
      $controller.sb_CNT.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("수량을 입력해주세요");
      return false;
    }

    if (
      $controller.sb_QUANTITIES.current &&
      $controller.sb_QUANTITIES.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("수량단위를 선택해주세요");
      return false;
    }

    return true;
  };

  //스케줄 예약 데이터 업데이트
  const handleScheduleDataUpdate = (e) => {
    if (checkValidate()) {
      let updateData = {
        sb_IDX: rowData.sb_IDX,
      };
      for (let key in $controller) {
        if ($controller[key].current) {
          updateData[key] = $controller[key].current.value;
        }
      }
      codeList.Modal.current.confirm(
        "해당 스케쥴 예약의 정보를 변경하시겠습니까?",
        (ret) => {
          if (ret) {
            updateReserveLCLData(e, updateData, "예약 정보가 변경 되었습니다.");
          }
        }
      );
    }
  };

  //BL등록
  const updateBLNO = (e) => {
    if ($controller.sb_BLNO.current.value) {
      const data = {
        sb_IDX: rowData.sb_IDX,
        sb_BLNO: $controller.sb_BLNO.current.value,
        sb_STATUS: "B",
      };
      selectRef.current.value = "B";
      updateReserveLCLData(e, data, "BL정보가 변경 되었습니다.");
    } else {
      codeList.Modal.current.alert("BL번호를 입력해주세요.");
    }
  };
  //BL삭제
  const deleteBLNO = (e) => {
    codeList.Modal.current.confirm(
      "등록된 BL정보를 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          const data = {
            sb_IDX: rowData.sb_IDX,
            sb_BLNO: "",
            sb_STATUS: "N",
          };
          $controller.sb_BLNO.current.value = "";
          selectRef.current.value = "N";
          updateReserveLCLData(e, data, "BL정보가 삭제 되었습니다.");
        }
      }
    );
  };

  //처리상태 업데이트
  const handleRequestUserUpdate = (e) => {
    let data = {
      sb_IDX: rowData.sb_IDX,
      sb_STATUS: selectRef.current.value,
      sb_STATUS2: rowData.sb_STATUS2,
    };

    if (adminStatus.current) {
      data["sb_STATUS2"] = adminStatus.current.value;
    }

    codeList.Modal.current.confirm(
      "해당 예약건의 처리상태를 변경하시겠습니까?",
      (ret) => {
        if (ret) {
          updateReserveLCLData(e, data, "예약 처리상태가 변경 되었습니다.");
        }
      }
    );
  };

  // 링크생성
  const createLink = (e) => {
    let url =
      "https://www.gbts.co.kr/backside/schedule_booking_information_link.php?num=";
    let randLink = util.buddibleEncrypt(rowData.sb_IDX);

    url = url + randLink + "#schedule_info_scroll";
    url = encodeURI(url);
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      {
        long_url: url,
      },
      msgID,
      (beforeData, newData) => {
        $controller.linkUrl.current.value = newData["link"];

        let updateData = {
          sb_IDX: rowData.sb_IDX,
          sb_ShortLink: newData["link"],
        };
        updateReserveLCLData(e, updateData, "예약정보 링크가 생성되었습니다.");
      }
    );
  };

  // 링크 복사
  const copyLink = () => {
    const el = $controller.linkUrl.current;
    el.select();
    document.execCommand("copy");
    if (document.execCommand("copy")) {
      codeList.Modal.current.alert("복사되었습니다.", () => {});
    }
  };

  // 링크 미리보기
  const openLink = () => {
    const el = $controller.linkUrl.current.value;
    if (!el) {
      codeList.Modal.current.alert("링크를 먼저 생성 해주세요.", () => {});
    } else {
      window.open(el, "", "_blank");
    }
  };

  //담당자와 바로대화
  const requestEnquiry = (managerCompCD, managerID) => {
    codeList.Modal.current.confirm(
      `로지톡으로 담당자에게 연결하시겠습니까?`,
      (ret) => {
        if (ret) {
          // localStorage.setItem(
          //   "enquiryManager",
          //   `${managerCompCD}|${managerID}`
          // );

          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "_blank", "width=1200, height=800");
        } else {
          codeList.Modal.current.alert("담당자 연결이 취소되었습니다.");
        }
      }
    );
  };

  return (
    <>
      <tr
        className="container"
        id={rowData.sb_IDX}
        style={{ background: "#F7F7F7" }}
      >
        <td colSpan="12">
          <div className="card border-0 shadow-none m-3 text-dark">
            <div
              className="card-body m-0 p-0"
              style={{ background: "#F7F7F7" }}
            >
              <p className="rounded-square bg-white h6 font-weight-bold mb-3">
                ■ 스케줄 관리번호 : {rowData.sm_UID}
              </p>

              <div className="rounded-square bg-white mb-3">
                <div className="mb-2 d-flex flex-row align-items-center">
                  <span className="h6 font-weight-bold mr-3 mb-0">
                    ■ 예약정보
                  </span>
                  <div className="d-flex flex-row align-items-center">
                    <span
                      className="d-inline-block bg-info-light mr-1"
                      style={{ width: 50, height: 20 }}
                    />
                    수정가능 항목
                  </div>
                </div>
                <div className="d-flex flex-column flex-xl-row flex-wrap border rounded mb-2">
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      FCL/LCL
                    </div>
                    <div className="p-2 text-center">{rowData.sb_FCL_LCL}</div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      VESSEL
                    </div>
                    <div className="p-2 pb-0 text-center">
                      <input
                        type="text"
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        ref={$controller.sb_VESSEL}
                        defaultValue={rowData.sb_VESSEL}
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      항차
                    </div>
                    <div className="p-2 pt-0 text-center">
                      <input
                        type="text"
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        ref={$controller.sb_VOY}
                        defaultValue={rowData.sb_VOY}
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column flex-xl-row justify-content-start">
                    <div className="border-right flex-grow-1">
                      <div
                        className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                        style={{ minWidth: 100 }}
                      >
                        POL
                      </div>
                      <div className="p-2 pt-0 text-center">
                        <p style={{ fontWeight: "bold", paddingBottom: "2px" }}>
                          {rowData.sb_POL_NAME}
                        </p>

                        <input
                          type="date"
                          ref={$controller.sb_DEPARTURE_DATE}
                          defaultValue={rowData.sb_DEPARTURE_DATE}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          className={`border-0 w-100 text-center text-danger ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                        />
                      </div>
                    </div>
                    <div className="border-right flex-grow-1">
                      <div
                        className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                        style={{ minWidth: 100 }}
                      >
                        POD
                      </div>
                      <div className="p-2 pt-0 text-center">
                        <p style={{ fontWeight: "bold", paddingBottom: "2px" }}>
                          {rowData.sb_POD_NAME}
                        </p>

                        <input
                          type="date"
                          ref={$controller.sb_ARRIVE_DATE}
                          defaultValue={rowData.sb_ARRIVE_DATE}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          className={`border-0 w-100 text-center text-danger ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                          style={{ color: "red" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column flex-xl-row justify-content-start">
                    <div className="border-right flex-grow-1">
                      <div
                        className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                        style={{ minWidth: 100 }}
                      >
                        서류마감(시간)
                      </div>
                      <div className="p-2 pt-0 text-center">
                        <input
                          type="date"
                          ref={$controller.sb_DOC_CLOSE}
                          defaultValue={rowData.sb_DOC_CLOSE}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          className={`border-0 w-100 text-center mb-1 ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                        />
                        <input
                          type="text"
                          ref={$controller.sb_DOC_CLOSE_TIME}
                          defaultValue={rowData.sb_DOC_CLOSE_TIME}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          placeholder="서류마감 시간을 입력해주세요"
                          className={`border-0 w-100 text-center ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1">
                      <div
                        className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                        style={{ minWidth: 100 }}
                      >
                        화물마감(시간)
                      </div>
                      <div className="p-2 pt-0 text-center">
                        <input
                          type="date"
                          ref={$controller.sb_CARGO_CLOSE}
                          defaultValue={rowData.sb_CARGO_CLOSE}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          className={`border-0 w-100 text-center mb-1 ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                        />
                        <input
                          type="text"
                          ref={$controller.sb_CARGO_CLOSE_TIME}
                          defaultValue={rowData.sb_CARGO_CLOSE_TIME}
                          readOnly={
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                          }
                          placeholder="화물마감 시간을 입력해주세요"
                          className={`border-0 w-100 text-center ${
                            !(loginInfo.isAdmin || loginInfo.isConsole) ||
                            rowData.sb_STATUS !== "N"
                              ? ""
                              : "bg-info-light"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-xl-row flex-wrap border rounded overflow-hidden">
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      품명
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        readOnly={
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        className={`border-0 w-100 text-center ${
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_PRODUCT_NAME}
                        ref={$controller.sb_PRODUCT_NAME}
                      />
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      화주 상호
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        readOnly={
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        className={`border-0 w-100 text-center ${
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_COMP_NM}
                        ref={$controller.sb_COMP_NM}
                      />
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      H/BL NO
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        readOnly={
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        className={`border-0 w-100 text-center ${
                          (!loginInfo.isAdmin && loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_USER_NM}
                        ref={$controller.sb_USER_NM}
                      />
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      중량(kg)
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_WEIGHT}
                        ref={$controller.sb_WEIGHT}
                      />
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      수량
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_CNT}
                        ref={$controller.sb_CNT}
                      />
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      단위
                    </div>
                    <div className="p-2 text-center">
                      <select
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_QUANTITIES}
                        ref={$controller.sb_QUANTITIES}
                        disabled={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                      >
                        {rowData.sb_FCL_LCL === "AIR" ? (
                          <option value="KG">KG</option>
                        ) : (
                          <>
                            <option value="">미선택</option>
                            <option value="CBM">CBM</option>
                            <option value="TEU">TEU</option>
                            <option value="FEU">FEU</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 부킹넘버 & CFS</p>
                <div className="d-flex flex-column flex-xl-row flex-wrap border rounded overflow-hidden mb-3">
                  <div className="border-right">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 200 }}
                    >
                      부킹넘버
                    </div>
                    <div className="p-2 text-center">
                      <input
                        type="text"
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_BOOK_NO}
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        placeholder="부킹넘버를 입력해주세요"
                        ref={$controller.sb_BOOK_NO}
                      />
                    </div>
                  </div>
                  <div className="border-right">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 150 }}
                    >
                      CFS CODE
                    </div>

                    <div className="p-2 text-center">
                      <input
                        type="text"
                        className={`border-0 w-100 text-center ${
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                            ? ""
                            : "bg-info-light"
                        }`}
                        defaultValue={rowData.sb_CFS}
                        placeholder="CFS CODE를 입력해주세요"
                        readOnly={
                          !(loginInfo.isAdmin || loginInfo.isConsole) ||
                          rowData.sb_STATUS !== "N"
                        }
                        ref={$controller.sb_CFS}
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            SEARCH_CFS_DETAIL(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      주소/전화번호
                    </div>
                    <div className="p-2 d-flex flex-row justify-content-between">
                      <p>
                        {cfsData && cfsData.hasOwnProperty("smKey") ? (
                          <>
                            <span className="font-weight-bold mr-2">
                              [ {cfsData.smName} ]
                            </span>
                            <span className="mr-2">
                              주소 : {cfsData.smAddrFull}
                            </span>
                            tel : {util.telFormatConvert(cfsData.smTel)}
                          </>
                        ) : loginInfo.isForwarder ? (
                          "-"
                        ) : (
                          "- CFS CODE 입력후 엔터를 누르면 CFS 데이터를 받아옵니다."
                        )}
                      </p>
                      <div
                        className={
                          cfsData && cfsData.hasOwnProperty("smKey")
                            ? "d-inline-block"
                            : "d-none"
                        }
                      >
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => {
                            if (cfsData && cfsData.hasOwnProperty("smKey")) {
                              window.open(
                                `https://www.gbts.co.kr/storeMall/${cfsData.smKey}`,
                                "_blank",
                                "width=1200, height=800, top=100,left=100"
                              );
                            }
                          }}
                        >
                          CFS 상세정보
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right text-danger">
                  {rowData.sb_STATUS !== "N" && (
                    <p style={{ padding: "0 2px 2px 0" }}>
                      ※ 예약 확정 후 수정하려면 상태를 예약요청 상태로 변경 후
                      수정 가능 합니다.
                    </p>
                  )}
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-sm btn-primary"
                    disabled={rowData.sb_STATUS !== "N"}
                    onClick={(e) => {
                      handleScheduleDataUpdate(e);
                    }}
                  >
                    수정사항 저장
                  </button>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 주요 안내 사항</p>
                <pre
                  className="border rounded p-2 mb-4"
                  style={{
                    fontFamily: "Roboto Helvetica Arial sans-serif",
                    fontSize: "0.875rem",
                  }}
                >
                  {rowData.sb_REMARK
                    ? rowData.sb_REMARK
                    : "- 별도로 등록된 안내사항이 없습니다"}
                </pre>
              </div>

              <div className="rounded-square bg-white mb-3">
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <p className="h6 font-weight-bold mb-0 mr-3">■ 첨부파일</p>
                  <UploadImageToS3WithPhp
                    region={"mall/booking"}
                    callback={(val) => {
                      handleUploadFile(val);
                    }}
                  >
                    <span className="btn btn-sm btn-dark mb-0 mr-2">
                      첨부파일 추가등록
                    </span>
                  </UploadImageToS3WithPhp>

                  <div
                    className="input-group mb-1 mr-1 "
                    style={{ width: 320 }}
                  >
                    <input
                      type="text"
                      className={`form-control ${
                        loginInfo.isForwarder || rowData.sb_STATUS !== "N"
                          ? ""
                          : "bg-info-light"
                      }`}
                      defaultValue={rowData.sb_BLNO}
                      ref={$controller.sb_BLNO}
                      placeholder="BL No를 입력해주세요"
                      aria-describedby="bl_button"
                      readOnly={loginInfo.isForwarder}
                    />
                    {(loginInfo.isAdmin || loginInfo.isConsole) && (
                      <div className="input-group-append">
                        <button
                          className="btn btn-dark"
                          type="button"
                          id="bl_button"
                          onClick={(e) => updateBLNO(e)}
                        >
                          {rowData.sb_BLNO ? "BL수정" : "BL등록"}
                        </button>
                      </div>
                    )}
                  </div>
                  {(loginInfo.isAdmin || loginInfo.isConsole) &&
                    rowData.sb_BLNO && (
                      <button
                        className="btn btn-danger mb-1"
                        type="button"
                        onClick={(e) => deleteBLNO(e)}
                      >
                        BL삭제
                      </button>
                    )}
                </div>
                {files && files.length > 0 ? (
                  <>
                    <p className="mb-2">
                      - 파일명을 클릭시 브라우저를 통해 미리 확인이 가능합니다.
                    </p>
                    <div className="mb-2">
                      {files.map((item, index) => {
                        let splitFile = item.split("?");
                        if (splitFile.length === 2) {
                          let fileName = splitFile[0];
                          let fileLink = splitFile[1];
                          return (
                            <p key={item + "_" + index} className="mb-1">
                              <span
                                className="text-primary font-weight-bold cursor_pointer mr-2"
                                onClick={() => {
                                  window.open(fileLink, "_blank");
                                }}
                              >
                                {fileName}
                              </span>
                              <button
                                className="btn btn-sm btn-primary mr-2"
                                onClick={() => {
                                  fileDownload(fileLink, fileName);
                                }}
                              >
                                다운로드
                              </button>
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  handleDeleteUploadFile(index, fileName);
                                }}
                              >
                                삭제
                              </button>
                            </p>
                          );
                        }
                      })}
                    </div>
                  </>
                ) : (
                  <p className="mb-1 py-2">- 등록된 첨부파일이 없습니다.</p>
                )}
              </div>

              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 요청 메모</p>
                <pre
                  className="border rounded p-3"
                  style={{
                    fontFamily: "Roboto Helvetica Arial sans-serif",
                    fontSize: "0.875rem",
                  }}
                >
                  {rowData.sb_MEMO
                    ? rowData.sb_MEMO
                    : "-등록된 요청사항이 없습니다."}
                </pre>
              </div>

              <div className="rounded-square bg-white mb-3">
                <div className="row">
                  <div className="col-6 d-flex flex-column justify-content-between">
                    {loginInfo.comp_gb === "Z" ? (
                      <div>
                        <p className="h6 mb-0">■ 회신 이메일</p>
                        <div className="mb-3">
                          {rowData.sb_EMAIL &&
                          rowData.sb_EMAIL.split("|").length > 0
                            ? rowData.sb_EMAIL.split("|").map((item, index) => {
                                return (
                                  <p key={item + "_" + index}>
                                    - 이메일 :{" "}
                                    <a href={`mailto: ${item}`}>{item}</a>
                                  </p>
                                );
                              })
                            : "- 등록된 회신이메일이 없습니다."}
                        </div>
                        <p className="h6 mb-2">■ 운임 결제조건</p>
                        <div>
                          -{" "}
                          {rowData.sb_PAYMENT === "A"
                            ? "사전현금"
                            : rowData.sb_PAYMENT === "B"
                            ? "사전카드"
                            : "계약후불"}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className="d-flex flex-row mt-2">
                      <button
                        className="btn btn-sm btn-primary mr-2"
                        onClick={() => {
                          createLink();
                        }}
                      >
                        예약정보 URL 생성
                      </button>
                      <input
                        className="form-control"
                        style={{ width: "40%", borderRadius: "5px 0 0 5px" }}
                        readOnly={true}
                        defaultValue={rowData.sb_ShortLink}
                        ref={$controller.linkUrl}
                      />
                      <button
                        className="btn btn-md btn-primary"
                        style={{ borderRadius: "0 5px 5px 0" }}
                        onClick={() => {
                          copyLink();
                        }}
                      >
                        복사
                      </button>

                      <button
                        className="btn btn-md btn-primary ml-2"
                        onClick={() => {
                          openLink();
                        }}
                      >
                        미리보기
                      </button>
                    </div>
                  </div>

                  <div className="col-6">
                    <div
                      style={
                        loginInfo.isAdmin || loginInfo.isForwarder
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                        <p
                          className="h6 mb-0 d-flex flex-row align-items-center"
                          style={{ width: 200 }}
                        >
                          ■ 콘솔사 :{" " + rowData.sb_FWD_NM}
                        </p>
                        <div style={{ width: 150 }}>
                          <button
                            className="btn btn-sm btn-primary w-100"
                            disabled={
                              !rowData.sb_PROVIDER_CODE ||
                              !rowData.sb_PROVIDER_ID
                            }
                            onClick={() => {
                              let comp_cd = rowData.sb_PROVIDER_CODE;
                              let user_id = rowData.sb_PROVIDER_ID;

                              requestEnquiry(comp_cd, user_id);
                            }}
                          >
                            담당자와 바로대화
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={
                        loginInfo.isAdmin || loginInfo.isConsole
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                        <p
                          className="h6 mb-0 d-flex flex-row align-items-center"
                          style={{ width: 200 }}
                        >
                          ■ 포워더 :{" " + rowData.sb_COMP_NAME}
                        </p>
                        <div style={{ width: 150 }}>
                          <button
                            className="btn btn-sm btn-primary w-100"
                            disabled={
                              !rowData.sb_PROVIDER_CODE ||
                              !rowData.sb_PROVIDER_ID
                            }
                            onClick={() => {
                              let comp_cd = rowData.sb_COMP_CD;
                              let user_id = rowData.sb_USER_ID;

                              requestEnquiry(comp_cd, user_id);
                            }}
                          >
                            담당자와 바로대화
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                      <p className="h6" style={{ width: 200 }}>
                        ■ 선박위치
                      </p>
                      <div style={{ width: 150 }}>
                        <button
                          className="btn btn-sm btn-primary w-100"
                          onClick={(e) => {
                            oceanlook.openPopup(rowData.sb_VESSEL);
                          }}
                          disabled={
                            !oceanlook.getIMO(rowData.sb_VESSEL) &&
                            !oceanlook.getMMSI(rowData.sb_VESSEL)
                          }
                        >
                          조회하기
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                      <p
                        className="h6 mb-0 d-flex flex-row align-items-center"
                        style={{ width: 200 }}
                      >
                        ■ 처리상태
                      </p>

                      <select
                        className="form-control"
                        style={{ width: 150 }}
                        defaultValue={rowData.sb_STATUS}
                        disabled={loginInfo.isForwarder}
                        ref={selectRef}
                        onChange={(e) => {
                          handleRequestUserUpdate(e);
                        }}
                      >
                        <option value="N">예약요청</option>
                        <option value="Y">예약확정</option>
                        <option value="B">B/L발행</option>
                        <option value="C">예약취소</option>
                      </select>
                    </div>
                    {loginInfo.isAdmin && (
                      <div className="d-flex flex-row justify-content-end align-items-center">
                        <p
                          className="h6 mb-0 d-flex flex-row align-items-center"
                          style={{ width: 200 }}
                        >
                          ■ 관리자 처리상태
                        </p>
                        <select
                          className="form-control"
                          style={{ width: 150 }}
                          defaultValue={rowData.sb_STATUS2}
                          ref={adminStatus}
                          onChange={(e) => {
                            handleRequestUserUpdate(e);
                          }}
                        >
                          <option value="A">대금요청</option>
                          <option value="B">결제완료</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
