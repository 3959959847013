/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";

import LoginModal from "./LoginModal";
import RegistModal from "./RegistModal";
import PopupRegistModal from "./PopupRegistModal";
import PersonalRegistModal from "./PersonalRegistModal";
import ChangePWModal from "./ChangePWModal";
import FindPWModal from "./FindPWModal";
import FindIDModal from "./FindIDModal";
import GBTSLogoSvg from "../assets/GBTSLogoSvg";

import Oceanlook from "../lib/oceanlook";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import CodeList from "../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const codeList = new CodeList();
const oceanlook = new Oceanlook();

const _mClassName = "CommonNav";
let loginInfo = null;

export default function CommonNav(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(""); // "" or  "show"
  const [isShowScheduleSub, setIsShowScheduleSub] = useState("");
  const [isShowPostSub, setIsShowPostSub] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    askNotificationPermission();
    window.addEventListener("resize", handleResizeMobileNavState);

    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData.ret) {
        loginInfo = userData["returnData"][0];
        setIsLogin(true);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n.ret) {
          loginInfo = n["returnData"][0];
          setIsLogin(true);
        }
      }
    );

    return () => {
      window.removeEventListener("resize", handleResizeMobileNavState);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (location.hash === "#regist" && !isLogin) {
      document.querySelector("#registModalBtn").click();
    }
  }, [isLogin]);

  useEffect(() => {
    const _body = $("body");
    if (isOpenMobileNav) {
      _body.css("height", "100vh");
      _body.css("overflow", "hidden");
    } else {
      _body.css("height", "100%");
      _body.css("overflow", "visible");
    }
  }, [isOpenMobileNav]);

  useEffect(() => {
    if (location.hash) {
      window.scrollTo({
        top: document.querySelector(location.hash)?.offsetTop,
      });
    }
  }, [location.hash]);

  const handleResizeMobileNavState = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      const layerElement = document.getElementById("layer");

      layerElement.className = "layer";
      setIsOpenMobileNav("");
      setIsMobile(false);
    }
  };

  const toggleMobileNavOpen = (e) => {
    const layerElement = document.getElementById("layer");
    if (isOpenMobileNav) {
      layerElement.className = "layer";
      setIsOpenMobileNav("");
    } else {
      layerElement.className = "layer layer-is-visible";
      setIsOpenMobileNav("show");
    }
  };

  // console.log($("body"));

  const toggleMobileNavSubMenu = (e, classHandler) => {
    if (!isMobile) return;
    e.preventDefault();
    classHandler((prevState) => {
      return prevState ? "" : " show_normal";
    });
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    codeList.Modal.current.confirm("로그아웃 하시겠습니까?", (ret) => {
      if (ret) {
        sessionStorage.removeItem("loginInfo");
        localStorage.removeItem("loginInfo");
        window.location.replace("/");
      }
    });
  };

  const notify = () => {
    setUnreadCount(unreadCount + 1);
    const options = {
      onOpen: (props) => console.log(props.foo),
      onClose: (props) => console.log(props.foo),
      onClick: (props) => console.log(props),
      autoClose: 6000,
    };
    const toastId = toast("toast", options);
    notifyWindow();
    toast.update(toastId, {
      type: toast.TYPE.DEFAULT,
      render: () => {
        return (
          <>
            <div
              className="customized_notify alert alert-info"
              style={{
                display: "inline-block",
                margin: "0px auto",
              }}
            >
              <span data-notify="title">
                <a>
                  <h4>(주)지비티에스</h4>
                </a>
              </span>
              <a>
                <span data-notify="message">
                  <figure>
                    <img src="/img/notify_img.jpg" alt={"pic"} />
                  </figure>
                </span>
              </a>
              <p>
                <a>테스트메세지 입니다. </a>
              </p>
            </div>
          </>
        );
      },
    });
  };
  const notifyWindow = () => {
    let img = "/img/notify_img.jpg";
    let text = "테스트메세지 입니다.";
    let notification = new Notification("(주)지비티에스-버디블", {
      body: text,
      icon: img,
    });
    setTimeout(notification.close.bind(notification), 6000);
  };

  const askNotificationPermission = () => {
    // 권한을 실제로 요구하는 함수
    function handlePermission(permission) {
      // 사용자의 응답에 관계 없이 크롬이 정보를 저장할 수 있도록 함
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
    }

    // 브라우저가 알림을 지원하는지 확인
    if (!("Notification" in window)) {
      console.log("이 브라우저는 알림을 지원하지 않습니다.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };
  return (
    <>
      <header
        id="plain"
        className="bg-white shadow-sm border-bottom z-index-99"
      >
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="container py-3 px-lg-0 px-xl-3 py-lg-0">
          <div className="row m-0 p-0 d-flex flex-row justify-content-between align-items-xl-center">
            <CommonNavLogo />
            <CommonNavMenuList
              isLogin={isLogin}
              isMobile={isMobile}
              isOpenMobileNav={isOpenMobileNav}
              isShowScheduleSub={isShowScheduleSub}
              setIsShowScheduleSub={setIsShowScheduleSub}
              isShowPostSub={isShowPostSub}
              setIsShowPostSub={setIsShowPostSub}
              toggleMobileNavOpen={toggleMobileNavOpen}
              toggleMobileNavSubMenu={toggleMobileNavSubMenu}
              handleLogout={handleLogout}
            />
            <CommonNavWebGNB
              isMobile={isMobile}
              isLogin={isLogin}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </header>
      <LoginModal
        setIsLogin={setIsLogin}
        toggleMobileNavOpen={toggleMobileNavOpen}
      />
      <RegistModal />
      <PopupRegistModal />
      <PersonalRegistModal />
      <ChangePWModal />
      <FindPWModal />
      <FindIDModal />
    </>
  );
}

const CommonNavLogo = () => {
  return (
    <div className="col-auto m-0 p-0 child-center">
      <div className="child-center justify-content-start">
        <Link to="/#mainTop">
          <div id="logo" className="m-0">
            <GBTSLogoSvg height="30px" />
          </div>
        </Link>
      </div>
    </div>
  );
};
const CommonNavMenuList = (props) => {
  const {
    isLogin,
    isMobile,
    isOpenMobileNav,
    isShowScheduleSub,
    setIsShowScheduleSub,
    isShowPostSub,
    setIsShowPostSub,
    toggleMobileNavOpen,
    toggleMobileNavSubMenu,
    handleLogout,
  } = props;
  return (
    <nav className="col m-0 p-0">
      <a
        className="cmn-toggle-switch cmn-toggle-switch__htx open_close mt-1"
        style={{ right: 0 }}
        onClick={(e) => {
          isMobile && toggleMobileNavOpen(e);
        }}
      >
        <span>Menu mobile</span>
      </a>

      <div className={`main-menu ${isOpenMobileNav}`}>
        <Link
          to="/"
          id="header_menu"
          onClick={(e) => {
            isMobile && toggleMobileNavOpen(e);
          }}
        >
          <GBTSLogoSvg height={"30px"} />
        </Link>
        <Link to="#" className="open_close" id="close_in">
          <i
            className="icon_set_1_icon-77"
            onClick={(e) => {
              isMobile && toggleMobileNavOpen(e);
            }}
          />
        </Link>

        <ul className="d-lg-flex flex-lg-row justify-content-lg-center">
          <li className="submenu text-lg-center px-lg-1">
            <Link
              to="/#mainService"
              id="show-submenu"
              className="show-submenu"
              onClick={(e) => {
                toggleMobileNavSubMenu(e, setIsShowScheduleSub);
              }}
            >
              스케줄 <i className="icon-down-open-mini mr-0" />
            </Link>
            <ul className={isShowScheduleSub}>
              <li>
                <Link
                  to="/ScheduleLCL"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  해상 LCL
                </Link>
              </li>
              <li>
                <Link
                  to="/ScheduleFCL"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  해상 FCL
                </Link>
              </li>
              <li>
                <Link
                  to="/ScheduleAIR"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  항공
                </Link>
              </li>
            </ul>
          </li>

          <li className="submenu text-lg-center px-lg-1">
            <Link
              to="/#mainService"
              id="show-submenu"
              className="show-submenu"
              onClick={(e) => {
                toggleMobileNavSubMenu(e, setIsShowScheduleSub);
              }}
            >
              서비스 안내 <i className="icon-down-open-mini mr-0" />
            </Link>
            <ul className={isShowScheduleSub}>
              <li className="submenu px-lg-1">
                <Link
                  to="/INFO"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  GBTS
                </Link>
              </li>
              <li className="submenu text-lg-center px-lg-1">
                <Link
                  to="/LogiTalkLanding"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  LOGITALK
                </Link>
              </li>
              <li className="submenu text-lg-center px-lg-1">
                <Link
                  to="/LogiWorkLanding"
                  id="show-submenu"
                  className="show-submenu"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  LOGIWORK
                </Link>
              </li>
            </ul>
          </li>

          <li className="submenu text-lg-center px-lg-1">
            <Link
              to="/storeMall"
              onClick={(e) => {
                isMobile && toggleMobileNavOpen(e);
              }}
            >
              화물창고
            </Link>
          </li>
          <li className="submenu text-lg-center px-lg-1">
            <a
              href="https://iamforwarder.gbts.co.kr/iamforwarder"
              target="_blank"
              onClick={(e) => {
                isMobile && toggleMobileNavOpen(e);
              }}
            >
              포워더 견적관리 서비스
            </a>
          </li>
          <li className="submenu text-lg-center px-lg-1">
            <a
              href="https://www.cardlogis.com/"
              target="_blank"
              onClick={(e) => {
                isMobile && toggleMobileNavOpen(e);
              }}
            >
              물류비 카드결제 서비스
            </a>
          </li>
          <li className="submenu text-lg-center px-lg-1">
            <Link
              to="/#services"
              id="show-submenu"
              className="show-submenu"
              onClick={(e) => {
                toggleMobileNavSubMenu(e, setIsShowPostSub);
              }}
            >
              부가 서비스 <i className="icon-down-open-mini" />
            </Link>
            <ul className={isShowPostSub}>
              <li>
                <Link
                  to="/Post/nt"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  공지사항
                </Link>
              </li>
              <li>
                <Link
                  to="/Post/ls"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  선사•항공사 공지사항
                </Link>
              </li>
              <li>
                <Link
                  to="/AdvertiseVideoCenter"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  홍보센터
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    oceanlook.openPopup();
                  }}
                >
                  선박위치 조회
                </Link>
              </li>
              <li>
                <Link
                  to="/Post/qa"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  Q&A 게시판
                </Link>
              </li>
              <li>
                <Link
                  to="/Post/pr"
                  onClick={(e) => {
                    isMobile && toggleMobileNavOpen(e);
                  }}
                >
                  추천선사 및 프로모션
                </Link>
              </li>
            </ul>
          </li>
          {isOpenMobileNav !== "" ? (
            isLogin ? (
              <>
                <li>
                  <Link
                    to="/LogiTalk"
                    target="_blank"
                    className="search-overlay-menu-btn cart_bt cursor_pointer text-decoration-none"
                  >
                    로지톡
                    <i>
                      <img
                        className="rounded-lg border"
                        src="/img/use_img/play_store_512.png"
                        alt="로지톡"
                        style={{ width: "1.75rem", height: "1.75rem" }}
                      />
                    </i>
                  </Link>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    href="/ERP"
                    style={{ lineHeight: 45 }}
                  >
                    <i className="icon-user" /> 관리페이지
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    href="/"
                    onClick={handleLogout}
                  >
                    <i className="icon-logout" /> 로그아웃
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a
                    className="text-decoration-none"
                    data-toggle="modal"
                    data-target="#loginModal"
                    href="#"
                  >
                    <i className="icon-login" /> 로그인
                  </a>
                </li>

                <li>
                  <a
                    className="text-decoration-none"
                    id="registModalBtn"
                    data-toggle="modal"
                    data-target="#popupRegistModal"
                    href="#"
                  >
                    <i className="icon-user-add-1" /> 회원가입
                  </a>
                </li>
              </>
            )
          ) : (
            ""
          )}
        </ul>
      </div>
    </nav>
  );
};

const CommonNavWebGNB = (props) => {
  const { isMobile, isLogin, handleLogout } = props;
  return (
    <div
      className={`col-auto m-0 p-0 child-center ${isMobile ? "d-none" : ""}`}
    >
      <ul className="d-flex flex-row align-items-center justify-content-end">
        {isLogin && (
          <>
            <li>
              <a
                href="/"
                onClick={handleLogout}
                className="transition-1 text-secondary font-weight-bold gnb-item"
              >
                로그아웃
              </a>
            </li>
            <li
              className="ml-1 pr-1 border-left border-secondary"
              style={{ height: "0.75rem" }}
            />
            <li>
              <a
                href="/ERP"
                className="transition-1 text-secondary font-weight-bold gnb-item"
              >
                관리페이지
              </a>
            </li>
          </>
        )}
        {!isLogin && (
          <>
            <li>
              <a
                data-toggle="modal"
                data-target="#loginModal"
                href="#"
                className="transition-1 text-secondary font-weight-bold gnb-item"
              >
                로그인
              </a>
            </li>

            <li
              className="ml-2 pr-2 border-left border-secondary"
              style={{ height: "1rem" }}
            />
            <li>
              <a
                id="registModalBtn"
                data-toggle="modal"
                data-target="#popupRegistModal"
                href="#"
                className="transition-1 text-secondary font-weight-bold gnb-item"
              >
                회원가입
              </a>
            </li>
          </>
        )}

        <li className="ml-2 d-flex flex-row align-items-center">
          <a
            className="text-freiscop overflow-hidden d-inline-flex flex-row align-items-center cursor_pointer slide-width"
            style={{ height: "1.75rem" }}
            onClick={() => {
              if (loginInfo) {
                window.open("/LogiTalk");
                return;
              }
              codeList.Modal.current.alert(
                "로그인 후 이용 가능한 서비스입니다."
              );
            }}
          >
            <img
              className="rounded-lg border"
              src="/img/use_img/play_store_512.png"
              alt="로지톡"
              style={{ width: "1.75rem", height: "1.75rem" }}
            />

            {/*{unreadCount > 0 && <strong>{unreadCount}</strong>}*/}
            <span className="font-weight-900 slide-width-hidden">
              <p className="pl-1" style={{ width: "3rem" }}>
                로지톡
              </p>
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};
